export enum CustomEventPersonType {
	VISITOR = "VISITOR",
	EXHIBITOR = "EXHIBITOR",
}

export enum CustomEventType {
	PERSON = "PERSON",
	ORGANIZATION = "ORGANIZATION",
}

export enum EventType {
	ATTENDEES = "ATTENDEES",
	EXHIBITORS = "EXHIBITORS",
	SPEAKERS = "SPEAKERS",
}
