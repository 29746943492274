import { z } from "zod";

export const LiProfileEntrySchema = z.object({
	id: z.number(),
	liProfileId: z.number(),
	url: z.string(),
});

export type LiProfileEntry = z.infer<typeof LiProfileEntrySchema>;

// export const LiProfilePostsEntrySchema = z.object({
// 	id: z.number(),
// 	liProfileId: z.number(),
// 	url: z.string(),
// });

// export type LiProfilePostsEntry = z.infer<typeof LiProfilePostsEntrySchema>;

// export const LiProfileCommentsEntrySchema = z.object({
// 	id: z.number(),
// 	liProfileId: z.number(),
// 	url: z.string(),
// });

// export type LiProfileCommentsEntry = z.infer<typeof LiProfileCommentsEntrySchema>;
