import { FunctionComponent, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

import { faBug, faSearch } from "@fortawesome/pro-regular-svg-icons";

import { classNames } from "@/utils/classNames";

import { ROUTE_CONFIG } from "@/routes/config";

import { authSelector, setAuth } from "@/pages/Public/redux/auth.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";

import { ReactComponent as ArrowDown } from "@assets/icons/topbar/arrow-down.svg";
import { ReactComponent as ArrowRightStartOnRectangle } from "@assets/icons/topbar/arrow-right-start-on-rectangle.svg";
import { ReactComponent as UserIcon } from "@assets/icons/topbar/user.svg";

import { clearLists } from "@/pages/Private/redux/list/list.slice";

import { User } from "@/pages/Public/pages/Login/schema/login";

import { ContextMenu } from "../ContextMenu/ContextMenu";
import { SvgIcon } from "../Icon/SvgIcon";
import { Icon } from "../Icon/Icon";
import { GlobalSearchModal } from "../Modals/GlobalSearchModal";

const { ACCOUNT } = ROUTE_CONFIG;

export const Topbar: FunctionComponent = () => {
	const { t } = useTranslation();

	const ts = (key: string) => t(`sidebar.${key}`);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const supportButton = useRef<HTMLButtonElement>(null);

	const { user } = useAppSelector(authSelector);

	const handleNavigateAccount = () => {
		navigate(ACCOUNT);
	};

	const initialUserAsString = localStorage.getItem("initialUser");

	let initialUser: { user: User } | null = null;
	const [showSearchModal, setShowSearchModal] = useState<boolean>(false);

	if (initialUserAsString) {
		initialUser = JSON.parse(initialUserAsString) as { user: User };
	}

	const resetInitialUser = () => {
		dispatch(setAuth(initialUser));
		dispatch(clearLists());
		localStorage.removeItem("initialUser");
		navigate(ROUTE_CONFIG.LISTS);
	};

	useEffect(() => {
		if (user) {
			Sentry.setUser({
				username: `${user.firstName} ${user.lastName}`,
				email: user.email,
			});

			const feedback = Sentry.feedbackIntegration({
				// Disable injecting the default widget
				autoInject: false,
				showBranding: false,
				onSubmitSuccess: () => {
					console.log("Feedback submitted successfully");
				},
			});

			try {
				feedback.remove();
				feedback.attachTo(supportButton.current as Element);
			} catch (e) {
				console.error("Error attaching feedback widget", e);
			}
		}
	}, [user]);

	return (
		<div className={classNames("flex-shrink-0 w-full flex flex-row justify-end items-center px-4")}>
			{initialUser && (
				<div className="absolute text-sm right-[480px] items-center">
					{initialUser?.user?.firstName} {initialUser?.user?.lastName} logged in as{" "}
					{user?.firstName} {user?.lastName} of <b>{user?.company?.name}</b>
				</div>
			)}
			<div className="absolute gap-2 flex-row flex cursor-pointer">
				<div
					className="relative min-w-[360px] h-[32px] p-0 input-styles pl-10 pr-8 peer flex items-center text-placeholder"
					onClick={() => {
						setShowSearchModal(true);
					}}
				>
					<div
						className={classNames(
							"absolute flex left-[14px] top-1 text-gray-500 peer-focus:text-brand-light"
						)}
					>
						<Icon className="mt-1" icon={faSearch} />
					</div>
					<span>{ts("globalSearch")}</span>
				</div>
				<button
					ref={supportButton}
					className="flex flex-row items-center w-fit ali"
					id="BUG_REPORT"
				>
					<span className="w-[26px] h-[26px] text-sm font-bold text-center text-white rounded-full flex items-center justify-center bg-brand-light p-1">
						<Icon icon={faBug} />
					</span>
				</button>
				<ContextMenu
					data={[
						[
							{
								title: "My Profile",
								icon: <SvgIcon className="w-[20px] h-[20px] text-gray-700" svgIcon={UserIcon} />,
								onClick: () => {
									handleNavigateAccount();
								},
							},
							{
								title: initialUser ? ts("back") : ts("logout"),
								icon: (
									<SvgIcon
										className="w-[20px] h-[20px] text-gray-700"
										svgIcon={ArrowRightStartOnRectangle}
									/>
								),
								onClick: () => (initialUser ? resetInitialUser() : navigate("/logout")),
							},
						],
					]}
					position="bottom-right"
					width="150"
				>
					<div className="flex flex-row items-center w-fit ali">
						<span className="w-[26px] h-[26px] text-xs text-center text-white rounded-full bg-brand-light leading-[26px]">
							{user?.firstName[0]}
							{user?.lastName[0]}
						</span>
						<SvgIcon className="w-[24px] h-[24px] text-gray-700" svgIcon={ArrowDown} />
					</div>
				</ContextMenu>
			</div>

			{showSearchModal && (
				<GlobalSearchModal isOpened={showSearchModal} setIsOpened={setShowSearchModal} />
			)}
		</div>
	);
};
