import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { PermissionCheck } from "@/components";

import { PermissionRoles } from "@/enum";

import { ButtonColor, ButtonSize } from "@/components/Button/types";

import { Button } from "@/components/Button/Button";

import { ROUTE_CONFIG } from "@/routes/config";

import { ApolloSearchesTable } from "../../components/ApolloSearchesTable";
import { CreateApolloModal } from "../../components/CreateApolloModal";

export const SavedSearches: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`prospectSearch.${key}`);

	const navigate = useNavigate();

	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<h3 className="mb-3">{ts("title")}</h3>
				</div>
				<div className="flex justify-end flex">
					<PermissionCheck requiredPermissions={[PermissionRoles.CREATE_APOLLO]}>
						<>
							<div className="mr-2">
								<Button
									color={ButtonColor.ACTION_SECONDARY}
									size={ButtonSize.S}
									testId="new-user-button"
									title={ts("create.button")}
									onClick={() => setIsInviteModalOpen(true)}
								/>
							</div>
							<div>
								<Button
									color={ButtonColor.ACTION}
									size={ButtonSize.S}
									testId="new-user-button"
									title={ts("searchButton")}
									onClick={() => navigate(ROUTE_CONFIG.APOLLO_SEARCH_PEOPLE)}
								/>
							</div>
						</>
					</PermissionCheck>
				</div>
			</div>

			{<ApolloSearchesTable />}

			{isInviteModalOpen && (
				<CreateApolloModal isOpen={isInviteModalOpen} setIsOpen={setIsInviteModalOpen} />
			)}
		</div>
	);
};
