import { Dispatch, SetStateAction, FunctionComponent, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Modal } from "@/components";

import { ListSourceType } from "@/enum/list.enum";

import { GeneralFilter, IFilter, LiEventFilter } from "../constans/general-filter";
import { MapsInboxAdditionalFilter } from "../constans/maps-filter";
import { JobsFilter } from "../constans/jobs-filter";

import { LiPostFilter } from "../constans/li-post-filter";
import { LiProfileFilter } from "../constans/li-profile-filter";
import { LiCompanyActivityFilter } from "../constans/li-company-activity-filter";
import { ListPerson } from "../../List/schema/list";
import { IFilterType } from "../../List/constans/general-filter";
import { useFilters } from "../utils/useFilters";

export interface IFilterWithValues {
	[key: string]: {
		value?:
			| string
			| number
			| {
					name: string;
					id: number;
			  }[]
			| {
					title: string;
					id: string;
			  }[]
			| {
					name: string;
					id: number;
			  };
		filterParam: string;
		type: IFilterType;
		customColumnId?: number;
	};
}

interface FilterModalProps {
	inboxData: ListPerson[];
	showFilterModal: boolean;
	setShowFilterModal: Dispatch<SetStateAction<boolean>>;
	filter?: IFilterWithValues;
	setFilter: Dispatch<SetStateAction<IFilterWithValues | undefined>>;
	listType?: ListSourceType;
}

export const FilterModal: FunctionComponent<FilterModalProps> = ({
	inboxData,
	showFilterModal,
	setShowFilterModal,
	filter,
	setFilter,
	listType,
}) => {
	const { t } = useTranslation();

	const { renderFilters, filterValue, setFilterValue, setFilterType } = useFilters({ filter });

	const onSubmit = () => {
		filterValue && setFilter(filterValue);
	};

	const resetFilter = () => {
		setFilterType({});
		setFilterValue({});
	};

	const FilterDataType = useMemo(() => {
		let FilterDataType: IFilter[] = [];

		if (listType === ListSourceType.LI_EVENTS) {
			FilterDataType = LiEventFilter;
		}

		if (listType === ListSourceType.MAPS) {
			FilterDataType = MapsInboxAdditionalFilter;
		}

		if (listType === ListSourceType.JOBS) {
			FilterDataType = JobsFilter;
		}

		if (listType === ListSourceType.LI_POST) {
			FilterDataType = LiPostFilter;
		}

		if (listType === ListSourceType.LI_PROFILE) {
			FilterDataType = LiProfileFilter;
		}

		if (listType === ListSourceType.LI_COMPANY_ACTIVITY) {
			FilterDataType = LiCompanyActivityFilter;
		}

		const foundPerson = inboxData.find((person) => person.prospect?.id);

		if (foundPerson) {
			FilterDataType = [...GeneralFilter, ...FilterDataType];
		}

		if (FilterDataType.length === 0) {
			FilterDataType = GeneralFilter;
		}

		return FilterDataType;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listType]);

	return (
		<Modal
			handleClear={() => resetFilter()}
			handleClose={() => setShowFilterModal(false)}
			handleSave={() => {
				onSubmit();
				setShowFilterModal(false);
			}}
			isOpened={showFilterModal}
			size="lg"
			submitButtonText={t("basics.confirm")}
			title={t("basics.filter")}
		>
			<div className="flex flex-col">{renderFilters(FilterDataType)}</div>
		</Modal>
	);
};
