import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/redux";

import { LiProfileState } from "@/types/liProfile";

import { liProfileApi } from "./liProfile.api";

const initialState: LiProfileState = {
	liProfiles: [],
};

export const liProfileSlice = createSlice({
	name: "liProfile",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			liProfileApi.endpoints.getLiProfiles.matchFulfilled,
			(state, { payload }) => {
				state.liProfiles = payload.data;
			}
		);
	},
});

export const {} = liProfileSlice.actions;
export const liProfileReducer = liProfileSlice.reducer;
export const liProfileSelector = (state: RootState) => state[liProfileSlice.name];
