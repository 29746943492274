export enum ApolloCounters {
	NONE = "NONE",
	IN_CURRENT = "IN_CURRENT",
	IN_OTHER = "IN_OTHER",
}

export enum ApolloType {
	SOURCE = "SOURCE",
	LEAD_FILTER = "LEAD_FILTER",
	ACCOUNT_FILTER = "ACCOUNT_FILTER",
	COUNTER = "COUNTER",
	LEAD_EXCLUSION = "LEAD_EXCLUSION",
}
