import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { CsvEnrichmentOverview } from "./pages/CsvEnrichmentOverview/CsvEnrichmentOverview";
import { CsvEnrichmentDetail } from "./pages/CsvEnrichmentDetail/CsvEnrichmentDetail";

export const CsvEnrichment: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<CsvEnrichmentOverview />} path="/" />
			<Route element={<CsvEnrichmentDetail />} path="/:id" />
		</Routes>
	);
};
