import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { SavedSearches } from "./pages/SavedSearches/SavedSearches";
import { Search } from "./pages/Search/Search";

export const Apollo: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<Search />} path="/:type" />
			<Route element={<Search />} path="/:type/:id" />
			<Route element={<SavedSearches />} path="/" />
		</Routes>
	);
};
