import { FunctionComponent } from "react";

import { classNames } from "@/utils/classNames";

interface CardProps {
	className?: string;
	children?: JSX.Element | JSX.Element[] | string;
}

export const CardContent: FunctionComponent<CardProps> = ({ className, children }) => {
	return <div className={classNames("p-2 pb-1 color-text text-xs", className)}>{children}</div>;
};
