import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardHeader, List, ListItem, Stack, TextField } from "@mui/material";

import { useParams } from "react-router-dom";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { format } from "date-fns";

import {
	useLazyGetApolloByIdQuery,
	useLazySearchApolloPeopleQuery,
} from "@/pages/Private/redux/apollo/apollo.api";

import { ApolloPeopleRequestParams } from "@/types/apollo";

import { ButtonColor, ButtonSize } from "@/components/Button/types";
import { Button } from "@/components/Button/Button";
import { BackLink } from "@/components/BackLink/BackLink";

import { InputField } from "@/components/InputField/InputField";

import { Card } from "@/components/Card/Card";

import { CardContent } from "@/components/CardContent/CardContent";

import { LoadingOverlay } from "@/components";

import { ApolloType } from "@/enum/apollo.enum";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { Radio } from "@/components/Radio/Radio";

import { ApolloIndustryTagAutocomplete } from "../../components/ApolloIndustryTagAutocomplete/ApolloIndustryTagAutocomplete";
import { TagAutocomplete } from "../../components/TagAutocomplete/TagAutocomplete";
import { ApolloPeopleResponse } from "../../schema/get-apollos";
import { ApolloTable } from "../../components/ApolloTable";
import { ApolloTagAutocomplete } from "../../components/ApolloTagAutocomplete/ApolloTagAutocomplete";
import { CreateApolloModal } from "../../components/CreateApolloModal";

export const SearchPeople: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();
	const ts = (key: string) => t(`prospectSearch.${key}`);

	const [filter, setFilter] = useState<string>("FromTo");
	const [timeFrameText, setTimeFrameText] = useState<number | null>(null);
	const [searchParams, setSearchParams] = useState<ApolloPeopleRequestParams>();
	const [fromEmployee, setFromEmployee] = useState<number | null>(null);
	const [tillEmployee, setTillEmployee] = useState<number | null>(null);
	const [results, setResults] = useState<ApolloPeopleResponse>();
	const [name, setName] = useState<string>();
	const [type, setType] = useState<ApolloType>();
	const [apolloSearchPeople, { isFetching }] = useLazySearchApolloPeopleQuery();
	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

	const [getApollo, { isLoading: fillLoading }] = useLazyGetApolloByIdQuery();

	useEffect(() => {
		if (id !== 0) {
			getApollo(+id).then((apolloResponse) => {
				if (apolloResponse.data) {
					setName(apolloResponse.data.name);
					setType(apolloResponse.data.type);

					setSearchParams(apolloResponse.data.config);
					const { organization_num_employees_ranges } = apolloResponse.data.config;

					if (organization_num_employees_ranges) {
						const [from, till] = organization_num_employees_ranges[0].split(",");

						if (from) {
							setFromEmployee(+from);
						}

						if (till) {
							setTillEmployee(+till);
						}
					}
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (!fromEmployee && !tillEmployee && searchParams?.organization_num_employees_ranges) {
			const { organization_num_employees_ranges, ...newSearchParams } = searchParams;

			setSearchParams({
				...newSearchParams,
			});
		} else if (!!fromEmployee || !!tillEmployee) {
			if (!searchParams?.organization_num_employees_ranges) {
				setSearchParams({
					...searchParams,
					organization_num_employees_ranges: [`${fromEmployee || ""}, ${tillEmployee || ""}`],
				});
			} else if (searchParams?.organization_num_employees_ranges) {
				const cmp: string[] = (
					searchParams?.organization_num_employees_ranges as string[]
				)[0]?.split(",");

				if (
					!cmp ||
					cmp[0].trim() !== (fromEmployee?.toString() || "") ||
					cmp[1].trim() !== (tillEmployee?.toString() || "")
				) {
					setSearchParams({
						...searchParams,
						organization_num_employees_ranges: [`${fromEmployee || ""}, ${tillEmployee || ""}`],
					});
				}
			}
		}
	}, [fromEmployee, searchParams, tillEmployee]);

	const handleSearch = async () => {
		if (searchParams) {
			const cRes = await apolloSearchPeople(searchParams);

			setResults(cRes.data);
		}
	};

	const CreateCheckboxForFilter: FunctionComponent<{
		text: string;
		value: string;
	}> = ({ text, value }) => (
		<Checkbox
			isChecked={
				(Array.isArray(searchParams?.organization_latest_funding_stage_cd) &&
					searchParams?.organization_latest_funding_stage_cd?.includes(value)) ??
				false
			}
			onChange={() => {
				let temp: string[] = Array.isArray(searchParams?.organization_latest_funding_stage_cd)
					? [...(searchParams?.organization_latest_funding_stage_cd ?? [])]
					: [];

				if (temp.includes(value)) {
					temp = temp.filter((item) => item !== value);
				} else {
					temp.push(value);
				}

				setSearchParams({
					...searchParams,
					organization_latest_funding_stage_cd: temp,
				});
			}}
		>
			<div>{text}</div>
		</Checkbox>
	);

	return (
		<div>
			<div className="sm:block flex flex-col items-start justify-between mb-1">
				<BackLink />
				<div className="flex items-end justify-between w-full mb-2">
					<div className="flex-grow">
						<h3 className="mb-3">{name ? name : ""} </h3>
						{type ? (
							<span className="text-xs text-gray-700">Type: {type.capitalizeString()}</span>
						) : (
							""
						)}
						{results ? (
							<>
								<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">
									{results.meta.total_entries} search results
								</p>
							</>
						) : (
							""
						)}
					</div>
				</div>
			</div>
			<Stack direction="row" justifyContent="flex-start">
				<Stack
					direction="column"
					justifyContent="flex-start"
					mb={4}
					mr={2}
					sx={{
						width: 250,
					}}
				>
					<Card>
						<CardContent>{"Search Terms"}</CardContent>
						<CardContent>
							<InputField
								handleChange={(event: ChangeEvent<HTMLInputElement>) => {
									if (event.target.value === "" && searchParams?.q_keywords) {
										const { q_keywords, ...newSearchParams } = searchParams;

										setSearchParams({
											...newSearchParams,
										});
									} else if (event.target.value) {
										setSearchParams({
											...searchParams,
											q_keywords: event.target.value,
										});
									}
								}}
								name={"q_keywords"}
								placeholder="Search terms"
								value={(searchParams?.q_keywords as string) || ""}
							/>
						</CardContent>
					</Card>
					<Card>
						<CardHeader
							subheader="Job Titles"
							sx={{ padding: 1 }}
							titleTypographyProps={{ variant: "h5" }}
						/>
						<CardContent>
							<List sx={{ padding: 0 }}>
								<ListItem disablePadding>
									<ApolloTagAutocomplete
										handleValueChange={(value) => {
											if (value.length > 0) {
												setSearchParams({
													...searchParams,
													person_titles: value.map((v) => v.name),
												});
											} else if (searchParams?.person_titles) {
												const { person_titles, ...newSearchParams } = searchParams;

												setSearchParams({
													...newSearchParams,
												});
											}
										}}
										kind="person_title"
										type="person_titles"
										value={(searchParams?.person_titles as unknown as string[]) || []}
									/>
								</ListItem>
								<ListItem disablePadding>
									<ApolloTagAutocomplete
										handleValueChange={(value) => {
											if (value.length > 0) {
												setSearchParams({
													...searchParams,
													person_not_titles: value.map((v) => v.name),
												});
											} else if (searchParams?.person_not_titles) {
												const { person_not_titles, ...newSearchParams } = searchParams;

												setSearchParams({
													...newSearchParams,
												});
											}
										}}
										kind="person_title"
										type="person_not_titles"
										value={(searchParams?.person_not_titles as unknown as string[]) || []}
									/>
								</ListItem>
								<ListItem disablePadding>
									<ApolloTagAutocomplete
										handleValueChange={(value) => {
											if (value.length > 0) {
												setSearchParams({
													...searchParams,
													person_seniorities: value.map((v) => v.name),
												});
											} else if (searchParams?.person_seniorities) {
												const { person_seniorities, ...newSearchParams } = searchParams;

												setSearchParams({
													...newSearchParams,
												});
											}
										}}
										kind="person_seniority"
										type="person_seniorities"
										value={(searchParams?.person_seniorities as unknown as string[]) || []}
									/>
								</ListItem>
								<ListItem disablePadding>
									<TextField
										hiddenLabel
										id="maxPerCompany"
										placeholder="Max per company"
										size="small"
										sx={{
											width: "100%",
										}}
										type="number"
										value={searchParams?.maxPerCompany || ""}
										variant="filled"
										onChange={(event) => {
											if (event.target.value) {
												setSearchParams({
													...searchParams,
													maxPerCompany: event.target.value,
												});
											} else if (searchParams?.maxPerCompany) {
												const { maxPerCompany, ...newSearchParams } = searchParams;

												setSearchParams({
													...newSearchParams,
												});
											}
										}}
									/>
								</ListItem>
							</List>
						</CardContent>
					</Card>
					<Card>
						<CardHeader
							subheader="Location"
							sx={{ padding: 1, paddingBottom: "0px !important" }}
							titleTypographyProps={{ variant: "overline" }}
						/>
						<CardContent>
							<List sx={{ padding: 0 }}>
								<ListItem disablePadding>
									<ApolloTagAutocomplete
										handleValueChange={(value) => {
											if (value.length > 0) {
												setSearchParams({
													...searchParams,
													person_locations: value.map((v) => v.name),
												});
											} else if (searchParams?.person_locations) {
												const { person_locations, ...newSearchParams } = searchParams;

												setSearchParams({
													...newSearchParams,
												});
											}
										}}
										kind="location"
										type="person_locations"
										value={(searchParams?.person_locations as unknown as string[]) || []}
									/>
								</ListItem>
								<ListItem disablePadding>
									<ApolloTagAutocomplete
										handleValueChange={(value) => {
											if (value.length > 0) {
												setSearchParams({
													...searchParams,
													organization_locations: value.map((v) => v.name),
												});
											} else if (searchParams?.organization_locations) {
												const { organization_locations, ...newSearchParams } = searchParams;

												setSearchParams({
													...newSearchParams,
												});
											}
										}}
										kind="location"
										type="organization_locations"
										value={(searchParams?.organization_locations as unknown as string[]) || []}
									/>
								</ListItem>
							</List>
						</CardContent>
					</Card>
					<Card>
						<CardHeader
							subheader="#Employees"
							sx={{ padding: 1, paddingBottom: "0px !important" }}
							titleTypographyProps={{ variant: "overline" }}
						/>
						<CardContent>
							<List sx={{ padding: 0 }}>
								<ListItem
									disablePadding
									sx={{
										width: "100%",
									}}
								>
									<Stack
										direction="row"
										justifyContent="space-between"
										sx={{
											width: "100%",
										}}
									>
										<TextField
											hiddenLabel
											id="employees_from"
											size="small"
											sx={{
												width: "45%",
											}}
											type="number"
											value={fromEmployee}
											variant="filled"
											onChange={(event) => {
												setFromEmployee(+event?.target?.value || null);
											}}
										/>
										<TextField
											hiddenLabel
											id="employees_till"
											size="small"
											sx={{
												width: "45%",
											}}
											type="number"
											value={tillEmployee}
											variant="filled"
											onChange={(event) => {
												setTillEmployee(+event?.target?.value || null);
											}}
										/>
									</Stack>
								</ListItem>
							</List>
						</CardContent>
					</Card>
					<Card>
						<CardHeader
							subheader="Funding"
							sx={{ padding: 1, paddingBottom: "0px !important" }}
							titleTypographyProps={{ variant: "overline" }}
						/>
						<CardContent>
							<List sx={{ padding: 0 }}>
								<ListItem
									disablePadding
									sx={{
										width: "100%",
									}}
								>
									<Stack
										direction="column"
										sx={{
											width: "100%",
										}}
									>
										<CreateCheckboxForFilter text="Seed" value="0" />
										<CreateCheckboxForFilter text="Angel" value="1" />
										<CreateCheckboxForFilter text="Venture (Round not Specified)" value="10" />
										<CreateCheckboxForFilter text="Series A" value="2" />
										<CreateCheckboxForFilter text="Series B" value="3" />
										<CreateCheckboxForFilter text="Series C" value="4" />
										<CreateCheckboxForFilter text="Series D" value="5" />
										<CreateCheckboxForFilter text="Series E" value="6" />
										<CreateCheckboxForFilter text="Series F" value="7" />
										<CreateCheckboxForFilter text="Debt Financing" value="13" />
										<CreateCheckboxForFilter text="Equity Crowdfunding" value="14" />
										<CreateCheckboxForFilter text="Convertible Note" value="15" />
										<CreateCheckboxForFilter text="Private Equity" value="11" />
										<CreateCheckboxForFilter text="Other" value="12" />
									</Stack>
								</ListItem>
								{filter === "FromTo" && (
									<ListItem
										disablePadding
										sx={{
											width: "100%",
											paddingY: 1,
										}}
									>
										<Stack
											direction="row"
											justifyContent="space-between"
											sx={{
												width: "100%",
											}}
										>
											{/*latest_funding_date_range: {max: "1_months_ago", min: "2024-02-04"} */}
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DatePicker
													label={ts("fromDate")}
													slotProps={{
														field: {
															readOnly: true,
														},
													}}
													sx={{
														width: "47%",
													}}
													onChange={(value) => {
														if (value !== null) {
															setSearchParams({
																...searchParams,
																latest_funding_date_range: {
																	...(searchParams?.latest_funding_date_range as unknown as {}),
																	max: format(value, "yyyy-MM-dd"),
																},
															});
														}
													}}
												/>
											</LocalizationProvider>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DatePicker
													label={ts("toDate")}
													slotProps={{
														field: {
															readOnly: true,
														},
													}}
													sx={{
														width: "47%",
													}}
													onChange={(value) => {
														if (value !== null) {
															setSearchParams({
																...searchParams,
																latest_funding_date_range: {
																	...(searchParams?.latest_funding_date_range as unknown as {}),
																	min: format(value, "yyyy-MM-dd"),
																},
															});
														}
													}}
												/>
											</LocalizationProvider>
										</Stack>
									</ListItem>
								)}
								{filter === "TimeFrame" && (
									<ListItem
										disablePadding
										sx={{
											width: "100%",
											paddingY: 1,
										}}
									>
										<Stack
											direction="row"
											sx={{
												width: "100%",
												display: "flex",
												alignItems: "center",
											}}
										>
											From
											<TextField
												hiddenLabel
												id="timeframe"
												size="small"
												sx={{
													width: "45%",
													paddingX: 1,
												}}
												type="number"
												value={timeFrameText}
												onChange={(event) => {
													setTimeFrameText(+event?.target?.value || null);
													setSearchParams({
														...searchParams,
														latest_funding_date_range: {
															max: `${+event?.target?.value}_months_ago`,
														},
													});
													// setInputText(+event?.target?.value || null);
												}}
											/>{" "}
											Months Ago
										</Stack>
									</ListItem>
								)}
								<ListItem
									disablePadding
									sx={{
										width: "100%",
										paddingY: 1,
									}}
								>
									<Stack
										direction="column"
										sx={{
											width: "100%",
										}}
									>
										<Radio
											isChecked={filter === "TimeFrame"}
											onChange={() => setFilter("TimeFrame")}
										>
											{ts("timeFrame")}
										</Radio>

										<Radio
											isChecked={filter === "FromTo"}
											onChange={() => {
												setFilter("FromTo");
												setSearchParams({
													...searchParams,
													latest_funding_date_range: {},
												});
												setTimeFrameText(null);
											}}
										>
											{ts("fromTo")}
										</Radio>
									</Stack>
								</ListItem>
							</List>
						</CardContent>
					</Card>
					<Card>
						<CardHeader
							subheader="Industry"
							sx={{ padding: 1, paddingBottom: "0px !important" }}
							titleTypographyProps={{ variant: "overline" }}
						/>
						<CardContent>
							<List sx={{ padding: 0 }}>
								<ListItem disablePadding>
									<ApolloIndustryTagAutocomplete
										handleValueChange={(value) => {
											if (value.length > 0) {
												setSearchParams({
													...searchParams,
													organization_industry_tag_ids: value.map((v) => v.id),
													industries: value,
												});
											} else if (searchParams?.organization_industry_tag_ids) {
												const { organization_industry_tag_ids, industries, ...newSearchParams } =
													searchParams;

												setSearchParams({
													...newSearchParams,
												});
											}
										}}
										kind="linkedin_industry"
										type="organization_industry_tag_ids"
										value={(searchParams?.industries as { id: string; name: string }[]) || []}
									/>
								</ListItem>
								<ListItem disablePadding>
									<TagAutocomplete
										handleValueChange={(value) => {
											if (value.length > 0) {
												setSearchParams({
													...searchParams,
													q_organization_keyword_tags: value,
												});
											} else if (searchParams?.q_organization_keyword_tags) {
												const { q_organization_keyword_tags, ...newSearchParams } = searchParams;

												setSearchParams({
													...newSearchParams,
												});
											}
										}}
										kind="q_organization_keyword_tags"
										type="q_organization_keyword_tags"
										value={(searchParams?.q_organization_keyword_tags as unknown as string[]) || []}
									/>
								</ListItem>
							</List>
						</CardContent>
					</Card>

					<div className="fixed bottom-[10px] w-[250px] bg-white z-10">
						<Stack mb={1}>
							<Button
								color={ButtonColor.ACTION}
								size={ButtonSize.S}
								testId="new-user-button"
								title={ts("search")}
								onClick={() => handleSearch()}
							/>
						</Stack>
						{results && (
							<Button
								color={ButtonColor.ACTION}
								disabled={!results}
								size={ButtonSize.S}
								testId="new-user-button"
								title={ts("save")}
								onClick={() => setIsInviteModalOpen(true)}
							/>
						)}
					</div>
				</Stack>
				<Stack direction="row" justifyContent="space-between" mb={4}>
					<Stack direction="column" justifyContent="flex-start" mb={4}>
						<>
							{isFetching && <LoadingOverlay />}
							{results && !isFetching && !fillLoading && (
								<ApolloTable data={results as ApolloPeopleResponse} handleSearch={handleSearch} />
							)}
						</>
					</Stack>
				</Stack>
			</Stack>
			{isInviteModalOpen && searchParams && (
				<CreateApolloModal
					amount={results?.meta.total_entries || 0}
					config={searchParams}
					id={id ? +id : undefined}
					isOpen={isInviteModalOpen}
					name={name}
					setIsOpen={setIsInviteModalOpen}
					type={type}
				/>
			)}
		</div>
	);
};
