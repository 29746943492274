import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Stack, TextField } from "@mui/material";

import { Chip } from "@material-tailwind/react";
import { format } from "date-fns";
import {
	faCalendarCircleUser,
	faCircleExclamation,
	faFile,
	faHandHoldingBox,
	faUserMagnifyingGlass,
} from "@fortawesome/pro-regular-svg-icons";

import { useNavigate, useSearchParams } from "react-router-dom";

import GoogleMapsIcon from "@assets/icons/google-maps.svg";

import LinkedinIcon from "@assets/icons/linkedin.svg";
import { ReactComponent as ThreeDots } from "@assets/icons/three-dots.svg";
import { ReactComponent as Plus } from "@assets/icons/plus.svg";
import { ReactComponent as MagnifyingGlass } from "@assets/icons/list-builder/magnifying-glass.svg";
import { ReactComponent as SquareCheck } from "@assets/icons/list-builder/square-check.svg";
import { ReactComponent as Square } from "@assets/icons/list-builder/square.svg";

import {
	useDeleteListMutation,
	useGetLeanListsQuery,
	useLazyDownloadSelectedListQuery,
	useResetListMutation,
	useUpdateListMutation,
} from "@/pages/Private/redux/list/list.api";

import { Icon, LoadingOverlay, Modal, PermissionCheck } from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import { Toggle } from "@/components/Toggle/Toggle";
import {
	ListProcessingStatus,
	ListRunningStatus,
	ListSourceType,
	ListStatus,
	UpdateType,
} from "@/enum/list.enum";

import { useStickyParams } from "@/utils/useStickyParams";

import { Button } from "@/components/Button/Button";

import { ButtonColor, ButtonSize } from "@/components/Button/types";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { Table, TableColumn } from "@/components/Table/Table";
import { listSelector, setListSorting } from "@/pages/Private/redux/list/list.slice";
import { Order, SortParams } from "@/types/types";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";

import { classNames } from "@/utils/classNames";
import { ContextMenu, MenuItem } from "@/components/ContextMenu/ContextMenu";

import { SvgIcon } from "@/components/Icon/SvgIcon";

import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";

import { Tooltip } from "@/components/Tooltip/Tooltip";

import { DownloadFile, List, UpdateList } from "../schema/list";
import { CreateListModal } from "./CreateListModal";
import { ListProcessingStatusChip } from "./ListProcessingStatusChip";

export const ListTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`list.${key}`), [t]);

	const [searchParams] = useSearchParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [showResetModal, setShowResetModal] = useState<boolean>(false);
	const [selectedList, setSelectedList] = useState<List | null>(null);
	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);
	const { searchValue, setSearchValue, query } = useSearch();
	const [deleteList] = useDeleteListMutation();
	const [resetList, { isLoading: resetListIsLoading }] = useResetListMutation();
	const [downloadSelectedList, { isFetching: downloadSelectedListIsLoading }] =
		useLazyDownloadSelectedListQuery();
	const [update, { isLoading: updateIsLoading }] = useUpdateListMutation();
	const handleSorting = (sortParams: SortParams) => dispatch(setListSorting(sortParams));
	const { sortingList, lists: data, listMeta } = useAppSelector(listSelector);

	const [selectedFiles, setSelectedFiles] = useState<DownloadFile[]>([]);
	const [selectAll, setSelectAll] = useState<boolean>(false);

	const [currentlyDownloadingSelected, setCurrentlyDownloadingSelected] = useState<boolean>(false);

	const [showOnlyRunning, setShowOnlyRunning] = useState<boolean | undefined>(undefined);
	const [selectedSourceType, setSelectedSourceType] = useState<AutoCompleteItem | undefined>(
		undefined
	);
	const sourceTypesList: AutoCompleteItem[] = [
		{
			id: "ALL",
			title: `${t("sidebar.all")}`,
		},
		{
			id: ListSourceType.PROSPECT_SEARCH,
			title: `${t("sidebar.searches")}`,
		},
		{
			id: ListSourceType.LI_EVENTS,
			title: `${t("sidebar.linkedinEvents")}`,
		},
		{
			id: ListSourceType.LI_PEOPLE,
			title: `${t("sidebar.linkedinPeopleSearch")}`,
		},
		{
			id: ListSourceType.LI_COMPANY_ACTIVITY,
			title: `${t("sidebar.linkedinCompanyActivity")}`,
		},
		{
			id: ListSourceType.LI_PROFILE,
			title: `${t("sidebar.linkedinProfile")}`,
		},
		{
			id: ListSourceType.CUSTOM_EVENTS,
			title: `${t("sidebar.customEvent")}`,
		},
		{
			id: ListSourceType.CSV_ENRICHMENTS,
			title: `${t("sidebar.csvEnrichment")}`,
		},
		{
			id: ListSourceType.JOBS,
			title: `${t("sidebar.jobs")}`,
		},
		{
			id: ListSourceType.MAPS,
			title: `${t("sidebar.maps")}`,
		},
		{
			id: ListSourceType.LI_POST,
			title: `${t("sidebar.linkedinPosts")}`,
		},
	];

	const [localLoading, setLocalLoading] = useState(false);
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (searchParams.has("list")) {
			const cListName = searchParams.get("list");

			if (cListName) {
				setSearchValue(cListName);
			}
		}
	}, [searchParams, setSearchValue]);

	useStickyParams("list", setSearchValue, searchValue);

	const [sortState, setSortState] = useState({ sortBy: "", sortOrder: 0 });

	useEffect(() => {
		if (sortingList.sortBy && sortingList.sortOrder) {
			let cSortOrder = 0;

			if (sortingList.sortOrder === Order.ASC) {
				cSortOrder = 1;
			}

			if (sortingList.sortOrder === Order.DESC) {
				cSortOrder = 2;
			}

			setSortState({
				sortBy: sortingList.sortBy,
				sortOrder: cSortOrder,
			});
		}
	}, [sortingList]);

	const { isLoading, isFetching } = useGetLeanListsQuery(
		{
			page,
			limit: 15,
			searchValue: query,
			active: showOnlyRunning,
			sourceType: selectedSourceType?.id !== "ALL" ? (selectedSourceType?.id as string) : undefined,
			sorting: sortingList,
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const [prevParams, setPrevParams] = useState({
		query,
		showOnlyRunning,
		selectedSourceType,
	});

	useEffect(() => {
		if (localLoading) {
			setLocalLoading(false);
		}

		if (data) {
			if (
				showOnlyRunning !== prevParams.showOnlyRunning ||
				selectedSourceType !== prevParams.selectedSourceType ||
				query !== prevParams.query
			) {
				setPage(1);
			}
		}

		setPrevParams({ query, showOnlyRunning, selectedSourceType });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, query]);

	const handleSelectFile = (file: DownloadFile) => {
		setSelectedFiles((prevSelectedFiles) => {
			const fileExists = prevSelectedFiles.some((selectedFile) => selectedFile.id === file.id);

			if (fileExists) {
				return prevSelectedFiles.filter((selectedFile) => selectedFile.id !== file.id);
			} else {
				return [...prevSelectedFiles, file];
			}
		});
	};

	const handleDownloadSelected = async (file?: DownloadFile[]) => {
		setCurrentlyDownloadingSelected(true);
		const blob = await downloadSelectedList({
			files: file ?? selectedFiles,
		}).unwrap();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");

		a.href = url;
		a.download = "listexport.xlsx";
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);

		setCurrentlyDownloadingSelected(false);
	};

	useEffect(() => {
		if (selectAll) {
			setSelectedFiles(data?.map((item) => ({ id: item.id, name: item.name })) || []);
		} else {
			setSelectedFiles([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAll, data]);

	const toggleSelectAll = () => {
		setSelectAll((prevSelectAll) => {
			if (prevSelectAll) {
				setSelectAll(false);

				return false;
			} else {
				setSelectAll(true);

				return true;
			}
		});
	};

	const handleToggle = useCallback(
		async (id: number, status: ListStatus) => {
			const updater: Partial<UpdateList> = {
				id: id,
				updateType: UpdateType.STATUS,
			};

			if (status === ListStatus.ACTIVE) {
				updater.status = ListStatus.PAUSED;
			} else if (status === ListStatus.PAUSED) {
				updater.status = ListStatus.ACTIVE;
			}

			await update({ ...updater }).unwrap();
		},
		[update]
	);

	const tableColumns: TableColumn[] = useMemo(() => {
		const columns: TableColumn[] = [
			{
				title: "",
				uid: "select",
				width: "52px",
				svgIcon: selectAll ? SquareCheck : Square,
				show: true,
				onHeaderClick: () => {
					toggleSelectAll();
				},
				render: (row: List, index?: number) => (
					<div className="group flex items-center">
						<div
							className={classNames(
								"w-[16px] text-center font-medium text-table-header",
								selectedFiles?.find((lf) => lf.id === row.id) ? "hidden" : "group-hover:hidden"
							)}
						>
							{typeof index !== "undefined" ? index + 1 : ""}
						</div>
						<Checkbox
							className={classNames(
								selectedFiles?.find((lf) => lf.id === row.id) ? "block" : "hidden group-hover:block"
							)}
							isChecked={!!selectedFiles?.find((lf) => lf.id === row.id)}
							name={"selected"}
							onChange={() => {
								handleSelectFile({ id: row.id, name: row.name });
							}}
						/>
					</div>
				),
			},
			{
				title: ts("status.title"),
				width: "100px",
				orderTitle: "status",
				orderStatus: sortState.sortBy === "status" ? sortState.sortOrder : 0,
				show: true,
				render: (row: List) => (
					<div>
						{row.sourceType === ListSourceType.CUSTOM_EVENTS &&
						(!row.csvEnrichments || row.csvEnrichments?.length === 0) ? (
							<Tooltip content={"Data not yet provided"}>
								<Icon className="text-status-info h-[22px]" icon={faCircleExclamation} />
							</Tooltip>
						) : (
							<Toggle
								isChecked={row?.status === ListStatus.ACTIVE}
								onChange={() => {
									if (row.id && row.status !== ListStatus.ERROR) {
										handleToggle(row.id, row.status);
									}
								}}
							/>
						)}
					</div>
				),
			},
			{
				title: ts("sourceType"),
				minWidth: "150px",
				uid: "source",
				orderTitle: "sourceType",
				orderStatus: sortState.sortBy === "sourceType" ? sortState.sortOrder : 0,
				show: true,
				render: (row: List) => (
					<div className="flex">
						{row?.sourceType === ListSourceType.PROSPECT_SEARCH ? (
							<div className="flex items-center">
								<Icon className="w-[18px] h-[18px] mr-1" icon={faUserMagnifyingGlass} />
								<span>Person</span>
							</div>
						) : (
							<></>
						)}
						{row?.sourceType === ListSourceType.LI_EVENTS ? (
							<div className="flex items-center">
								<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={LinkedinIcon} />
								<span>Events</span>
							</div>
						) : (
							<></>
						)}
						{row?.sourceType === ListSourceType.LI_PEOPLE ? (
							<div className="flex items-center">
								<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={LinkedinIcon} />
								<span>People</span>
							</div>
						) : (
							<></>
						)}
						{row?.sourceType === ListSourceType.MAPS ? (
							<div className="flex items-center">
								<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={GoogleMapsIcon} />
								<span>Maps</span>
							</div>
						) : (
							<></>
						)}
						{row?.sourceType === ListSourceType.LI_COMPANY_ACTIVITY ? (
							<div className="flex items-center">
								<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={LinkedinIcon} />
								<span>Company Tracker</span>
							</div>
						) : (
							<></>
						)}
						{row?.sourceType === ListSourceType.LI_PROFILE_VISITS ? (
							<div className="flex items-center">
								<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={LinkedinIcon} />
								<span>Profile Visits</span>
							</div>
						) : (
							<></>
						)}
						{row?.sourceType === ListSourceType.LI_PROFILE ? (
							<div className="flex items-center">
								<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={LinkedinIcon} />
								<span>Profile Tracker</span>
							</div>
						) : (
							<></>
						)}
						{row?.sourceType === ListSourceType.LI_POST ? (
							<div className="flex items-center">
								<img alt="LinkedinIcon" className="h-[18px] mr-1 rounded" src={LinkedinIcon} />
								<span>Post</span>
							</div>
						) : (
							<></>
						)}
						{row?.sourceType === ListSourceType.CUSTOM_EVENTS ? (
							<div className="flex items-center">
								<Icon className="w-[18px] h-[18px]  mr-1" icon={faCalendarCircleUser} />
								<span>Custom Event</span>
							</div>
						) : (
							<></>
						)}
						{row?.sourceType === ListSourceType.CSV_ENRICHMENTS ? (
							<div className="flex items-center">
								<Icon className="w-[18px] h-[18px]  mr-1" icon={faFile} />
								<span>CSV File</span>
							</div>
						) : (
							<></>
						)}
						{row?.sourceType === ListSourceType.JOBS ? (
							<div className="flex items-center">
								<Icon className="w-[18px] h-[18px]  mr-1" icon={faHandHoldingBox} />
								<span>{t(`sidebar.jobs`)}</span>
							</div>
						) : (
							<></>
						)}
					</div>
				),
			},
			{
				title: ts("name"),
				maxWidth: "330px",
				orderTitle: "name",
				orderStatus: sortState.sortBy === "name" ? sortState.sortOrder : 0,
				uid: "name",
				render: (row: List) => (
					<div className="flex">
						<Link href={`/app/lists/detail/${row?.id}`}>{row?.name}</Link>
					</div>
				),
			},
			{
				title: ts("processingStatus"),
				minWidth: "180px",
				orderTitle: "processingStatus",
				orderStatus: sortState.sortBy === "processingStatus" ? sortState.sortOrder : 0,
				uid: "processingStatus",
				render: (row: List) => (
					<div className="flex">
						<ListProcessingStatusChip processingStatus={row.processingStatus} />
					</div>
				),
			},
			{
				title: ts("newLeads"),
				width: "112px",
				orderTitle: "newLeads",
				orderStatus: sortState.sortBy === "newLeads" ? sortState.sortOrder : 0,
				uid: "newLeads",
				render: (row: List) => (
					<div className="flex">
						{row.found && row?.found - (row?.processed ?? 0) - (row?.savedForLater ?? 0) > 0 ? (
							<Chip
								className="bg-brand-light"
								color="blue"
								size="sm"
								value={`+${row?.found - (row?.processed ?? 0) - (row?.savedForLater ?? 0)}`}
							/>
						) : (
							""
						)}
					</div>
				),
			},
			{
				title: ts("savedForLater"),
				width: "140px",
				orderTitle: "savedForLater",
				orderStatus: sortState.sortBy === "savedForLater" ? sortState.sortOrder : 0,
				uid: "savedForLater",
				render: (row: List) => (
					<div className="flex">
						{row.savedForLater ? (
							<Chip className="bg-brand-light" color="blue" size="sm" value={row?.savedForLater} />
						) : (
							""
						)}
					</div>
				),
			},
			{
				title: ts("lastModifiedAt"),
				width: "140px",
				orderTitle: "updatedAt",
				orderStatus: sortState.sortBy === "updatedAt" ? sortState.sortOrder : 0,
				uid: "lastModifiedAt",
				render: (row: List) => (
					<div className="flex">
						<div className="text-sm">{format(new Date(row?.updatedAt), "dd.MM.yyyy HH:ii")}</div>
					</div>
				),
			},
			{
				title: ts("lastModifiedBy"),
				width: "200px",
				orderTitle: "updatedBy",
				orderStatus: sortState.sortBy === "updatedBy" ? sortState.sortOrder : 0,
				uid: "lastModifiedBy",
				render: (row: List) => (
					<div className="flex">
						{row.updatedBy && (
							<div className="flex flex-row w-fit items-center">
								<span className="text-xs mr-2 text-white rounded-full bg-brand-light p-1">
									{row.updatedBy?.firstName[0]}
									{row.updatedBy?.lastName[0]}
								</span>
								{row.updatedBy?.firstName} {row.updatedBy?.lastName}
							</div>
						)}
					</div>
				),
			},
			{
				title: "",
				minWidth: 56,
				render: (row: List) => {
					const options: MenuItem[] = [
						{
							title: ts("editList"),
							onClick: () => {
								navigate(`/app/lists/create/settings/${row.id}`);
							},
						},
						{
							title: ts("download"),
							onClick: () => {
								handleDownloadSelected([{ id: row.id, name: row.name }]);
							},
						},
						{
							title: ts("resetList"),
							permissions: [PermissionRoles.GLOBAL_ADMIN],
							disabled: [ListProcessingStatus.PROCESSING].includes(row.processingStatus),
							onClick: () => {
								handleResetModal(row);
							},
						},
						{
							title: ts("deleteList"),
							permissions: [PermissionRoles.LIST],
							disabled: [ListProcessingStatus.PROCESSING].includes(row.processingStatus),
							onClick: () => {
								handleDeleteModal(row);
							},
						},
					];

					return (
						<Stack direction="row" justifyContent="flex-end" spacing={2}>
							<ContextMenu data={[options]} position="bottom-right" width="150">
								<SvgIcon
									className="w-[20px] h-[20px] text-gray-700 cursor-pointer"
									svgIcon={ThreeDots}
								/>
							</ContextMenu>
						</Stack>
					);
				},
			},
		];

		return columns;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAll, ts, update, t, selectedFiles, handleToggle, toggleSelectAll]);

	const handleResetModal = (row: List) => {
		setShowResetModal(true);
		setSelectedList(row);
	};

	const handleDeleteModal = (row: List) => {
		setShowDeleteModal(true);
		setSelectedList(row);
	};

	const handleDeleteList = async () => {
		try {
			await deleteList(selectedList?.id ?? 0).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	const handleResetList = async () => {
		try {
			await resetList(selectedList?.id ?? 0).unwrap();
			setShowResetModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	const handleSelectStatus = (value: AutoCompleteItem) => {
		if (value.id === ListRunningStatus.ALL) {
			setShowOnlyRunning(undefined);
		} else if (value.id === ListRunningStatus.ACTIVE) {
			setShowOnlyRunning(true);
		} else if (value.id === ListRunningStatus.PAUSED) {
			setShowOnlyRunning(false);
		}
	};

	const handleFetchMore = () => {
		if (selectAll) {
			toggleSelectAll();
		}

		setPage(page + 1);
	};

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between  mb-8">
				<div className="flex items-end align-baseline justify-between w-1/2">
					<div className="flex-grow mr-4 ">
						<InputField
							handleChange={(e: ChangeEvent<HTMLInputElement>) => {
								setLocalLoading(true);
								setSearchValue(e.target.value);
							}}
							iconInside={<SvgIcon className="text-gray-500" svgIcon={MagnifyingGlass} />}
							name={"search"}
							placeholder={ts("search")}
							value={searchValue}
						/>
					</div>
				</div>
				<div className="flex items-center justify-end gap-2 mb-3">
					<Dropdown
						data={[
							{
								title: ts("activity.all"),
								id: ListRunningStatus.ALL,
							},
							{
								title: ts("activity.running"),
								id: ListRunningStatus.ACTIVE,
							},
							{
								title: ts("activity.stopped"),
								id: ListRunningStatus.PAUSED,
							},
						]}
						defaultValue={{
							title: ts("activity.all"),
							id: ListRunningStatus.ALL,
						}}
						floating={true}
						handleSelect={(value: AutoCompleteItem) => {
							handleSelectStatus(value);
						}}
						title={ts("status.title")}
						value={{
							title: getActivityStatusText(),
							id: getActiveStatus(),
						}}
					/>
					<Dropdown
						data={sourceTypesList}
						defaultValue={sourceTypesList[0]}
						floating={true}
						handleSelect={(value?: AutoCompleteItem) => {
							setSelectedSourceType(value);
						}}
						title={ts("sourceType")}
						value={{
							title: `${selectedSourceType?.title || ""} `,
							id: selectedSourceType?.title || ("" as string),
						}}
					/>
					<PermissionCheck requiredPermissions={[PermissionRoles.LIST]}>
						<Button
							color={ButtonColor.ACTION}
							image={<SvgIcon className="w-[16px] h-[16px] mr-2" svgIcon={Plus} />}
							size={ButtonSize.ML}
							testId="new-user-button"
							title={`New List`}
							onClick={() => navigate("/app/lists/create")}
						/>
					</PermissionCheck>
				</div>
			</div>

			{isLoading || updateIsLoading ? <LoadingOverlay /> : ""}
			<div className="relative w-full">
				<div className="pr-4 pb-[20px]">
					<Table
						columns={tableColumns}
						currentlyDownloadingSelected={
							(downloadSelectedListIsLoading && currentlyDownloadingSelected) || false
						} //for export
						data={data || []}
						handleExport={() => {
							handleDownloadSelected(); //for export
						}}
						handleFetchMore={() => {
							if (
								listMeta?.itemCount &&
								data.length < listMeta?.itemCount &&
								!isFetching &&
								page < listMeta.pageCount
							) {
								handleFetchMore();
							}
						}}
						handleSorting={(sortParams: SortParams) => {
							setLocalLoading(true);
							setPage(1);
							handleSorting(sortParams);
						}}
						hideColumnsCount={true}
						hidePagination={true}
						isFetching={isFetching}
						isLoading={isLoading || localLoading}
						overallCount={listMeta.itemCount}
						page={page}
						showExport={selectedFiles.length > 0}
						totalPages={listMeta.pageCount}
					/>
				</div>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteList}
					// isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<div className="text-sm text-gray-700 mb-4">{ts("delete.confirmation")}</div>
					<TextField
						fullWidth
						defaultValue={selectedList?.name}
						inputProps={{ readOnly: true }}
						label={ts("name")}
					/>
				</Modal>
			)}

			{showResetModal && (
				<Modal
					handleClose={() => setShowResetModal(false)}
					handleSave={handleResetList}
					isLoading={resetListIsLoading}
					isOpened={showResetModal}
					submitButtonColor="error"
					submitButtonText={t("basics.reset")}
					title={ts("reset.title")}
				>
					<div className="text-sm text-gray-700 mb-4">{ts("reset.confirmation")}</div>
					<TextField
						fullWidth
						defaultValue={selectedList?.name}
						inputProps={{ readOnly: true }}
						label={ts("name")}
					/>
				</Modal>
			)}

			{isInviteModalOpen && (
				<CreateListModal isOpen={isInviteModalOpen} setIsOpen={setIsInviteModalOpen} />
			)}
		</div>
	);

	function getActiveStatus(): string | number | null {
		if (showOnlyRunning) {
			return ListRunningStatus.ACTIVE;
		}

		if (showOnlyRunning === false) {
			return ListRunningStatus.PAUSED;
		}

		return ListRunningStatus.ALL;
	}

	function getActivityStatusText(): string {
		if (showOnlyRunning) {
			return ts("activity.running");
		}

		if (showOnlyRunning === false) {
			return ts("activity.stopped");
		}

		return ts("activity.all");
	}
};
