import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";

import { faCalendar, faCalendarCheck, faClose } from "@fortawesome/pro-regular-svg-icons";

import { format } from "date-fns";

import { ReactComponent as MagnifyingGlass } from "@assets/icons/list-builder/magnifying-glass.svg";

import {
	useAssignCsvEnrichmentToCustomEventMutation,
	useResetCustomEventMutation,
	useGetCustomEventsQuery,
} from "@/pages/Private/redux/customEvent/customEvent.api";

import { Icon, LoadingOverlay, Modal } from "@/components";
import { useSearch } from "@/utils";

import { InputField } from "@/components/InputField/InputField";

import { Table, TableColumn } from "@/components/Table/Table";
import { useBulkFunctions } from "@/utils/userBulkFunctions";

import { JobsScrapeStatus } from "@/enum/scrape-enum";
import { AutoCompleteItem as DropDownItem, Dropdown } from "@/components/Dropdown/Dropdown";

import { SvgIcon } from "@/components/Icon/SvgIcon";

import { useGetCsvEnrichmentsQuery } from "@/pages/Private/redux/csvEnrichment/csvEnrichment.api";
import { classNames } from "@/utils/classNames";

import { CustomEvent } from "../schema/customEvent";
import { LinkedinParams } from "../../LiPeople/components/LinkedinTagAutocomplete";

export const CustomEventTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`customEvent.${key}`), [t]);
	const [selectedCustomEvent, setSelectedCustomEvent] = useState<CustomEvent | null>(null);
	const [selectedAutoComplete, setSelectedAutoComplete] = useState<LinkedinParams[]>([]);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [showReassignmentModal, setShowReassignmentModal] = useState<boolean>(false);
	const [showClearModal, setShowClearModal] = useState<boolean>(false);
	const [resetCustomEvent] = useResetCustomEventMutation();
	const [confirmReset, setConfirmReset] = useState<string>("");

	const { setSearchValue: setCsvSearchValue, query: csvQuery } = useSearch();

	const { data: csvData, isLoading: csvIsLoading } = useGetCsvEnrichmentsQuery({
		page: 1,
		limit: 10,
		searchValue: csvQuery,
	});

	const [status, setStatus] = useState<DropDownItem | undefined>(undefined);
	const { selectAll, toggleSelectAll } = useBulkFunctions();

	const [assign, { isLoading: assignIsLoading }] = useAssignCsvEnrichmentToCustomEventMutation();

	const { data, isLoading, isFetching } = useGetCustomEventsQuery({
		page,
		limit,
		searchValue: query,
	});

	const handleFileSelection = (customEvent: CustomEvent) => {
		setSelectedCustomEvent(customEvent);
		setShowReassignmentModal(true);
	};

	const handleClearFiles = (customEvent: CustomEvent) => {
		setSelectedCustomEvent(customEvent);
		setShowClearModal(true);
	};

	const tableColumns: TableColumn[] = useMemo(() => {
		return [
			{
				title: "status",
				width: "fit-content",
				allowExclude: false,
				uid: "name",
				hideable: false,
				moveable: false,
				show: true,
				render: (row: CustomEvent) => (
					<div>
						{row?.status === JobsScrapeStatus.DRAFT && (
							<Icon className="text-status-info" icon={faCalendar} />
						)}
						{row?.status === JobsScrapeStatus.DONE && (
							<Icon className="text-status-success" icon={faCalendarCheck} />
						)}
					</div>
				),
			},
			{
				title: "name",
				width: "fit-content",
				allowExclude: false,
				uid: "name",
				hideable: false,
				moveable: false,
				show: true,
				render: (row: CustomEvent) => <div>{row?.name}</div>,
			},
			{
				title: "file",
				width: "fit-content",
				allowExclude: false,
				uid: "name",
				minWidth: "150px",
				hideable: false,
				moveable: false,
				show: true,
				render: (row: CustomEvent) => (
					<div>
						<div
							className={classNames(
								"!py-2 !h-[34px] items-center group text-sm pl-2 bg-brand-white border border-gray-200 rounded-xl  cursor-pointer flex justify-between",
								row?.csvEnrichments?.length > 0 ? "text-status-info " : "text-blue-action"
							)}
							onClick={() =>
								row?.csvEnrichments?.length === 0 ? handleFileSelection(row) : handleClearFiles(row)
							}
						>
							{row.csvEnrichments?.length === 0 ? "Select file" : row.csvEnrichments?.length}
							{row.csvEnrichments?.length > 0 && (
								<Icon
									className="ml-2 text-status-error hidden group-hover:inline-flex mr-2"
									icon={faClose}
								/>
							)}
						</div>
					</div>
				),
			},
			{
				title: ts("company"),
				width: "fit-content",
				allowExclude: false,
				uid: "company",
				hideable: false,
				moveable: false,
				show: true,
				render: (row: CustomEvent) => <div>{row?.company?.name}</div>,
			},
			{
				title: ts("eventType"),
				width: "fit-content",
				allowExclude: false,
				uid: "eventAt",
				hideable: false,
				moveable: false,
				show: true,
				render: (row: CustomEvent) => <div>{row?.eventType}</div>,
			},
			{
				title: ts("eventAt"),
				width: "fit-content",
				allowExclude: false,
				uid: "eventAt",
				hideable: false,
				moveable: false,
				show: true,
				render: (row: CustomEvent) => (
					<div>{row?.eventAt && format(new Date(row?.eventAt), "dd.MM.yyyy")}</div>
				),
			},
			{
				title: ts("listProvidedUntil"),
				width: "fit-content",
				allowExclude: false,
				uid: "eventAt",
				hideable: false,
				moveable: false,
				show: true,
				render: (row: CustomEvent) => (
					<div>
						{row?.listProvidedUntil && format(new Date(row?.listProvidedUntil), "dd.MM.yyyy")}
					</div>
				),
			},
			{
				title: ts("listProvidedAt"),
				width: "fit-content",
				allowExclude: false,
				uid: "eventAt",
				hideable: false,
				moveable: false,
				show: true,
				render: (row: CustomEvent) => (
					<div>{row?.listProvidedAt && format(new Date(row?.listProvidedAt), "dd.MM.yyyy")}</div>
				),
			},
		];
	}, [ts]);

	const handleChangeRowsPerPage = (newLimit: number) => {
		setLimit(newLimit);
		setPage(1);
	};

	const assignCsvToCustomEvent = async (ids: number[]) => {
		await assign({ customEventId: selectedCustomEvent?.id ?? 0, csvEnrichmentIds: ids }).unwrap();

		setSelectedCustomEvent(null);
		setSelectedAutoComplete([]);
	};

	const handleCloseClearModal = () => {
		setShowClearModal(false);
		setConfirmReset("");
	};

	const handleResetCustomEvent = async () => {
		try {
			await resetCustomEvent(selectedCustomEvent?.id ?? 0).unwrap();
			setConfirmReset("");
			setShowClearModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	return !data ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			{csvIsLoading && <LoadingOverlay />}
			<div className="flex items-end align-baseline justify-between  mb-8">
				<div className="flex items-end align-baseline justify-between w-1/2">
					<div className="flex-grow mr-4 ">
						<InputField
							handleChange={(e: ChangeEvent<HTMLInputElement>) => {
								setSearchValue(e.target.value);
							}}
							iconInside={<SvgIcon className="text-gray-500" svgIcon={MagnifyingGlass} />}
							name={"search"}
							placeholder={ts("search")}
							value={searchValue}
						/>
					</div>
				</div>
				<div className="flex items-center justify-end gap-2 mb-3">
					<Dropdown
						data={[
							{ title: "All", id: "" },
							{ title: "Done", id: JobsScrapeStatus.DONE },
							{ title: "Open", id: JobsScrapeStatus.DRAFT },
						]}
						defaultValue={{ title: "All", id: "" }}
						floating={true}
						handleSelect={(value?: DropDownItem) => {
							setStatus(value);
						}}
						title={ts("status")}
						value={{
							title: `${status?.title || ""} `,
							id: status?.title || ("" as string),
						}}
					/>
				</div>
			</div>
			<div className="relative w-full pr-0">
				<div className="pr-4 pb-[20px]">
					<Table
						columns={tableColumns}
						data={data.data || []}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						handleFetchPage={(page) => {
							if (selectAll) {
								toggleSelectAll();
							}

							setPage(page);
						}}
						hidePagination={false}
						isLoading={isFetching}
						limit={limit}
						overallCount={data.meta?.itemCount}
						page={page}
						totalPages={data.meta?.pageCount}
					/>
				</div>
			</div>
			{showClearModal && (
				<Modal
					disableSave={selectedCustomEvent?.name !== confirmReset}
					handleClose={handleCloseClearModal}
					handleSave={handleResetCustomEvent}
					isLoading={isLoading}
					isOpened={showClearModal}
					submitButtonColor="error"
					submitButtonText={t("basics.reset")}
					title={ts("reset.title")}
				>
					<div className="text-sm mb-4">
						Do you want to reset the following custom event? This will remove all assigned files.
					</div>

					<InputField isDisabled={true} name={"name"} value={selectedCustomEvent?.name || ""} />
					<div className="text-sm mb-4">
						To confirm the reset, please type the name of the custom event.
					</div>
					<InputField
						handleChange={(e) => setConfirmReset(e.target.value)}
						name="confirm"
						value={confirmReset}
					/>
				</Modal>
			)}
			{showReassignmentModal && (
				<Modal
					disableSave={selectedAutoComplete?.length === 0}
					handleClose={() => setShowReassignmentModal(false)}
					handleSave={async () => {
						if (selectedAutoComplete && selectedAutoComplete.length > 0) {
							await assignCsvToCustomEvent(
								selectedAutoComplete.map((selectedAutoComplete) =>
									parseInt(selectedAutoComplete.id)
								)
							);
							selectAll && toggleSelectAll();
							setShowReassignmentModal(false);
						}
					}}
					isLoading={assignIsLoading}
					isOpened={showReassignmentModal}
					size="md"
					submitButtonText={t("basics.confirm")}
					title={ts("reassign")}
				>
					<div className="mt-4 flex flex-col">
						<div className="text-sm mb-4">
							Please select the files that you want to assign to the custom event.
						</div>
						{csvData ? (
							<Autocomplete
								multiple
								defaultValue={[]}
								getOptionLabel={(option) => option.name}
								id={"csv_selection"}
								options={
									csvData?.data
										?.map((c) => {
											return {
												id: c.id.toString(),
												name: c.name,
											} as LinkedinParams;
										})
										.filter((csv) => {
											if (selectedAutoComplete?.map((s) => s.id)?.includes(csv.id)) {
												return false;
											}

											return true;
										}) || []
								}
								renderInput={(params) => (
									<TextField
										sx={{
											width: "100%",
											minHeight: 48,
											marginBottom: 1,
											"& .MuiFilledInput-root": {
												minHeight: 48,
												marginBottom: 0,
												borderRadius: 1.5,
												paddingTop: "10px",
												paddingBottom: "10px !important",
												paddingLeft: 1.5,
											},
										}}
										{...params}
									/>
								)}
								size="medium"
								sx={{
									width: "100%",
								}}
								onChange={(event, value) => {
									setSelectedAutoComplete(value);
								}}
								onInputChange={(event, newInputValue) => {
									setCsvSearchValue(newInputValue);
								}}
							/>
						) : (
							""
						)}
					</div>
				</Modal>
			)}
		</div>
	);
};
