import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	LiPost,
	LiPostResponse,
	LiPostResponseSchema,
	LiPostSchema,
} from "../../pages/LiPost/schema/liPost";
import {
	LiPostResultResponse,
	LiPostResultResponseSchema,
} from "../../pages/LiPost/schema/liPostResult";

export const liPostApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getLiPosts: builder.query<LiPostResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "liPost",
				params: {
					...(params && { ...params }),
				},
				responseSchema: LiPostResponseSchema,
			}),
			providesTags: ["LiPost"],
		}),
		getLiPost: builder.query<LiPost, number>({
			query: (id: number) => ({
				url: `liPost/${id}`,
				params: {
					id,
				},
				responseSchema: LiPostSchema,
			}),
			providesTags: ["LiPost"],
		}),
		getLiPostResults: builder.query<LiPostResultResponse, RequestPaginationParams & { id: number }>(
			{
				query: ({ id, ...params }) => ({
					url: `liPost/results/${id}`,
					params: {
						...(params && { ...params }),
					},
					responseSchema: LiPostResultResponseSchema,
				}),
				providesTags: ["LiPost"],
			}
		),
		createLiPost: builder.mutation<Partial<LiPost>, Partial<LiPost>>({
			query: (body) => ({
				url: `liPost`,
				method: "POST",
				body,
				responseSchema: LiPostSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "LiPost"];
				}

				return ["LiPost"];
			},
		}),
		updateLiPost: builder.mutation<Partial<LiPost>, Partial<LiPost>>({
			query: ({ id, ...body }) => ({
				url: `liPost/${id}`,
				method: "PATCH",
				body,
				responseSchema: LiPostSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "LiPost"];
				}

				return ["LiPost"];
			},
		}),
		toggleLiPost: builder.mutation<Partial<LiPost>, Partial<LiPost>>({
			query: ({ id, ...body }) => ({
				url: `liPost/${id}`,
				method: "PATCH",
				body,
				responseSchema: LiPostSchema,
			}),
			invalidatesTags: ["LiPost"],
		}),
		deleteLiPost: builder.mutation<Partial<LiPost>, { id: number; listId?: number }>({
			query: ({ id, listId }) => ({
				url: listId ? `liPost/${id}/list/${listId}` : `liPost/${id}`,
				method: "DELETE",
				responseSchema: LiPostSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "LiPost"];
				}

				return ["LiPost"];
			},
		}),
	}),
});

export const {
	useGetLiPostsQuery,
	useLazyGetLiPostsQuery,
	useGetLiPostQuery,
	useGetLiPostResultsQuery,
	useUpdateLiPostMutation,
	useDeleteLiPostMutation,
	useToggleLiPostMutation,
	useCreateLiPostMutation,
} = liPostApi;
export const accountApiReducer = liPostApi.reducer;
export const accountApiMiddleware = liPostApi.middleware;
