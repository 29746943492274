import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/system";

import { Modal } from "@/components";

import { useCreateCompaniesMutation } from "@/pages/Private/redux/companies/companies.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { Dropdown, AutoCompleteItem } from "@/components/Dropdown/Dropdown";
import { InputField } from "@/components/InputField/InputField";
import { YearlyOrMonthly } from "@/enum/company.enum";

import { CreateCompanies, CreateCompaniesSchema } from "../schema/companies";

interface createCompaniesModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateCompaniesModal: FunctionComponent<createCompaniesModalProps> = ({
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`companies.${key}`);
	const [createCompanies, { isLoading, error }] = useCreateCompaniesMutation();

	const {
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<CreateCompanies>({
		defaultValues: {
			name: undefined,
			linkedinUrl: undefined,
		},
		resolver: zodResolver(CreateCompaniesSchema),
	});

	const [currentFormState, setCurrentFormState] = useState<Partial<CreateCompanies>>({
		name: undefined,
		linkedinUrl: undefined,
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof CreateCompanies) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const onSubmit = async (values: CreateCompanies) => {
		try {
			await createCompanies(values).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<InputField
					error={!!errors.name?.message}
					handleChange={(e) => handleSaveFormState(e.target.value, "name")}
					label={ts("name")}
					name={"name"}
					placeholder={ts("name")}
					value={currentFormState.name ?? ""}
				/>

				<InputField
					error={!!errors.linkedinUrl?.message}
					handleChange={(e) => {
						handleSaveFormState(e.target.value, "linkedinUrl");
					}}
					label={ts("linkedinUrl")}
					name={"linkedinUrl"}
					placeholder={ts("linkedinUrl")}
					value={currentFormState.linkedinUrl || ""}
				/>

				<InputField
					error={!!errors.filtered?.message}
					handleChange={(e) => handleSaveFormState(+e.target.value, "filtered")}
					label={ts("credits")}
					name={"credits"}
					placeholder={ts("credits")}
					type="number"
					value={currentFormState.filtered || ""}
				/>

				<Dropdown
					data={[
						{
							title: "Monthly",
							id: YearlyOrMonthly.MONTHLY,
						},
						{
							title: "Yearly",
							id: YearlyOrMonthly.YEARLY,
						},
					]}
					floating={false}
					handleSelect={function (value?: AutoCompleteItem) {
						if (value?.id && value?.id !== currentFormState.yearlyOrMonthly) {
							handleSaveFormState(value?.id, "yearlyOrMonthly");
						}
					}}
					label="Type"
					value={
						currentFormState.yearlyOrMonthly
							? {
									title:
										currentFormState.yearlyOrMonthly === YearlyOrMonthly.MONTHLY
											? "Monthly"
											: "Yearly",
									id: currentFormState.yearlyOrMonthly,
							  }
							: undefined
					}
				/>
			</Stack>
			{getAllErrors(error, formErrors).length ? (
				<Box sx={{ mt: 2 }}>{renderErrorMessages(getAllErrors(error, formErrors))}</Box>
			) : null}
		</Modal>
	);
};
