import { FunctionComponent } from "react";

import { classNames } from "@/utils/classNames";

interface CardProps {
	className?: string;
	children?: JSX.Element | JSX.Element[] | string;
}

export const Card: FunctionComponent<CardProps> = ({ className, children }) => {
	return (
		<div
			className={classNames(
				"p-2 border-1 border-grey mb-2 bg-white rounded-lg shadow-md",
				className
			)}
		>
			{children}
		</div>
	);
};
