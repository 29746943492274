import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import { PeopleResponse, PeopleResponseSchema } from "../../pages/Apollo/schema/get-people";
import {
	OpenAi,
	OpenAiResponse,
	OpenAiResponseSchema,
	OpenAiSchema,
} from "../../pages/OpenAi/schema/openAi";
import { OpenAiPrompt, OpenAiPromptSchema } from "../../pages/OpenAi/schema/openAiPrompt";

export const openAiApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getOpenAis: builder.query<OpenAiResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "ai-enrichments",
				params: {
					...(params && { ...params }),
				},
				responseSchema: OpenAiResponseSchema,
			}),
			providesTags: ["OpenAi"],
		}),
		getOpenAiForScrape: builder.query<OpenAi, RequestPaginationParams & { id: number }>({
			query: (params) => ({
				url: `ai-enrichments/${params.id}`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: OpenAiSchema,
			}),
			providesTags: ["OpenAi"],
		}),
		getOpenAiAttendees: builder.query<PeopleResponse, RequestPaginationParams & { id: number }>({
			query: (params) => ({
				url: `ai-enrichments/${params.id}/attendees`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: PeopleResponseSchema,
			}),
			providesTags: ["People"],
		}),
		getOpenAi: builder.query<OpenAi, number>({
			query: (id: number) => ({
				url: `ai-enrichments/${id}`,
				params: {
					id,
				},
				responseSchema: OpenAiSchema,
			}),
			providesTags: ["OpenAi"],
		}),
		// getOpenAiById: builder.query<SavedOpenAiResponse, number>({
		// 	query: (id) => ({
		// 		url: `ai-enrichments/${id}`,
		// 		responseSchema: SavedOpenAiSchema,
		// 	}),
		// 	providesTags: [{ type: "OpenAis", id: "DETAIL" }],
		// }),
		triggerOpenAi: builder.mutation<void, number>({
			query: (id) => ({
				url: `ai-enrichments/trigger/${id}`,
				method: "POST",
			}),
			invalidatesTags: ["OpenAi"],
		}),
		createOpenAi: builder.mutation<Partial<OpenAi>, Partial<OpenAi>>({
			query: ({ name, aiEnrichmentTarget }) => ({
				url: `ai-enrichments`,
				method: "POST",
				body: {
					name,
					aiEnrichmentTarget,
				},
				responseSchema: OpenAiSchema,
			}),
			invalidatesTags: ["OpenAi"],
		}),
		updateOpenAi: builder.mutation<Partial<OpenAi>, Partial<OpenAi>>({
			query: ({ name, aiEnrichmentTarget, jsonResponse, id }) => ({
				url: `ai-enrichments/${id}`,
				method: "PATCH",
				body: {
					name,
					aiEnrichmentTarget,
					jsonResponse,
				},
				responseSchema: OpenAiSchema,
			}),
			invalidatesTags: ["OpenAi"],
		}),
		updatePrompt: builder.mutation<Partial<OpenAi>, Partial<OpenAiPrompt>>({
			query: ({ prompt, order, aiEnrichmentId, id, promptRole }) => ({
				url: `ai-enrichments/${aiEnrichmentId}/${id}`,
				method: "PATCH",
				body: {
					prompt,
					order,
					promptRole,
				},
				responseSchema: OpenAiPromptSchema,
			}),
			invalidatesTags: ["OpenAi"],
		}),
		createPrompt: builder.mutation<Partial<OpenAi>, Partial<OpenAiPrompt>>({
			query: ({ prompt, order, aiEnrichmentId, promptRole }) => ({
				url: `ai-enrichments/${aiEnrichmentId}`,
				method: "POST",
				body: {
					prompt,
					order,
					promptRole,
				},
				responseSchema: OpenAiPromptSchema,
			}),
			invalidatesTags: ["OpenAi"],
		}),
		deleteOpenAiPrompt: builder.mutation<Partial<OpenAi>, { id: number; promptId: number }>({
			query: ({ id, promptId }) => ({
				url: `ai-enrichments/${id}/${promptId}`,
				method: "DELETE",
				responseSchema: OpenAiSchema,
			}),
			invalidatesTags: ["OpenAi"],
		}),
		deleteOpenAi: builder.mutation<Partial<OpenAi>, number>({
			query: (id) => ({
				url: `ai-enrichments/${id}`,
				method: "DELETE",
				responseSchema: OpenAiSchema,
			}),
			invalidatesTags: ["OpenAi"],
		}),
	}),
});

export const {
	useGetOpenAisQuery,
	useLazyGetOpenAisQuery,
	useGetOpenAiQuery,
	useGetOpenAiForScrapeQuery,
	useLazyGetOpenAiForScrapeQuery,
	useGetOpenAiAttendeesQuery,
	useUpdateOpenAiMutation,
	useDeleteOpenAiMutation,
	useDeleteOpenAiPromptMutation,
	useTriggerOpenAiMutation,
	useCreateOpenAiMutation,
	useUpdatePromptMutation,
	useCreatePromptMutation,
} = openAiApi;
export const accountApiReducer = openAiApi.reducer;
export const accountApiMiddleware = openAiApi.middleware;
