import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { Radio } from "@/components/Radio/Radio";

import { Modal } from "@/components";

import { useUpdateMapMutation } from "@/pages/Private/redux/map/map.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { MapScrapeType } from "@/enum/map.enum";

import { CreateMap, CreateMapSchema, Map } from "../schema/map";
import { TagAutocomplete } from "../../Apollo/components/TagAutocomplete/TagAutocomplete";

interface CreateMapModalProps {
	map: Map;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditMapModal: FunctionComponent<CreateMapModalProps> = ({
	isOpen,
	setIsOpen,
	map,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`map.${key}`);
	const [update, { error, isLoading }] = useUpdateMapMutation();
	const [subtypes, setSubtypes] = useState<string[]>(map.subtypes || []);

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<CreateMap>({
		defaultValues: {
			name: map.name,
			query: map.query,
			limit: map.limit ?? undefined,
			lat: map.lat ?? undefined,
			lng: map.lng ?? undefined,
			region: map.region ?? undefined,
			language: map.language ?? undefined,
			zoom: map.zoom ?? undefined,
			mapScrapeType: map.mapScrapeType ?? undefined,
			repeatDaily: map.repeatDaily ?? false,
		},
		resolver: zodResolver(CreateMapSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: Partial<Map>) => {
		try {
			await update({ id: map.id, ...values, subtypes }).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			size="lg"
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>

				<TextField
					error={!!errors.query?.message}
					label={ts("query")}
					placeholder={ts("queryPlaceholder")}
					{...register("query")}
				/>

				<div className="flex flex-row">
					<div className="flex flex-col w-full mr-6">
						<TextField
							error={!!errors.limit?.message}
							label={ts("limit")}
							name="limit"
							placeholder={ts("limitPlaceholder")}
							type="number"
							value={getValues("limit") ?? ""}
							onChange={(e) => {
								if (e.target.value === "") {
									return setValue("limit", undefined, { shouldValidate: true });
								} else {
									setValue("limit", +e.target.value, { shouldValidate: true });
								}
							}}
						/>

						<TextField
							error={!!errors.lat?.message}
							label={ts("lat")}
							name="lat"
							placeholder={ts("latPlaceholder")}
							type="number"
							value={getValues("lat") ?? ""}
							onChange={(e) => {
								if (e.target.value === "") {
									return setValue("lat", undefined, { shouldValidate: true });
								} else {
									setValue("lat", +e.target.value, { shouldValidate: true });
								}
							}}
						/>

						<TextField
							error={!!errors.lng?.message}
							label={ts("lng")}
							name="lng"
							placeholder={ts("lngPlaceholder")}
							type="number"
							value={getValues("lng") ?? ""}
							onChange={(e) => {
								if (e.target.value === "") {
									return setValue("lng", undefined, { shouldValidate: true });
								} else {
									setValue("lng", +e.target.value, { shouldValidate: true });
								}
							}}
						/>

						<TextField
							error={!!errors.zoom?.message}
							label={ts("zoom")}
							name="zoom"
							placeholder={ts("zoomPlaceholder")}
							type="number"
							value={getValues("zoom") ?? ""}
							onChange={(e) => {
								if (e.target.value === "") {
									return setValue("zoom", undefined, { shouldValidate: true });
								} else {
									setValue("zoom", +e.target.value, { shouldValidate: true });
								}
							}}
						/>
					</div>

					<div className="flex flex-col w-full ml-6">
						<TextField
							error={!!errors.region?.message}
							label={ts("region")}
							placeholder={ts("regionPlaceholder")}
							{...register("region")}
						/>

						<TextField
							error={!!errors.language?.message}
							label={ts("language")}
							placeholder={ts("languagePlaceholder")}
							{...register("language")}
						/>

						<TagAutocomplete
							handleValueChange={(value) => {
								setSubtypes(value.map((v) => v));
							}}
							kind="subtypes"
							type="subtypes"
							value={subtypes && subtypes?.length > 0 ? subtypes : undefined}
						/>
						<label>
							<p className="text-xs">
								{ts("moreInformation")}{" "}
								<Link
									href="https://daltonluka.com/blog/google-my-business-categories"
									target="_blank"
								>
									https://daltonluka.com/blog/google-my-business-categories
								</Link>
							</p>
						</label>
					</div>
				</div>
				<div className="flex flex-col">
					<div className="text-sm font-bold mb-2">{ts("scrapeLevel")}</div>
					<Radio
						className="mb-8"
						isChecked={getValues("mapScrapeType") === MapScrapeType.GET_INFO_AT}
						name="getInfoAt"
						value="getInfoAt"
						onChange={() =>
							setValue("mapScrapeType", MapScrapeType.GET_INFO_AT, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("getInfoAt")}
					</Radio>
					<Radio
						className="mb-8"
						isChecked={getValues("mapScrapeType") === MapScrapeType.GET_MANAGING_DIRECTOR}
						name="getManagingDirector"
						value="getManagingDirector"
						onChange={() =>
							setValue("mapScrapeType", MapScrapeType.GET_MANAGING_DIRECTOR, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("getManagingDirector")}
					</Radio>
					<Radio
						className="mb-8"
						isChecked={getValues("mapScrapeType") === MapScrapeType.GET_ENRICHMENT}
						name="getEnrichment"
						value="getEnrichment"
						onChange={() =>
							setValue("mapScrapeType", MapScrapeType.GET_ENRICHMENT, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("getEnrichment")}
					</Radio>
				</div>
				<div className="flex flex-col">
					<div className="text-sm font-bold mb-2">{ts("scrapeDaily")}</div>
					<Checkbox
						className="mb-8"
						isChecked={getValues("repeatDaily")}
						onChange={() =>
							setValue("repeatDaily", !getValues("repeatDaily"), {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("repeatDaily")}
					</Checkbox>
				</div>
				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</Stack>
		</Modal>
	);
};
