/* eslint-disable @typescript-eslint/no-explicit-any */
import { flatten } from "flat";

import { Order, SortParams } from "@/types/types";

import { ListPerson } from "../../List/schema/list";

export const replaceWithRealValues = (
	value: any | undefined,
	inboxItem: ListPerson,
	suggestions: string[]
) => {
	if (!value) {
		return "";
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const newVal = value?.at(0)?.children?.reduce((collect: any[], c: any) => {
		return [...collect, c];
	}, []);

	if (!newVal) {
		return "";
	}

	let textValue = newVal
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		?.map((n: any) => {
			let rtn = "";

			if (!rtn && n.text) {
				rtn = n.text;
			}

			if (!rtn && n.character !== undefined) {
				rtn = `{${n.character}}`;
			}

			return rtn;
		})
		.join("");

	for (const suggestion of suggestions) {
		if (textValue.includes(`{${suggestion}}`)) {
			const objectPath = suggestion.split("_");

			let realValue = inboxItem;

			do {
				const path = objectPath.shift() as keyof typeof realValue;

				if (path && realValue[path]) {
					if (Array.isArray(realValue[path])) {
						realValue = realValue[path]?.at(0);
					} else {
						realValue = realValue[path];
					}
				}
			} while (objectPath.length);

			textValue = textValue.replaceAll(`{${suggestion}}`, realValue as unknown as string);
		}
	}

	return textValue;
};

export const getSuggestions = (inboxItem: ListPerson) => {
	const mySuggestions: any = {
		prospect: inboxItem.prospect,
	};

	if (inboxItem.liProfileEntryResult) {
		mySuggestions.liProfileEntryResult = {
			content: inboxItem.liProfileEntryResult?.content,
			contentUrl: inboxItem.liProfileEntryResult?.contentUrl,
			contentResponse: inboxItem.liProfileEntryResult?.contentResponse,
			contentResponseUrl: inboxItem.liProfileEntryResult?.contentResponseUrl,
			contentDate: inboxItem.liProfileEntryResult?.contentDate,
		};
	}

	if (inboxItem.liPostResult) {
		mySuggestions.liPostResult = {
			content: inboxItem.liPostResult?.content,
			contentUrl: inboxItem.liPostResult?.contentUrl,
			contentDate: inboxItem.liPostResult?.contentDate,
			likes: inboxItem.liPostResult?.likes,
			comments: inboxItem.liPostResult?.comments,
			shares: inboxItem.liPostResult?.shares,
		};
	}

	if (inboxItem.liEventEntry) {
		mySuggestions.liEventEntry = inboxItem.liEventEntry;
	}

	if (inboxItem.liCompanyActivityEntryResult) {
		mySuggestions.liCompanyActivityEntryResult = {
			content: inboxItem.liCompanyActivityEntryResult?.content,
			contentUrl: inboxItem.liCompanyActivityEntryResult?.contentUrl,
			contentResponse: inboxItem.liCompanyActivityEntryResult?.contentResponse,
			contentResponseUrl: inboxItem.liCompanyActivityEntryResult?.contentResponseUrl,
			contentDate: inboxItem.liCompanyActivityEntryResult?.contentDate,
		};
	}

	if (inboxItem.offer) {
		mySuggestions.offer = inboxItem.offer;
	}

	if (inboxItem.mapBusiness) {
		mySuggestions.mapBusiness = inboxItem.mapBusiness;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const res: any = flatten(mySuggestions, {
		delimiter: "_",
	});

	return Object.keys(res).filter((key) => !Array.isArray(res[key]));
};

export const getOrderStatus = (sortingInbox: SortParams, orderTitle: string) => {
	const hasCurrentSorting = sortingInbox.sortBy === orderTitle;

	let orderStatus = 0;

	if (hasCurrentSorting && sortingInbox.sortOrder === Order.ASC) {
		orderStatus = 1;
	}

	if (hasCurrentSorting && sortingInbox.sortOrder === Order.DESC) {
		orderStatus = 2;
	}

	return orderStatus;
};
