import { FunctionComponent } from "react";

import { UsageTable } from "../components/UsageTable";

export const UsageOverview: FunctionComponent = () => {
	return (
		<>
			<UsageTable />
		</>
	);
};
