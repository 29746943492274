import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import { RequestsFilterType } from "@/enum/requests.enum";

import { RequestsResponse, RequestsResponseSchema } from "../../pages/Request/schema/requests";

export const requestsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getRequests: builder.query<
			RequestsResponse,
			RequestPaginationParams & { filterType?: RequestsFilterType } & { id?: number; type?: string }
		>({
			query: ({ id, type, filterType, ...params }) => ({
				url: id && type ? `request/${type}/${id}` : "request",
				params: {
					...(params && { ...params }),
					...(filterType && { filterType }),
				},
				responseSchema: RequestsResponseSchema,
			}),
			providesTags: ["Requests"],
		}),
		// getRequestByType: builder.query<Requests, number>({
		// 	query: (id: number) => ({
		// 		url: `request/${id}`,
		// 		params: {
		// 			id,
		// 		},
		// 		responseSchema: RequestsSchema,
		// 	}),
		// 	providesTags: ["Requests"],
		// }),
	}),
});

export const { useGetRequestsQuery } = requestsApi;
export const accountApiReducer = requestsApi.reducer;
export const accountApiMiddleware = requestsApi.middleware;
