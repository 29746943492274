import { createSlice } from "@reduxjs/toolkit";

import { BlacklistState } from "@/types/blacklist";

import { RootState } from "@/redux";

import { blacklistApi } from "./blacklist.api";

const initialState: BlacklistState = {
	blacklists: [],
};

export const blacklistSlice = createSlice({
	name: "blacklist",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			blacklistApi.endpoints.getBlacklists.matchFulfilled,
			(state, { payload }) => {
				state.blacklists = payload.data;
			}
		);
	},
});

export const {} = blacklistSlice.actions;
export const blacklistReducer = blacklistSlice.reducer;
export const blacklistSelector = (state: RootState) => state[blacklistSlice.name];
