import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { LiCompanyActivityOverview } from "./pages/LiCompanyActivityOverview/LiCompanyActivityOverview";
import { LiCompanyActivityDetail } from "./pages/LiCompanyActivityDetail/LiCompanyActvityDetail";

export const LiCompanyActivity: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<LiCompanyActivityOverview />} path="/" />
			<Route element={<LiCompanyActivityDetail />} path="/:id" />
		</Routes>
	);
};
