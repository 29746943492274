import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { useUpdateMeMutation } from "@/pages/Private/redux/account/account.api";

import { ToggleItem } from "./ToggleItem";

export const LinkedinAutomation: FunctionComponent<{ autoAccept: boolean }> = ({ autoAccept }) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`account.${key}`);

	const [updateMe, { isLoading }] = useUpdateMeMutation();

	const handle2FactAuthChange = async () => {
		await updateMe({ autoAccept: !autoAccept }).unwrap();
	};

	return (
		<ToggleItem
			description={ts("enableAutoAcceptDescription")}
			isLoading={isLoading}
			label={ts("enableAutoAccept")}
			name={ts("linkedinAutomation")}
			value={autoAccept}
			onChange={handle2FactAuthChange}
		/>
	);
};
