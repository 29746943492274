import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Stack, TextField, Typography } from "@mui/material";

import { faArrowUpRightFromSquare, faPen, faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import {
	useDeleteLiProfileMutation,
	useGetLiProfilesQuery,
	useToggleLiProfileMutation,
} from "@/pages/Private/redux/liProfile/liProfile.api";

import { Toggle } from "@/components/Toggle/Toggle";
import { ListStatus } from "@/enum/list.enum";

import { LiProfile } from "../schema/liProfile";
import { UpdateLiProfileModal } from "./UpdateLiProfileModal";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LiProfileTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`liProfile.${key}`), [t]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [selectedLiProfile, setSelectedLiProfile] = useState<LiProfile | null>(null);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [deleteLiProfile] = useDeleteLiProfileMutation();
	const [toggleLiProfile] = useToggleLiProfileMutation();

	const { data, isLoading } = useGetLiProfilesQuery({
		page,
		limit,
		searchValue: query,
	});

	const columns: TableColumn<LiProfile>[] = useMemo(() => {
		const handleToggle = async (id: number, status: ListStatus) => {
			const updater: Partial<LiProfile> = {
				id: id,
			};

			if (status === ListStatus.ACTIVE) {
				updater.activeStatus = ListStatus.PAUSED;
			} else if (status === ListStatus.PAUSED) {
				updater.activeStatus = ListStatus.ACTIVE;
			}

			await toggleLiProfile({ ...updater }).unwrap();
		};

		return [
			{
				label: "",
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								<Toggle
									isChecked={row?.activeStatus === ListStatus.ACTIVE}
									onChange={() => {
										if (row.id && row.activeStatus !== ListStatus.ERROR) {
											handleToggle(row.id, row.activeStatus);
										}
									}}
								/>
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Link href={`/app/linkedin-profile/${row?.id}`}>
								<Typography color="inherit" mb={0} variant="body2">
									{row?.name}
								</Typography>
							</Link>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("urlTitle"),
				minWidth: 200,
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<div className="flex">
							<Link className="!mr-1 inline-flex" href={`${row.url}`} target="_blank">
								<Icon icon={faArrowUpRightFromSquare} size="xl" />
							</Link>
						</div>
					</Stack>
				),
			},
			{
				label: ts("type"),
				minWidth: 200,
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Typography color="inherit" mb={0} variant="body2">
							{row?.type}
						</Typography>
					</Stack>
				),
			},
			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<PermissionCheck requiredPermissions={[PermissionRoles.LI_PROFILE]}>
							<>
								<Box
									sx={{
										color: "neutral.500",
										"&:hover": {
											color: "primary.main",
											cursor: "pointer",
										},
									}}
									onClick={() => handleEditModal(row)}
								>
									<Icon icon={faPen} size="xl" />
								</Box>
								<Box
									sx={{
										color: "neutral.500",
										"&:hover": {
											color: "primary.main",
											cursor: "pointer",
										},
									}}
									onClick={() => handleDeleteModal(row)}
								>
									<Icon icon={faTrashCan} size="xl" />
								</Box>
							</>
						</PermissionCheck>
					</Stack>
				),
			},
		];
	}, [toggleLiProfile, ts]);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	const handleDeleteModal = (row: LiProfile) => {
		setShowDeleteModal(true);
		setSelectedLiProfile(row);
	};

	const handleEditModal = (row: LiProfile) => {
		setShowEditModal(true);
		setSelectedLiProfile(row);
	};

	const handleDeleteLiProfile = async () => {
		try {
			await deleteLiProfile({ id: selectedLiProfile?.id ?? 0 }).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	return !data ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteLiProfile}
					isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedLiProfile?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}
			{showEditModal && selectedLiProfile && (
				<UpdateLiProfileModal
					isOpen={showEditModal}
					liProfile={selectedLiProfile}
					setIsOpen={setShowEditModal}
				/>
			)}
		</div>
	);
};
