import { ChangeEvent, FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import { Tab } from "@mui/material";

import { useParams } from "react-router-dom";

import { useHasPermissions } from "@/utils/useHasPermissions";
import { PermissionRoles } from "@/enum";
import { PermissionCheck } from "@/components";

import { RequestsTable } from "@/pages/Private/pages/Request/components/RequestsTable";
import { BackLink } from "@/components/BackLink/BackLink";

import { CsvEnrichmentResultsTable } from "../../components/CsvEnrichmentResultsTable";

enum Tabs {
	RESULTS = "RESULTS",
	COSTS = "COSTS",
}

export const CsvEnrichmentDetail: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`csvEnrichment.${key}`);
	const { id = 0 } = useParams();
	const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.RESULTS);

	const { hasPermissions } = useHasPermissions();

	let tabs = [
		{ label: ts("tabs.results"), value: Tabs.RESULTS, permission: PermissionRoles.JOB },
		{ label: ts("tabs.costs"), value: Tabs.COSTS, permission: PermissionRoles.GLOBAL_ADMIN },
	];

	tabs = tabs.filter((tab) => hasPermissions([tab.permission]));

	const handleTabsChange = useCallback((_: ChangeEvent<{}>, value: Tabs): void => {
		setCurrentTab(value);
	}, []);

	return (
		<>
			<BackLink />
			<TabContext value={currentTab}>
				<TabList aria-label="Account tabs" sx={{ mb: 4 }} onChange={handleTabsChange}>
					{tabs.map((tab, index) => (
						<Tab key={index} label={tab.label} value={tab.value} />
					))}
				</TabList>

				<TabPanel sx={{ p: 0 }} value={Tabs.RESULTS}>
					<div className="sm:block flex flex-col items-start justify-between mb-6">
						{<CsvEnrichmentResultsTable />}
					</div>
				</TabPanel>
				<PermissionCheck requiredPermissions={[PermissionRoles.GLOBAL_ADMIN]}>
					<>
						<TabPanel sx={{ p: 0 }} value={Tabs.COSTS}>
							<RequestsTable id={+id} type={"csvEnrichment"} />
						</TabPanel>
					</>
				</PermissionCheck>
			</TabContext>
		</>
	);
};
