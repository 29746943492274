import {
	ChangeEvent,
	Dispatch,
	FunctionComponent,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import { faSearch } from "@fortawesome/pro-regular-svg-icons";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import { Tab, debounce } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { skipToken } from "@reduxjs/toolkit/dist/query";

import { classNames } from "@/utils/classNames";

import { useSearch } from "@/utils";

import { ReactComponent as ListBullet } from "@assets/icons/sidebar/listBullet.svg";

import { useGetListPersonQuery } from "@/pages/Private/redux/list/list.api";

import { ListPerson } from "@/pages/Private/pages/List/schema/list";

import { ListPersonStatus } from "@/pages/Private/pages/List/components/ListPersonStatus";

import { Modal } from "./Modal";
import { SearchField } from "../SearchField/SearchField";
import { Icon } from "../Icon/Icon";
import { TabChip } from "../TabChip/TabChip";
import { Loader } from "../Loader/Loader";

import { Spinner } from "../Spinner/Spinner";
import { SvgIcon } from "../Icon/SvgIcon";

interface GlobalSearchModalProps {
	isOpened: boolean;
	setIsOpened: Dispatch<SetStateAction<boolean>>;
}

enum Tabs {
	PROSPECTS = "PROSPECTS",
}

export const GlobalSearchModal: FunctionComponent<GlobalSearchModalProps> = ({
	isOpened,
	setIsOpened,
}) => {
	const { t } = useTranslation();

	const ts = (key: string) => t(`sidebar.${key}`);

	const navigate = useNavigate();
	const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.PROSPECTS);
	const { limit, searchValue, setSearchValue } = useSearch();
	const [page, setPage] = useState(1);
	const [listData, setListData] = useState<ListPerson[]>([]);
	const [listLoading, setListLoading] = useState(false);
	const [search, setSearch] = useState("");

	const { data, isLoading, isFetching } = useGetListPersonQuery(
		searchValue.length >= 2 ? { page: page, limit: limit, searchValue: searchValue } : skipToken
	);

	const tabs = [
		{
			label: ts("tabs.prospects"),
			value: Tabs.PROSPECTS,
		},
	];

	useEffect(() => {
		if (data) {
			setListData((prevData) => [...prevData, ...data.data]);
			setListLoading(false);
		}
	}, [data]);

	const handleTabsChange = useCallback((_: ChangeEvent<{}>, value: Tabs): void => {
		setCurrentTab(value);
	}, []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedSearchChange = useCallback(
		debounce((value) => {
			setSearchValue(value);
			setPage(1);
			setListLoading(true);
			setListData([]);
		}, 300),
		[]
	);

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		setSearch(value);

		if (value.length < 2) {
			setSearchValue("");
			setPage(1);
			setListLoading(false);
			setListData([]);

			return;
		}

		setSearch(value);
		debouncedSearchChange(value);
	};

	const handleSelectItem = (selected: Partial<ListPerson>) => {
		navigate(`/app/lists/detail/${selected?.list?.id}`);
		setSearch("");
		setSearchValue("");
		setPage(1);
		setIsOpened(false);
	};

	const fetchMore = () => {
		if (!isFetching && page < (data?.meta?.pageCount || 0)) {
			setPage((prevPage) => prevPage + 1);
		}
	};

	useEffect(() => {
		if (isOpened) {
			const tableContainer = document.querySelector("#CustomListAutocomplete-element");

			const isLastElementVisible = () => {
				const lastElement = document.querySelector(
					"#CustomListAutocomplete-items  button:nth-last-child(1)"
				);

				const rect = lastElement?.getBoundingClientRect();

				if (rect) {
					const isVisible =
						rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);

					if (isVisible) {
						fetchMore();
					}
				}
			};

			const handleScroll = debounce(isLastElementVisible, 300);

			tableContainer?.addEventListener("scroll", handleScroll);

			isLastElementVisible();

			return () => {
				tableContainer?.removeEventListener("scroll", handleScroll);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpened, listData]);

	return (
		<Modal
			handleClose={() => {
				setIsOpened(false);
			}}
			isOpened={isOpened}
			size="md"
			title=""
		>
			<SearchField
				handleChange={handleSearchChange}
				handleReset={() => {
					setSearch("");
					setSearchValue("");
					setPage(1);
				}}
				icon={<Icon className="" icon={faSearch} />}
				iconPosition="left"
				name="item"
				placeholder={ts("globalSearch")}
				value={search}
			/>
			<div
				className="flex flex-col max-h-[700px] px-2 mt-4 overflow-y-auto bb-scrollbar-darker overflow-hidden"
				id="CustomListAutocomplete-element"
			>
				<TabContext value={currentTab}>
					<TabList
						aria-label="Account tabs"
						sx={{ mb: 2, borderBottom: 1, borderColor: "divider" }}
						onChange={handleTabsChange}
					>
						{tabs.map((tab, index) => (
							<Tab
								key={index}
								label={
									<div
										className={classNames(
											"flex leading-[28px]",
											currentTab === tab.value ? " px-7" : ""
										)}
									>
										{tab.label}{" "}
										{tab.value === Tabs.PROSPECTS &&
										data?.meta?.itemCount &&
										data?.meta.itemCount > 0 ? (
											<TabChip
												active={currentTab === tab.value}
												label={`${data?.meta?.itemCount}`}
											/>
										) : (
											""
										)}
									</div>
								}
								sx={{ py: 1.25 }}
								value={tab.value}
							/>
						))}
					</TabList>

					<TabPanel sx={{ p: 0 }} value={Tabs.PROSPECTS}>
						<div className="flex flex-col">
							<div className="text-placeholder text-ssm">Results</div>
							<div className={classNames("flex flex-col w-full")} id="CustomListAutocomplete-items">
								{isLoading || listLoading ? (
									<div className="mt-[15%]">
										<Loader />
									</div>
								) : (
									<>
										{listData?.map((item) => {
											const { id, prospect, list } = item;

											return (
												<button
													key={`option-select-${id}`}
													className={classNames(
														"w-full flex items-center flex-row justify-between py-2.5 px-3 text-sm text-gray-900 bg-white hover:bg-gray-100 duration-200 cursor-pointer relative"
													)}
													onClick={() => {
														handleSelectItem(item);
													}}
												>
													<div className="flex items-center flex-row">
														<div className="px-2 py-2 bg-divider rounded-lg">
															<SvgIcon
																className="w-[24px] h-[24px] text-gray-700"
																svgIcon={ListBullet}
															/>
														</div>

														<div className="flex flex-col items-start ml-4">
															<div className="mr-3 ml-2 text-black-100 text-ssm">
																{prospect?.name}
															</div>
															{list ? (
																<div className="px-2 py-1 text-placeholder text-ssm">
																	{list?.name}
																</div>
															) : (
																<></>
															)}
														</div>

														<div className="ml-4">
															<ListPersonStatus status={item.assignStatus} />
														</div>
													</div>
												</button>
											);
										})}
										{!listData?.length && (
											<p className="py-2.5 px-3 text-sm italic text-gray-700">
												{t("basics.noResults")}
											</p>
										)}
										{isFetching && !isLoading && (
											<div className="flex items-center justify-center py-4">
												<Spinner color="#6B7280" />
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</TabPanel>
				</TabContext>
			</div>
		</Modal>
	);
};
