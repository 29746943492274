import { IFilterType } from "../../List/constans/general-filter";

export interface IFilter {
	name: string;
	filterParam: string;
	subSelectFilterParam?: string;
	filterByType: IFilterType[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	filterByValue: any[];
	customColumnId?: number;
}

const assignedTo: IFilter = {
	name: "assignedTo",
	filterParam: "list.name",
	filterByType: [
		"dropdown",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const name: IFilter = {
	name: "name",
	filterParam: "prospect.name",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const email: IFilter = {
	name: "email",
	filterParam: "prospect.email",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const linkedinUrl: IFilter = {
	name: "linkedinUrl",
	filterParam: "prospect.linkedinUr",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const country: IFilter = {
	name: "country",
	filterParam: "prospect.country",
	filterByType: [
		"dropdown",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const city: IFilter = {
	name: "city",
	filterParam: "prospect.city",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const state: IFilter = {
	name: "state",
	filterParam: "prospect.state",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const company: IFilter = {
	name: "company",
	filterParam: "organization.name",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const companyCountry: IFilter = {
	name: "companyCountry",
	filterParam: "organization.country",
	filterByType: [
		"dropdown",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const companyState: IFilter = {
	name: "companyState",
	filterParam: "organization.state",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const companyCity: IFilter = {
	name: "companyCity",
	filterParam: "organization.city",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const companyLinkedinUrl: IFilter = {
	name: "companyLinkedinUrl",
	filterParam: "organization.linkedinUrl",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const foundedYear: IFilter = {
	name: "foundedYear",
	filterParam: "organization.foundedYear",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

const title: IFilter = {
	name: "title",
	filterParam: "prospect.title",
	filterByType: [
		"dropdownCustom",
		"dropdownCustomExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const seniority: IFilter = {
	name: "seniority",
	filterParam: "prospect.seniority",
	filterByType: [
		"dropdown",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const website: IFilter = {
	name: "website",
	filterParam: "organization.websiteUrl",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const industry: IFilter = {
	name: "industry",
	filterParam: "organization.industry",
	filterByType: [
		"dropdown",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const estimatedNumEmployees: IFilter = {
	name: "estimatedNumEmployees",
	filterParam: "organization.estimatedNumEmployees",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

const inclusionStatus: IFilter = {
	name: "inclusionStatus",
	filterParam: "list_person.inclusionStatus",
	filterByType: ["dropdown"],
	filterByValue: [],
};

const event: IFilter = {
	name: "event",
	filterParam: "li_event_entry.name",
	filterByType: ["dropdown"],
	filterByValue: [],
};

const list: IFilter = {
	name: "list",
	filterParam: "list.name",
	subSelectFilterParam: "list_person.prospectId",
	filterByType: ["dropdown"],
	filterByValue: [],
};

const attendees: IFilter = {
	name: "attendees",
	filterParam: "li_event_entry.attendees",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

export const GeneralFilter: IFilter[] = [
	assignedTo,
	name,
	title,
	seniority,
	email,
	linkedinUrl,
	country,
	city,
	state,
	company,
	companyCountry,
	companyState,
	companyCity,
	companyLinkedinUrl,
	foundedYear,
	website,
	estimatedNumEmployees,
	inclusionStatus,
	list,
];

export const FrequentlyUsedFiltersConfig: IFilter[] = [
	title,
	seniority,
	country,
	industry,
	estimatedNumEmployees,
];

export const AdditionalColumnsFiltersConfig: IFilter[] = [
	city,
	state,
	company,
	companyCountry,
	companyState,
	companyCity,
	companyLinkedinUrl,
	foundedYear,
];

export const LiEventFilter: IFilter[] = [attendees, event];

export const CampaignRuleFieldsGeneral: IFilter[] = [
	assignedTo,
	name,
	title,
	seniority,
	email,
	linkedinUrl,
	country,
	city,
	state,
	company,
	companyCountry,
	companyState,
	companyCity,
	companyLinkedinUrl,
	foundedYear,
	website,
	estimatedNumEmployees,
	inclusionStatus,
	list,
];
