import { FunctionComponent } from "react";

import ExternalLinkIcon from "@assets/icons/externalLink.svg";

export const ExternalLink: FunctionComponent = () => {
	return (
		<div className="relative mr-1 w-[20px]">
			<img alt="ExternalLinkIcon" className="h-[24px] w-[24px]" src={ExternalLinkIcon} />
		</div>
	);
};
