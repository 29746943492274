import { createSlice } from "@reduxjs/toolkit";

import { ScoreState } from "@/types/score";

import { RootState } from "@/redux";

import { scoreApi } from "./score.api";

const initialState: ScoreState = {
	scores: [],
};

export const scoreSlice = createSlice({
	name: "score",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(scoreApi.endpoints.getScores.matchFulfilled, (state, { payload }) => {
			state.scores = payload.data;
		});
	},
});

export const {} = scoreSlice.actions;
export const scoreReducer = scoreSlice.reducer;
export const scoreSelector = (state: RootState) => state[scoreSlice.name];
