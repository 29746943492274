import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import useLocalStorageState from "use-local-storage-state";

import {
	faCheck,
	faColumns3,
	faMegaphone,
	faSearch,
	faSliders,
} from "@fortawesome/pro-regular-svg-icons";

import { AutoComplete, AutoCompleteItem } from "@/components/AutoComplete/AutoComplete";

import { Icon, LoadingOverlay, Modal } from "@/components";
import { useSearch } from "@/utils";

import {
	useBulkAssignListViewMutation,
	useBulkAssignMutation,
	useLazyGetCampaignQuery,
} from "@/pages/Private/redux/list/list.api";

import { Table, TableColumn } from "@/components/Table/Table";
import { useBulkFunctions } from "@/utils/userBulkFunctions";
import { SortParams } from "@/types/types";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { clearInbox, listSelector, setInboxSorting } from "@/pages/Private/redux/list/list.slice";

import { Button } from "@/components/Button/Button";

import { ButtonColor, ButtonSize } from "@/components/Button/types";

import { AssignStatus, InclusionFilterStatus, ListSourceType } from "@/enum/list.enum";

import { useCopyToClipboard } from "@/utils/useCopyToClipboard";

import { InputField } from "@/components/InputField/InputField";

import { classNames } from "@/utils/classNames";

import { TItem } from "@/components/SortableList/SortableList";

import { useSnackBar } from "@/utils/useSnackbar";

import { useGetLemlistsQuery } from "@/pages/Private/redux/lemlist/lemlist.api";

import { ListPerson } from "../../List/schema/list";
import { FilterModal, IFilterWithValues } from "./FilterModal";
import { GeneralTableColumns } from "./TableTypes/GeneralTableColumns";
import { ColumnModal } from "./ColumnModal";
import { getOrderStatus } from "../utils/colum-utils";

interface UnassignedTableProps {
	assignStatus?: AssignStatus;
	listType?: ListSourceType;
}

export interface FilterUnassignedTable {
	name?: string;
	email?: string;
	linkedinUrl?: string;
	country?: string;
	city?: string;
	state?: string;
	company?: string;
	companyCountry?: string;
	companyState?: string;
	companyCity?: string;
	companyLinkedinUrl?: string;
	title?: string;
	seniority?: string;
	website?: string;
	inclusionStatus?: InclusionFilterStatus;
	event?: {
		name: string;
		id: number;
	}[];
}

export const CampaignTable: FunctionComponent<UnassignedTableProps> = ({
	assignStatus,
	listType,
}) => {
	const { t } = useTranslation();

	const ts = useCallback((key: string) => t(`inbox.${key}`), [t]);
	const { sortingInbox, campaign: data, meta } = useAppSelector(listSelector);

	const { renderSnackbar, handleCopyClick } = useCopyToClipboard();
	const { handleOpenSnackbar, renderMessageSnackbar } = useSnackBar();
	const { page, setPage, limit, query, setLimit, setSearchValue, searchValue } = useSearch();
	const { query: lemlistQuery, setSearchValue: setSearchLemlist } = useSearch();

	const [selectedStatus, setSelectedStatus] = useState<AutoCompleteItem | null>();
	const [showSelectColumns, setShowSelectColumns] = useState(false);

	const dispatch = useAppDispatch();
	const { selectedItems, selectAll, handleSelectAll, handleSelectItem, toggleSelectAll } =
		useBulkFunctions();

	const [bulkAssignListView] = useBulkAssignListViewMutation();
	const [bulkAssign] = useBulkAssignMutation();

	const [showReassignmentModal, setShowReassignmentModal] = useState<boolean>(false);
	const [showReassignmentPlannedModal, setShowReassignmentPlannedModal] = useState<boolean>(false);

	const [, setEditableColumn] = useState<TItem>();
	const [, setShowEditColumn] = useState<boolean>(false);
	const [, setShowDeleteColumn] = useState<boolean>(false);
	const [, setShowReportRecordModal] = useState<boolean>(false);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	// const [addEntireCompanyToBlacklist, setAddEntireCompanyToBlacklist] = useState<boolean>(false);
	const [selectedItemForConfirmation, setSelectedItemForConfirmation] = useState<ListPerson | null>(
		null
	);
	const [selectedCampaign, setSelectedCampaign] = useState<AutoCompleteItem | null>();

	const [filter, setFilter] = useState<IFilterWithValues>();
	const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
	const [showEdit, setShowEdit] = useState<{ id: number; name: string }>();
	const [tableConfig, setTableConfig] = useLocalStorageState<TItem[]>(`campaign`, {
		defaultValue: [],
	});

	const [getCampaign, { isLoading, isFetching }] = useLazyGetCampaignQuery();

	const { data: lemlists } = useGetLemlistsQuery({
		page: 1,
		limit: 10,
		searchValue: lemlistQuery,
	});

	const handleSorting = (sortParams: SortParams) => {
		setPage(1);
		dispatch(setInboxSorting(sortParams));
	};

	useEffect(() => {
		dispatch(clearInbox());
		getCampaign({
			page,
			limit,
			searchValue: query,
			sorting: sortingInbox,
			assignStatus,
			filter,
		});
	}, [assignStatus, dispatch, filter, getCampaign, limit, page, query, sortingInbox]);

	const handleChangeRowsPerPage = (newLimit: number) => {
		setLimit(newLimit);
		setPage(1);
	};

	const handleContextMenu = (status: string, row: ListPerson) => {
		setSelectedItemForConfirmation(row);
		switch (status) {
			case "confirmAssignment": {
				setShowConfirmModal(true);
				break;
			}

			case "reassign": {
				setShowReassignmentModal(true);

				break;
			}

			case "reassignPlanned": {
				setShowReassignmentPlannedModal(true);

				break;
			}

			case "reportRecord": {
				setShowReportRecordModal(true);
				break;
			}
		}
	};

	useEffect(() => {
		if (selectAll) {
			handleSelectAll(data?.map((item) => item.id) || []);
		} else {
			handleSelectAll([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAll, data]);

	const [previousColumnCount, setPreviousColumnCount] = useState(0);

	const updateTableConfig = useCallback(
		(columns: TableColumn[]) => {
			const newConfig = columns.map((c, index) => {
				return {
					uid: c.uid ?? "",
					id: +index + 1,
					show: typeof c.show !== "undefined" ? c.show : true,
					content: c.title,
					locked: !!c.locked || false,
					allowExclude: typeof c.allowExclude !== "undefined" ? c.allowExclude : true,
					custom: c.uid?.includes("custom-") || false,
					columnId: c.uid?.includes("custom-") ? +c.uid.split("-")[1] : undefined,
					moveable: c.moveable,
					hideable: c.hideable,
				};
			});

			if (JSON.stringify(newConfig) !== JSON.stringify(tableConfig)) {
				setTableConfig(newConfig);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setTableConfig, tableConfig]
	);

	const tableColumns: TableColumn[] = useMemo(() => {
		const getColumnsBasedOnListType = () => {
			const columns: TableColumn[] = GeneralTableColumns({
				selectAll,
				toggleSelectAll,
				selectedItems,
				handleSelectItem,
				handleCopyClick,
				handleContextMenu,
				ts,
				sortingInbox,
				setShowEdit,
				showEdit,
			});

			return columns;
		};

		let columns: TableColumn[] = getColumnsBasedOnListType();

		if (data && data.length > 0) {
			for (const rec of data) {
				if (rec.aiEnrichmentResultOrg) {
					for (const aiEnrichmentColumn of rec.aiEnrichmentResultOrg) {
						if (!aiEnrichmentColumn.aiEnrichment?.name) {
							continue;
						}

						const hasColumn = columns.find(
							(c) => c.uid === `list-ai-${aiEnrichmentColumn.aiEnrichmentId}`
						);

						if (!hasColumn) {
							const title = aiEnrichmentColumn.aiEnrichment.name;
							const width = "fit-content";
							const minWidth = "100px";
							const uid = `list-ai-${aiEnrichmentColumn.aiEnrichmentId}`;
							const show = true;
							const moveable = true;
							const hideable = true;
							const orderTitle = `list-ai-${aiEnrichmentColumn.aiEnrichmentId}`;
							const orderStatus = getOrderStatus(sortingInbox, orderTitle);

							const render = (row: ListPerson) => {
								const correctAiEnrichmentColumn = row.aiEnrichmentResultOrg?.find(
									(a) => a.aiEnrichmentId === aiEnrichmentColumn.aiEnrichmentId
								);

								return (
									<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
										<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
											{correctAiEnrichmentColumn?.results}
										</Stack>
									</Stack>
								);
							};

							columns = [
								...columns,
								{
									title,
									uid,
									minWidth,
									width,
									render,
									orderTitle,
									orderStatus,
									show,
									moveable,
									hideable,
								},
							];
						}
					}
				}

				if (rec.aiEnrichmentResultPerson) {
					for (const aiEnrichmentColumn of rec.aiEnrichmentResultPerson) {
						if (!aiEnrichmentColumn.aiEnrichment?.name) {
							continue;
						}

						const hasColumn = columns.find(
							(c) => c.uid === `list-ai-${aiEnrichmentColumn.aiEnrichmentId}`
						);

						if (!hasColumn) {
							const title = aiEnrichmentColumn.aiEnrichment.name;
							const width = "fit-content";
							const minWidth = "100px";
							const uid = `list-ai-${aiEnrichmentColumn.aiEnrichmentId}`;
							const show = true;
							const moveable = true;
							const hideable = true;
							const orderTitle = `list-ai-${aiEnrichmentColumn.aiEnrichmentId}`;

							const render = (row: ListPerson) => {
								const correctAiEnrichmentColumn = row.aiEnrichmentResultPerson?.find(
									(a) => a.id === aiEnrichmentColumn.id
								);

								return (
									<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
										<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
											{correctAiEnrichmentColumn?.results}
										</Stack>
									</Stack>
								);
							};

							columns = [
								...columns,
								{ title, uid, minWidth, width, render, orderTitle, show, moveable, hideable },
							];
						}
					}
				}
			}
		}

		if (tableConfig?.length) {
			for (const c of columns) {
				const configIndex = tableConfig.findIndex((tc) => tc?.uid === c.uid);
				const currentIndex = columns.findIndex((tc) => tc?.uid === c.uid);

				if (configIndex > -1 && currentIndex > -1 && configIndex !== currentIndex) {
					const newConfigColumn = columns[currentIndex];

					columns.splice(currentIndex, 1);

					columns = [
						...columns.slice(0, configIndex),
						newConfigColumn,
						...columns.slice(configIndex),
					];
				}
			}

			columns = columns.map((c) => {
				const config = tableConfig.find((tc) => {
					return tc?.uid === c?.uid;
				});

				if (config) {
					c.show = config.show;
				}

				return c;
			});
		}

		return columns;
	}, [
		data,
		tableConfig,
		selectAll,
		toggleSelectAll,
		selectedItems,
		handleSelectItem,
		handleCopyClick,
		ts,
		sortingInbox,
		showEdit,
	]);

	const handleBulkAssign = async () => {
		if (!data) {
			return;
		}

		let param: Array<{ listPersonId: number; assignStatus: AssignStatus }> = [];

		if (selectedItemForConfirmation) {
			const cObj = data.find((item) => item.id === selectedItemForConfirmation.id);

			if (cObj && cObj.plannedAssignStatus) {
				param = [
					{
						listPersonId: selectedItemForConfirmation.id,
						assignStatus: cObj.plannedAssignStatus,
					},
				];
			}
		} else {
			param = selectedItems
				.map((id) => {
					const cObj = data.find((item) => item.id === id);

					if (!cObj) {
						return undefined;
					}

					return {
						listPersonId: cObj.id,
						assignStatus: cObj.plannedAssignStatus,
					};
				})
				.filter(Boolean) as Array<{ listPersonId: number; assignStatus: AssignStatus }>;
		}

		if (param.length > 0) {
			await bulkAssignListView({
				assign: param,
			}).unwrap();
		}

		handleOpenSnackbar(ts("bulkAssignSuccess"));
		setSelectedItemForConfirmation(null);
	};

	const handleBulkReassign = async () => {
		if (!data) {
			return;
		}

		let param: Array<{
			listPersonId: number;
			lemlistId?: number;
			assignedToUserId?: number;
		}> = [];

		const lemlist = lemlists?.data.find((c) => c.id === selectedCampaign?.id);

		if (!lemlist) {
			return;
		}

		if (selectedItemForConfirmation) {
			const cObj = data.find((item) => item.id === selectedItemForConfirmation.id);

			if (!cObj) {
				return;
			}

			const lemlistId = lemlist.id;

			if (!lemlistId) {
				return;
			}

			if (cObj) {
				param = [
					{
						listPersonId: selectedItemForConfirmation.id,
						lemlistId: lemlistId,
					},
				];
			}
		} else {
			param = selectedItems
				.map((id) => {
					const cObj = data.find((item) => item.id === id);

					if (!cObj) {
						return;
					}

					const lemlistId = lemlist.id;

					if (!lemlistId) {
						return;
					}

					return {
						listPersonId: id,
						lemlistId: lemlistId,
					};
				})
				.filter(Boolean) as Array<{
				listPersonId: number;
				lemlistId?: number;
				assignedToUserId?: number;
			}>;
		}

		await bulkAssign({
			assign: param.filter(Boolean) as {
				listPersonId: number;
				lemlistId?: number;
				assignedToUserId?: number;
			}[],
			assignStatus: AssignStatus.ASSIGNING,
		}).unwrap();
	};

	useEffect(() => {
		if (data.length !== 0) {
			const currentColumnCount = data[0]?.list?.columns?.length || 0;

			if (currentColumnCount !== previousColumnCount) {
				updateTableConfig(tableColumns);
				setPreviousColumnCount(currentColumnCount);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, previousColumnCount, updateTableConfig]);

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full overflow-auto">
			{isFetching && <LoadingOverlay />}
			<div className="flex items-start align-baseline justify-between w-full mb-2">
				<div className="flex-grow mr-4 max-w-[50%] w-[390px] md-w-[240px] ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						icon={faSearch}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
				<div className="w-[fit-content] flex">
					<div className="w-[fit-content] mr-2">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							image={<Icon className="mr-2" icon={faColumns3} />}
							size={ButtonSize.L}
							title={ts("columns")}
							onClick={() => setShowSelectColumns(true)}
						/>
					</div>
					<div className="w-[fit-content] mr-2">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							image={<Icon className="mr-2" icon={faSliders} />}
							size={ButtonSize.L}
							title={`${ts("filter")}${
								filter && Object.keys(filter).length > 0 ? ` (${Object.keys(filter).length})` : ""
							}`}
							onClick={() => setShowFilterModal(true)}
						/>
					</div>
				</div>
			</div>
			<div
				className={classNames(
					"items-end align-baseline justify-start fixed bottom-12 z-50 bg-white w-full py-4",
					selectedItems.length > 0 ? "flex" : "hidden"
				)}
			>
				<>
					<div className="w-[fit-content] mr-2">
						<Button
							className="w-[auto]"
							disabled={!selectedCampaign && selectedItems.length === 0}
							image={<Icon className="mr-2" icon={faCheck} />}
							size={ButtonSize.ML}
							title={ts("confirmAssignment")}
							onClick={() => setShowConfirmModal(true)}
						/>
					</div>
				</>
			</div>

			{showSelectColumns && tableConfig && setShowSelectColumns && (
				<ColumnModal
					setEditableColumn={setEditableColumn}
					setShowDeleteColumn={setShowDeleteColumn}
					setShowEditColumn={setShowEditColumn}
					setShowSelectColumns={setShowSelectColumns}
					setTableConfig={setTableConfig}
					showSelectColumns={showSelectColumns}
					tableConfig={tableConfig}
				/>
			)}

			{showFilterModal && (
				<FilterModal
					filter={filter}
					inboxData={data}
					listType={listType}
					setFilter={(e) => {
						setPage(1);
						setFilter(e);
					}}
					setShowFilterModal={setShowFilterModal}
					showFilterModal={showFilterModal}
				/>
			)}
			{showReassignmentModal && (
				<Modal
					handleClose={() => setShowReassignmentModal(false)}
					handleSave={async () => {
						await handleBulkReassign();
						selectAll && toggleSelectAll();
						setShowReassignmentModal(false);
					}}
					isOpened={showReassignmentModal}
					size="md"
					submitButtonText={t("basics.confirm")}
					title={ts("reassign")}
				>
					<div className="mb-4 flex flex-row items-center">
						{lemlists ? (
							<AutoComplete
								data={
									lemlists?.data?.map((c) => {
										return { id: c.id, title: c.name } as AutoCompleteItem;
									}) || []
								}
								handleSearch={(value: string) => setSearchLemlist(value)}
								handleSelect={(value?: AutoCompleteItem) => setSelectedCampaign(value)}
								icon={faMegaphone}
								label={ts("campaign")}
								selectedItem={selectedCampaign?.title ?? ""}
							/>
						) : (
							""
						)}
					</div>
				</Modal>
			)}
			{showConfirmModal && (
				<Modal
					handleClose={() => setShowConfirmModal(false)}
					handleSave={async () => {
						await handleBulkAssign();
						selectAll && toggleSelectAll();
						setShowConfirmModal(false);
					}}
					isOpened={showConfirmModal}
					size="xs"
					submitButtonText={t("basics.confirm")}
					title={ts("confirmAssignment")}
				>
					{selectedItemForConfirmation ? (
						<>
							{ts("confirmAssignment")} for {selectedItemForConfirmation?.prospect?.name}?
						</>
					) : (
						<>
							{ts("confirmAssignment")} for all {selectedItems.length} selected items?
						</>
					)}
				</Modal>
			)}
			{showReassignmentPlannedModal && (
				<Modal
					handleClose={() => setShowReassignmentPlannedModal(false)}
					handleSave={async () => {
						await handleBulkAssign();
						selectAll && toggleSelectAll();
						setShowReassignmentPlannedModal(false);
					}}
					isOpened={showReassignmentPlannedModal}
					size="md"
					submitButtonText={t("basics.confirm")}
					title={ts("reassign")}
				>
					<div className="mb-4 flex flex-row items-center">
						<AutoComplete
							data={[
								{
									id: AssignStatus.DO_NOT_CONTACT,
									title: ts("doNotContact"),
								},
								{
									id: AssignStatus.TO_BE_NURTURED,
									title: ts("toBeNurtured"),
								},
							]}
							handleSearch={(value: string) => console.log(value)}
							handleSelect={(value?: AutoCompleteItem) => setSelectedStatus(value)}
							label={ts("status")}
							selectedItem={selectedStatus?.title ?? ""}
						/>
					</div>
				</Modal>
			)}

			<div className="relative w-full">
				<div className="pr-4 pb-[20px]">
					<Table
						columns={tableColumns}
						data={data || []}
						filter={filter}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						handleFetchPage={(page) => {
							if (selectAll) {
								toggleSelectAll();
							}

							setPage(page);
						}}
						handleSorting={(sortParams: SortParams) => {
							setPage(1);
							handleSorting(sortParams);
						}}
						hidePagination={false}
						isLoading={isFetching}
						limit={limit}
						page={page}
						totalPages={meta?.pageCount}
					/>
				</div>
			</div>
			{renderSnackbar()}
			{renderMessageSnackbar()}
		</div>
	);
};
