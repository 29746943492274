import { FunctionComponent } from "react";

import { useParams } from "react-router-dom";

import { useGetScoreQuery } from "@/pages/Private/redux/score/score.api";

import { LoadingOverlay } from "@/components/LoadingOverlay/LoadingOverlay";

import { EditScoreDetail } from "../../components/EditScoreDetail";

export const ScoreDetail: FunctionComponent = () => {
	const { id = 0 } = useParams();

	const { data, isLoading } = useGetScoreQuery(+id);

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			{isLoading && <LoadingOverlay />}
			<div className="w-full">{data && <EditScoreDetail data={data} />}</div>
		</div>
	);
};
