import { ChangeEvent, FunctionComponent, useCallback } from "react";
import { Stack } from "@mui/material";

import { useTranslation } from "react-i18next";

import { LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { InputField } from "@/components/InputField/InputField";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { useGetScoresQuery } from "@/pages/Private/redux/score/score.api";

import { useNonStickySearch } from "@/utils/useNonStickySearch";

import { Score } from "../../Score/schema/score.schema";

export interface ScoreListProps {
	selectedScores?:
		| {
				id: number;
		  }[]
		| undefined;
	setSelectedScore: (source: { id: number }[]) => void;
}

export const ScoreList: FunctionComponent<ScoreListProps> = ({
	selectedScores,
	setSelectedScore,
}) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`score.${key}`), [t]);
	const { page, setPage, limit, setLimit, searchValue, query, setSearchValue } =
		useNonStickySearch();

	const { data, isLoading } = useGetScoresQuery({
		page,
		limit,
		searchValue: query,
	});

	const columns: TableColumn<Score>[] = [
		{
			label: "",
			width: 20,
			format: (listSource: Score) => (
				<Checkbox
					isChecked={!!selectedScores?.find((lf) => lf.id === listSource.id)}
					name={`score-${listSource.id}`}
					onChange={() => {
						const isSelected = !!selectedScores?.find((lf) => lf.id === listSource.id);

						if (isSelected) {
							setSelectedScore((selectedScores || []).filter((lf) => lf.id !== listSource.id));
						} else {
							setSelectedScore([...(selectedScores || []), { id: listSource.id }]);
						}
					}}
				/>
			),
		},
		{
			label: ts("name"),
			format: (row) => (
				<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
					<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
						<div className="text-sm flex items-center">{row?.name}</div>
					</Stack>
				</Stack>
			),
		},
	];

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	return !data || isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full mt-8">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">{ts("subtitle")}</p>
				</div>
			</div>
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					allowNavigation={false}
					columns={columns}
					data={data.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
		</div>
	);
};
