import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/system";
import { Card, CardContent, Unstable_Grid2 as Grid, TextField, Typography } from "@mui/material";

import { Button } from "@/components/Button/Button";
import { ButtonColor, ButtonSize } from "@/components/Button/types";
import { useSaveApiKeysMutation } from "@/pages/Private/redux/company/company.api";

import { CompanyType, SaveApiKeysCompany, SaveApiKeysCompanySchema } from "../schema/company";

type ApiKeyLemlistProps = {
	company?: CompanyType;
};

export const ApiKeyLemlist: FunctionComponent<ApiKeyLemlistProps> = ({ company }) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`company.${key}`);

	const [canEdit, setCanEdit] = useState<boolean>(false);
	const [updateCompany, { isLoading }] = useSaveApiKeysMutation();

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<SaveApiKeysCompany>({
		defaultValues: {
			lemlistApiKey: company?.hasLemlistApiKey ? "******************" : "",
		},
		resolver: zodResolver(SaveApiKeysCompanySchema),
	});

	const onSubmit = async (values: SaveApiKeysCompany) => {
		const apiKey = values?.lemlistApiKey?.replaceAll("*", "") || "";

		const newValues = {
			lemlistApiKey: apiKey.length > 10 ? apiKey : undefined,
		};

		await updateCompany({ ...newValues }).unwrap();

		setCanEdit(false);
	};

	return (
		<>
			<Card>
				<CardContent>
					<Grid container spacing={3}>
						<Grid md={4} xs={12}>
							<Typography variant="h6">{ts("lemlistApiKey")}</Typography>
						</Grid>

						<Grid md={8} xs={12}>
							<Stack spacing={3}>
								<div className="flex flex-col">
									<TextField
										fullWidth
										error={!!errors.lemlistApiKey?.message}
										inputProps={{ readOnly: !canEdit }}
										label={ts("lemlistApiKey")}
										size="small"
										variant="filled"
										{...register("lemlistApiKey")}
									/>

									{!canEdit && (
										<div className="w-[100px] mr-2">
											<Button
												color={ButtonColor.ACTION_SECONDARY}
												size={ButtonSize.S}
												title={t("basics.edit")}
												onClick={() => setCanEdit(true)}
											/>
										</div>
									)}

									{canEdit && (
										<div className="w-[300px] flex">
											<div className="w-[100px] mr-2">
												<Button
													color={ButtonColor.ACTION_SECONDARY}
													size={ButtonSize.S}
													title={t("basics.cancel")}
													onClick={() => setCanEdit(false)}
												/>
											</div>

											<div className="w-[100px]">
												<Button
													isLoading={isLoading}
													size={ButtonSize.S}
													title={t("basics.save")}
													onClick={handleSubmit(onSubmit)}
												/>
											</div>
										</div>
									)}
								</div>
							</Stack>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</>
	);
};
