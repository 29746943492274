export enum LiProfileEntryType {
	LINKEDIN_PROFILE_POSTS = "LINKEDIN_PROFILE_POSTS",
	LINKEDIN_PROFILE_COMMENTS = "LINKEDIN_PROFILE_COMMENTS",
	LINKEDIN_PROFILE_LIKES_TO_POST = "LINKEDIN_PROFILE_LIKES_TO_POST",
	LINKEDIN_PROFILE_LIKES_TO_COMMENT = "LINKEDIN_PROFILE_LIKES_TO_COMMENT",
	LINKEDIN_PROFILE_COMMENTS_TO_POST = "LINKEDIN_PROFILE_COMMENTS_TO_POST",
}

export enum LiProfileType {
	POST = "POST",
	COMMENT = "COMMENT",
	LIKE_TO_POST = "LIKE_TO_POST",
	LIKE_TO_COMMENT = "LIKE_TO_COMMENT",
	COMMENT_TO_POST = "COMMENT_TO_POST",
}
