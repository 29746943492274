import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

import { CsvEnrichmentScrapeStatus } from "@/enum/csv-enrichment.enum";

import { ApolloOrgSchema } from "../../Apollo/schema/apolloOrg";

export const CsvEnrichmentOrganizationSchema = z.object({
	id: z.number(),
	csvEnrichmentId: z.string().trim(),
	name: z.string().nullable(),
	companyLinkedinUrl: z.string().nullable(),
	domain: z.string().nullable(),
	cleanedName: z.string().nullable(),
	status: z.nativeEnum(CsvEnrichmentScrapeStatus),
	organization: ApolloOrgSchema.optional(),
	companyCity: z.string().nullable(),
	companyCountry: z.string().nullable(),
	companyState: z.string().nullable(),
	companyLocation: z.string().nullable(),
});

export const CsvEnrichmentOrganizationResponseSchema = z.object({
	data: z.array(CsvEnrichmentOrganizationSchema),
	meta: MetaDataSchema,
});

export type CsvEnrichmentOrganization = z.infer<typeof CsvEnrichmentOrganizationSchema>;

export type CsvEnrichmentOrganizationResponse = z.infer<
	typeof CsvEnrichmentOrganizationResponseSchema
>;
