import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack, TextField, Typography } from "@mui/material";

import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import { Link } from "react-router-dom";

import {
	useDeleteCsvEnrichmentMutation,
	useGetCsvEnrichmentsQuery,
	useUpdateCsvEnrichmentMutation,
} from "@/pages/Private/redux/csvEnrichment/csvEnrichment.api";

import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import { JobsScrapeStatus } from "@/enum/scrape-enum";

import { PARSED_ENV } from "@/constants";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { useAppSelector } from "@/redux/hooks";

import { Toggle } from "@/components/Toggle/Toggle";

import { ListStatus } from "@/enum/list.enum";

import { ROUTE_CONFIG } from "@/routes/config";

import { CsvEnrichment } from "../schema/csvEnrichment";
import { CsvEnrichmentStatus } from "./CsvEnrichmentStatus";

export const CsvEnrichmentTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`csvEnrichment.${key}`), [t]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedCsvEnrichment, setSelectedCsvEnrichment] = useState<CsvEnrichment | null>(null);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [deleteCsvEnrichment] = useDeleteCsvEnrichmentMutation();
	const [updateCsvEnrichment] = useUpdateCsvEnrichmentMutation();
	const { token } = useAppSelector(authSelector);

	const { data, isLoading } = useGetCsvEnrichmentsQuery({
		page,
		limit,
		searchValue: query,
	});

	const columns: TableColumn<CsvEnrichment>[] = useMemo(() => {
		const handleToggle = async (id: number, status: ListStatus) => {
			const updater: Partial<CsvEnrichment> = {
				id: id,
			};

			if (status === ListStatus.ACTIVE) {
				updater.activeStatus = ListStatus.PAUSED;
			} else if (status === ListStatus.PAUSED) {
				updater.activeStatus = ListStatus.ACTIVE;
			}

			await updateCsvEnrichment({ ...updater }).unwrap();
		};

		const downloadAll = async (url: string) => {
			const response = await fetch(PARSED_ENV.REACT_APP_API_URL + `/api/${url}`, {
				headers: {
					authorization: `Bearer ${token}`,
				},
			});

			return await response.text();
		};

		const handleDownloadAll = async (id: number, name: string) => {
			const link = document.createElement("a");

			const csv = await downloadAll(`csvEnrichment/download/${id}`);

			if (csv) {
				const blob = new Blob([csv], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);

				link.download = `${name}.csv` || "";

				link.href = url;

				link.click();
			}
		};

		return [
			{
				label: "",
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								<Toggle
									isChecked={row?.activeStatus === ListStatus.ACTIVE}
									onChange={() => {
										if (row.id && row.activeStatus !== ListStatus.ERROR) {
											handleToggle(row.id, row.activeStatus);
										}
									}}
								/>
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								<Link to={`${ROUTE_CONFIG.CSV_ENRICHMENTS}/${row?.id}`}>{row?.name}</Link>
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("status"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<CsvEnrichmentStatus row={row} />
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("type"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.type}
							</Typography>
						</Stack>
					</Stack>
				),
			},

			{
				label: ts("found"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.doneCount} / {row?.allCount}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("processed"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.processedCount} / {row?.allCount}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("downloads"),
				format: (row) => {
					return row.status === JobsScrapeStatus.DONE ? (
						<Stack alignItems="flex-start" direction="column" spacing={0}>
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										cursor: "pointer",
										color: "primary.main",
									},
								}}
								onClick={() => {
									handleDownloadAll(row.id, `${row.name}`);
								}}
							>
								{`${row.name}`}
							</Box>
						</Stack>
					) : (
						<></>
					);
				},
			},
			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<PermissionCheck requiredPermissions={[PermissionRoles.CSV_ENRICHMENTS]}>
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										color: "primary.main",
										cursor: "pointer",
									},
								}}
								onClick={() => handleDeleteModal(row)}
							>
								<Icon icon={faTrashCan} size="xl" />
							</Box>
						</PermissionCheck>
					</Stack>
				),
			},
		];
	}, [ts, updateCsvEnrichment, token]);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	const handleDeleteModal = (row: CsvEnrichment) => {
		setShowDeleteModal(true);
		setSelectedCsvEnrichment(row);
	};

	const handleDeleteCsvEnrichment = async () => {
		try {
			await deleteCsvEnrichment({ id: selectedCsvEnrichment?.id ?? 0 }).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	return !data ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteCsvEnrichment}
					isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedCsvEnrichment?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}
		</div>
	);
};
