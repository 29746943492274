import { TableColumn } from "@/components/Table/Table";

import { SortParams } from "@/types/types";

import { ListPerson } from "../schema/list";
import { getContextMenuItem } from "../utils/contextMenu";
import {
	renderSelectColumn,
	renderMaybeColumn,
	renderAssignToColumn,
	renderNameColumn,
	renderCompanyColumn,
	renderContentTypeColumn,
	renderLiProfileContentColumn,
	renderLiProfileContentResponseColumn,
	renderCompanyCityColumn,
	renderCompanyCountryColumn,
	renderCompanyStateColumn,
	renderConnectionsColumn,
	renderEstimatedNumEmployeesColumn,
	renderFoundedYearColumn,
	renderGenderColumn,
	renderIndustryColumn,
	renderLanguageColumn,
	renderOtherListsColumn,
	renderPersonCityColumn,
	renderPersonCountryColumn,
	renderPersonStateColumn,
	renderSeniorityColumn,
	renderTitleColumn,
	renderFollowers,
	renderSourceNameColumn,
} from "../utils/allColumns";

export interface LiContentTableColumnsProps {
	selectAll: boolean;
	toggleSelectAll: () => void;
	selectedItems: number[];
	handleSelectItem: (id: number) => void;
	handleCopyClick: (value: string) => void;
	handleContextMenu: (value: string, row: ListPerson) => void;
	ts: (key: string) => string;
	sortingInbox: SortParams;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
}

export const LiContentTableColumns = ({
	selectAll,
	toggleSelectAll,
	selectedItems,
	handleSelectItem,
	handleCopyClick,
	handleContextMenu,
	ts,
	sortingInbox,
	setShowEdit,
	showEdit,
}: LiContentTableColumnsProps): TableColumn[] => {
	return [
		renderSelectColumn(toggleSelectAll, selectAll, selectedItems, handleSelectItem),
		renderMaybeColumn(),
		renderAssignToColumn(ts, handleCopyClick),
		{ ...getContextMenuItem(handleContextMenu, ts) },
		renderNameColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit),
		renderCompanyColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit),
		renderContentTypeColumn(ts, sortingInbox),
		renderLiProfileContentColumn(ts, handleCopyClick, sortingInbox),
		renderLiProfileContentResponseColumn(ts, handleCopyClick, sortingInbox),
		renderGenderColumn(ts, handleCopyClick, sortingInbox),
		renderTitleColumn(ts, handleCopyClick, sortingInbox),
		renderSeniorityColumn(ts, handleCopyClick, sortingInbox),
		renderConnectionsColumn(ts, sortingInbox),
		renderIndustryColumn(ts, handleCopyClick, sortingInbox),
		renderEstimatedNumEmployeesColumn(ts, handleCopyClick, sortingInbox),
		renderFoundedYearColumn(ts, handleCopyClick, sortingInbox),
		renderPersonCityColumn(ts, handleCopyClick, sortingInbox),
		renderPersonStateColumn(ts, handleCopyClick, sortingInbox),
		renderPersonCountryColumn(ts, handleCopyClick, sortingInbox),
		renderCompanyCityColumn(ts, handleCopyClick, sortingInbox),
		renderCompanyStateColumn(ts, handleCopyClick, sortingInbox),
		renderCompanyCountryColumn(ts, handleCopyClick, sortingInbox),
		renderFollowers(ts, sortingInbox),
		renderLanguageColumn(ts, sortingInbox),
		renderOtherListsColumn(ts, sortingInbox),
		renderSourceNameColumn(ts, handleCopyClick, sortingInbox),
	];
};
