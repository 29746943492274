import {
	company,
	companyCity,
	companyLinkedinUrl,
	companyState,
	foundedYear,
	IFilter,
	list,
} from "./general-filter";

const jobTitle: IFilter = {
	name: "jobTitle",
	filterParam: "offer.jobTitle",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const jobCompany: IFilter = {
	name: "company",
	filterParam: "offer.companyName",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const jobCompanyCountry: IFilter = {
	name: "companyCountry",
	filterParam: "offer.companyCountry",
	filterByType: [
		"dropdown",
		"dropdownExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const jobCountry: IFilter = {
	name: "jobCountry",
	filterParam: "offer.jobCountry",
	filterByType: [
		"dropdown",
		"dropdownExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const jobCity: IFilter = {
	name: "jobCity",
	filterParam: "offer.jobCity",
	filterByType: [
		"dropdownCustom",
		"dropdownCustomExclude",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const jobCompanyState: IFilter = {
	name: "companyState",
	filterParam: "offer.companyState",
	filterByType: [
		"dropdown",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const jobCompanyCity: IFilter = {
	name: "companyCity",
	filterParam: "offer.companyCity",
	filterByType: [
		"dropdownCustom",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const JobsFilter: IFilter[] = [jobTitle];

export const FrequentlyUsedJobFiltersConfig: IFilter[] = [jobTitle];

export const AdditionalColumnsJobFiltersConfig: IFilter[] = [
	company,

	companyState,
	companyCity,
	companyLinkedinUrl,
	foundedYear,
	list,
];

export const AdditionalColumnsNonLinkedinJobFiltersConfig: IFilter[] = [
	jobCompanyCountry,
	jobCompanyCity,
	jobCompanyState,
	jobCountry,
	jobCity,
	list,
];

export const AdditionalColumnsInfoAtFiltersConfig: IFilter[] = [jobCountry, jobCity, list];

export const AdditionalColumnsNonLinkedinJobFiltersConfigForCreation: IFilter[] = [
	jobCompanyCountry,
	jobCompanyCity,
	jobCompanyState,
	jobCountry,
	jobCity,
];
