import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as Imprint } from "@assets/icons/create-list/liEvents.svg";

import { ReactComponent as PeopleSearch } from "@assets/icons/create-list/peopleSearch.svg";
import { Breadcrumb } from "@/components/Breadcrump/Breadcrump";
import { ReactComponent as TrackCompany } from "@assets/icons/create-list/trackCompany.svg";

import { Button } from "@/components/Button/Button";
import { useGetListQuery, useUpdateListDetailsMutation } from "@/pages/Private/redux/list/list.api";

import { LoadingOverlay } from "@/components";

import { ButtonColor } from "@/components/Button/types";

import { JobScrapeType } from "@/enum/job.enum";

import { MapScrapeType } from "@/enum/map.enum";

import { ListSourceType, UpdateType } from "@/enum/list.enum";

import { DetailsTile } from "./components/DetailsTile";
import { Stepper } from "./components/Stepper";
import { getAvailableSteps, isDetailsNeeded, getDoneSteps } from "../../utils/create-list-steps";

export const ListEditDetails: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();

	console.log(id);

	const navigate = useNavigate();
	const ts = (key: string) => t(`list.${key}`);

	const { data, isFetching } = useGetListQuery(+id);

	const [listType, setListType] = useState<MapScrapeType | JobScrapeType | null>(
		data?.scrapeLevel || null
	);

	const [update, { isLoading }] = useUpdateListDetailsMutation();

	const submit = async () => {
		if (!listType || !data) {
			return;
		}

		const createdList = await update({
			id: data.id,
			scrapeLevel: listType,
			updateType: UpdateType.SETTINGS,
		}).unwrap();

		navigate(`/app/lists/create/source/${createdList.id}`);
	};

	return isFetching || !data ? (
		<LoadingOverlay />
	) : (
		<div className="sm:block flex flex-col items-start justify-between mb-6 pb-8">
			<Breadcrumb
				path={[
					{ name: ts("title"), link: "/" },
					{ name: ts("create.title"), link: "/create" },
					{ name: data?.name || "", link: `/create/${id}/details` },
				]}
			/>
			<div className="text-lg leading-9 mb-5">Create {ts(data.sourceType)} List</div>
			<Stepper
				availableSteps={getAvailableSteps(isDetailsNeeded(data), data)}
				currentStep="details"
				doneSteps={getDoneSteps(data)}
				listId={data.id}
			/>
			<div className="w-full">
				{data.sourceType === ListSourceType.MAPS && (
					<>
						<DetailsTile
							description={t("map.getInfoAt")}
							icon={PeopleSearch}
							isSelected={listType === MapScrapeType.GET_INFO_AT}
							listType={MapScrapeType.GET_INFO_AT}
							setListType={setListType}
							title="Info@..."
						/>

						<DetailsTile
							description={t("map.getManagingDirector")}
							icon={Imprint}
							isSelected={listType === MapScrapeType.GET_MANAGING_DIRECTOR}
							listType={MapScrapeType.GET_MANAGING_DIRECTOR}
							setListType={setListType}
							title="CEO from imprint"
						/>

						<DetailsTile
							description={t("map.getEnrichment")}
							icon={TrackCompany}
							isSelected={listType === MapScrapeType.GET_ENRICHMENT}
							listType={MapScrapeType.GET_ENRICHMENT}
							setListType={setListType}
							title="LinkedIn Company"
						/>
					</>
				)}

				{data.sourceType === ListSourceType.JOBS && (
					<>
						<DetailsTile
							description={t("jobs.getInfoAt")}
							icon={PeopleSearch}
							isSelected={listType === JobScrapeType.GET_INFO_AT}
							listType={JobScrapeType.GET_INFO_AT}
							setListType={setListType}
							title="Info@..."
						/>

						<DetailsTile
							description={t("jobs.getManagingDirector")}
							icon={Imprint}
							isSelected={listType === JobScrapeType.GET_MANAGING_DIRECTOR}
							listType={JobScrapeType.GET_MANAGING_DIRECTOR}
							setListType={setListType}
							title="CEO from imprint"
						/>

						<DetailsTile
							description={t("jobs.getPersonFromOffer")}
							icon={Imprint}
							isSelected={listType === JobScrapeType.GET_PERSON_FROM_OFFER}
							listType={JobScrapeType.GET_PERSON_FROM_OFFER}
							setListType={setListType}
							title="Person from Job Offer"
						/>

						<DetailsTile
							description={t("jobs.getEnrichment")}
							icon={TrackCompany}
							isSelected={listType === JobScrapeType.GET_ENRICHMENT}
							listType={JobScrapeType.GET_ENRICHMENT}
							setListType={setListType}
							title="LinkedIn Company"
						/>
					</>
				)}
			</div>
			<div className="absolute mt-14 h-[72px] flex flex-row justify-between items-center w-auto border-t border-t-border px-8 py-[14px] rounded-t-16 bottom-0 right-0 z-20 bg-white w-full">
				<div></div>
				<div className="flex flex-row self-left items-center gap-x-4">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						disabled={listType === null}
						isLoading={isLoading}
						title={t("basics.previous")}
						onClick={submit}
					/>
					<Button
						disabled={listType === null}
						isLoading={isLoading}
						title={t("basics.next")}
						onClick={submit}
					/>
				</div>
			</div>
		</div>
	);
};
