export enum EmploymentType {
	FULLTIME = "FULLTIME",
	PARTTIME = "PARTTIME",
	CONTRACTOR = "CONTRACTOR",
	TEMPORARY = "TEMPORARY",
	VOLUNTEER = "VOLUNTEER",
	INTERN = "INTERN",
	OTHER = "OTHER",
}

export enum JobScrapeType {
	GET_INFO_AT = "GET_INFO_AT",
	GET_ENRICHMENT = "GET_ENRICHMENT",
	GET_MANAGING_DIRECTOR = "GET_MANAGING_DIRECTOR",
	GET_PERSON_FROM_OFFER = "GET_PERSON_FROM_OFFER",
}
