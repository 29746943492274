import { ChangeEvent, FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, Link, Stack, Typography } from "@mui/material";

import { LinkSimple, TwitterLogo, FacebookLogo, LinkedinLogo } from "phosphor-react";

import { LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { useSearch } from "@/utils";

import { ApolloPeopleResponse } from "../schema/get-apollos";

export const OrganizationTable: FunctionComponent<{ data: ApolloPeopleResponse }> = ({ data }) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`prospectSearch.${key}`), [t]);

	const { page, setPage, limit, setLimit } = useSearch();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const columns: TableColumn<any>[] = useMemo(
		() => [
			{
				label: ts("name"),
				minWidth: 250,
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						{row?.logoUrl ? (
							<Box
								component="img"
								height={"40px"}
								mr={1}
								src={row?.logoUrl}
								sx={{ borderRadius: 1 }}
							/>
						) : (
							<></>
						)}

						<div className="flex flex-col">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.name} {row?.foundedYear ? `(${row?.foundedYear})` : ""}
							</Typography>
							<div className="flex">
								<Link
									className="inline-block w-[18px] mr-2"
									href={row?.linkedinUrl}
									target="_blank"
								>
									<LinkedinLogo className="inline-block w-[18px] h-[18px]" />
								</Link>
								<Link className="inline-block w-[18px] mr-2" href={row?.websiteUrl} target="_blank">
									<LinkSimple className="inline-block w-[18px] h-[18px]" />
								</Link>
								{row?.twitterUrl ? (
									<Link
										className="inline-block w-[18px] mr-2"
										href={row?.twitterUrl}
										target="_blank"
									>
										<TwitterLogo className="inline-block w-[18px] h-[18px]" />
									</Link>
								) : (
									<></>
								)}
								{row?.facebookUrl ? (
									<Link
										className="inline-block w-[18px] mr-2"
										href={row?.facebookUrl}
										target="_blank"
									>
										<FacebookLogo className="inline-block w-[18px] h-[18px]" />
									</Link>
								) : (
									<></>
								)}
							</div>
						</div>
					</Stack>
				),
			},
			{
				label: ts("employees"),
				format: (row) => (
					<Stack alignItems="center" direction="row" spacing={1}>
						<Stack alignItems={"center"} direction="row" justifyContent="flex-start" mb={4}>
							<div className="text-sm">{row.estimatedNumEmployees}</div>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("industry"),
				minWidth: 200,
				format: (row) => (
					<Stack alignItems="center" direction="row" spacing={1}>
						<Stack alignItems={"center"} direction="row" justifyContent="flex-start" mb={4}>
							<div className="text-sm">{row.industry}</div>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("keywords"),
				minWidth: 200,
				format: (row) => (
					<Stack alignItems="center" direction="row" spacing={1}>
						<Stack alignItems={"center"} direction="row" justifyContent="flex-start" mb={4}>
							<div className="text-sm">{row.keywords?.join(", ").substring(0, 100)}...</div>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("location"),
				minWidth: 200,
				format: (row) => (
					<Stack alignItems="center" direction="row" spacing={1}>
						<Stack alignItems={"center"} direction="row" justifyContent="flex-start" mb={4}>
							<div className="text-sm">
								{row.city}, {row.country}
							</div>
						</Stack>
					</Stack>
				),
			},
		],
		[ts]
	);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(0);
	};

	return !data ? (
		<LoadingOverlay />
	) : (
		<Card>
			<TableComponent
				columns={columns}
				data={data.data || []}
				handleChangeLimit={handleChangeRowsPerPage}
				handleChangePage={(_: unknown, page: number) => setPage(page)}
				hidePagination={true}
				itemCount={data.meta.total_entries ?? 0}
				limit={limit}
				page={page}
			/>
		</Card>
	);
};
