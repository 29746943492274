export enum PermissionRoles {
	CREATE_ROLE = "CREATE_ROLE",
	READ_ROLES = "READ_ROLES",
	UPDATE_ROLE = "UPDATE_ROLE",
	DELETE_ROLE = "DELETE_ROLE",
	INVITE_USER = "INVITE_USER",
	GET_USER = "GET_USER",
	GET_ME = "GET_ME",
	DELETE_USER = "DELETE_USER",
	CHANGE_PASSWORD = "CHANGE_PASSWORD",
	UPDATE_ME = "UPDATE_ME",
	UPDATE_USER = "UPDATE_USER",
	UPDATE_USER_ROLE = "UPDATE_USER_ROLE",
	GENERATE_2FA = "GENERATE_2FA",
	GET_PERMISSION = "GET_PERMISSION",
	UPDATE_PERMISSION = "UPDATE_PERMISSION",
	MANAGE_CONFIGURATION = "MANAGE_CONFIGURATION",
	BLACKLIST_JWT = "BLACKLIST_JWT",
	LOGOUT = "LOGOUT",
	DISABLE_2FA = "DISABLE_2FA",
	VERIFY_2FA = "VERIFY_2FA",
	CREATE_PITCHDECK = "CREATE_PITCHDECK",
	UPDATE_PITCHDECK = "UPDATE_PITCHDECK",
	READ_PITCHDECKS = "READ_PITCHDECKS",
	DELETE_PITCHDECK = "DELETE_PITCHDECK",
	UPLOAD_FILES = "UPLOAD_FILES",
	SEARCH_APOLLO = "SEARCH_APOLLO",
	CREATE_APOLLO = "CREATE_APOLLO",
	UPDATE_APOLLO = "UPDATE_APOLLO",
	DELETE_APOLLO = "DELETE_APOLLO",
	STEPSTONE = "STEPSTONE",
	INDEED = "INDEED",
	COOKIE = "COOKIE",
	SXWX = "SXWX",
	EVENT_TRANSFORM = "EVENT_TRANSFORM",
	AI_ENRICHMENTS = "AI_ENRICHMENTS",
	CSV_ENRICHMENTS = "CSV_ENRICHMENTS",
	LI_EVENT = "LI_EVENT",
	LI_PEOPLE = "LI_PEOPLE",
	LI_COMPANY_ACTIVITY = "LI_COMPANY_ACTIVITY",
	LI_PROFILE_VISITS = "LI_PROFILE_VISITS",
	LI_PROFILE = "LI_PROFILE",
	LIST = "LIST",
	NORTHDATA = "NORTHDATA",
	LEMLIST = "LEMLIST",
	CUSTOM_EVENTS = "CUSTOM_EVENTS",
	DESKTOP = "DESKTOP",
	BLACKLIST = "BLACKLIST",
	COMPANY = "COMPANY",
	GLOBAL_ADMIN = "GLOBAL_ADMIN",
	TESTER = "TESTER",
	JOB = "JOB",
}
