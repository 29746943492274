import { FunctionComponent, useState } from "react";

import { FileUploader } from "react-drag-drop-files";

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";

import { Button } from "@/components/Button/Button";

import {
	useLazyDownloadCsvEnrichmentQuery,
	useUpdateCsvEnrichmentFileMutation,
} from "@/pages/Private/redux/csvEnrichment/csvEnrichment.api";

import { List } from "../schema/list";
import {
	CreateCsvEnrichment,
	CreateCsvEnrichmentSchema,
	CsvEnrichment,
} from "../../CsvEnrichment/schema/csvEnrichment";

export interface DownloadSourceProps {
	list: List;
}

export const DownloadSource: FunctionComponent<DownloadSourceProps> = ({ list }) => {
	const [updateCsvEnrichment, { isLoading }] = useUpdateCsvEnrichmentFileMutation();
	const [fileContent, setFileContent] = useState<{
		file: string;
		id: number;
	}>();

	const [download, { isLoading: downloadIsLoading }] = useLazyDownloadCsvEnrichmentQuery();

	const { handleSubmit, setValue, getValues } = useForm<CreateCsvEnrichment>({
		defaultValues: {
			withEmail: false,
		},
		resolver: zodResolver(CreateCsvEnrichmentSchema),
	});

	const onSubmit = async (values: CreateCsvEnrichment) => {
		try {
			const { file: fileRaw } = values;

			const formData = new FormData();
			const res: Response = await fetch(fileRaw);
			const blob: Blob = await res.blob();
			const file = new File([blob], "file.csv");

			formData.append("file", file);
			formData.append("id", fileContent?.id.toString() || "");
			formData.append("type", "xlsx");

			if (fileContent) {
				const csvId = fileContent.id;

				await updateCsvEnrichment({
					form: formData,
					listId: list.id,
					id: csvId,
				}).unwrap();
			}
		} catch (err) {
			console.error(err);
		}
	};

	const handleDownload = (csvEnrichment: CsvEnrichment) => async () => {
		const blob = await download(csvEnrichment.id).unwrap();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");

		a.href = url;
		a.download = `${csvEnrichment.name}.xlsx`;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	};

	return (
		<div className="w-full mt-8 bg-brand-white p-4 rounded-xl border border-gray-200">
			<div className="text-md mb-8">Files has been provided</div>
			<div className="w-full bg-white border border-divider rounded-xl p-4 text-sm">
				To make sure that you get the best results possible, you can download this list and remove
				not needed records, before you proceed.
			</div>
			{list?.csvEnrichments?.map((csvEnrichment, index) => {
				return (
					<div key={`csvEnrichment-${index * 2}`}>
						{/* {previewIsLoading && <Loader />} */}

						<div className="bg-white border flex flex-col gap-4 border-divider rounded-xl p-4 mt-4">
							<div className="w-full bg-white rounded-xl text-sm">
								{csvEnrichment.name} includes {csvEnrichment.amountInCsv} records
							</div>
							<div className="mb-4">
								<Button
									isLoading={downloadIsLoading}
									title={`Download ${csvEnrichment.name}`}
									onClick={handleDownload(csvEnrichment)}
								/>
							</div>

							<div className="w-full bg-white rounded-xl text-sm">
								to change your results, you can upload a new list
							</div>

							<FileUploader
								handleChange={(file: File) => {
									console.log(file);
									if (!getValues("name")) {
										setValue("name", file.name, {
											shouldValidate: true,
											shouldDirty: true,
										});
									}

									setValue("file", URL.createObjectURL(file));
									setFileContent({
										file: URL.createObjectURL(file),
										id: csvEnrichment.id || 0,
									});
								}}
								name="file"
								types={["xlsx"]}
							/>

							<Button
								disabled={fileContent?.id !== csvEnrichment.id}
								isLoading={isLoading}
								title={`Upload new ${csvEnrichment.name}`}
								onClick={handleSubmit(onSubmit)}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
};
