import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { Campaigns } from "./pages/Campaigns/Campaigns";
import { Replies } from "./pages/Replies/Replies";
import { NoReply } from "./pages/NoReply/NoReply";
import { ToBeNurtured } from "./pages/ToBeNurtured/ToBeNurtured";
import { DoNotContact } from "./pages/DoNotContact/DoNotContact";

export const ListView: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<Campaigns />} path="/campaigns" />
			<Route element={<Replies />} path="/replies" />
			<Route element={<NoReply />} path="/no-reply" />
			<Route element={<ToBeNurtured />} path="/to-be-nurtured" />
			<Route element={<DoNotContact />} path="/do-not-contact" />
		</Routes>
	);
};
