import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

export const ApolloOrgSchema = z.object({
	id: z.number(),
	name: z.string(),
	organizationid: z.string(),
	emailFormat: z.string().nullable().optional(),
	city: z.string().nullable().optional(),
	country: z.string().nullable().optional(),
	industry: z.string().nullable().optional(),
	keywords: z.string().nullable().optional(),
	logoUrl: z.string().nullable().optional(),
	postalCode: z.string().nullable().optional(),
	rawAddress: z.string().nullable().optional(),
	twitterUrl: z.string().nullable().optional(),
	websiteUrl: z.string().nullable().optional(),
	facebookUrl: z.string().nullable().optional(),
	linkedinUrl: z.string().nullable().optional(),
	foundedYear: z.number().nullable().optional(),
	annualRevenue: z.number().nullable().optional(),
	primaryDomain: z.string().nullable().optional(),
	seoDescription: z.string().nullable().optional(),
	shortDescription: z.string().nullable().optional(),
	estimatedNumEmployees: z.number().nullable().optional(),
});
export const ApolloOrgResponseSchema = z.object({
	data: z.array(ApolloOrgSchema),
	meta: MetaDataSchema,
});

export type ApolloOrg = z.infer<typeof ApolloOrgSchema>;

export type ApolloOrgResponse = z.infer<typeof ApolloOrgResponseSchema>;
