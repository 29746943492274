import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { classNames } from "@/utils/classNames";

import { ReactComponent as ChevronUp } from "@assets/icons/inbox/chevron-up.svg";
import { ReactComponent as ChevronDown } from "@assets/icons/inbox/chevron-down.svg";
import { SvgIcon } from "@/components/Icon/SvgIcon";

import { ReactComponent as Tick } from "@assets/icons/inbox/tick.svg";

import { AssignStatus } from "@/enum/list.enum";

export const DefaultValues = [
	{
		name: AssignStatus.RUNNING,
		path: `/app/list-view/campaigns`,
	},
	{
		name: AssignStatus.REPLIES,
		path: `/app/list-view/replies`,
	},
	{
		name: AssignStatus.NO_REPLY,
		path: `/app/list-view/no-reply`,
	},
	{
		name: AssignStatus.TO_BE_NURTURED,
		path: `/app/list-view/to-be-nurtured`,
	},
	{
		name: AssignStatus.DO_NOT_CONTACT,
		path: `/app/list-view/do-not-contact`,
	},
];

export interface CustomListViewListItem {
	name: AssignStatus;
	path: string;
}

export interface CustomListViewListAutocompleteProps {
	handleSelect: (value?: Partial<CustomListViewListItem>) => void;
	selectedItem: string;
	data: CustomListViewListItem[];
	label?: string;
	testId?: string;
	floating?: boolean;
}

export const CustomListViewListAutocomplete: FunctionComponent<
	CustomListViewListAutocompleteProps
> = ({ handleSelect, selectedItem, data, label, floating }) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`inbox.${key}`), [t]);

	// Dropdown Logic
	const [showDropdown, setShowDropdown] = useState(false);
	const selectFieldRef = useRef<HTMLDivElement>(null);

	const handleSelectItem = (selected: Partial<CustomListViewListItem>) => {
		handleSelect(selected);
		setShowDropdown(false);
	};

	useEffect(() => {
		const handleClick = ({ target }: MouseEvent) => {
			if (target && target instanceof HTMLElement) {
				if (target.id === "closeIcon") {
				} else if (selectFieldRef.current && !selectFieldRef.current.contains(target as Node)) {
					setShowDropdown(false);
				}
			}
		};

		window.addEventListener("click", handleClick);

		return () => window.removeEventListener("click", handleClick);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectFieldRef, showDropdown]);

	// Result rendering
	const renderResults = () => {
		const results = data;

		return (
			<div className={classNames("flex flex-col w-full ")} id="CustomListAutocomplete-items">
				<div className="border-t border-t-border box-border mx-3"></div>

				<>
					{results.map((item) => {
						return (
							<button
								key={`option-select-${item}`}
								className={classNames(
									"w-full flex items-center flex-row justify-between py-2.5 px-3 text-sm text-gray-900 bg-white hover:bg-gray-100 duration-200 cursor-pointer relative"
								)}
								onClick={() => {
									handleSelectItem(item);
								}}
							>
								<div className="flex items-center flex-row">
									<div className="mr-3 ml-2">{ts(item.name)}</div>
								</div>

								{item.name === selectedItem && (
									<SvgIcon className="w-[20px] h-[20px] text-brand-light" svgIcon={Tick} />
								)}
							</button>
						);
					})}
					{!results.length && (
						<p className="py-2.5 px-3 text-sm italic text-gray-700">{t("basics.noResults")}</p>
					)}
				</>
			</div>
		);
	};

	const handleClickInput = () => setShowDropdown(!showDropdown);

	return (
		<div ref={selectFieldRef} className="relative z-20">
			<div className="flex items-center cursor-pointer" onClick={handleClickInput}>
				<div className="text-lg font-semibold leading-8 mr-3">{label}</div>
				<SvgIcon
					className="w-[24px] h-[24px] text-gray-700"
					svgIcon={showDropdown ? ChevronUp : ChevronDown}
				/>
			</div>

			{showDropdown && (
				<div
					className={classNames(
						" w-[250px] mt-1 overflow-hidden bg-white border border-gray-200 rounded-md h-[200px] overflow-y-auto bb-scrollbar-darker",
						floating ? "absolute" : "relative"
					)}
					id="CustomListAutocomplete-element"
				>
					{renderResults()}
				</div>
			)}
		</div>
	);
};
