import { z } from "zod";

import { InviteFormSchema } from "./invite-user";

export const UpdateUserFormSchema = InviteFormSchema.partial();
export const UpdateUserSchema = UpdateUserFormSchema.omit({ roles: true, companyId: true });

export type UpdateUserForm = z.infer<typeof UpdateUserFormSchema>;
export type UpdateUser = z.infer<typeof UpdateUserSchema>;

export const UpdateUserRolesSchema = z.object({
	roles: z.array(z.object({ id: z.number().gt(0) })),
});

export type UpdateUserRoles = z.infer<typeof UpdateUserRolesSchema>;
