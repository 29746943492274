import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { Modal } from "@/components";

import { useCreateJobMutation } from "@/pages/Private/redux/job/job.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { Dropdown, AutoCompleteItem } from "@/components/Dropdown/Dropdown";
import { EmploymentType, JobScrapeType } from "@/enum/job.enum";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { Radio } from "@/components/Radio/Radio";

import { CreateJob, CreateJobSchema } from "../schema/job";

interface createJobModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateJobModal: FunctionComponent<createJobModalProps> = ({ isOpen, setIsOpen }) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`job.${key}`);

	const [employmentType, setEmploymentType] = useState<EmploymentType>(EmploymentType.FULLTIME);

	const [createJob, { error, isLoading }] = useCreateJobMutation();

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<CreateJob>({
		defaultValues: {
			name: "",
			query: "",
			employmentType: EmploymentType.FULLTIME,
			jobScrapeType: JobScrapeType.GET_INFO_AT,
			repeatDaily: false,
		},
		resolver: zodResolver(CreateJobSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: CreateJob) => {
		try {
			await createJob({ ...values, employmentType }).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>

				<TextField
					error={!!errors.query?.message}
					label={ts("query")}
					placeholder={ts("queryPlaceholder")}
					{...register("query")}
				/>

				<Dropdown
					data={[
						{
							title: ts(EmploymentType.FULLTIME),
							id: EmploymentType.FULLTIME,
						},
						{
							title: ts(EmploymentType.PARTTIME),
							id: EmploymentType.PARTTIME,
						},
						{
							title: ts(EmploymentType.INTERN),
							id: EmploymentType.INTERN,
						},
						{
							title: ts(EmploymentType.CONTRACTOR),
							id: EmploymentType.CONTRACTOR,
						},
					]}
					floating={true}
					handleSelect={(value: AutoCompleteItem) => {
						setEmploymentType(value.id as EmploymentType);
					}}
					label={ts("employmentType")}
				/>

				<div className="flex flex-col">
					<div className="text-smd font-semibold mb-2">{ts("scrapeLevel")}</div>
					<Radio
						className="mb-8"
						isChecked={getValues("jobScrapeType") === JobScrapeType.GET_INFO_AT}
						name="getInfoAt"
						value="getInfoAt"
						onChange={() =>
							setValue("jobScrapeType", JobScrapeType.GET_INFO_AT, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("getInfoAt")}
					</Radio>
					<Radio
						className="mb-8"
						isChecked={getValues("jobScrapeType") === JobScrapeType.GET_MANAGING_DIRECTOR}
						name="getManagingDirector"
						value="getManagingDirector"
						onChange={() =>
							setValue("jobScrapeType", JobScrapeType.GET_MANAGING_DIRECTOR, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("getManagingDirector")}
					</Radio>
					<Radio
						className="mb-8"
						isChecked={getValues("jobScrapeType") === JobScrapeType.GET_ENRICHMENT}
						name="getEnrichment"
						value="getEnrichment"
						onChange={() =>
							setValue("jobScrapeType", JobScrapeType.GET_ENRICHMENT, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("getEnrichment")}
					</Radio>
					<Radio
						className="mb-8"
						isChecked={getValues("jobScrapeType") === JobScrapeType.GET_PERSON_FROM_OFFER}
						name="getPersonFromOffer"
						value="getPersonFromOffer"
						onChange={() =>
							setValue("jobScrapeType", JobScrapeType.GET_PERSON_FROM_OFFER, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("getPersonFromOffer")}
					</Radio>
				</div>

				<div className="flex flex-col">
					<div className="text-smd font-semibold mb-2">{ts("scrapeDaily")}</div>
					<Checkbox
						className="mb-8"
						isChecked={getValues("repeatDaily")}
						onChange={() =>
							setValue("repeatDaily", !getValues("repeatDaily"), {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("repeatDaily")}
					</Checkbox>
				</div>

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</Stack>
		</Modal>
	);
};
