import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	LiProfileVisits,
	LiProfileVisitsResponse,
	LiProfileVisitsResponseSchema,
	LiProfileVisitsSchema,
	UpdateLiProfileVisits,
} from "../../pages/LiProfileVisits/schema/liProfileVisits";

export const liProfileVisitsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getLiProfileVisits: builder.query<LiProfileVisitsResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "liProfileVisits",
				params: {
					...(params && { ...params }),
				},
				responseSchema: LiProfileVisitsResponseSchema,
			}),
			providesTags: ["LiProfileVisits"],
		}),
		getLiProfileVisit: builder.query<LiProfileVisits, number>({
			query: (id: number) => ({
				url: `liProfileVisits/${id}`,
				params: {
					id,
				},
				responseSchema: LiProfileVisitsSchema,
			}),
			providesTags: ["LiProfileVisits"],
		}),
		createLiProfileVisits: builder.mutation<Partial<LiProfileVisits>, Partial<LiProfileVisits>>({
			query: (body) => ({
				url: `liProfileVisits`,
				method: "POST",
				body,
				responseSchema: LiProfileVisitsSchema,
			}),
			invalidatesTags: ["LiProfileVisits"],
		}),
		updateLiProfileVisits: builder.mutation<Partial<LiProfileVisits>, UpdateLiProfileVisits>({
			query: ({ id, ...body }) => ({
				url: `liProfileVisits/${id}`,
				method: "PATCH",
				body,
				responseSchema: LiProfileVisitsSchema,
			}),
			invalidatesTags: ["LiProfileVisits"],
		}),
		deleteLiProfileVisits: builder.mutation<Partial<LiProfileVisits>, number>({
			query: (id) => ({
				url: `liProfileVisits/${id}`,
				method: "DELETE",
				responseSchema: LiProfileVisitsSchema,
			}),
			invalidatesTags: ["LiProfileVisits"],
		}),
	}),
});

export const {
	useGetLiProfileVisitsQuery,
	useLazyGetLiProfileVisitsQuery,
	useGetLiProfileVisitQuery,
	useUpdateLiProfileVisitsMutation,
	useDeleteLiProfileVisitsMutation,
	useCreateLiProfileVisitsMutation,
} = liProfileVisitsApi;
export const accountApiReducer = liProfileVisitsApi.reducer;
export const accountApiMiddleware = liProfileVisitsApi.middleware;
