import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { LiPeopleOverview } from "./pages/LiPeopleOverview/LiPeopleOverview";

export const LiPeople: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<LiPeopleOverview />} path="/" />
		</Routes>
	);
};
