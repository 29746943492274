import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";

interface ApolloTagAutocompleteProps {
	value?: string[];
	kind: string;
	type: string;
	handleValueChange: (value: string[]) => void;
}

export const TagAutocomplete: FunctionComponent<ApolloTagAutocompleteProps> = ({
	value,
	handleValueChange,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`prospectSearch.${key}`);

	return (
		<>
			<Autocomplete
				freeSolo
				multiple
				id="tags-filled"
				options={[]}
				renderInput={(params) => <TextField {...params} label={ts("keywords")} variant="filled" />}
				size="small"
				sx={{
					width: "100%",
				}}
				value={value}
				onChange={(event, value) => {
					handleValueChange(value);
				}}
			/>
		</>
	);
};
