import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Stack, TextField, Typography } from "@mui/material";

import { faPen, faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import { format, parseISO } from "date-fns";

import clockIcon from "@assets/icons/clock.svg";

import {
	useDeleteLiEventMutation,
	useGetLiEventsQuery,
	useToggleLiEventMutation,
} from "@/pages/Private/redux/liEvent/liEvent.api";
import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import { Toggle } from "@/components/Toggle/Toggle";

import { ListStatus } from "@/enum/list.enum";

import { LiEvent } from "../schema/liEvent";
import { LiEventStatus } from "./LiEventStatus";
import { EditLiEventModal } from "./EditLiEventModal";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LiEventTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`liEvent.${key}`), [t]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [selectedLiEvent, setSelectedLiEvent] = useState<LiEvent | null>(null);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [deleteLiEvent] = useDeleteLiEventMutation();
	const [toggleLiEvent] = useToggleLiEventMutation();

	const { data, isLoading } = useGetLiEventsQuery({
		page,
		limit,
		searchValue: query,
	});

	const handleToggle = async (id: number, status: ListStatus) => {
		const updater: Partial<LiEvent> = {
			id: id,
		};

		if (status === ListStatus.ACTIVE) {
			updater.activeStatus = ListStatus.PAUSED;
		} else if (status === ListStatus.PAUSED) {
			updater.activeStatus = ListStatus.ACTIVE;
		}

		await toggleLiEvent({ ...updater }).unwrap();
	};

	const columns: TableColumn<LiEvent>[] = useMemo(
		() => [
			{
				label: "",
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								<Toggle
									isChecked={row?.activeStatus === ListStatus.ACTIVE}
									onChange={() => {
										if (row.id && row.activeStatus !== ListStatus.ERROR) {
											handleToggle(row.id, row.activeStatus);
										}
									}}
								/>
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Link href={`/app/linkedin-events/${row?.id}`}>
								<Typography color="inherit" mb={0} variant="body2">
									{row?.name}
								</Typography>
							</Link>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("status"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<LiEventStatus status={row.status} />
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("lastRunAt"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row.lastRunAt ? format(parseISO(row.lastRunAt), "HH:mm dd.MM.yyyy") : ""}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("nextRunAt"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row.lastRunAt ? (
									<div className="flex items-center">
										<img alt="clockicon" className="text-green mr-1" src={clockIcon} />
										{format(
											new Date(parseISO(row.lastRunAt).getTime() + 1000 * 60 * 60 * 24 * 5),
											"HH:mm dd.MM.yyyy"
										)}
									</div>
								) : (
									""
								)}
							</Typography>
						</Stack>
					</Stack>
				),
			},

			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						{/* <Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Link href={row.urls[0]} target="_blank">
								<Typography color="inherit" mb={0} variant="body2">
									<img alt="open link in new tab" src={launchIcon} />
								</Typography>
							</Link>
						</Stack> */}

						<PermissionCheck requiredPermissions={[PermissionRoles.LI_EVENT]}>
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										color: "primary.main",
										cursor: "pointer",
									},
								}}
								onClick={() => handleEditModal(row)}
							>
								<Icon icon={faPen} size="xl" />
							</Box>
						</PermissionCheck>
						<PermissionCheck requiredPermissions={[PermissionRoles.LI_EVENT]}>
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										color: "primary.main",
										cursor: "pointer",
									},
								}}
								onClick={() => handleDeleteModal(row)}
							>
								<Icon icon={faTrashCan} size="xl" />
							</Box>
						</PermissionCheck>
					</Stack>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ts]
	);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	const handleEditModal = (row: LiEvent) => {
		setShowEditModal(true);
		setSelectedLiEvent(row);
	};

	const handleDeleteModal = (row: LiEvent) => {
		setShowDeleteModal(true);
		setSelectedLiEvent(row);
	};

	const handleDeleteLiEvent = async () => {
		try {
			await deleteLiEvent({ id: selectedLiEvent?.id ?? 0 }).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	return !data ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteLiEvent}
					isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedLiEvent?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}

			{showEditModal && selectedLiEvent && (
				<EditLiEventModal
					isOpen={showEditModal}
					liEvent={selectedLiEvent}
					setIsOpen={setShowEditModal}
				/>
			)}
		</div>
	);
};
