import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { BackLink } from "@/components/BackLink/BackLink";

import { CreateOpenAiModal } from "../../components/CreateOpenAiModal";
import { OpenAiPromptTable } from "../../components/OpenAiPromptTable";

export const OpenAiDetail: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`openAi.${key}`);
	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<BackLink />
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<h3 className="mb-3">{ts("title")}</h3>
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">{ts("subtitle")}</p>
				</div>
			</div>

			{<OpenAiPromptTable />}

			{isInviteModalOpen && (
				<CreateOpenAiModal isOpen={isInviteModalOpen} setIsOpen={setIsInviteModalOpen} />
			)}
		</div>
	);
};
