import { InclusionStatus } from "@/enum/list.enum";

import { SvgIcon } from "@/components/Icon/SvgIcon";
import { ContextMenu, MenuItem } from "@/components/ContextMenu/ContextMenu";
import { ReactComponent as Tick } from "@assets/icons/inbox/tick.svg";
import { ReactComponent as Reassign } from "@assets/icons/inbox/reassign.svg";
import { ReactComponent as Clock } from "@assets/icons/inbox/clock.svg";
import { ReactComponent as Exclude } from "@assets/icons/inbox/exclude.svg";
import { ReactComponent as Include } from "@assets/icons/inbox/include.svg";
import { ReactComponent as CrossedCircle } from "@assets/icons/inbox/crossed-circle.svg";
import { ReactComponent as Flag } from "@assets/icons/inbox/flag.svg";
import { ReactComponent as ThreeDots } from "@assets/icons/three-dots.svg";

import { ListPerson } from "../schema/list";

export const createContextMenuItems = (
	row: ListPerson,
	handleContextMenu: (value: string, row: ListPerson) => void,
	ts: (key: string) => string
): MenuItem[][] => {
	const itemsBlock1: MenuItem[] = [
		{
			title: ts("confirmAssignment"),
			icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={Tick} />,
			onClick: () => handleContextMenu && handleContextMenu("confirmAssignment", row),
		},
		{
			title: ts("reassign"),
			icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={Reassign} />,
			onClick: () => handleContextMenu && handleContextMenu("reassign", row),
		},
		row.inclusionStatus === InclusionStatus.SAVED_FOR_LATER
			? {
					title: ts("revert"),
					icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={Clock} />,
					onClick: () => handleContextMenu && handleContextMenu("revert", row),
			  }
			: {
					title: ts("saveForLater"),
					icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={Clock} />,
					onClick: () => handleContextMenu && handleContextMenu("saveForLater", row),
			  },
	];

	const itemsBlock2: MenuItem[] = [];

	if (
		row.inclusionStatus === InclusionStatus.MAYBE ||
		row.inclusionStatus === InclusionStatus.ADDED
	) {
		itemsBlock2.push({
			title: ts("excludeFromList"),
			icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={Exclude} />,
			onClick: () => handleContextMenu && handleContextMenu("excludeFromList", row),
		});
	} else if (
		row.inclusionStatus === InclusionStatus.EXCLUDED ||
		row.inclusionStatus === InclusionStatus.MANUALLY_EXCLUDED
	) {
		itemsBlock2.push({
			title: ts("addToList"),
			icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={Include} />,
			onClick: () => handleContextMenu && handleContextMenu("addToList", row),
		});
	}

	if (row.inclusionStatus !== InclusionStatus.BLACKLIST) {
		itemsBlock2.push({
			title: ts("toBlackList"),
			icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={CrossedCircle} />,
			onClick: () => handleContextMenu && handleContextMenu("toBlackList", row),
		});
	} else {
		itemsBlock2.push({
			title: ts("revertFromBlacklist"),
			icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={Clock} />,
			onClick: () => handleContextMenu && handleContextMenu("revertFromBlacklist", row),
		});
	}

	itemsBlock2.push({
		title: ts("reportRecord"),
		icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={Flag} />,
		onClick: () => handleContextMenu && handleContextMenu("reportRecord", row),
	});

	return [itemsBlock1, itemsBlock2].filter((block) => block.length > 0);
};

export const getContextMenuItem = (
	handleContextMenu: (value: string, row: ListPerson) => void,
	ts: (key: string) => string
) => {
	return {
		title: "",
		width: "fit-content",
		locked: "leftFourth",
		allowExclude: false,
		orderStatus: 0,
		hideable: false,
		moveable: false,
		uid: "contextMenu",
		show: true,
		render: (row: ListPerson) => (
			<>
				{handleContextMenu && (
					<>
						<div className="flex items-center">
							<ContextMenu
								data={createContextMenuItems(row, handleContextMenu, ts)}
								position="bottom"
								width="200"
							>
								<SvgIcon
									className="w-[20px] h-[20px] text-gray-700 cursor-pointer"
									svgIcon={ThreeDots}
								/>
							</ContextMenu>
						</div>
					</>
				)}
			</>
		),
	};
};
