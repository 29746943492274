import { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
// eslint-disable-next-line import/no-duplicates
import { de } from "date-fns/locale";
import { zodResolver } from "@hookform/resolvers/zod";
// eslint-disable-next-line import/no-duplicates
import { format } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { deDE } from "@mui/x-date-pickers/locales";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
	useCreateCustomEventMutation,
	useUpdateCustomEventMutation,
} from "@/pages/Private/redux/customEvent/customEvent.api";

import { EventType } from "@/enum/custom-event.enum";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import { Button } from "@/components/Button/Button";
import { ButtonColor } from "@/components/Button/types";
import { InputField } from "@/components/InputField/InputField";

import { classNames } from "@/utils/classNames";

import { List } from "../../List/schema/list";
import { CreateCustomEvent, CreateCustomEventSchema, CustomEvent } from "../schema/customEvent";

interface CreateCustomEventFormProps {
	customEvent?: CustomEvent | null;
	list: List;
}

export const CreateCustomEventForm: FunctionComponent<CreateCustomEventFormProps> = ({
	customEvent,
	list,
}) => {
	console.log(customEvent);
	const { t } = useTranslation();
	const ts = (key: string) => t(`customEvent.${key}`);
	const [createCustomEvent, { error, isLoading }] = useCreateCustomEventMutation();
	const [updateCustomEvent, { error: updateError, isLoading: updateIsLoading }] =
		useUpdateCustomEventMutation();
	const [accessProvided, setAccessProvided] = useState<boolean>(false);

	const InitialState = useMemo(() => {
		return {
			eventType: EventType.ATTENDEES,
		};
	}, []);

	const [currentFormState, setCurrentFormState] = useState<Partial<CreateCustomEvent>>(
		customEvent
			? {
					...customEvent,
					eventAt: new Date(customEvent.eventAt),
					listProvidedUntil: new Date(customEvent.listProvidedUntil),
			  }
			: InitialState
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof CreateCustomEvent) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const {
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isDirty },
	} = useForm<CreateCustomEvent>({
		defaultValues: InitialState,
		resolver: zodResolver(CreateCustomEventSchema),
	});

	useEffect(() => {
		if (customEvent) {
			console.log(customEvent);
			reset({
				id: customEvent.id,
				name: customEvent.name,
				url: customEvent.url,
				eventType: customEvent.eventType,
				eventAt: new Date(customEvent.eventAt),
				listProvidedUntil: new Date(customEvent.listProvidedUntil),
				loginNotes: customEvent.loginNotes,
			});

			if (customEvent.credentialsSaved) {
				setAccessProvided(true);
			}
		} else {
			reset(InitialState);
		}
	}, [InitialState, customEvent, reset]);

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: CreateCustomEvent) => {
		try {
			if (customEvent?.id) {
				await updateCustomEvent({ id: customEvent.id, ...values, listId: list.id }).unwrap();
			} else {
				await createCustomEvent({ ...values, listId: list.id }).unwrap();
			}
		} catch (err) {
			console.error(err);
		}
	};

	console.log(errors);

	return (
		<div className="">
			<div className="text-md mb-5">Sources</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.name?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "name");
					}}
					label={ts("name")}
					name={"name"}
					placeholder={ts("namePlaceholder")}
					value={currentFormState?.name || ""}
				/>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.url?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "url");
					}}
					label={ts("url")}
					name={"url"}
					placeholder={ts("urlPlaceholder")}
					value={currentFormState?.url || ""}
				/>
			</div>

			<div className="flex flex-col w-full mb-4">
				<div className="relative flex items-center justify-between mb-2">
					<label
						className={classNames("block text-sm font-medium text-gray-900 appearance-none")}
						htmlFor={"eventAt"}
					>
						{ts("eventAt")}
					</label>
				</div>
				<div className="relative">
					<LocalizationProvider
						adapterLocale={de}
						dateAdapter={AdapterDateFns}
						localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
					>
						<DatePicker
							// label={ts("fromDate")}
							name="eventAt"
							slotProps={{
								field: {
									readOnly: true,
								},
							}}
							sx={{
								width: "100%",
								minHeight: 48,
								marginBottom: 1,
								"& .MuiFilledInput-root": {
									minHeight: 48,
									marginBottom: 0,
									borderRadius: 1.5,
									paddingTop: "10px",
									paddingBottom: "10px !important",
									paddingLeft: 1.5,
									"& .MuiFilledInput-input": {
										padding: 0,
									},
								},
							}}
							value={currentFormState.eventAt || null}
							onChange={(value) => {
								if (value !== null) {
									const formattedDate = new Date(format(value, "yyyy-MM-dd"));

									handleSaveFormState(formattedDate, "eventAt");
								}
							}}
						/>
					</LocalizationProvider>
				</div>
			</div>

			<div className="flex flex-col w-full mb-4">
				<div className="relative flex items-center justify-between mb-2">
					<label
						className={classNames("block text-sm font-medium text-gray-900 appearance-none")}
						htmlFor={"listProvidedUntil"}
					>
						{ts("listProvidedUntil")}
					</label>
				</div>
				<div className="relative">
					<LocalizationProvider
						adapterLocale={de}
						dateAdapter={AdapterDateFns}
						localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
					>
						<DatePicker
							name="listProvidedUntil"
							slotProps={{
								field: {
									readOnly: true,
								},
							}}
							sx={{
								width: "100%",
								minHeight: 48,
								marginBottom: 1,
								"& .MuiFilledInput-root": {
									minHeight: 48,
									marginBottom: 0,
									borderRadius: 1.5,
									paddingTop: "10px",
									paddingBottom: "10px !important",
									paddingLeft: 1.5,
									"& .MuiFilledInput-input": {
										padding: 0,
									},
								},
							}}
							value={currentFormState.listProvidedUntil || null}
							onChange={(value) => {
								if (value !== null) {
									const formattedDate = new Date(format(value, "yyyy-MM-dd"));

									handleSaveFormState(formattedDate, "listProvidedUntil");
								}
							}}
						/>
					</LocalizationProvider>
				</div>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					className="w-full min-h-[100px]"
					error={!!errors.loginNotes?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "loginNotes");
					}}
					label={ts("loginNotes")}
					multiline={true}
					name={"loginNotes"}
					placeholder={ts("loginNotesPlaceholder")}
					value={currentFormState?.loginNotes || ""}
				/>
			</div>

			{accessProvided ? (
				<div className="flex flex-row gap-4 w-full mb-4">
					<Button
						color={ButtonColor.DANGER}
						title="Reset access"
						onClick={() => {
							setAccessProvided(false);
						}}
					/>
				</div>
			) : (
				<>
					<div className="flex flex-row gap-4 w-full mb-4">
						<InputField
							error={!!errors.username?.message}
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								handleSaveFormState(event.target.value, "username");
							}}
							label={ts("username")}
							name={"username"}
							placeholder={ts("usernamePlaceholder")}
							value={currentFormState?.username || ""}
						/>
					</div>

					<div className="flex flex-row gap-4 w-full mb-4">
						<InputField
							error={!!errors.password?.message}
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								handleSaveFormState(event.target.value, "password");
							}}
							label={ts("password")}
							name={"password"}
							placeholder={ts("passwordPlaceholder")}
							type="password"
							value={currentFormState?.password || ""}
						/>
					</div>

					<div className="flex flex-row gap-4 w-full mb-4">
						<InputField
							className="w-full min-h-[100px]"
							error={!!errors.otpSecret?.message}
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								handleSaveFormState(event.target.value, "otpSecret");
							}}
							label={ts("otpSecret")}
							multiline={true}
							name={"otpSecret"}
							placeholder={ts("otpSecretPlaceholder")}
							value={currentFormState?.otpSecret || ""}
						/>
					</div>
				</>
			)}

			<div className="mb-2">
				{getAllErrors(error || updateError, formErrors).length
					? renderErrorMessages(getAllErrors(error || updateError, formErrors))
					: null}
			</div>
			<div className="flex gap-4 w-full justify-end">
				<div className="flex max-w-[100px] w-full">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						title="cancel"
						onClick={() => {
							if (customEvent) {
								reset({
									id: customEvent.id,
									name: customEvent.name,
									url: customEvent.url,
									eventType: customEvent.eventType,
									eventAt: new Date(customEvent.eventAt),
									listProvidedUntil: new Date(customEvent.listProvidedUntil),
									loginNotes: customEvent.loginNotes,
								});
							} else {
								reset(InitialState);
							}
						}}
					/>
				</div>
				<div className="flex max-w-[150px] w-full">
					<Button
						disabled={!isDirty}
						isLoading={isLoading || updateIsLoading}
						title={customEvent?.id ? "Save" : "Upload"}
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</div>
		</div>
	);
};
