import { ChangeEvent, FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";

import { Error, CheckCircle } from "@mui/icons-material";

import { useParams } from "react-router-dom";

import { useGetJobQuery } from "@/pages/Private/redux/job/job.api";
import { LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { useSearch } from "@/utils";

import { Enrichments } from "@/enum/enrichments.enum";

import { Offer } from "../schema/offer";

export const OfferTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();
	const ts = useCallback((key: string) => t(`job.${key}`), [t]);

	const { page, setPage, limit, setLimit } = useSearch();

	const { data: jobData, isFetching } = useGetJobQuery(+id);

	const renderStatus = (status: Enrichments) => {
		switch (status) {
			case Enrichments.DONE:
				return (
					<>
						<CheckCircle color="primary" /> Found
					</>
				);
			case Enrichments.INVALID:
				return (
					<>
						<Error color="warning" />
						Not enriched
					</>
				);
			default:
				return <></>;
		}
	};

	const columns: TableColumn<Offer>[] = useMemo(
		() => [
			{
				label: ts("jobTitle"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<a
								className="cursor-pointer text-brand-light"
								href={row.offerLink ?? ""}
								rel="noreferrer"
								target="_blank"
							>
								<Typography color="inherit" mb={0} variant="body2">
									{row?.jobTitle}
								</Typography>
							</a>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.name}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("email"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.email || row.companyEmail}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("phoneNumber"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.phoneNumber}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("company"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.cleanedCompanyName || row.companyName}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("status"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{renderStatus(row.status)}
						</Stack>
					</Stack>
				),
			},
		],
		[ts]
	);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	return isFetching ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<h3 className="mb-3">{jobData?.name}</h3>
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">{jobData?.query}</p>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={(jobData?.offers as unknown as Offer[]) || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={jobData?.offers.length ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
		</div>
	);
};
