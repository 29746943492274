import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

import { EmploymentType, JobScrapeType } from "@/enum/job.enum";

import { Enrichments } from "@/enum/enrichments.enum";

import { ListStatus } from "@/enum/list.enum";
import { JobsScrapeStatus } from "@/enum/scrape-enum";
import { ERROR_TYPE } from "@/utils";

import { PeopleSchema } from "../../Apollo/schema/get-people";

import { ApolloOrgSchema } from "../../Apollo/schema/apolloOrg";

const { REQUIRED } = ERROR_TYPE;

export const OfferJobSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	query: z.string().min(4, { message: REQUIRED }).trim(),
	employmentType: z.nativeEnum(EmploymentType),
	status: z.nativeEnum(JobsScrapeStatus),
	activeStatus: z.nativeEnum(ListStatus),
	jobScrapeType: z.nativeEnum(JobScrapeType),
	repeatDaily: z.boolean(),
});

export const OfferSchema = z.object({
	id: z.number(),
	jobId: z.number(),
	jobTitle: z.string().nullable(),
	offerLink: z.string().nullable(),
	companyLogo: z.string().nullable(),
	description: z.string().nullable(),
	employmentType: z.nativeEnum(EmploymentType),
	jobLocation: z.string().nullable(),
	jobCity: z.string().nullable(),
	jobState: z.string().nullable(),
	jobCountry: z.string().nullable(),
	postedAt: z.date().nullable(),
	name: z.string().nullable(),
	firstName: z.string().nullable(),
	lastName: z.string().nullable(),
	linkedinUrl: z.string().nullable(),
	email: z.string().nullable(),
	emailStatus: z.string().nullable(),
	companyEmail: z.string().nullable(),
	companyLocation: z.string().nullable(),
	companyCity: z.string().nullable(),
	companyState: z.string().nullable(),
	companyCountry: z.string().nullable(),
	companyName: z.string().nullable(),
	cleanedCompanyName: z.string().nullable(),
	companyDomain: z.string().nullable(),
	companyLinkedinUrl: z.string().nullable(),
	title: z.string().nullable(),
	headline: z.string().nullable(),
	location: z.string().nullable(),
	city: z.string().nullable(),
	state: z.string().nullable(),
	country: z.string().nullable(),
	phoneNumber: z.string().nullable(),
	status: z.nativeEnum(Enrichments),
	prospect: PeopleSchema.optional(),
	organization: ApolloOrgSchema.optional(),
	job: OfferJobSchema.optional(),
});

export const OffersResponseSchema = z.object({
	data: z.array(OfferSchema),
	meta: MetaDataSchema,
});

export type Offer = z.infer<typeof OfferSchema>;

export type OffersResponse = z.infer<typeof OffersResponseSchema>;
