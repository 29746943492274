import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { ListStatus } from "@/enum/list.enum";

const { REQUIRED } = ERROR_TYPE;

export const CronSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	timestamp: z.date().nullable(),
	recordId: z.number().nullable(),
	recordName: z.string().nullable(),
	priority: z.number().nullable(),
	nextExecutionInSeconds: z.number().nullable(),
	nextExecution: z.date().nullable(),
	status: z.string().nullable(),
});

export const ToggleCronSchema = z.object({
	id: z.number(),
	activeStatus: z.nativeEnum(ListStatus),
});

export const CronResponseSchema = z.object({
	data: z.array(CronSchema),
	meta: MetaDataSchema,
});

export type Cron = z.infer<typeof CronSchema>;

export type ToggleCron = z.infer<typeof ToggleCronSchema>;

export type CronResponse = z.infer<typeof CronResponseSchema>;
