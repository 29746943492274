export default {
	translation: {
		filter: {
			noFilter: "Kein Filter",
			textContains: "Text enthält",
			textDoesNotContain: "Text enthält nicht",
			textStartsWith: "Text beginnt mit",
			textEndsWith: "Text endet mit",
			textIsExactly: "Text ist genau",
			isIn: "Ist nicht leer",
			isNotIn: "Ist leer",
			isEmpty: "Ist leer",
			isNotEmpty: "Ist nicht leer",
			greaterEqual: "Größer gleich",
			lessEqual: "Kleiner gleich",
			between: "Zwischen",
			dropdown: "Mehrfachauswahl",
			dropdownCustom: "Mehrfachauswahl",
			dropdownExclude: "Mehrfachausschluss",
			dropdownCustomExclude: "Mehrfachausschluss",
		},
		usage: {
			filtered: "Gefiltert",
			identified: "Identifiziert",
			time: "Zeit",
			used: "Verwendet",
			notUsed: "Nicht verwendet",
			filteredProspects: "Gefilterte Prospects",
			identifiedProspects: "Identifizierte Prospects",
			aiInputTokens: "AI Eingabe Tokens",
			aiOutputTokens: "AI Ausgabe Tokens",
		},
		liPost: {
			update: {
				title: "Update Linkedin Posts",
			},
			create: {
				title: "Create Linkedin Posts",
				button: "Create Linkedin Posts",
			},
			delete: {
				title: "Delete Linkedin Posts",
			},
			scrapeCriteria: "Scrape Criteria",
			filterCriteria: "Filter Criteria",
			name: "Name",
			namePlaceholder: "Posts about #Digitalization",
			queryPlaceholder: "Digitalization",
			authorJobTitlePlaceholder: "CEO",
			search: "Search",
			title: "Linkedin Posts",
			subtitle: "Your Linkedin Post scrape jobs",
			query: "Suchbegriff",
			date_posted: "Date Posted",
			relevance: "Relevance",
			authorJobTitle: "Author Job Title",
			contentType: "Content Type",
			ContentType: "Content Type",
			photos: "Photos",
			videos: "Videos",
			liveVideos: "Live Videos",
			collaborativeArticles: "Collaborative Articles",
			documents: "Documents",
			status: "Status",
			fromMember: "From Member",
			fromOrganization: "From Organization",
			authorCompany: "Author Company",
			authorIndustry: "Author Industry",
			mentionsMember: "Mentions Member",
			mentionsOrganization: "Mentions Organization",
			scrapeDaily: "Scrape Daily",
			titlePerson: "Title",
			headline: "Headline",
			linkedinUrl: "LinkedIn URL",
			content: "Content",
			country: "Persönliches Land",
			city: "Personal City",
			companyName: "Company Name",
			companyLinkedinUrl: "Company LinkedIn URL",
			companyCountry: "Company Country",
			companyCity: "Company City",
			tabs: {
				results: "Results",
				costs: "Costs",
			},
			fromTo: "Von - Bis",
			timeFrame: "Zeitfenster",
			fromDate: "Von",
			toDate: "Bis",
		},
		cron: {
			title: "Cron Übersicht",
			subtitle: "Hier finden Sie alle Ihre Cron Jobs",
			search: "Nach CronJob suchen",
			name: "Name",
			status: "Status",
			recordId: "Record ID",
			recordName: "Record Name",
			priority: "Priorität",
			nextExecution: "Nächste Ausführung",
			startedAt: "Gestartet um",
		},
		requests: {
			application: "Applikation",
			requests: "Anfragen",
			costs: "Kosten",
			time: "Zeit",
			companyId: "Firmen-ID",
		},
		map: {
			title: "Google Maps",
			subtitle: "Hier finden Sie alle Ihre Google Maps Scrapings",
			companyName: "Name",
			reviewCount: "Anzahl der Bewertungen",
			rating: "Bewertung",
			type: "Typ",
			socialMedia: "Soziale Medien",
			companyEmail: "E-Mail",
			website: "Webseite",
			companyCountry: "Firma Land",
			companyCity: "Firma Stadt",
			companyState: "Firma Bundesland",
			status: "Status",
			countOfResults: "Anzahl der Ergebnisse",
			scrapeDaily: "Tägliches Scrape",
			repeatDaily: "Täglich wiederholen",
			create: {
				title: "Neuen Google Maps Job erstellen",
				button: "Neuer Google Maps Job",
			},
			search: "Nach Google Maps Job suchen",
			delete: {
				title: "Google Maps Job löschen",
			},
			name: "Name",
			query: "Suchbegriff",
			limit: "Limit",
			lat: "Breitengrad",
			lng: "Längengrad",
			region: "Region",
			zoom: "Zoom",
			language: "Sprache",
			getInfoAt: "Nach Unternehmensweiter Info@ E-Mail Adresse suchen",
			getEnrichment: "Komplettes Enrichment um später nach speziellen Positionen zu suchen",
			getManagingDirector: "Geschäftsführer aus Impressum etc suchen",
			moreInformation: "Für weitere Informationen besuchen Sie:",
			latPlaceholder: "41,12456",
			lngPlaceholder: "29,12456",
			limitPlaceholder: "100",
			queryPlaceholder: "Heizungsbauer",
			zoomPlaceholder: "10",
			languagePlaceholder: "de",
			regionPlaceholder: "de",
			scrapeLevel: "Scrape Level",
			phoneNumber: "Telefonnummer",
			tabs: {
				results: "Ergebnisse",
				costs: "Kosten",
			},
			fromUrlPlaceholder: "Paste your Google Maps URL",
			fromUrl: "From URL",
		},
		companies: {
			title: "Andere Organisationen",
			subtitle: "Hier finden Sie alle Organisationen die Zugriff zu dieser Applikation haben",
			search: "Nach Organisation suchen",
			name: "Name",
			linkedinUrl: "LinkedIn URL",
			users: "Benutzer",
			autoInvite: "Autoeinladung zur Firmen Linkedin Page",
			create: {
				button: "Neue Organisation erstellen",
				title: "Neue Organisation erstellen",
			},
			delete: {
				title: "Organisation löschen",
			},
			update: {
				title: "Organisation bearbeiten",
			},
			credits: "Credits",
		},
		company: {
			title: "Dein Unternehmen",
			tabs: {
				general: "Allgemein",
				user: "Benutzer",
				roles: "Rollen",
				apiKeys: "API Schlüssel",
				costs: "Kosten",
				blacklist: "Blacklist",
				usage: "Nutzung",
			},
			companyInformation: "Firmeninformationen",
			autoInvite: "Autoeinladung zur Firmen Linkedin Page",
			apolloApiKey: "Apollo API Schlüssel",
			lemlistApiKey: "Lemlist API Schlüssel",
		},
		blacklist: {
			title: "Blacklist",
			search: "Nach Blacklist suchen",
			domain: "Domain",
			email: "E-Mail",
			linkedinUrl: "LinkedIn URL",
			type: "Typ",
			create: {
				button: "Neue Blacklist erstellen",
				title: "Neue Blacklist erstellen",
				upload: "Blacklist hochladen",
			},
			delete: {
				title: "Blacklist löschen",
				confirmation: "Möchten Sie diese Blacklist wirklich löschen?",
			},
		},
		score: {
			title: "Lead Scoring",
			subtitle: "Hier finden Sie alle Ihre Lead Scoring Daten",
			create: {
				button: "Neuen Score erstellen",
				title: "Neuen Score erstellen",
			},
			description: "Beschreibung",
			search: "Nach Score suchen",
			name: "Name",
			TITLE: "Titel",
			SENIORITY: "Hierarchie Level",
			LOCATION: "Standort",
			COMPANY_LOCATION: "Firmenstandort",
			EMPLOYEES: "Mitarbeiteranzahl",
			INDUSTRY: "Branche",
			KEYWORDS: "Schlüsselwörter",
			NUMBER_IN_LEAD_FILTER: "Anzahl in Abteilungsfilter",
		},
		inbox: {
			headline: "Inbox",
			subtitle: "Hier finden Sie alle Ihre potentiellen Leads",
			list: "Liste",
			status: "Status",
			name: "Name",
			title: "Titel",
			level: "Level",
			company: "Firma",
			campaign: "Kampagne",
			connections: "Verbindungen",
			companyEmail: "Firmen E-Mail",
			industry: "Branche",
			companyStats: "Firmendaten",
			companyFounded: "Gründung",
			companyEmployees: "#Mitarbeiter",
			personLocation: "Personen-standort",
			companyLocation: "Firmen-standort",
			language: "Sprache",
			gender: "Geschlecht",
			founded: "Gegründet",
			jobs: "Jobs",
			tabs: {
				unassigned: "Offen",
				assigned: "Zugewiesen",
				blacklist: "Blacklist",
				notNow: "Später",
			},
			otherLists: "Andere Listen",
			otherEvents: "Events",
			event: "Event",
			eventAttendees: "Teilnehmer",
			eventAt: "Event am",
			assignTo: "Zuweisen an",
			companyName: "Firmenname",
			googleMaps: "Google Maps",
			reviewCount: "Anzahl der Bewertungen",
			rating: "Bewertung",
			type: "Typ",
			socialMedia: "Soziale Medien",
			website: "Webseite",
			phoneNumber: "Telefonnummer",
			companyCountry: "Firma Land",
			companyCity: "Firma Stadt",
			companyState: "Firma Bundesland",
			city: "Stadt",
			state: "Bundesland",
			country: "Persönliches Land",
			selectColumns: "Spalten auswählen",
			close: "Schließen",
			companyPhone: "Firmen Telefonnummer",
			jobTitle: "Job Titel",
			postedAt: "Veröffentlicht am",
			employmentType: "Beschäftigungsart",
			TEXT: "Text",
			NUMBER: "Zahl",
			LINK: "Link",
			BOOLEAN: "Checkbox",
			AI_PROMPT: "AI Prompt",
			RANDOM_VALUE: "Zufälliger Wert",
			delete: {
				title: "Spalte löschen",
				confirmation: "Möchten Sie diese Spalte wirklich löschen?",
			},
			POST: "LinkedIn Profile Posts",
			COMMENT: "LinkedIn Profile Comments",
			LIKE_TO_POST: "LinkedIn Profile Likes to Post",
			LIKE_TO_COMMENT: "LinkedIn Profile Likes to Comment",
			COMMENT_TO_POST: "LinkedIn Profile Comments to Post",
			content: "Post",
			contentResponse: "Comment",
			applyToAll: "Apply to all",
			applyTo10: "Apply to first 10 rows",
			confirmAssignment: "Confirm Assignment",
			searchForEmail: "Search for email",
			revertAssignment: "Revert Assignment",
			reassign: "Reassign",
			campaignAssignment: "Assign to campaign",
			listAssignment: "Assign to list",
			personAssignment: "Assign to person",
			person: "Person",
			saveForLater: "Save for later",
			excludeFromList: "Exclude from list",
			addToList: "Add to list",
			toBlackList: "To Blacklist",
			reportRecord: "Report Record",
			bulkStatusChangeSuccess: "Status changed successfully",
			addToBlackListSuccess: "Added to blacklist successfully",
			reportReasonSent: "Report reason sent successfully",
			bulkAssignSuccess: "Assigned successfully",
			bulkAssignFailed: "Assigning failed",
			bulkAssignRevertSuccess: "Reverted successfully",
			addingToBlacklist: "Adding to blacklist",
			addingToBlacklistConfirmationMessage: `Are you sure you want to add this lead to the Blacklist? This action is permanent and
			cannot be undone. Please specify if you want to add only this person to the blacklist
			or the entire company.`,
			addEntireCompanyToBlacklist: "Add entire company to blacklist",
			saveForLaterConfirmationMessage: "Add this lead to check later?",
			excludeFromListConfirmationMessage:
				"Are you sure you want to exclude this lead from the list?",
			addToListConfirmationMessage: "Are you sure you want to add this lead to the list?",
			specifyReason: "Please, specify the reason of reporting record.",
			specifyProblem: "Please, specify the problem.",
			revert: "Revert",
			revertFromBlacklist: "Revert from Blacklist",
			checkLater: "Check later",
			addColumn: "Add Column",
			filters: "Filters",
			columns: "Columns",
			linkedinUrl: "LinkedIn URL",
			companyLinkedinUrl: "Company LinkedIn URL",
			assignedTo: "Assigned to",
			email: "E-Mail",
			estimatedNumEmployees: "#Employees",
			inclusionStatus: "Inclusion Status",
			seniority: "Seniority",
			filter: "Filter",
			foundedYear: "Founded Year",
			likes: "Likes",
			comments: "Comments",
			shares: "Shares",
			comment: "Comment",
			contentDate: "Content Date",
			followers: "Followers",
			sourceType: "Source Type",
			reply: "Reply",
			message: "Message",
			responseStatus: "Status",
			jobCountry: "Job Country",
			jobCity: "Job City",
			attendees: "Attendees",
			sourceName: "Source Name",
			toBeNurtured: "To be nurtured",
			doNotContact: "Do not contact",
			RUNNING: "Campaign",
			REPLIES: "Replies",
			NO_REPLY: "No Reply",
			DO_NOT_CONTACT: "Do not contact",
			TO_BE_NURTURED: "To be nurtured",
			cityBranch: "Branch City",
			stateBranch: "Branch State",
			countryBranch: "Branch Country",
			filterOptions: {
				BOTH: "All",
				ADDED: "Pass (default)",
				MAYBE: "Incomplete data",
				EXCLUDED: "No pass",
				MANUALLY_EXCLUDED: "Manually excluded",
				BLACKLIST: "Global Blacklist",
				SAVED_FOR_LATER: "Saved for later",
			},
		},
		dashboard: {
			title: "Willkommen",
		},
		list: {
			title: "Listen",
			search: "Nach Listen suchen...",
			searchInList: "Search in list...",
			sort: "Sort",
			name: "Name",
			source: "Quelle",
			sourceType: "Quellentyp",
			leadFilter: "Kundenfilter",
			accountFilter: "Kontenfilter",
			enrichments: "AI Enrichment",
			allIncludingExcluded: "Alle inkl. Ausgeschlossene",
			processingStatus: "Verarbeitungsstatus",
			downloads: "Downloads",
			editList: "Liste bearbeiten",
			editListNew: "Liste bearbeiten (New)",
			deleteList: "Liste löschen",
			resetList: "Liste zurücksetzen",
			download: "Download",
			reset: {
				title: "Liste zurücksetzen",
				confirmation: "Möchten Sie diese Liste wirklich zurücksetzen?",
			},
			delete: {
				title: "Liste löschen",
				confirmation: "Möchten Sie diese Liste wirklich löschen?",
			},
			create: {
				button: "Neue Liste erstellen",
				title: "Neue Liste erstellen",
			},
			processed: "Verarbeitet",
			found: "Gefunden",
			downloadSelected: "Ausgewählte herunterladen",
			subtitle: "Ihre Listen",
			sources: {
				title: "Quellen",
				search: "Nach Quelle suchen",
				name: "Name",
				subtitle: "Wählen Sie die Quelle, die Sie verwenden möchten",
			},
			account: {
				title: "Unternehmensfilter",
				search: "Nach Unternehmensfilter suchen",
				name: "Name",
				subtitle: "Wählen Sie einen Unternehmensfilter",
			},
			lead: {
				title: "Abteilungsfilter",
				search: "Nach Abteilungsfilter suchen",
				name: "Name",
				subtitle: "Wählen Sie einen Abteilungsfilter",
				type: "Typ",
			},
			aiEnrichment: {
				title: "AI Enrichment",
				search: "Nach AI Enrichment suchen",
				name: "Name",
				subtitle: "Wählen Sie AI Enrichment",
				type: "Enrichmenttyp",
			},
			scores: {
				title: "Lead Scoring",
			},
			campaign: {
				title: "Kampagnen",
				search: "Nach Kampagnen suchen",
				name: "Name",
				subtitle: "Wählen Sie eine Zielkampagne",
				sync: "Synchronisieren",
			},
			settings: {
				title: "Settings",
				subtitle: "Settings",
				name: "Name",
				maxProspects: "max Prospects",
				maxProspectsPerDay: "Max. Prospects per day",
				maxPerCompany: "max per company",
				withEmail: "prospect needs to have email",
				tryToFindEmail: "Try to find email - but will continue without",
				withLinkedin: "prospect needs to have LinkedIn",
				onlyNewLeads: "only new leads - leads that have not been added to other lists",
				once: "One-time",
				ongoing: "Daily",
			},
			additionalSources: {
				title: "Personenfilter",
				search: "Nach zusätzlicher Quelle suchen",
				name: "Name",
				subtitle: "Wählen Sie eine zusätzliche Quelle",
			},
			status: {
				title: "Status",
				DONE: "Fertig",
				WAITING: "Warten auf Ausführung",
				IDLE: "Inaktiv",
				PROCESSING: "In Bearbeitung",
				IN_PROGRESS: "In Bearbeitung",
				DESKTOP_APP: "Waiting for Desktop App",
				API_WAITING: "In Bearbeitung",
				DRAFT: "Erstellt",
				ERROR: "Fehler",
				ended: "Ended",
				running: "Running",
				paused: "Paused",
				waiting: "Waiting",
			},
			ListType: "Quellentyp",
			searchBase: "Such-/Filterbasis",
			regularity: "Repeat search",
			log: {
				tabs: {
					settings: "Konfiguration",
					logs: "Logs",
					costs: "Kosten",
				},
			},
			iteration: "Iteration",
			automaticUpdate: "Automatisch aktualisieren",
			loadMore: "Mehr laden",
			lastModifiedAt: "Zuletzt bearbeitet",
			lastModifiedBy: "Zuletzt bearbeitet von",
			newLeads: "Offene Leads",
			savedForLater: "Saved for later",
			activity: {
				all: "All",
				running: "Running",
				stopped: "Stopped",
			},
			MAPS: "Google Maps",
			JOBS: "Jobs",
			sorting: {
				nameASC: "Sort A -> Z",
				nameDESC: "Sort Z -> A",
				leadsASC: "Leads Ascending",
				leadsDESC: "Leads Descending",
			},
			LI_EVENTS: "LinkedIn Events",
			CSV_ENRICHMENTS: "CSV Upload",
			LI_COMPANY_ACTIVITY: "LinkedIn Company Tracking",
			LI_POST: "LinkedIn Posts",
			LI_PROFILE_VISITS: "LinkedIn Profile Visits",
			PROSPECT_SEARCH: "Prospect Search",
			LINKEDIN_PROFILE_POSTS: "LinkedIn Profile Posts",
			LI_PROFILE: "LinkedIn Profile Tracking",
			LINKEDIN_PROFILE_COMMENTS: "LinkedIn Profile Comments",
			LINKEDIN_PROFILE_LIKES_TO_POST: "LinkedIn Profile Likes to Post",
			LINKEDIN_PROFILE_LIKES_TO_COMMENT: "LinkedIn Profile Likes to Comment",
			LINKEDIN_PROFILE_COMMENTS_TO_POST: "LinkedIn Profile Comments to Post",
			namePlaceholder: "Name der Liste",
			LI_PEOPLE: "LinkedIn People Search",
			CUSTOM_EVENTS: "Industry Event",
			assignStatus: {
				unassigned: "Unassigned",
				assigned: "Assigned",
				blacklist: "Blacklist",
				notNow: "Contact later",
			},
		},
		enum: {
			searchBase: {
				PERSON: "Personen aus Quelle. Kann gefiltert werden.",
				COMPANY: "Unternehmen aus Quelle. Benötigt personen filter um Personen zu finden.",
				SOURCE: "Google Maps / Jobs ohne Personen. Nur Info@ E-Mail Adressen.",
			},
		},
		northdata: {
			title: "Bundesanzeiger",
			search: "Nach Bundesanzeiger suchen",
			name: "Name",
			lastDays: "Letzte Tage",
			status: "Status",
			type: "Typ",
			create: {
				button: "Neue Bundesanzeigersuche erstellen",
				title: "Neue Bundesanzeigersuche erstellen",
			},
			subtitle: "Ihre Bundesanzeigersuchen",
			delete: {
				title: "Bundesanzeigersuche löschen",
			},
		},
		stepstone: {
			title: "Stepstone",
			search: "Nach Stepstone suchen",
			name: "Name",
			status: "Status",
			company: "Unternehmen",
			subtitle: "Ihre Stepstone Data",
			url: "URL der Stepstone Data",
			maxJobOffers: "Max Stellenangebote",
			email: "E-Mail",
			found: "Gefunden",
			processed: "Processed",
			create: {
				button: "Neuen Stepstone-Scraper erstellen",
				title: "Neuen Stepstone-Scraper erstellen",
			},
		},
		indeed: {
			title: "Indeed",
			search: "Nach Indeed suchen",
			name: "Name",
			status: "Status",
			company: "Unternehmen",
			subtitle: "Ihre Indeed Data",
			url: "URL der Indeed Data",
			maxJobOffers: "Max Stellenangebote",
			email: "E-Mail",
			found: "Gefunden",
			processed: "Processed",
			create: {
				button: "Neuen Indeed-Scraper erstellen",
				title: "Neuen Indeed-Scraper erstellen",
			},
		},
		customEvent: {
			title: "Messen und Veranstaltungen",
			search: "Nach Veranstaltung suchen",
			name: "Name",
			namePlaceholder: "Name of the event",
			urlPlaceholder: "URL of the event",
			loginNotesPlaceholder: "Login notes",
			usernamePlaceholder: "Username",
			passwordPlaceholder: "Password",
			otpSecretPlaceholder: "OTP Secret",
			status: "Status",
			subtitle: "Ihre Messen und Veranstaltungen",
			url: "URL der Messen und Veranstaltungen",
			email: "E-Mail",
			found: "Gefunden",
			downloads: "Downloads",
			processed: "Processed",
			personList: "Personenliste",
			organizationList: "Organisationsliste",
			create: {
				button: "Neuen Veranstaltungssuch-Scraper erstellen",
				title: "Neuen Veranstaltungssuch-Scraper erstellen",
			},
			listProvidedUntil: "Liste bereitgestellt bis",
			eventAt: "Event am",
			loginNotes: "Login Notizen",
			username: "Benutzername",
			password: "Passwort",
			otpSecret: "OTP Secret",
			reset: {
				title: "Reset assigned files",
			},
			reassign: "Assign files to industry event",
			eventType: "Event Type",
			company: "Company",
			listProvidedAt: "List provided at",
		},
		liEvent: {
			allowScrape: "Scrape erlauben",
			title: "LinkedIn Events",
			search: "Nach Veranstaltung suchen",
			name: "Name",
			attendees: "Teilnehmer",
			status: "Status",
			company: "Unternehmen",
			eventAt: "Zeitpunkt",
			subtitle: "Ihre LinkedIn Events",
			url: "URL der LinkedIn Veranstaltung",
			urls: "URLs der LinkedIn Veranstaltungssuche",
			searchTerm: "Suchbegriff zur Eventsuche",
			lastRunAt: "Letzte Durchführung",
			nextRunAt: "Nächste Durchführung",
			email: "E-Mail",
			employees: "Mitarbeiter",
			annualRevenue: "Jahresumsatz",
			specific: "Spezifische Linkedin Event Url(s)",
			defaultSearch: "Nach Suchbegriff suchen",
			create: {
				button: "Neuen Veranstaltungssuch-Scraper erstellen",
				title: "Neuen Veranstaltungssuch-Scraper erstellen",
			},
			update: {
				title: "Veranstaltungssuch-Scraper bearbeiten",
			},
			minDaysBeforeScrape: "Scrape X Tage vor Event",
			minAttendeesBeforeScrape: "Oder ab X Teilnehmern (optional)",
			scrapeCriteria: "Wann soll gescraped werden?",
			filterCriteria: "Teilnehmerfilter",
			profileLanguages: "Profil Sprachen",
			english: "Englisch",
			german: "Deutsch",
			spanish: "Spanisch",
			french: "Französisch",
			portuguese: "Portugiesisch",
			addToSearch: "Add to search",
			save: "Speichern",
		},
		liPeople: {
			title: "LinkedIn Personensuche",
			search: "Nach Personen suchen",
			name: "Name",
			subtitle: "Ihre LinkedIn Personensuche",
			urls: "URLs der Linkedin Personensuche",
			urlTitle: "URLs",
			create: {
				button: "Neuen Personensuche-Scraper erstellen",
				title: "Neuen Personensuche-Scraper erstellen",
			},
			update: {
				title: "Aktualisieren Personensuche-Scraper",
			},
			defaultSearch: "Standardsuche",
			specific: "Spezifische Url(s)",
			keyword: "Suchbegriff",
			titleFreeText: "Titel Freitext",
			companyFreeText: "Firmenname Freitext",
			firstName: "Vorname",
			lastName: "Nachname",
			location: {
				label: "Standort",
			},
			language: {
				label: "Sprache",
			},
			serviceCategory: {
				label: "Service Kategorie",
			},
			industry: {
				label: "Branche",
			},
			schoolFreeText: "Schule Freitext",
			school: {
				label: "Schule",
			},
			specificTags: "Spezifische Tags",
			personalDetails: "Persönliche Details",
			followerOf: "Follower von",
			keywordPlaceholder: "Digitalisierung",
			titleFreeTextPlaceholder: "CEO",
			companyFreeTextPlaceholder: "Digital Now",
			firstNamePlaceholder: "Bill",
			lastNamePlaceholder: "Gates",
			schoolFreeTextPlaceholder: "Harvard",
			namePlaceholder: "New Linkedin People Search",
		},
		liCompanyActivity: {
			title: "LinkedIn Firmenaktivität",
			search: "Nach Firmenaktivität suchen",
			name: "Name",
			subtitle: "Ihre LinkedIn Firmenaktivität",
			url: "URL der Linkedin Firmenaktivität",
			urlTitle: "URLs",
			status: "Status",
			type: "Typ",
			titlePerson: "Titel",
			headline: "Überschrift",
			linkedinUrl: "LinkedIn URL",
			content: "Inhalt",
			contentResponse: "Inhaltsantwort",
			country: "Persönliches Land",
			city: "Stadt",
			companyName: "Firmenname",
			companyLinkedinUrl: "LinkedIn URL der Firma",
			companyCountry: "Firma Land",
			companyCity: "Firma Stadt",
			create: {
				button: "Neuen Firmenaktivität-Scraper erstellen",
				title: "Neuen Firmenaktivität-Scraper erstellen",
			},
			update: {
				title: "Aktualisieren Firmenaktivität-Scraper",
			},
			tabs: {
				results: "Ergebnisse",
				costs: "Kosten",
			},
			scrapeFollowers: "Follower scrapen",
			scrapeFollowersWarning: "Achtung: Hierfür benötigen Sie Admin-Rechte auf der LinkedIn-Seite",
			namePlaceholder: "Digital Now",
			urlPlaceholder: "https://www.linkedin.com/company/digital-now-io/",
			timeFrame: "Zeitfenster in Tagen (Post ist max. 30 Tage alt)",
			timeFramePlaceholder: "30",
		},
		liProfile: {
			title: "LinkedIn Profile",
			search: "Nach Profile suchen",
			name: "Name",
			subtitle: "Ihre LinkedIn Profile",
			url: "URL des Linkedin Profiles",
			urlTitle: "URLs",
			type: "Typ",
			observeIndividuals: "Personen beobachten",
			individualExample: `"Hallo Person, ich habe gesehen, dass du gepostet hast …"`,
			reactionsWithPerson: "Reaktionen mit Person",
			reactedWithExample: `"Hey Xyz, ich habe gesehen, dass dir der Beitrag von Person gefallen hat …"`,
			titlePerson: "Titel",
			headline: "Überschrift",
			linkedinUrl: "LinkedIn URL",
			content: "Inhalt",
			contentResponse: "Inhaltsantwort",
			country: "Persönliches Land",
			city: "Stadt",
			companyName: "Firmenname",
			companyLinkedinUrl: "LinkedIn-URL der Firma",
			companyCountry: "Firma Land",
			companyCity: "Firma Stadt",
			create: {
				button: "Neuen Profile-Scraper erstellen",
				title: "Neuen Profile-Scraper erstellen",
			},
			update: {
				title: "Aktualisieren Profile-Scraper",
			},
			tabs: {
				results: "Ergebnisse",
				costs: "Kosten",
			},
			linkedinProfilePosts: "LinkedIn Profilposts",
			linkedinProfileComments: "LinkedIn Profilkommentare",
			linkedinProfileLikesToPost: "LinkedIn Profil-Likes zu Post",
			linkedinProfileLikesToComment: "LinkedIn Profil-Likes zu Kommentar",
			linkedinProfileCommentsToPost: "LinkedIn Profil-Kommentare zu Post",
			observeAction: "Observe Action",
			observeReaction: "Observe Reaction",
			namePlaceholder: "Bill Gates",
			urlPlaceholder: "https://www.linkedin.com/in/williamhgates/",
			timeFrame: "Zeitfenster in Tagen (Post ist max. 30 Tage alt)",
			timeFramePlaceholder: "30",
		},
		openAi: {
			title: "AI Enrichment",
			subtitle: "Kontakte oder Konten mit AI enrichen",
			search: "Nach AI Enrichment suchen",
			name: "Name",
			type: "Typ",
			aiEnrichmentTarget: "AI Enrichment für",
			aiEnrichmentTargets: {
				contact: "Kontaktebene",
				account: "Kontenebene",
			},
			create: {
				button: "Neues AI Enrichment erstellen",
				title: "Neues AI Enrichment erstellen",
			},
			update: {
				title: "AI Enrichment aktualisieren",
			},
		},
		cookie: {
			title: "Cookie",
			subtitle: "Cookie-Einstellungen",
			search: "Suchen",
			cookie: "Cookie",
			application: "Anwendung",
			initialConfig: "Cookie-Konfiguration",
			username: "Benutzername",
			password: "Passwort",
			apiKey: "API-Schlüssel",
			user: "Benutzer",
			create: {
				button: "Neues Cookie erstellen",
				title: "Neues Cookie erstellen",
			},
			update: {
				title: "Cookie aktualisieren",
			},
		},
		sidebar: {
			searches: "Prospects suchen",
			prospects: "Prospects",
			stepstone: "Stepstone",
			northdata: "Bundesanzeiger",
			indeed: "Indeed",
			users: "Benutzer",
			roles: "Benutzerrollen",
			logout: "Abmelden",
			inbox: "Inbox",
			cookie: "Cookie",
			csvEnrichment: "CSV Anreicherung",
			linkedinEvents: "LinkedIn Events",
			linkedinCompanyActivity: "Company Profile Tracker",
			linkedinProfile: "Person Profile Tracker",
			linkedinProfileVisits: "LinkedIn Profilbesuche",
			linkedinPeopleSearch: "LinkedIn Personensuche",
			openai: "AI Enrichment",
			accounts: "Konten",
			workflow: "Workflow",
			settings: "Einstellungen",
			accountSettings: "Kontoeinstellungen",
			profile: "Mein Profil",
			list: "Listen",
			listBuilder: "Listen Builder",
			customEvent: "Messen & Veranstaltungen",
			leadScoring: "Lead Scoring",
			dataSources: "Datenquellen",
			organization: "Deine Organisation",
			blacklist: "Blacklist",
			companies: "Andere Organisationen",
			back: "Zurück zu deinem Account",
			jobs: "Job Angebote",
			maps: "Google Maps",
			cron: "Data processing Jobs",
			linkedinPosts: "LinkedIn Posts",
			all: "All",
			otherOrganizations: "Andere Organisationen",
			globalBlacklist: "Globale Blacklist",
			listViews: "List views",
			campaigns: "Kampagnen",
			replies: "Replies",
			noReply: "No Reply",
			toBeNurtured: "To Be Nurtured",
			doNotContact: "Do Not Contact",
			tabs: {
				prospects: "Prospects",
			},
			globalSearch: "Search for name, email or LinkedIn Url",
		},
		csvEnrichment: {
			title: "CSV Dateianreicherung",
			type: "Typ",
			name: "Dateiname",
			amountInCsv: "Zeilen in CSV",
			downloads: "Download",
			found: "Gefunden",
			processed: "Verarbeitet",
			status: "Status",
			search: "Nach Dateiname suchen",
			withEmail: "mit E-Mail-Adressen",
			subtitle:
				"Reichern Sie Ihre CSV-Datei mit zusätzlichen Informationen an. Bitte verwenden Sie nur CSV-Dateien, die ',' als Trennzeichen verwenden. <br /><br />Wenn Sie eine Person anreichern möchten, sollte die Datei sollte mindestens eine der folgenden Spalten oder Kombinationen von Spalten enthalten: <br /><strong>1. linkedinUrl, 2. email, 3. name and companyName</strong>. <br /><br />Wenn Sie ein Unternehmen anreichern möchten, sollte die Datei die folgenden Spalten enthalten: <br /><strong>1. companyLinkedinUrl, 2. website</strong>. <br /><br />Wenn Sie Personen anhand ihrer Position in vordefinierten Unternehmen anreichern möchten, sollte die Datei die folgenden Spalten enthalten: <br /><strong>1. companyName, 2. title</strong>.",
			create: {
				title: "Neue CSV Anreicherung erstellen",
				button: "CSV-Datei hochladen",
			},
			delete: {
				title: "CSV Dateianreicherung löschen",
			},
			foundFields: "Die folgenden Felder wurden gefunden",
			companyEnrichment:
				"Genügend Daten für die Suche nach Personen in Unternehmen. Die folgenden Methoden können angewandt werden:",
			personByTitle:
				"Genügend Daten für die Suche nach bestimmten Positionen in Unternehmen. Die folgenden Methoden können angewandt werden:",
			tabs: {
				results: "Ergebnisse",
				costs: "Kosten",
			},
			namePlaceholder: "Name der CSV Quelle",
		},
		prospectSearch: {
			save: "Speichern",
			tabs: {
				people: "Personen",
				companies: "Organisationen",
			},
			organization: {
				title: "Organisationen suchen",
				search: "Suchen",
				save: "Speichern",
			},
			company: "Organisation",
			employees: "Mitarbeiter",
			industry: "Branche",
			keywords: "Schlüsselwörter",
			location: "Standort",
			title: "Prospects suchen",
			searchButton: "Direkt suchen",
			person_titles: {
				label: "Jobtitel",
			},
			person_not_titles: {
				label: "Jobtitel ausschließen",
			},
			person_seniorities: {
				label: "Senioritätsebene",
			},
			person_locations: {
				label: "Standort der Person",
			},
			organization_locations: {
				label: "Standort der Firma",
			},
			organization_industry_tag_ids: {
				label: "Branche",
			},
			downloads: "Downloads",
			type: "Typ",
			name: "Name",
			linkedin: "LinkedIn",
			add: "Zus. Info.",
			found: "#Prospects",
			download: "Downloads",
			leadsPerDay: "Leads pro Tag",
			amount: "Menge",
			create: {
				button: "Neue Suche erstellen",
				title: "Neue Liste/Filter erstellen",
			},
			scrapeProspects: "#Scrape",
			withoutEmail: "ohne E-Mail zulassen",
			status: "Status",
			search: "Nach Prospect suchen",
			campaignSearch: "Nach Kampagnennamen suchen",
			companySearch: "Nach Firmennamen suchen",
			fromTo: "Von - Bis",
			timeFrame: "Zeitfenster",
			fromDate: "Von",
			toDate: "Bis",
			SOURCE: "Quelle / Filter",
			LEAD_FILTER: "Abteilungsfilter",
			LEAD_EXCLUSION: "Abteilungsausschluss",
			COUNTER: "Abteilungsgröße",
		},
		prospects: {
			title: "Prospects",
			search: "Nach Prospect suchen",
			campaignSearch: "Nach Kampagnennamen suchen",
			companySearch: "Nach Firmennamen suchen",
			company: "Firma",
			campaign: "Kampagne",
			email: "E-Mail",
			name: "Name",
			linkedin: "LinkedIn",
			add: "Zus. Info.",
		},
		errors: {
			invalidEmail: "Ungültige E-Mail-Adresse",
			invalidPassword: "Ungültiges Passwort",
			passwordGuidelinesNotMet: "Passwortrichtlinien nicht erfüllt",
			termsAcceptanceMissing: "Bitte akzeptieren Sie die Nutzungsbedingungen",
			invalidValue: "Ungültiger Wert",
			invalidData: "Ungültige Daten",
			userNotConfirmed: "Benutzer nicht bestätigt",
			passwordMismatch: "Passwörter stimmen nicht überein",
			invalidToken: "Ungültiges Token",
			emailAlreadyTaken: "E-Mail-Adresse bereits vergeben",
			wentWrong: "Etwas ist schief gelaufen",
			unauthorized: "Nicht autorisiert",
			invalidAuthCode: "Ungültiger Authentifizierungscode",
			nameMissing: "Name fehlt",
			requiredFields: "Nicht alle erforderlichen Felder sind ausgefüllt.",
			expectedNumber: "Nummer erwartet / geben Sie 0 ein, wenn nicht benötigt.",
			customColumnAlreadyExists: "Spalte existiert bereits",
			wrongUrlShouldBeLinkedinProfile: "Falsche URL. Sollte eine LinkedIn-Profil-URL sein",
			notEnoughCredits: "Nicht genügend Credits",
		},
		account: {
			connectionScraping: "LinkedIn-Verbindungen",
			enableConnectionScraping: "Scraping deiner LinkedIn-Verbindungen aktivieren",
			enableConnectionScrapingDescription:
				"Dies ermöglicht zu ermitteln zu welchen Prospects du oder deine Kollegen bereits eine Verindung auf Linkedin haben.",
			linkedinAutomation: "LinkedIn-Automatisierung",
			enableAutoAccept: "Auto-Akzeptieren aktivieren",
			enableAutoAcceptDescription: "Automatisches Akzeptieren von LinkedIn-Verbindungsanfragen",
			profileVisits: "Profilbesuche",
			warningPremiumAccountRequired: "Premium-Account erforderlich",
			enableProfileVisitsScraping: "Profilbesuche aktivieren",
			changePassword: "Passwort ändern",
			currentPassword: "Aktuelles Passwort",
			disableTwoFactorAuth: "2FA deaktivieren",
			enableTwoFactorAuth: "2FA aktivieren",
			enableTwoFactorAuthDescription: "Scannen Sie den QR-Code mit Ihrer Authentifizierungs-App",
			newPassword: "Neues Passwort",
			profileInformation: "Profilinformationen",
			profileSecurity: "Profilsicherheit",
			repeatPassword: "Neues Passwort wiederholen",
			tabs: {
				general: "Allgemein",
				notifications: "Benachrichtigungen",
				linkedinConnections: "LinkedIn-Verbindungen",
			},
			title: "Konto",
			linkedinConnections: "LinkedIn-Verbindungen",
		},
		job: {
			title: "Job Angebote",
			jobTitle: "Job Titel",
			subtitle: "Hier finden Sie alle Job Angebote",
			search: "Nach Job Angebot suchen",
			name: "Name",
			status: "Status",
			company: "Unternehmen",
			url: "URL",
			countOfResults: "Anzahl der Ergebnisse",
			create: {
				button: "Neue Job Angebot Suche erstellen",
				title: "Neue Job Angebot Suche erstellen",
			},
			delete: {
				title: "Job Angebot Suche löschen",
			},

			query: "Suchbegriff",
			queryPlaceholder: "Heizungsbauer",
			employmentType: "Beschäftigungsart",
			FULLTIME: "Vollzeit",
			PARTTIME: "Teilzeit",
			CONTRACTOR: "Freelancer",
			INTERN: "Praktikant",
			scrapeLevel: "Scrape Level",
			getInfoAt: "Nach Unternehmensweiter Info@ E-Mail Adresse suchen",
			getEnrichment: "Finde LinkedIn company um mit Titeln nach Personen zu suchen",
			getManagingDirector: "Geschäftsführer aus Impressum etc suchen",
			getPersonFromOffer: "Personen aus dem Job-Angebot suchen",
			tabs: {
				results: "Ergebnisse",
				costs: "Kosten",
			},
			email: "E-Mail",
			phoneNumber: "Telefonnummer",
		},
		basics: {
			selectColumns: "Spalten auswählen",
			wait: "Bitte warten",
			reset: "Zurücksetzen",
			pleaseSelect: "Bitte wählen",
			noResults: "Keine Ergebnisse",
			account: "Konto",
			actions: "Aktionen",
			admin: "Admin",
			back: "Zurück",
			block: "Blockieren",
			cancel: "Abbrechen",
			change: "Bearbeiten",
			code: "Code",
			confirmationClose: "Are you sure you want to close the window?",
			confirm: "Bestätigen",
			confirmPassword: "Passwort bestätigen",
			createSomething: "Erstelle {{something}}",
			delete: "Löschen",
			description: "Beschreibung",
			dragAndDropImage: "Ziehen & Ablegen",
			edit: "Bearbeiten",
			editSomething: "Bearbeite {{something}}",
			email: "E-Mail-Adresse",
			enterSomething: "Geben Sie {{something}} ein",
			firstName: "Vorname",
			home: "Startseite",
			label: "Label",
			lastName: "Nachname",
			linkedinUrl: "LinkedIn URL",
			logout: "Abmelden",
			name: "Name",
			noData: "Keine Daten",
			password: "Passwort",
			permissions: "Berechtigungen",
			resetPassword: "Passwort zurücksetzen",
			role: "Rolle",
			save: "Speichern",
			saveChanges: "Änderungen speichern",
			search: "Suche {{something}}...",
			select: "Auswählen",
			selectSomething: "Wähle {{something}}",
			setPassword: "Passwort festlegen",
			status: "Status",
			termsAndConditions:
				"<noLink>Ich habe die</noLink> <link1>Allgemeinen Geschäftsbedingungen</link1> gelesen",
			upload: "Hochladen",
			value: "Wert",
			warning: "Achtung",
			filter: "Filter",
			clear: "Reset All Filters",
			close: "Schließen",
			rows: "Rows",
			columns: "Columns",
			columnsShown: "Columns shown",
			export: "Export",
			next: "Next",
			previous: "Previous",
			addCriteria: "Add Criteria",
			copyCriteria: "Copy Criteria",
		},
		errorPages: {
			buttonText: "Zur Startseite",
			description:
				"Entweder haben Sie einen verdächtigen Weg probiert oder Sie sind versehentlich hierher gekommen. Egal was es ist, versuchen Sie es über die Navigation.",
			notFound: "404: Die Seite, die Sie suchen, ist hier nicht",
			serverError: "500: Interner Serverfehler",
			unauthorize: "401: Autorisierung erforderlich",
			forbidden: "403: Verboten",
		},
		forgotPassword: {
			forgotPasswordCta: "Link zum Zurücksetzen senden",
			submittedFeedbackMessage:
				"Überprüfen Sie Ihren Posteingang. Wir senden eine E-Mail mit Anweisungen, wie Sie Ihr Passwort zurücksetzen können!",
			title: "Passwort vergessen",
		},
		inviteUser: {
			button: "Neuen Nutzer einladen",
			modal: {
				cta: "Einladen",
				emailPlaceholder: "E-Mail eingeben",
				firstNamePlaceholder: "Vorname eingeben",
				lastNamePlaceholder: "Nachname eingeben",
				rolePlaceholder: "Rolle auswählen",
				title: "Benutzer einladen",
			},
		},
		login: {
			emailPlaceholder: "Ihre E-Mail-Adresse",
			forgotPasswordCta: "Passwort vergessen?",
			loginCta: "Anmelden",
			passwordPlaceholder: "Ihr Passwort",
			title: "Anmelden",
			invalidData: "E-Mail oder Passwort falsch",
		},
		modals: {
			addAvatar: {
				description: "<underline>Klicken zum Hochladen</underline> oder ziehen und ablegen",
				fileSize: "Maximale Dateigröße ist 3 MB",
				title: "Datei hochladen",
			},
			deleteRole: {
				title: "Rolle löschen",
			},
			deleteUser: {
				title: "Benutzer löschen",
			},
			editUser: {
				rolePlaceholder: "Rollen auswählen",
				title: "Benutzer bearbeiten",
			},
		},
		twoFA: {
			code: "Code",
			cta: "Bestätigen",
			title: "2FA-Code eingeben",
		},
		users: {
			title: "Benutzer",
			search: "Benutzer suchen",
		},
		userRoles: {
			title: "Rollen",
			button: "Benutzerrollen",
			modal: {
				create: {
					permissionPlaceholder: "Berechtigungen auswählen",
					title: "Rolle erstellen",
				},
				edit: {
					title: "Rolle aktualisieren",
				},
			},
			placeholders: {
				codePlaceholder: "Eingeben",
				namePlaceholder: "Name eingeben",
			},
		},
	},
};
