import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { ScoreOverview } from "./pages/ScoreOverview/ScoreOverview";
import { ScoreDetail } from "./pages/ScoreDetail/ScoreDetail";

export const Score: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<ScoreOverview />} path="/" />
			<Route element={<ScoreDetail />} path="/:id" />
		</Routes>
	);
};
