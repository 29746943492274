import { createSlice } from "@reduxjs/toolkit";

import { ApolloState } from "@/types/apollo";

import { RootState } from "@/redux";

import { apolloApi } from "./apollo.api";

const initialState: ApolloState = {
	apollos: [],
};

export const apolloSlice = createSlice({
	name: "apollo",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(apolloApi.endpoints.getApollos.matchFulfilled, (state, { payload }) => {
			state.apollos = payload.data;
		});
	},
});

export const {} = apolloSlice.actions;
export const apolloReducer = apolloSlice.reducer;
export const apolloSelector = (state: RootState) => state[apolloSlice.name];
