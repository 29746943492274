import {
	ChangeEventHandler,
	FocusEventHandler,
	FunctionComponent,
	RefObject,
	useState,
} from "react";
import TextareaAutosize from "react-textarea-autosize";

import QuestionMarkIcon from "@/assets/icons/question-mark.svg";
import { classNames } from "@/utils/classNames";

interface TextAreaProps {
	value: string;
	placeholder?: string;
	handleChange?: ChangeEventHandler<HTMLTextAreaElement>;
	name: string;
	error?: boolean | string;
	touched?: boolean;
	showError: boolean;
	handleBlur?: FocusEventHandler<HTMLTextAreaElement>;
	label?: string;
	information?: string;
	isOptional?: boolean;
	maxLength?: number;
	className?: string;
	onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
	ref?: RefObject<HTMLTextAreaElement>;
	disabled?: boolean;
}

export const TextArea: FunctionComponent<TextAreaProps> = ({
	value,
	placeholder,
	handleChange,
	name,
	error,
	showError,
	touched,
	handleBlur,
	label,
	information,
	isOptional,
	maxLength,
	className,
	onKeyDown,
	ref,
	disabled,
}) => {
	const [showInformation, setShowInformation] = useState(false);

	return (
		<>
			{label && (
				<div className="relative flex w-full items-center justify-between mb-2">
					<label
						className={classNames(
							"block label-styles text-sm font-medium text-gray-900 appearance-none"
						)}
						htmlFor={name}
					>
						{label}
						{isOptional && " (Optional)"}
					</label>
					{information && (
						<img
							alt="Question mark icon"
							className="w-4 h-4 ml-2 text-gray-500 cursor-pointer hover:text-brand-light"
							src={QuestionMarkIcon}
							onMouseLeave={() => setShowInformation(false)}
							onMouseOver={() => setShowInformation(true)}
						/>
					)}
					{showInformation && (
						<p className="absolute bottom-7 right-0 left-20 bg-white shadow-all p-3 rounded-md text-xs">
							{information}
						</p>
					)}
				</div>
			)}
			<div className="relative w-full">
				<TextareaAutosize
					ref={ref}
					className={classNames(
						"sm:shadow-mb-card sm:border-none",
						"input-styles resize-none w-full min-h-[200px] py-2 px-3",
						showError && error ? "border-status-error" : "",
						touched && !error ? "border-status-success" : "",
						!!maxLength && "pb-6",
						className || ""
					)}
					disabled={disabled}
					maxLength={maxLength}
					name={name}
					placeholder={placeholder}
					value={value}
					onBlur={handleBlur}
					onChange={handleChange}
					onKeyDown={onKeyDown}
				/>
				{maxLength && (
					<p className="absolute right-2 bottom-2 text-xs text-gray-500">{value.length}/500</p>
				)}
			</div>
		</>
	);
};
