import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { useNavigate } from "react-router-dom";

import { Modal } from "@/components";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

// import { useCreateApolloMutation } from "@/pages/Private/redux/apollo/apollo.api";

import {
	useSaveSearchApolloPeopleMutation,
	useUpdateApolloMutation,
} from "@/pages/Private/redux/apollo/apollo.api";
import { ApolloPeopleRequestParams, ApolloSavePeopleRequestParams } from "@/types/apollo";
import { Radio } from "@/components/Radio/Radio";
import { ApolloType } from "@/enum/apollo.enum";
import { ROUTE_CONFIG } from "@/routes/config";

interface createApolloModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	config?: ApolloPeopleRequestParams;
	amount?: number;
	name?: string;
	leadsPerDay?: number;
	type?: ApolloType;
	id?: number;
}

export const CreateApolloModal: FunctionComponent<createApolloModalProps> = ({
	isOpen,
	setIsOpen,
	config,
	amount,
	leadsPerDay,
	type,
	name,
	id,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`prospectSearch.${key}`);
	const navigate = useNavigate();
	const [saveSearch, { isLoading, error }] = useSaveSearchApolloPeopleMutation();
	const [updateApollo] = useUpdateApolloMutation();

	const [filter, setFilter] = useState<ApolloType>(type || ApolloType.SOURCE);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<ApolloSavePeopleRequestParams>({
		defaultValues: {
			name: name || "",
			type: type || ApolloType.SOURCE,
			config,
			leadsPerDay: leadsPerDay || 0,
			amount: amount || 0,
		},
	});

	useEffect(() => {
		setValue("type", filter);
	}, [filter, setValue]);

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: ApolloSavePeopleRequestParams) => {
		try {
			if (id) {
				const updateValues = { ...values, id, type: filter };

				await updateApollo({ ...updateValues }).unwrap();
			} else {
				const createdApollo = await saveSearch(values).unwrap();

				navigate(`${ROUTE_CONFIG.APOLLO_SEARCH_PEOPLE}/${createdApollo.id}`);
			}

			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts(name ? "update.title" : "create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>
				<Radio
					isChecked={filter === ApolloType.SOURCE}
					onChange={() => setFilter(ApolloType.SOURCE)}
				>
					Source/Filter list
				</Radio>
				<Radio
					isChecked={filter === ApolloType.LEAD_FILTER}
					onChange={() => setFilter(ApolloType.LEAD_FILTER)}
				>
					Department filter
				</Radio>
				<Radio
					isChecked={filter === ApolloType.LEAD_EXCLUSION}
					onChange={() => setFilter(ApolloType.LEAD_EXCLUSION)}
				>
					Department exclusion
				</Radio>
				<Radio
					isChecked={filter === ApolloType.COUNTER}
					onChange={() => setFilter(ApolloType.COUNTER)}
				>
					Department Counter
				</Radio>

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</Stack>
		</Modal>
	);
};
