import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { LiEventOverview } from "./pages/LiEventOverview/LiEventOverview";
import { LiEventDetail } from "./pages/LiEventDetail/LiEventDetail";
import { LiEventEntryAttendees } from "./pages/LiEventEntryAttendees/LiEventEntryAttendees";

export const LiEvent: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<LiEventOverview />} path="/" />
			<Route element={<LiEventEntryAttendees />} path="/attendees/:id" />
			<Route element={<LiEventDetail />} path="/:id" />
		</Routes>
	);
};
