import { ChangeEvent, FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Stack, Typography } from "@mui/material";
import { LinkedIn, Report } from "@mui/icons-material";
import { useParams } from "react-router-dom";

import { useGetLiEventAttendeesQuery } from "@/pages/Private/redux/liEvent/liEvent.api";
import { LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { useSearch } from "@/utils";

import { LiEventEntryAttendee } from "../schema/liEventEntryAttendee";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LiEventEntryAttendeesTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();
	const ts = useCallback((key: string) => t(`liEvent.${key}`), [t]);
	const { page, setPage, limit, setLimit, query } = useSearch();

	const { data: liEventData, isLoading } = useGetLiEventAttendeesQuery({
		id: +id,
		page,
		limit,
		searchValue: query,
	});

	const columns: TableColumn<LiEventEntryAttendee>[] = useMemo(() => {
		const EURO = new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" });

		return [
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.name}
							</Typography>
							<Typography
								color="inherit"
								mb={0}
								sx={{
									fontWeight: 700,
								}}
								variant="body2"
							>
								{row?.prospect.title}
							</Typography>
							{row?.prospect?.blacklist && <Report />}
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("email"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.prospect?.email}
							</Typography>
							{row.prospect?.linkedinUrl ? (
								<Link href={row.prospect?.linkedinUrl || ""} target="_blank">
									<LinkedIn />
								</Link>
							) : (
								<></>
							)}
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("company"),
				format: (row) => (
					<Stack alignItems={"center"} direction="row" justifyContent="flex-start">
						{row?.prospect?.organization?.logoUrl ? (
							<Box
								component="img"
								height={"32px"}
								mr={1}
								src={row?.prospect?.organization?.logoUrl}
								sx={{ borderRadius: 1 }}
							/>
						) : (
							<></>
						)}
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.prospect?.organization?.name}{" "}
								{row?.prospect?.organization?.foundedYear &&
									`(${row.prospect?.organization?.foundedYear})`}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("employees"),
				align: "right",
				format: (row) => (
					<Stack alignItems={"center"} direction="row" justifyContent="flex-end">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-end">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.prospect?.organization?.estimatedNumEmployees || 0}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("annualRevenue"),
				align: "right",
				format: (row) => (
					<Stack alignItems={"center"} direction="row" justifyContent="flex-end">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-end">
							<Typography color="inherit" mb={0} variant="body2">
								{EURO.format(row.prospect.organization?.annualRevenue || 0)}
							</Typography>
						</Stack>
					</Stack>
				),
			},
		];
	}, [ts]);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	return !liEventData || isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					{/* <h3 className="mb-3">{liEventData.name}</h3>
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">
						<a href={liEventData.url} rel="noreferrer" target="_blank">
							{liEventData.url}
						</a>
					</p> */}
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={liEventData.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={liEventData.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
		</div>
	);
};
