import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Stack, Typography } from "@mui/material";

import { Error, CheckCircle } from "@mui/icons-material";

import { useParams } from "react-router-dom";

import {
	faArrowUpRightFromSquare,
	faCalendar,
	faMapLocation,
	faStar,
} from "@fortawesome/pro-regular-svg-icons";

import {
	FacebookLogo,
	GithubLogo,
	InstagramLogo,
	LinkedinLogo,
	PinterestLogo,
	SnapchatLogo,
	TiktokLogo,
	TwitterLogo,
	YoutubeLogo,
} from "phosphor-react";

import yelp from "@/assets/icons/yelp.png";
import { useGetMapBusinessesQuery } from "@/pages/Private/redux/map/map.api";
import { Icon, LoadingOverlay } from "@/components";
import { Enrichments } from "@/enum/enrichments.enum";

import { Table, TableColumn } from "@/components/Table/Table";

import { useCopyToClipboard } from "@/utils/useCopyToClipboard";

import { MapBusiness } from "../schema/mapBusiness";

export const MapBusinessTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();
	const ts = useCallback((key: string) => t(`map.${key}`), [t]);
	const { renderSnackbar, handleCopyClick } = useCopyToClipboard();
	const [page, setPage] = useState(1);
	const [allData, setAllData] = useState<MapBusiness[]>([]);

	const {
		data: mapData,
		isLoading,
		isFetching,
	} = useGetMapBusinessesQuery({
		id: +id,
		page: page,
		limit: 15,
	});

	useEffect(() => {
		if (mapData?.data) {
			setAllData((prevData) => [...prevData, ...mapData.data]);
		}
	}, [mapData]);

	const renderStatus = (status: Enrichments) => {
		switch (status) {
			case Enrichments.DONE:
				return (
					<>
						<CheckCircle color="primary" /> Found
					</>
				);
			case Enrichments.INVALID:
				return (
					<>
						<Error color="warning" />
						Not enriched
					</>
				);
			default:
				return <></>;
		}

		return <></>;
	};

	const handleFetchMore = () => {
		setPage(page + 1);
	};

	const tableColumns: TableColumn[] = useMemo(() => {
		const columns: TableColumn[] = [
			{
				title: ts("companyName"),
				width: "fit-content",
				orderTitle: "companyName",
				orderStatus: 0,
				uid: "companyName",
				render: (row: MapBusiness) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography
								color="inherit"
								mb={0}
								style={{ cursor: "pointer" }}
								variant="body2"
								onClick={() => row?.companyName && handleCopyClick(row.companyName)}
							>
								{row?.companyName}
							</Typography>
							<div className="flex mt-1">
								{row.website && (
									<Link href={row.website} target="_blank">
										<Icon className="h-[16px] flex mr-2" icon={faArrowUpRightFromSquare} />
									</Link>
								)}
								{row.placeLink && (
									<Link href={row.placeLink} target="_blank">
										<Icon className="h-[16px] flex mr-2" icon={faMapLocation} />
									</Link>
								)}
								{row.reviewsLink && (
									<Link href={row.reviewsLink} target="_blank">
										<Icon className="h-[16px] flex mr-2" icon={faStar} />
									</Link>
								)}
								{row.bookingLink && (
									<Link href={row.bookingLink} target="_blank">
										<Icon className="h-[16px] flex mr-2" icon={faCalendar} />
									</Link>
								)}
							</div>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("reviewCount"),
				width: "fit-content",
				orderTitle: "reviewCount",
				orderStatus: 0,
				uid: "reviewCount",
				render: (row: MapBusiness) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography
								color="inherit"
								mb={0}
								style={{ cursor: "pointer" }}
								variant="body2"
								onClick={() => row?.reviewCount && handleCopyClick(row.reviewCount.toString())}
							>
								{row?.reviewCount}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("rating"),
				width: "fit-content",
				orderTitle: "rating",
				orderStatus: 0,
				uid: "rating",
				render: (row: MapBusiness) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography
								color="inherit"
								mb={0}
								style={{ cursor: "pointer" }}
								variant="body2"
								onClick={() => row?.rating && handleCopyClick(row.rating.toString())}
							>
								{row?.rating}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("type"),
				width: "fit-content",
				orderTitle: "type",
				orderStatus: 0,
				uid: "type",
				render: (row: MapBusiness) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.type}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("socialMedia"),
				width: "fit-content",
				orderStatus: 0,
				uid: "socialMedia",
				render: (row: MapBusiness) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<div className="flex">
							{row.facebookUrl && (
								<Link href={row.facebookUrl} target="_blank">
									<FacebookLogo size="20" />
								</Link>
							)}
							{row.yelpUrl && (
								<Link href={row.yelpUrl} target="_blank">
									<img alt="yelp" className="h-[20px]" src={yelp} />
								</Link>
							)}
							{row.tiktokUrl && (
								<Link href={row.tiktokUrl} target="_blank">
									<TiktokLogo size="20" />
								</Link>
							)}
							{row.instagramUrl && (
								<Link href={row.instagramUrl} target="_blank">
									<InstagramLogo size="20" />
								</Link>
							)}
							{row.snapchatUrl && (
								<Link href={row.snapchatUrl} target="_blank">
									<SnapchatLogo size="20" />
								</Link>
							)}
							{row.twitterUrl && (
								<Link href={row.twitterUrl} target="_blank">
									<TwitterLogo size="20" />
								</Link>
							)}
							{row.youtubeUrl && (
								<Link href={row.youtubeUrl} target="_blank">
									<YoutubeLogo size="20" />
								</Link>
							)}
							{row.githubUrl && (
								<Link href={row.githubUrl} target="_blank">
									<GithubLogo size="20" />
								</Link>
							)}
							{row.pinterestUrl && (
								<Link href={row.pinterestUrl} target="_blank">
									<PinterestLogo size="20" />
								</Link>
							)}
							{row.companyLinkedinUrl && (
								<Link href={row.companyLinkedinUrl} target="_blank">
									<LinkedinLogo size="20" />
								</Link>
							)}
						</div>
					</Stack>
				),
			},
			{
				title: ts("companyEmail"),
				width: "fit-content",
				orderTitle: "companyEmail",
				orderStatus: 0,
				uid: "companyEmail",
				render: (row: MapBusiness) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.companyEmail && (
								<button
									className="cursor-pointer"
									onClick={() => row?.companyEmail && handleCopyClick(row.companyEmail)}
								>
									{row.companyEmail}
								</button>
							)}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("website"),
				width: "fit-content",
				orderTitle: "website",
				orderStatus: 0,
				uid: "website",
				render: (row: MapBusiness) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.website && (
								<Link href={row.website} target="_blank">
									{row.website}
								</Link>
							)}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("phoneNumber"),
				width: "fit-content",
				orderTitle: "phoneNumber",
				orderStatus: 0,
				uid: "phoneNumber",
				render: (row: MapBusiness) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.phoneNumber}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("companyCountry"),
				width: "fit-content",
				orderTitle: "companyCountry",
				orderStatus: 0,
				uid: "companyCountry",
				render: (row: MapBusiness) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.companyCountry && (
								<span
									className="cursor-pointer"
									onClick={() => row?.companyCountry && handleCopyClick(row.companyCountry)}
								>
									{row.companyCountry}
								</span>
							)}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("companyCity"),
				width: "fit-content",
				orderTitle: "companyCity",
				orderStatus: 0,
				uid: "companyCity",
				render: (row: MapBusiness) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.companyCountry && (
								<span
									className="cursor-pointer"
									onClick={() => row?.companyCity && handleCopyClick(row.companyCity)}
								>
									{row.companyCity}
								</span>
							)}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("status"),
				width: "fit-content",
				orderTitle: "status",
				orderStatus: 0,
				uid: "status",
				render: (row: MapBusiness) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{renderStatus(row.status)}
						</Stack>
					</Stack>
				),
			},
		];

		return columns;
	}, [ts, handleCopyClick]);

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="relative w-full">
				<div className="w-[fit-content] overflow-visible pr-4">
					<Table
						columns={tableColumns}
						data={allData || []}
						handleFetchMore={() => {
							if (
								mapData?.meta?.itemCount &&
								allData.length < mapData?.meta?.itemCount &&
								!isFetching
							) {
								handleFetchMore();
							}
						}}
						hidePagination={true}
						// handleSorting={handleSorting}
						isFetching={isFetching}
						isLoading={isLoading}
						page={page}
						totalPages={mapData?.meta.pageCount}
					/>
				</div>
			</div>
			{renderSnackbar()}
		</div>
	);
};
