import { ChangeEvent, FunctionComponent, useCallback } from "react";
import { Stack } from "@mui/material";

import { useTranslation } from "react-i18next";

import { faRefresh } from "@fortawesome/pro-regular-svg-icons";

import { Icon, LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { InputField } from "@/components/InputField/InputField";
import {
	useGetLemlistsQuery,
	useSyncCampaignsMutation,
} from "@/pages/Private/redux/lemlist/lemlist.api";

import { Radio } from "@/components/Radio/Radio";

import { useNonStickySearch } from "@/utils/useNonStickySearch";

import { Lemlist } from "../schema/list";

export interface LemlistListProps {
	selectedLemlist?:
		| {
				id: number;
		  }
		| undefined;
	setSelectedLemlist: (source: { id: number }) => void;
}

export const LemlistList: FunctionComponent<LemlistListProps> = ({
	selectedLemlist,
	setSelectedLemlist,
}) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`list.campaign.${key}`), [t]);
	const { page, setPage, limit, setLimit, searchValue, query, setSearchValue } =
		useNonStickySearch();

	const { data, isFetching } = useGetLemlistsQuery({
		page,
		limit,
		searchValue: query,
	});

	const [syncCampaigns, { isLoading }] = useSyncCampaignsMutation();

	console.log("---page lemlist list---", page);

	const columns: TableColumn<Lemlist>[] = [
		{
			label: "",
			width: 20,
			format: (listSource: Lemlist) => (
				<Radio
					isChecked={selectedLemlist?.id === listSource.id}
					name={`${listSource.id}`}
					onChange={() => {
						setSelectedLemlist({ id: listSource.id });
					}}
				/>
			),
		},
		{
			label: ts("name"),
			format: (row) => (
				<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
					<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
						<div className="text-sm flex items-center">{row?.name}</div>
					</Stack>
				</Stack>
			),
		},
	];

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	return !data || isFetching || isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full mt-8">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">{ts("subtitle")}</p>
				</div>
			</div>
			<div className="flex items-center align-baseline w-full mb-8">
				<div className="flex-grow max-w-[400px] mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
				<div className="flex justify-start w-[200px]">
					<label
						className="text-sm flex items-center cursor-pointer"
						onClick={() => syncCampaigns()}
					>
						<Icon className="border rounded p-1 mr-1 h-[20px]" color="#1d3df2" icon={faRefresh} />{" "}
						<div className="inline-flex">{ts("sync")}</div>
					</label>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					allowNavigation={false}
					columns={columns}
					data={data.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
		</div>
	);
};
