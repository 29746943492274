import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/redux";

import { LiProfileVisitsState } from "@/types/liProfileVisits";

import { liProfileVisitsApi } from "./liProfileVisits.api";

const initialState: LiProfileVisitsState = {
	liProfileVisits: [],
};

export const liProfileVisitsSlice = createSlice({
	name: "liProfileVisits",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			liProfileVisitsApi.endpoints.getLiProfileVisits.matchFulfilled,
			(state, { payload }) => {
				state.liProfileVisits = payload.data;
			}
		);
	},
});

export const {} = liProfileVisitsSlice.actions;
export const liProfileVisitsReducer = liProfileVisitsSlice.reducer;
export const liProfileVisitsSelector = (state: RootState) => state[liProfileVisitsSlice.name];
