import { createSlice } from "@reduxjs/toolkit";

import { LiPostState } from "@/types/liPost";

import { RootState } from "@/redux";

import { liPostApi } from "./liPost.api";

const initialState: LiPostState = {
	liPosts: [],
};

export const liPostSlice = createSlice({
	name: "liPost",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(liPostApi.endpoints.getLiPosts.matchFulfilled, (state, { payload }) => {
			state.liPosts = payload.data;
		});
	},
});

export const {} = liPostSlice.actions;
export const liPostReducer = liPostSlice.reducer;
export const liPostSelector = (state: RootState) => state[liPostSlice.name];
