import { z } from "zod";

import { CustomColumnTypes } from "@/enum/list.enum";
import { MetaDataSchema } from "@/schemas/meta-data";

export const ColumnConfigSchema = z.object({
	id: z.number(),
	listId: z.number(),
	name: z.string(),
	type: z.nativeEnum(CustomColumnTypes),
	config: z.any(),
});

export const ColumnConfigResponseSchema = z.object({
	data: z.array(ColumnConfigSchema),
	meta: MetaDataSchema,
});

export type ColumnConfig = z.infer<typeof ColumnConfigSchema>;

export type ColumnConfigResponse = z.infer<typeof ColumnConfigResponseSchema>;
