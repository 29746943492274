import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { CsvEnrichmentScrapeStatus } from "@/enum/csv-enrichment.enum";

import { ApolloOrgSchema } from "../../Apollo/schema/apolloOrg";

import { ApolloPeopleResponseSchema } from "../../Apollo/schema/get-apollos";

const { REQUIRED } = ERROR_TYPE;

export const CsvEnrichmentPersonSchema = z.object({
	id: z.number(),
	csvEnrichmentId: z.string().trim(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	firstName: z.string().nullable(),
	lastName: z.string().nullable(),
	linkedinUrl: z.string().nullable(),
	email: z.string().nullable(),
	companyName: z.string().nullable(),
	title: z.string().nullable(),
	headline: z.string().nullable(),
	prospect: ApolloPeopleResponseSchema.optional(),
	cleanedCompanyName: z.string().trim(),
	companyDomain: z.string().nullable(),
	location: z.string().nullable(),
	country: z.string().nullable(),
	state: z.string().nullable(),
	city: z.string().nullable(),
	companyCity: z.string().nullable(),
	companyCountry: z.string().nullable(),
	companyState: z.string().nullable(),
	companyLocation: z.string().nullable(),
	companyLinkedinUrl: z.string().nullable(),
	employmentHistory: z.string().nullable(),

	status: z.nativeEnum(CsvEnrichmentScrapeStatus),
	organization: ApolloOrgSchema.optional(),
});

export const CsvEnrichmentPersonResponseSchema = z.object({
	data: z.array(CsvEnrichmentPersonSchema),
	meta: MetaDataSchema,
});

export type CsvEnrichmentPerson = z.infer<typeof CsvEnrichmentPersonSchema>;

export type CsvEnrichmentPersonResponse = z.infer<typeof CsvEnrichmentPersonResponseSchema>;
