import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { UsageOverview } from "./pages/UsageOverview";

export const Usage: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<UsageOverview />} path="/" />
		</Routes>
	);
};
