export enum ScoreTypes {
	TITLE = "TITLE",
	SENIORITY = "SENIORITY",
	LOCATION = "LOCATION",
	COMPANY_LOCATION = "COMPANY_LOCATION",
	EMPLOYEES = "EMPLOYEES",
	INDUSTRY = "INDUSTRY",
	KEYWORDS = "KEYWORDS",
	NUMBER_IN_LEAD_FILTER = "NUMBER_IN_LEAD_FILTER",
}

export const NumericScoreTypes = [ScoreTypes.EMPLOYEES, ScoreTypes.NUMBER_IN_LEAD_FILTER];

export const LocationScoreTypes = [ScoreTypes.LOCATION, ScoreTypes.COMPANY_LOCATION];
