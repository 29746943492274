import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { Modal } from "@/components";

import { useCreateCustomEventMutation } from "@/pages/Private/redux/customEvent/customEvent.api";

import { Radio } from "@/components/Radio/Radio";
import { CustomEventType } from "@/enum/custom-event.enum";

import { CreateCustomEvent, CreateCustomEventSchema } from "../schema/customEvent";

interface createCustomEventModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateCustomEventModal: FunctionComponent<createCustomEventModalProps> = ({
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`customEvent.${key}`);
	const [createCustomEvent] = useCreateCustomEventMutation();
	const [filter, setFilter] = useState<CustomEventType>(CustomEventType.PERSON);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<CreateCustomEvent>({
		defaultValues: {
			name: "",
			url: "",
		},
		resolver: zodResolver(CreateCustomEventSchema),
	});

	const onSubmit = async (values: CreateCustomEvent) => {
		try {
			await createCustomEvent({ ...values }).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			// isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>

				<TextField
					error={!!errors.url?.message}
					label={ts("url")}
					placeholder={ts("url")}
					{...register("url")}
				/>

				<Radio
					isChecked={filter === CustomEventType.PERSON}
					onChange={() => setFilter(CustomEventType.PERSON)}
				>
					{ts("personList")}
				</Radio>
				<Radio
					isChecked={filter === CustomEventType.ORGANIZATION}
					onChange={() => setFilter(CustomEventType.ORGANIZATION)}
				>
					{ts("organizationList")}
				</Radio>

				{/* {getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null} */}
			</Stack>
		</Modal>
	);
};
