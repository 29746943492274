import { FunctionComponent } from "react";

import { SvgIcon } from "@/components/Icon/SvgIcon";
import { classNames } from "@/utils/classNames";
import { MapScrapeType } from "@/enum/map.enum";
import { JobScrapeType } from "@/enum/job.enum";

interface TileProps {
	title: string;
	description: string;
	icon: React.ElementType<React.SVGProps<SVGSVGElement>>;
	isSelected: boolean;
	listType: MapScrapeType | JobScrapeType;
	setListType: (listType: MapScrapeType | JobScrapeType) => void;
}

export const DetailsTile: FunctionComponent<TileProps> = ({
	title,
	description,
	icon,
	isSelected,
	setListType,
	listType,
}) => {
	return (
		<button
			className={classNames(
				"border border-divider rounded-xl  mr-5 mb-4 self-stretch w-full flex",
				isSelected ? "border-tile-active" : "border-divider"
			)}
			onClick={() => setListType(listType)}
		>
			<div className="px-5 py-3  self-stretch">
				<div className="w-full items-center flex py-1 mb-3">
					<SvgIcon className="w-[40px] h-[40px] mr-2" svgIcon={icon} />
					<div className="text-black-100">{title}</div>
				</div>
				<hr className="w-full border-divider mb-3" />
				<div className="text-text text-left text-ssm">{description}</div>
			</div>
		</button>
	);
};
