import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { Modal } from "@/components";

import { useUpdateLiCompanyActivityMutation } from "@/pages/Private/redux/liCompanyActivity/liCompanyActivity.api";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import {
	CreateLiCompanyActivity,
	CreateLiCompanyActivitySchema,
	LiCompanyActivity,
} from "../schema/liCompanyActivity";

interface updateLiCompanyActivityModalProps {
	liCompanyActivity: LiCompanyActivity;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const UpdateLiCompanyActivityModal: FunctionComponent<updateLiCompanyActivityModalProps> = ({
	liCompanyActivity,
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liCompanyActivity.${key}`);

	const [selectedLiCompanyActivity, setSelectedLiCompanyActivity] =
		useState<LiCompanyActivity>(liCompanyActivity);

	const [updateLiCompanyActivity, { isLoading, error }] = useUpdateLiCompanyActivityMutation();

	useEffect(() => {
		if (liCompanyActivity) {
			setSelectedLiCompanyActivity(liCompanyActivity);
		}
	}, [liCompanyActivity]);

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<CreateLiCompanyActivity>({
		defaultValues: {
			name: selectedLiCompanyActivity?.name ?? "",
			url: selectedLiCompanyActivity?.url ?? "",
			scrapeFollowers: selectedLiCompanyActivity?.scrapeFollowers ?? false,
		},
		resolver: zodResolver(CreateLiCompanyActivitySchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: CreateLiCompanyActivity) => {
		try {
			await updateLiCompanyActivity({
				id: selectedLiCompanyActivity.id,
				name: values.name,
				url: values.url,
				scrapeFollowers: values.scrapeFollowers,
			}).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("update.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>
				<TextField
					error={!!errors.url?.message}
					label={ts("url")}
					placeholder={ts("url")}
					{...register("url")}
				/>
				<Checkbox
					className="mb-8"
					isChecked={getValues("scrapeFollowers")}
					onChange={() =>
						setValue("scrapeFollowers", !getValues("scrapeFollowers"), {
							shouldDirty: true,
							shouldValidate: true,
						})
					}
				>
					{ts("scrapeFollowers")}
				</Checkbox>

				{getValues("scrapeFollowers") ? (
					<Alert severity="warning">{ts("scrapeFollowersWarning")}</Alert>
				) : null}

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</Stack>
		</Modal>
	);
};
