import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	CreateMap,
	Map,
	MapResponse,
	MapResponseSchema,
	MapSchema,
	ToggleMap,
	ToggleMapSchema,
} from "../../pages/Map/schema/map";
import {
	MapBusinesssResponse,
	MapBusinesssResponseSchema,
} from "../../pages/Map/schema/mapBusiness";
import { PreviewMapResponse, PreviewMapResponseSchema } from "../../pages/Map/schema/preview";

export const mapApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getMaps: builder.query<MapResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "map",
				params: {
					...(params && { ...params }),
				},
				responseSchema: MapResponseSchema,
			}),
			providesTags: ["Map"],
		}),
		getMap: builder.query<Map, number>({
			query: (id: number) => ({
				url: `map/${id}`,
				responseSchema: MapSchema,
			}),
			providesTags: ["Map"],
		}),
		getMapPreview: builder.query<PreviewMapResponse, number>({
			query: (id: number) => ({
				url: `map/preview/${id}`,
				responseSchema: PreviewMapResponseSchema,
			}),
			providesTags: (result, error, arg) => [{ type: "MapPreview", id: arg }],
		}),
		getMapBusinesses: builder.query<MapBusinesssResponse, RequestPaginationParams & { id: number }>(
			{
				query: ({ id, ...params }) => ({
					url: `map/businesses/${id}`,
					params: {
						...(params && { ...params }),
					},
					responseSchema: MapBusinesssResponseSchema,
				}),
				providesTags: ["Map"],
			}
		),
		createMap: builder.mutation<Partial<Map>, CreateMap>({
			query: (body) => ({
				url: `map`,
				method: "POST",
				body,
				responseSchema: MapSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "Map"];
				}

				return ["Map"];
			},
		}),
		updateMap: builder.mutation<Partial<Map>, Partial<Map> & { listId?: number }>({
			query: ({ id, ...body }) => ({
				url: `map/${id}`,
				method: "PATCH",
				body,
				responseSchema: MapSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "Map", { type: "MapPreview", id: arg.id }];
				}

				return ["Map", { type: "MapPreview", id: arg.id }];
			},
		}),
		toggleMap: builder.mutation<Partial<Map>, ToggleMap>({
			query: ({ id, ...body }) => ({
				url: `map/${id}`,
				method: "PATCH",
				body,
				responseSchema: ToggleMapSchema,
			}),
		}),
		deleteMap: builder.mutation<Partial<Map>, { id: number; listId?: number }>({
			query: ({ id, listId }) => ({
				url: listId ? `map/${id}/list/${listId}` : `map/${id}`,
				method: "DELETE",
				responseSchema: MapSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "Map"];
				}

				return ["Map"];
			},
		}),
	}),
});

export const {
	useGetMapsQuery,
	useLazyGetMapsQuery,
	useGetMapQuery,
	useGetMapBusinessesQuery,
	useUpdateMapMutation,
	useDeleteMapMutation,
	useCreateMapMutation,
	useToggleMapMutation,
	useLazyGetMapPreviewQuery,
} = mapApi;
export const accountApiReducer = mapApi.reducer;
export const accountApiMiddleware = mapApi.middleware;
