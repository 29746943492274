import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { useNavigate } from "react-router-dom";

import { Modal } from "@/components";

import { useCreateListMutation } from "@/pages/Private/redux/list/list.api";

import { ListSourceType, SearchBase } from "@/enum/list.enum";
import { Radio } from "@/components/Radio/Radio";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { CreateList, CreateListSchema } from "../schema/list";

interface createListModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateListModal: FunctionComponent<createListModalProps> = ({ isOpen, setIsOpen }) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`list.${key}`);
	const navigate = useNavigate();
	const [createList, { isLoading, error }] = useCreateListMutation();

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<CreateList>({
		defaultValues: {
			name: "",
			sourceType: ListSourceType.PROSPECT_SEARCH,
			searchBase: SearchBase.PERSON,
		},
		resolver: zodResolver(CreateListSchema),
	});

	const onSubmit = async (values: CreateList) => {
		try {
			const { sourceType, searchBase } = values;

			let newSearchBase = searchBase;

			if (
				![ListSourceType.MAPS, ListSourceType.JOBS].includes(sourceType) &&
				newSearchBase === SearchBase.SOURCE
			) {
				newSearchBase = SearchBase.PERSON;
			}

			const updateValues = { ...values, searchBase: newSearchBase };

			const createdList = await createList(updateValues).unwrap();

			setIsOpen(false);

			navigate(`/app/lists/settings/${createdList.id}`);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			size="lg"
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>

				<div className="flex flex-col">
					<div className="text-sm">{ts("sourceType")}</div>
					<div className="mb-2 mt-2">
						<Radio
							isChecked={getValues("sourceType") === ListSourceType.PROSPECT_SEARCH}
							name="sourceType"
							onChange={() =>
								setValue("sourceType", ListSourceType.PROSPECT_SEARCH, {
									shouldValidate: true,
									shouldDirty: true,
								})
							}
						>
							{t(`sidebar.searches`)}
						</Radio>
					</div>
					<div className="mb-2">
						<Radio
							isChecked={getValues("sourceType") === ListSourceType.LI_EVENTS}
							name="sourceType"
							onChange={() =>
								setValue("sourceType", ListSourceType.LI_EVENTS, {
									shouldValidate: true,
									shouldDirty: true,
								})
							}
						>
							{t(`sidebar.linkedinEvents`)}
						</Radio>
					</div>
					<div className="mb-2">
						<Radio
							isChecked={getValues("sourceType") === ListSourceType.LI_PEOPLE}
							name="sourceType"
							onChange={() =>
								setValue("sourceType", ListSourceType.LI_PEOPLE, {
									shouldValidate: true,
									shouldDirty: true,
								})
							}
						>
							{t(`sidebar.linkedinPeopleSearch`)}
						</Radio>
					</div>
					<div className="mb-2">
						<Radio
							isChecked={getValues("sourceType") === ListSourceType.LI_COMPANY_ACTIVITY}
							name="sourceType"
							onChange={() =>
								setValue("sourceType", ListSourceType.LI_COMPANY_ACTIVITY, {
									shouldValidate: true,
									shouldDirty: true,
								})
							}
						>
							{t(`sidebar.linkedinCompanyActivity`)}
						</Radio>
					</div>
					<div className="mb-2">
						<Radio
							isChecked={getValues("sourceType") === ListSourceType.LI_PROFILE_VISITS}
							name="sourceType"
							onChange={() => {
								setValue("sourceType", ListSourceType.LI_PROFILE_VISITS, {
									shouldValidate: true,
									shouldDirty: true,
								});
							}}
						>
							{t(`sidebar.linkedinProfileVisits`)}
						</Radio>
					</div>
					<div className="mb-2">
						<Radio
							isChecked={getValues("sourceType") === ListSourceType.LI_PROFILE}
							name="sourceType"
							onChange={() => {
								setValue("sourceType", ListSourceType.LI_PROFILE, {
									shouldValidate: true,
									shouldDirty: true,
								});
							}}
						>
							{t(`liProfile.name`)}
						</Radio>
					</div>

					<div className="mb-2">
						<Radio
							isChecked={getValues("sourceType") === ListSourceType.LI_POST}
							name="sourceType"
							onChange={() =>
								setValue("sourceType", ListSourceType.LI_POST, {
									shouldValidate: true,
									shouldDirty: true,
								})
							}
						>
							{t(`sidebar.linkedinPosts`)}
						</Radio>
					</div>
					<div className="mb-2">
						<Radio
							isChecked={getValues("sourceType") === ListSourceType.CUSTOM_EVENTS}
							name="sourceType"
							onChange={() =>
								setValue("sourceType", ListSourceType.CUSTOM_EVENTS, {
									shouldValidate: true,
									shouldDirty: true,
								})
							}
						>
							{t(`sidebar.customEvent`)}
						</Radio>
					</div>
					<div className="mb-2">
						<Radio
							isChecked={getValues("sourceType") === ListSourceType.CSV_ENRICHMENTS}
							name="sourceType"
							onChange={() =>
								setValue("sourceType", ListSourceType.CSV_ENRICHMENTS, {
									shouldValidate: true,
									shouldDirty: true,
								})
							}
						>
							{t(`sidebar.csvEnrichment`)}
						</Radio>
					</div>
					<div className="mb-2">
						<Radio
							isChecked={getValues("sourceType") === ListSourceType.JOBS}
							name="sourceType"
							onChange={() =>
								setValue("sourceType", ListSourceType.JOBS, {
									shouldValidate: true,
									shouldDirty: true,
								})
							}
						>
							{t(`sidebar.jobs`)}
						</Radio>
					</div>
					<div className="mb-2">
						<Radio
							isChecked={getValues("sourceType") === ListSourceType.MAPS}
							name="sourceType"
							onChange={() =>
								setValue("sourceType", ListSourceType.MAPS, {
									shouldValidate: true,
									shouldDirty: true,
								})
							}
						>
							{t(`sidebar.maps`)}
						</Radio>
					</div>
				</div>

				{getValues("sourceType") !== ListSourceType.PROSPECT_SEARCH && (
					<div className="flex flex-col">
						<div className="text-sm">{ts("searchBase")}</div>
						<div className="mb-2 mt-2">
							<Radio
								isChecked={getValues("searchBase") === SearchBase.PERSON}
								name="searchBase"
								onChange={() =>
									setValue("searchBase", SearchBase.PERSON, {
										shouldValidate: true,
										shouldDirty: true,
									})
								}
							>
								{t(`enum.searchBase.PERSON`)}
							</Radio>
						</div>
						<div className="mb-2">
							<Radio
								isChecked={getValues("searchBase") === SearchBase.COMPANY}
								name="searchBase"
								onChange={() =>
									setValue("searchBase", SearchBase.COMPANY, {
										shouldValidate: true,
										shouldDirty: true,
									})
								}
							>
								{t(`enum.searchBase.COMPANY`)}
							</Radio>
						</div>
						{[ListSourceType.MAPS, ListSourceType.JOBS].includes(getValues("sourceType")) && (
							<div className="mb-2">
								<Radio
									isChecked={getValues("searchBase") === SearchBase.SOURCE}
									name="searchBase"
									onChange={() =>
										setValue("searchBase", SearchBase.SOURCE, {
											shouldValidate: true,
											shouldDirty: true,
										})
									}
								>
									{t(`enum.searchBase.SOURCE`)}
								</Radio>
							</div>
						)}
					</div>
				)}

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</Stack>
		</Modal>
	);
};
