export enum MapBusinessStatus {
	OPEN = "OPEN",
	CLOSED_TEMPORARILY = "CLOSED_TEMPORARILY",
	CLOSED = "CLOSED",
}

export enum MapScrapeType {
	GET_INFO_AT = "GET_INFO_AT",
	GET_ENRICHMENT = "GET_ENRICHMENT",
	GET_MANAGING_DIRECTOR = "GET_MANAGING_DIRECTOR",
}
