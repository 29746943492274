import { FunctionComponent, useCallback } from "react";

import { useTranslation } from "react-i18next";

import Item from "@/components/SortableList/Item";
import { TItem, SortableList } from "@/components/SortableList/SortableList";
import { Toggle } from "@/components/Toggle/Toggle";
import { Modal } from "@/components";

export interface ColumnModalProps {
	setShowSelectColumns: (value: boolean) => void;
	showSelectColumns: boolean;
	tableConfig: TItem[];
	setTableConfig: (value: TItem[]) => void;
	setEditableColumn: (value: TItem | undefined) => void;
	setShowDeleteColumn: (value: boolean) => void;
	setShowEditColumn: (value: boolean) => void;
}

export const ColumnModal: FunctionComponent<ColumnModalProps> = ({
	setShowSelectColumns,
	showSelectColumns,
	tableConfig,
	setTableConfig,
	setEditableColumn,
	setShowDeleteColumn,
	setShowEditColumn,
}) => {
	const { t } = useTranslation();

	const ts = useCallback((key: string) => t(`inbox.${key}`), [t]);

	const handleClearLocalStorage = () => {
		setTableConfig([]);
	};

	return (
		<Modal
			clearButtonText="Reset to default"
			handleClear={() => handleClearLocalStorage()}
			handleSave={() => setShowSelectColumns(false)}
			isOpened={showSelectColumns}
			size="md"
			submitButtonColor="BRAND"
			submitButtonText={ts("close")}
			title={ts("selectColumns")}
		>
			<div className="mb-6 flex flex-row items-center">
				<Toggle
					className="mr-3"
					isChecked={tableConfig.filter((c) => !c.locked).every((c) => c.show)}
					onChange={() => {
						const newShow = !tableConfig.filter((c) => !c.locked).every((c) => c.show);

						setTableConfig(
							tableConfig.map((c) => {
								if (!c.locked) {
									c.show = newShow;
								}

								return c;
							})
						);
					}}
				/>
				<div className="text-ssm">Display all columns</div>
			</div>

			{tableConfig
				.filter((c) => c.allowExclude && !c.moveable)
				.map((c, index) => {
					return (
						<Item
							key={`locked-${c.uid}-${index}`}
							handleShowToggle={(uid) => {
								const item = tableConfig.find((c) => c.uid === uid);

								if (item) {
									item.show = !(typeof item.show !== "undefined" ? item.show : true);
									const index = tableConfig.findIndex((c) => c.uid === uid);

									const newTableConfig = [
										...tableConfig.slice(0, index),
										item,
										...tableConfig.slice(index + 1),
									];

									setTableConfig(newTableConfig);
								}
							}}
							item={
								{
									id: index + 1,
									content: c.content,
									locked: c.locked,
									show: c.show,
									uid: c.uid,
									moveable: c.moveable,
									hideable: c.hideable,
								} as TItem
							}
						/>
					);
				})}

			<SortableList
				defaultItems={tableConfig
					?.filter((c) => c.moveable)
					.map((c, index) => {
						return {
							...c,
							id: index + 1,
							content: c.content,
							locked: c.locked,
							show: c.show,
							uid: c.uid,
							moveable: c.moveable,
							hideable: c.hideable,
						};
					})}
				handleDelete={(uid) => {
					const item = tableConfig.find((c) => c.uid === uid);

					setEditableColumn(item);

					setShowDeleteColumn(true);
				}}
				handleEdit={(uid) => {
					const item = tableConfig.find((c) => c.uid === uid);

					setEditableColumn(item);

					setShowEditColumn(true);
				}}
				handleShowToggle={(uid) => {
					const item = tableConfig.find((c) => c.uid === uid);

					if (item) {
						item.show = !(typeof item.show !== "undefined" ? item.show : true);
						const index = tableConfig.findIndex((c) => c.uid === uid);

						const newTableConfig = [
							...tableConfig.slice(0, index),
							item,
							...tableConfig.slice(index + 1),
						];

						setTableConfig(newTableConfig);
					}
				}}
				handleSwitchColumns={(uid, uid2) => {
					const index1 = tableConfig.findIndex((c) => c.uid === uid);
					const index2 = tableConfig.findIndex((c) => c.uid === uid2);

					const temp = tableConfig[index1];

					tableConfig[index1] = tableConfig[index2];
					tableConfig[index2] = temp;

					setTableConfig([...tableConfig]);
				}}
			/>
		</Modal>
	);
};
