import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomEventTable } from "../../components/CustomEventTable";
import { CreateCustomEventModal } from "../../components/CreateCustomEventModal";

export const CustomEventOverview: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`customEvent.${key}`);
	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<h3 className="mb-3">{ts("title")}</h3>
				</div>
			</div>

			{<CustomEventTable />}

			{isInviteModalOpen && (
				<CreateCustomEventModal isOpen={isInviteModalOpen} setIsOpen={setIsInviteModalOpen} />
			)}
		</div>
	);
};
