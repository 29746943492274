import { z } from "zod";

import { Enrichments } from "@/enum/enrichments.enum";
import { MetaDataSchema } from "@/schemas/meta-data";

import { LiCompanyActivityType } from "@/enum/licompanyactivity.enum";

import { ApolloOrgSchema } from "../../Apollo/schema/apolloOrg";
import { ApolloPeopleResponseSchema } from "../../Apollo/schema/get-apollos";

export const LiCompanyActivityEntryResultSchema = z.object({
	id: z.number(),
	liCompanyActivityEntryId: z.number(),
	type: z.nativeEnum(LiCompanyActivityType),
	content: z.string().nullable(),
	contentUrl: z.string().nullable(),
	contentResponse: z.string().nullable(),
	contentResponseUrl: z.string().nullable(),
	name: z.string().nullable(),
	firstName: z.string().nullable(),
	lastName: z.string().nullable(),
	linkedinUrl: z.string().nullable(),
	companyLocation: z.string().nullable(),
	companyCity: z.string().nullable(),
	companyState: z.string().nullable(),
	companyCountry: z.string().nullable(),
	companyName: z.string().nullable(),
	cleanedCompanyName: z.string().nullable(),
	companyDomain: z.string().nullable(),
	companyLinkedinUrl: z.string().nullable(),
	title: z.string().nullable(),
	headline: z.string().nullable(),
	location: z.string().nullable(),
	city: z.string().nullable(),
	state: z.string().nullable(),
	country: z.string().nullable(),
	status: z.nativeEnum(Enrichments),
	prospect: ApolloPeopleResponseSchema.optional(),
	organization: ApolloOrgSchema.optional(),
});

export const LiCompanyActivityEntryResultsResponseSchema = z.object({
	data: z.array(LiCompanyActivityEntryResultSchema),
	meta: MetaDataSchema,
});

export type LiCompanyActivityEntryResult = z.infer<typeof LiCompanyActivityEntryResultSchema>;

export type LiCompanyActivityEntryResultsResponse = z.infer<
	typeof LiCompanyActivityEntryResultsResponseSchema
>;
