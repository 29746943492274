import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	LemlistAll,
	LemlistAllSchema,
	LemlistResponse,
	LemlistResponseSchema,
} from "../../pages/List/schema/list";

export const lemlistApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getLemlists: builder.query<LemlistResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "lemlist",
				params: {
					...(params && { ...params }),
				},
				responseSchema: LemlistResponseSchema,
			}),
			providesTags: ["Lemlist"],
		}),
		getAllLemlists: builder.query<LemlistAll, void>({
			query: () => ({
				url: "lemlist/all",
				responseSchema: LemlistAllSchema,
			}),
			providesTags: ["Lemlist"],
		}),
		syncCampaigns: builder.mutation<void, void>({
			query: (body) => ({
				url: `lemlist/sync`,
				method: "POST",
				body,
			}),
			invalidatesTags: ["Lemlist"],
		}),
	}),
});

export const {
	useGetLemlistsQuery,
	useLazyGetLemlistsQuery,
	useGetAllLemlistsQuery,
	useSyncCampaignsMutation,
} = lemlistApi;
export const accountApiReducer = lemlistApi.reducer;
export const accountApiMiddleware = lemlistApi.middleware;
