import { z } from "zod";

import { UserStatus } from "@/enum";
import { UserLanguage } from "@/enum/user.enum";
import { PeopleSchema } from "@/pages/Private/pages/Apollo/schema/get-people";
import { UserRoleSchema } from "@/pages/Private/pages/UserRoles/schema/roles";
import { ERROR_TYPE } from "@/utils";

const { REQUIRED, INVALID_EMAIL, INVALID_VALUE } = ERROR_TYPE;

export const LoginSchema = z.object({
	email: z.string().min(1, { message: REQUIRED }).email(INVALID_EMAIL),
	password: z.string().min(1, { message: REQUIRED }).trim(),
});

export const LoginRequestSchema = z.object({
	email: z.string().email(INVALID_EMAIL),
	password: z.string().min(8, { message: INVALID_VALUE }),
});

export const CompanySchema = z
	.object({
		id: z.number().gt(0),
		name: z.string().nullable(),
		linkedinUrl: z.string().nullable(),
		autoInvite: z.boolean(),
		hasApolloApiKey: z.boolean(),
	})
	.nullable();

export const FetchMeResponseSchema = z.object({
	id: z.number().gt(0),
	firstName: z.string().min(1),
	lastName: z.string().min(1),
	email: z.string().email(INVALID_EMAIL),
	linkedinUrl: z.string().optional().nullable(),
	is2FAEnabled: z.boolean(),
	isBlocked: z.boolean(),
	status: z.nativeEnum(UserStatus),
	userLanguage: z.nativeEnum(UserLanguage),
	company: CompanySchema,
	roles: z.array(UserRoleSchema),
	prospects: z.array(PeopleSchema).optional(),
	autoAccept: z.boolean(),
	profileVisits: z.boolean(),
	connectionScraping: z.boolean(),
});

export const LoginResponseSchema = z.object({
	accessToken: z.string().optional(),
	otpToken: z.string().optional(),
	user: FetchMeResponseSchema,
});

export type LoginType = z.infer<typeof LoginSchema>;
export type User = z.infer<typeof FetchMeResponseSchema>;
export type LoginResponse = z.infer<typeof LoginResponseSchema>;
