import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

import { YearlyOrMonthly } from "@/enum/company.enum";

import { UserSchema } from "../../Users/schema/invite-user";

export const CompaniesSchema = z.object({
	id: z.number(),
	name: z.string(),
	linkedinUrl: z.string().optional(),
	autoInvite: z.boolean(),
	hasApolloApiKey: z.boolean(),
	hasLemlistApiKey: z.boolean(),
	admins: z.array(UserSchema),
	users: z.array(UserSchema),
	filtered: z.number(),
	yearlyOrMonthly: z.nativeEnum(YearlyOrMonthly),
});

export const CreateCompaniesSchema = z.object({
	name: z.string(),
	linkedinUrl: z.string().optional(),
	filtered: z.number().optional(),
	yearlyOrMonthly: z.nativeEnum(YearlyOrMonthly),
});

export const UpdateCompaniesSchema = z.object({
	id: z.number().optional(),
	name: z.string(),
	linkedinUrl: z.string().optional(),
	autoInvite: z.boolean(),
	filtered: z.number().optional(),
	yearlyOrMonthly: z.nativeEnum(YearlyOrMonthly),
});

export const CompaniesResponseSchema = z.object({
	data: z.array(CompaniesSchema),
	meta: MetaDataSchema,
});

export type Companies = z.infer<typeof CompaniesSchema>;

export type CreateCompanies = z.infer<typeof CreateCompaniesSchema>;

export type UpdateCompanies = z.infer<typeof UpdateCompaniesSchema>;

export type CompaniesResponse = z.infer<typeof CompaniesResponseSchema>;
