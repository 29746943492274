import { ChangeEvent, FunctionComponent, useCallback } from "react";
import { Stack } from "@mui/material";

import { useTranslation } from "react-i18next";

import { LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { InputField } from "@/components/InputField/InputField";
import { useGetListAiEnrichmentQuery } from "@/pages/Private/redux/list/list.api";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { AiEnrichmentTarget } from "@/enum/ai-enrichment.enum";

import { useNonStickySearch } from "@/utils/useNonStickySearch";

import { ListAiEnrichment } from "../schema/list";

export interface AiEnrichmentListProps {
	selectedAiEnrichment?:
		| {
				id: number;
				type: AiEnrichmentTarget;
		  }[]
		| undefined;
	setSelectedAiEnrichment: (source: { id: number; type: AiEnrichmentTarget }[]) => void;
}

export const AiEnrichmentList: FunctionComponent<AiEnrichmentListProps> = ({
	selectedAiEnrichment,
	setSelectedAiEnrichment,
}) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`list.aiEnrichment.${key}`), [t]);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue } = useNonStickySearch();

	const { data, isLoading } = useGetListAiEnrichmentQuery({
		page,
		limit,
		searchValue: searchValue,
	});

	console.log("---page ai list---", page);

	const handleSelectAiEnrichment = (source: { id: number; type: AiEnrichmentTarget }) => {
		const foundItem = selectedAiEnrichment?.find((aiEnrichment) => aiEnrichment.id === source.id);

		if (foundItem) {
			const newSelectedAiEnrichment = selectedAiEnrichment?.filter(
				(aiEnrichment) => aiEnrichment.id !== source.id
			);

			setSelectedAiEnrichment(newSelectedAiEnrichment || []);
		} else {
			setSelectedAiEnrichment([...(selectedAiEnrichment || []), source]);
		}
	};

	const columns: TableColumn<ListAiEnrichment>[] = [
		{
			label: "",
			width: 20,
			format: (listSource: ListAiEnrichment) => (
				<Checkbox
					isChecked={
						!!selectedAiEnrichment?.find((aiEnrichment) => aiEnrichment.id === listSource.id) ||
						false
					}
					name={`${listSource.id}-${listSource.aiEnrichmentTarget}`}
					onChange={() => {
						handleSelectAiEnrichment({ id: listSource.id, type: listSource.aiEnrichmentTarget });
					}}
				/>
			),
		},
		{
			label: ts("name"),
			format: (row) => (
				<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
					<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
						<div className="text-sm flex items-center">{row?.name}</div>
					</Stack>
				</Stack>
			),
		},
		{
			label: ts("type"),
			format: (row) => (
				<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
					<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
						<div className="text-sm flex items-center">{row.aiEnrichmentTarget}</div>
					</Stack>
				</Stack>
			),
		},
	];

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	return !data || isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full mt-8">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">{ts("subtitle")}</p>
				</div>
			</div>
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					allowNavigation={false}
					columns={columns}
					data={data.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
		</div>
	);
};
