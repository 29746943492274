import { TableColumn } from "@/components/Table/Table";

import { SortParams } from "@/types/types";

import { getContextMenuItem } from "../../utils/contextMenu";
import {
	rendeListSourceColumn,
	renderAssignToListViewColumn,
	renderCompanyColumn,
	renderMaybeColumn,
	renderNameListViewsColumn,
	renderReplyResponseStatusColumn,
	renderReplyStatusColumn,
	renderSelectColumn,
	renderTitleColumn,
} from "../../utils/allColumns";
import { ListPerson } from "../../../List/schema/list";

export interface RepliesTableColumnsProps {
	selectAll: boolean;
	toggleSelectAll: () => void;
	selectedItems: number[];
	handleSelectItem: (id: number) => void;
	handleCopyClick: (value: string) => void;
	handleContextMenu: (value: string, row: ListPerson) => void;
	ts: (key: string) => string;
	sortingInbox: SortParams;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
}

export const RepliesTableColumns = ({
	selectAll,
	toggleSelectAll,
	selectedItems,
	handleSelectItem,
	handleCopyClick,
	handleContextMenu,
	ts,
	sortingInbox,
	setShowEdit,
	showEdit,
}: RepliesTableColumnsProps): TableColumn[] => {
	return [
		renderSelectColumn(toggleSelectAll, selectAll, selectedItems, handleSelectItem),
		renderMaybeColumn(),
		renderAssignToListViewColumn(ts, handleCopyClick),
		{ ...getContextMenuItem(handleContextMenu, ts) },
		renderReplyResponseStatusColumn(ts, handleCopyClick, sortingInbox),
		renderNameListViewsColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit),
		renderCompanyColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit),
		// renderGenderColumn(ts, handleCopyClick, sortingInbox),
		renderTitleColumn(ts, handleCopyClick, sortingInbox),
		rendeListSourceColumn(ts, handleCopyClick, sortingInbox),
		renderReplyStatusColumn(ts, handleCopyClick, sortingInbox),
		// renderSeniorityColumn(ts, handleCopyClick, sortingInbox),
		// renderConnectionsColumn(ts, sortingInbox),
		// renderIndustryColumn(ts, handleCopyClick, sortingInbox),
		// {
		// 	title: ts("companyPhone"),
		// 	width: "fit-content",
		// 	orderTitle: "organization.phone",
		// 	orderStatus: 0,
		// 	uid: "companyPhone",
		// 	hideable: true,
		// 	moveable: true,
		// 	show: true,
		// 	render: (row: ListPerson) => (
		// 		<div>
		// 			<a href={`tel: ${row.prospect?.organization?.phone}`}>
		// 				<Icon className="mr-1" icon={faPhone} />
		// 			</a>
		// 			<button
		// 				className="cursor-pointer"
		// 				onClick={() => handleCopyClick(row.prospect?.organization?.phone ?? "")}
		// 			>
		// 				{row.prospect?.organization?.phone ?? ""}
		// 			</button>
		// 		</div>
		// 	),
		// },
		// renderEstimatedNumEmployeesColumn(ts, handleCopyClick, sortingInbox),
		// renderFoundedYearColumn(ts, handleCopyClick, sortingInbox),
		// renderPersonCityColumn(ts, handleCopyClick, sortingInbox),
		// renderPersonStateColumn(ts, handleCopyClick, sortingInbox),
		// renderPersonCountryColumn(ts, handleCopyClick, sortingInbox),
		// renderCompanyCityColumn(ts, handleCopyClick, sortingInbox),
		// renderCompanyStateColumn(ts, handleCopyClick, sortingInbox),
		// renderCompanyCountryColumn(ts, handleCopyClick, sortingInbox),
		// renderLanguageColumn(ts, sortingInbox),
		// renderFollowers(ts, sortingInbox),
		// renderOtherListsColumn(ts, sortingInbox),
	];
};
