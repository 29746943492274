import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { AssignStatus } from "@/enum/list.enum";

import { RepliesTable } from "../../components/RepliesTable";

import {
	CustomListViewListAutocomplete,
	CustomListViewListItem,
	DefaultValues,
} from "../../components/CustomListAutocomplete";

export const ToBeNurtured: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`inbox.${key}`);

	const navigate = useNavigate();

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<div className="flex items-end justify-between w-full mb-7">
				<div className="flex-grow inline-flex items-center justify-between">
					<div className="flex-grow inline-flex items-center">
						<CustomListViewListAutocomplete
							data={DefaultValues}
							floating={true}
							handleSelect={(value?: Partial<CustomListViewListItem>) => {
								if (value && value.path !== undefined) {
									navigate(value.path);
									console.log("selected");
								} else {
									console.log("No value selected");
								}
							}}
							label={ts(AssignStatus.TO_BE_NURTURED)}
							selectedItem={AssignStatus.TO_BE_NURTURED}
						/>
					</div>
				</div>
			</div>
			<RepliesTable assignStatus={AssignStatus.TO_BE_NURTURED} />
		</div>
	);
};
