import { FunctionComponent } from "react";

import Linkedin from "@assets/icons/linkedinRounded.svg";
import Checked from "@assets/icons/checked.svg";
import Failed from "@assets/icons/failed.svg";
import Refresh from "@assets/icons/refresh.svg";

export interface LinkedinIconProps {
	status: "checked" | "failed" | "refresh";
}

export const LinkedinIcon: FunctionComponent<LinkedinIconProps> = ({ status }) => {
	return (
		<div className="relative mr-1 w-[20px]">
			{status === "checked" && <img className="absolute right-[-1px] top-[-1px]" src={Checked} />}
			{status === "failed" && <img className="absolute right-[-1px] top-[-1px]" src={Failed} />}
			{status === "refresh" && <img className="absolute right-[-1px] top-[-1px]" src={Refresh} />}
			<img alt="linkedin" src={Linkedin} />
		</div>
	);
};
