import { z } from "zod";

export const PreviewMapResponseItemSchema = z.object({
	business_id: z.string().nullable(),
	google_id: z.string().nullable(),
	place_id: z.string().nullable(),
	google_mid: z.string().nullable(),
	phone_number: z.string().nullable(),
	name: z.string().nullable(),
	latitude: z.number().nullable(),
	longitude: z.number().nullable(),
	full_address: z.string().nullable(),
	review_count: z.number().nullable(),
	rating: z.number().nullable(),
	timezone: z.string().nullable(),
	opening_status: z.string().nullable(),
	working_hours: z.record(z.string(), z.array(z.string())).nullable(),
	website: z.string().nullable(),
	verified: z.boolean().nullable(),
	place_link: z.string().nullable(),
	cid: z.string().nullable(),
	reviews_link: z.string().nullable(),
	owner_id: z.string().nullable(),
	owner_link: z.string().nullable(),
	owner_name: z.string().nullable(),
	booking_link: z.string().nullable(),
	reservations_link: z.string().nullable(),
	business_status: z.string().nullable(),
	type: z.string().nullable(),
	subtypes: z.array(z.string()).nullable(),
	photos_sample: z
		.array(
			z.object({
				photo_id: z.string().nullable(),
				photo_url: z.string().nullable(),
				photo_url_large: z.string().nullable(),
				video_thumbnail_url: z.string().nullable(),
				latitude: z.number().nullable(),
				longitude: z.number().nullable(),
				type: z.string().nullable(),
				photo_datetime_utc: z.string().nullable(),
				photo_timestamp: z.number().nullable(),
			})
		)
		.nullable(),
	reviews_per_rating: z.number().nullable(),
	photo_count: z.number().nullable(),
	about: z
		.object({
			summary: z.string().nullable(),
			details: z.record(z.string(), z.record(z.string(), z.boolean())).nullable(),
		})
		.nullable(),
	address: z.string().nullable(),
	order_link: z.string().nullable(),
	price_level: z.string().nullable(),
	district: z.string().nullable(),
	street_address: z.string().nullable(),
	city: z.string().nullable(),
	zipcode: z.string().nullable(),
	state: z.string().nullable(),
	country: z.string().nullable(),
});

export const PreviewMapResponseSchema = z.object({
	data: z.array(PreviewMapResponseItemSchema),
	status: z.string(),
	request_id: z.string(),
	parameters: z.object({
		query: z.string(),
		language: z.string(),
		region: z.string(),
		limit: z.number(),
		offset: z.number(),
		lat: z.number(),
		lng: z.number(),
		extract_emails_and_contacts: z.boolean(),
	}),
});

export type PreviewMapResponse = z.infer<typeof PreviewMapResponseSchema>;

export type PreviewMapResponseItem = z.infer<typeof PreviewMapResponseItemSchema>;
