import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icon, Modal } from "@/components";
import { classNames } from "@/utils/classNames";

interface StepperProps {
	listId: number;
	doneSteps: string[];
	availableSteps: string[];
	currentStep: string;
	isDirty?: boolean;
}

export const Stepper: FunctionComponent<StepperProps> = ({
	listId,
	doneSteps,
	availableSteps,
	currentStep,
	isDirty,
}) => {
	const navigate = useNavigate();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [step, setStep] = useState<string>("");

	const renderStep = (step: string, isDone: boolean, isSelected: boolean) => {
		return (
			<div
				className="items-center flex flex-col cursor-pointer"
				onClick={() => {
					if (isDirty) {
						setStep(step);
						setIsModalOpen(true);
					} else {
						navigate(`/app/lists/create/${step}/${listId}`);
					}
				}}
			>
				<div
					className={`h-[24px] w-[24px] ${
						isDone || isSelected ? "bg-brand-light" : "bg-white"
					} border border-gray-200 rounded-full flex justify-center items-center`}
				>
					<Icon className={classNames("text-white w-[16px] h-[16px]")} icon={faCheck} />
				</div>
				<div
					className={classNames(
						"text-ssm leading-7",
						isSelected ? "text-text underline" : "text-placeholder",
						isDone ? "text-text" : "text-placeholder"
					)}
				>
					{step}
				</div>
			</div>
		);
	};

	return (
		<div className="flex flex-row items-start justify-between w-full mb-7">
			{availableSteps.map((step, index) => (
				<>
					{!!index && <hr className="grow border-stepper-gap border-dashed mb-5 mt-3 mr-4 ml-4" />}
					{renderStep(step, doneSteps.includes(step), currentStep === step)}
				</>
			))}
			{isDirty && (
				<Modal
					handleClose={() => setIsModalOpen(false)}
					handleSave={() => navigate(`/app/lists/create/${step}/${listId}`)}
					isOpened={isModalOpen}
					submitButtonText="Leave"
					title="Unsaved Changes"
				>
					<div>Do you want to leave this page?</div>
				</Modal>
			)}
		</div>
	);
};
