import { FunctionComponent } from "react";

import AppLogo from "@/assets/images/favicon.png";

export const Logo: FunctionComponent<{ handleClick: () => void }> = ({ handleClick }) => {
	return (
		<div className="px-8 pt-3 block relative top-0 left-0">
			<img
				alt="logo"
				className=" m-2 mb-[20px] w-[24px] h-[24px] 13i:max-w-[210px] cursor-pointer z-40"
				src={`${AppLogo}`}
				onClick={handleClick}
			/>
		</div>
	);
};
