import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { CronTable } from "../../components/CronTable";

export const CronOverview: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`cron.${key}`);

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<h3 className="mb-3">{ts("title")}</h3>
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">{ts("subtitle")}</p>
				</div>
			</div>

			{<CronTable />}
		</div>
	);
};
