import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Box, Stack } from "@mui/system";

import { Modal } from "@/components";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { useUpdateCompaniesMutation } from "@/pages/Private/redux/companies/companies.api";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { YearlyOrMonthly } from "@/enum/company.enum";
import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";

import { InputField } from "@/components/InputField/InputField";

import { Companies, UpdateCompanies, UpdateCompaniesSchema } from "../schema/companies";

interface updateCompaniesModalProps {
	companies: Companies;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const UpdateCompaniesModal: FunctionComponent<updateCompaniesModalProps> = ({
	companies,
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`companies.${key}`);
	const [update, { isLoading, error }] = useUpdateCompaniesMutation();

	const [currentFormState, setCurrentFormState] = useState<UpdateCompanies>({
		name: companies.name,
		linkedinUrl: companies.linkedinUrl || "",
		autoInvite: companies.autoInvite || false,
		filtered: companies.filtered || 0,
		yearlyOrMonthly: companies.yearlyOrMonthly,
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof UpdateCompanies) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const {
		setValue,
		getValues,
		handleSubmit,
		formState: { errors },
	} = useForm<UpdateCompanies>({
		defaultValues: {
			name: companies.name,
			linkedinUrl: companies.linkedinUrl || "",
			autoInvite: companies.autoInvite || false,
			filtered: companies.filtered || 0,
			yearlyOrMonthly: companies.yearlyOrMonthly,
		},
		resolver: zodResolver(UpdateCompaniesSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: UpdateCompanies) => {
		try {
			await update({ ...values, id: companies.id }).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("update.title")}
		>
			<Stack spacing={2}>
				<InputField
					error={!!errors.name?.message}
					handleChange={(e) => handleSaveFormState(e.target.value, "name")}
					label={ts("name")}
					name={"name"}
					placeholder={ts("name")}
					value={currentFormState.name}
				/>

				<InputField
					error={!!errors.linkedinUrl?.message}
					handleChange={(e) => {
						handleSaveFormState(e.target.value, "linkedinUrl");
					}}
					label={ts("linkedinUrl")}
					name={"linkedinUrl"}
					placeholder={ts("linkedinUrl")}
					value={currentFormState.linkedinUrl || ""}
				/>

				<InputField
					error={!!errors.filtered?.message}
					handleChange={(e) => handleSaveFormState(+e.target.value, "filtered")}
					label={ts("credits")}
					name={"credits"}
					placeholder={ts("credits")}
					type="number"
					value={currentFormState.filtered || ""}
				/>

				<Dropdown
					data={[
						{
							title: "Monthly",
							id: YearlyOrMonthly.MONTHLY,
						},
						{
							title: "Yearly",
							id: YearlyOrMonthly.YEARLY,
						},
					]}
					floating={true}
					handleSelect={function (value?: AutoCompleteItem) {
						if (value?.id && value?.id !== currentFormState.yearlyOrMonthly) {
							handleSaveFormState(value?.id, "yearlyOrMonthly");
						}
					}}
					label="Type"
					value={
						currentFormState.yearlyOrMonthly
							? {
									title:
										currentFormState.yearlyOrMonthly === YearlyOrMonthly.MONTHLY
											? "Monthly"
											: "Yearly",
									id: currentFormState.yearlyOrMonthly,
							  }
							: undefined
					}
				/>

				<Checkbox
					className="mb-8"
					isChecked={getValues("autoInvite")}
					onChange={() =>
						setValue("autoInvite", !getValues("autoInvite"), {
							shouldDirty: true,
							shouldValidate: true,
						})
					}
				>
					{ts("autoInvite")}
				</Checkbox>
			</Stack>
			{getAllErrors(error, formErrors).length ? (
				<Box sx={{ mt: 2 }}>{renderErrorMessages(getAllErrors(error, formErrors))}</Box>
			) : null}
		</Modal>
	);
};
