import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { PromptRole } from "@/enum/ai-enrichment.enum";

const { REQUIRED } = ERROR_TYPE;

export const OpenAiPromptSchema = z.object({
	id: z.number().optional(),
	prompt: z.string().min(4, { message: REQUIRED }).trim(),
	order: z.number(),
	promptRole: z.nativeEnum(PromptRole),
	aiEnrichmentId: z.number(),
});

export const OpenAiPromptsResponseSchema = z.object({
	data: z.array(OpenAiPromptSchema),
	meta: MetaDataSchema,
});

export type OpenAiPrompt = z.infer<typeof OpenAiPromptSchema>;

export type OpenAiPromptsResponse = z.infer<typeof OpenAiPromptsResponseSchema>;
