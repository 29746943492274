import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	CreateJob,
	Job,
	JobResponse,
	JobResponseSchema,
	JobSchema,
	ToggleJob,
	ToggleJobSchema,
} from "../../pages/Job/schema/job";

export const jobApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getJobs: builder.query<JobResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "job",
				params: {
					...(params && { ...params }),
				},
				responseSchema: JobResponseSchema,
			}),
			providesTags: ["Job"],
		}),
		getJob: builder.query<Job, number>({
			query: (id: number) => ({
				url: `job/${id}`,
				responseSchema: JobSchema,
			}),
			providesTags: ["Job"],
		}),
		createJob: builder.mutation<Partial<Job>, CreateJob>({
			query: (body) => ({
				url: `job`,
				method: "POST",
				body,
				responseSchema: JobSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "Job"];
				}

				return ["Job"];
			},
		}),
		updateJob: builder.mutation<Partial<Job>, Partial<Job> & { listId?: number }>({
			query: ({ id, ...body }) => ({
				url: `job/${id}`,
				method: "PATCH",
				body,
				responseSchema: JobSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "Job"];
				}

				return ["Job"];
			},
		}),
		toggleJob: builder.mutation<Partial<Job>, ToggleJob>({
			query: ({ id, ...body }) => ({
				url: `job/${id}`,
				method: "PATCH",
				body,
				responseSchema: ToggleJobSchema,
			}),
		}),
		deleteJob: builder.mutation<Partial<Job>, { id: number; listId?: number }>({
			query: ({ id, listId }) => ({
				url: listId ? `job/${id}/list/${listId}` : `job/${id}`,
				method: "DELETE",
				responseSchema: JobSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "Job"];
				}

				return ["Job"];
			},
		}),
	}),
});

export const {
	useGetJobsQuery,
	useLazyGetJobsQuery,
	useGetJobQuery,
	useUpdateJobMutation,
	useDeleteJobMutation,
	useCreateJobMutation,
	useToggleJobMutation,
} = jobApi;
export const accountApiReducer = jobApi.reducer;
export const accountApiMiddleware = jobApi.middleware;
