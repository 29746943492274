import { ChangeEvent, FunctionComponent, useCallback } from "react";
import { Stack } from "@mui/material";

import { useTranslation } from "react-i18next";

import { faUserMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";

import { Icon, LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { InputField } from "@/components/InputField/InputField";
import { useGetListLeadFilterQuery } from "@/pages/Private/redux/list/list.api";

import { ListSourceType } from "@/enum/list.enum";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { useNonStickySearch } from "@/utils/useNonStickySearch";

import { ListSource } from "../schema/list";

export interface LeadFilterListProps {
	selectedLeadFilters?:
		| {
				id: number;
				sourceType: ListSourceType;
		  }[]
		| undefined;
	setSelectedLeadFilter: (source: { id: number; sourceType: ListSourceType }[]) => void;
}

export const LeadFilterList: FunctionComponent<LeadFilterListProps> = ({
	selectedLeadFilters,
	setSelectedLeadFilter,
}) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`list.lead.${key}`), [t]);
	const { page, setPage, limit, setLimit, searchValue, query, setSearchValue } =
		useNonStickySearch();

	const { data, isLoading } = useGetListLeadFilterQuery({
		page,
		limit,
		searchValue: query,
	});

	const columns: TableColumn<ListSource>[] = [
		{
			label: "",
			width: 20,
			format: (listSource: ListSource) => (
				<Checkbox
					isChecked={!!selectedLeadFilters?.find((lf) => lf.id === listSource.id)}
					name={`${listSource.id}-${listSource.sourceType}`}
					onChange={() => {
						const isSelected = !!selectedLeadFilters?.find((lf) => lf.id === listSource.id);

						if (isSelected) {
							setSelectedLeadFilter(
								(selectedLeadFilters || []).filter((lf) => lf.id !== listSource.id)
							);
						} else {
							setSelectedLeadFilter([
								...(selectedLeadFilters || []),
								{ id: listSource.id, sourceType: listSource.sourceType },
							]);
						}
					}}
				/>
			),
		},
		{
			label: ts("name"),
			format: (row) => (
				<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
					<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
						<div className="text-sm flex items-center">
							{row.sourceType === ListSourceType.PROSPECT_SEARCH && (
								<Icon className="w-[24px] h-[24px]" icon={faUserMagnifyingGlass} />
							)}

							{row?.name}
						</div>
					</Stack>
				</Stack>
			),
		},
		{
			label: ts("type"),
			format: (row) => (
				<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
					<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
						<div className="text-sm flex items-center">
							{t(`prospectSearch.${row?.type as string}`)}
						</div>
					</Stack>
				</Stack>
			),
		},
	];

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	return !data || isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full mt-8">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">{ts("subtitle")}</p>
				</div>
			</div>
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					allowNavigation={false}
					columns={columns}
					data={data.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
		</div>
	);
};
