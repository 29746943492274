import { createSlice } from "@reduxjs/toolkit";

import { CustomEventState } from "@/types/customEvent";

import { RootState } from "@/redux";

import { customEventApi } from "./customEvent.api";

const initialState: CustomEventState = {
	customEvents: [],
};

export const customEventSlice = createSlice({
	name: "customEvent",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			customEventApi.endpoints.getCustomEvents.matchFulfilled,
			(state, { payload }) => {
				state.customEvents = payload.data;
			}
		);
	},
});

export const {} = customEventSlice.actions;
export const customEventReducer = customEventSlice.reducer;
export const customEventSelector = (state: RootState) => state[customEventSlice.name];
