/* eslint-disable @typescript-eslint/no-explicit-any */
import { flatten } from "flat";

import { Order, SortParams } from "@/types/types";

import { ListPerson } from "../schema/list";

export const replaceWithRealValues = (
	value: any | undefined,
	inboxItem: ListPerson,
	suggestions: string[]
) => {
	if (!value) {
		return "";
	}

	const textValue = value.flatMap(
		(el: any) =>
			el.children?.map((n: any) => {
				const text = n.text || (n.character !== undefined ? `{${n.character}}` : "");

				return text;
			}) || []
	);

	let result = "";
	let isListItem = false;

	for (const text of textValue) {
		if (text === "") {
			if (result) {
				result += "\n";
			}
		} else if (text.startsWith("- ") || text.startsWith("* ")) {
			if (result) {
				result += "\n";
			}

			result += text;
			isListItem = true;
		} else if (text.startsWith("{")) {
			if (result) {
				result += text;
			}
		} else {
			if (result && !result.endsWith("}") && !isListItem) {
				result += "\n";
			}

			result += text;
		}
	}

	let resultTextValue = result.trim();

	for (const suggestion of suggestions) {
		const placeholder = `{${suggestion}}`;
		const objectPath = suggestion.split("_");
		let realValue: any = inboxItem;

		for (const path of objectPath) {
			if (realValue) {
				realValue = Array.isArray(realValue[path]) ? realValue[path][0] : realValue[path];
			}
		}

		if (resultTextValue.includes(placeholder)) {
			resultTextValue = resultTextValue.replaceAll(placeholder, String(realValue));
		}
	}

	return resultTextValue;
};

export const getSuggestions = (inboxItem: ListPerson) => {
	const mySuggestions: any = {
		prospect: inboxItem.prospect,
	};

	if (inboxItem.liProfileEntryResult) {
		mySuggestions.liProfileEntryResult = {
			content: inboxItem.liProfileEntryResult?.content,
			contentUrl: inboxItem.liProfileEntryResult?.contentUrl,
			contentResponse: inboxItem.liProfileEntryResult?.contentResponse,
			contentResponseUrl: inboxItem.liProfileEntryResult?.contentResponseUrl,
			contentDate: inboxItem.liProfileEntryResult?.contentDate,
		};
	}

	if (inboxItem.liPostResult) {
		mySuggestions.liPostResult = {
			content: inboxItem.liPostResult?.content,
			contentUrl: inboxItem.liPostResult?.contentUrl,
			contentDate: inboxItem.liPostResult?.contentDate,
			likes: inboxItem.liPostResult?.likes,
			comments: inboxItem.liPostResult?.comments,
			shares: inboxItem.liPostResult?.shares,
		};
	}

	if (inboxItem.liEventEntry) {
		mySuggestions.liEventEntry = inboxItem.liEventEntry;
	}

	if (inboxItem.liCompanyActivityEntryResult) {
		mySuggestions.liCompanyActivityEntryResult = {
			content: inboxItem.liCompanyActivityEntryResult?.content,
			contentUrl: inboxItem.liCompanyActivityEntryResult?.contentUrl,
			contentResponse: inboxItem.liCompanyActivityEntryResult?.contentResponse,
			contentResponseUrl: inboxItem.liCompanyActivityEntryResult?.contentResponseUrl,
			contentDate: inboxItem.liCompanyActivityEntryResult?.contentDate,
		};
	}

	if (inboxItem.offer) {
		mySuggestions.offer = inboxItem.offer;
	}

	if (inboxItem.mapBusiness) {
		mySuggestions.mapBusiness = inboxItem.mapBusiness;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const res: any = flatten(mySuggestions, {
		delimiter: "_",
	});

	return Object.keys(res).filter((key) => !Array.isArray(res[key]));
};

export const getOrderStatus = (sortingInbox: SortParams, orderTitle: string) => {
	const hasCurrentSorting = sortingInbox.sortBy === orderTitle;

	let orderStatus = 0;

	if (hasCurrentSorting && sortingInbox.sortOrder === Order.ASC) {
		orderStatus = 1;
	}

	if (hasCurrentSorting && sortingInbox.sortOrder === Order.DESC) {
		orderStatus = 2;
	}

	return orderStatus;
};
