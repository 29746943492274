import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

export const ScoreTypeFilterSchema = z.object({
	id: z.number(),
	scoreId: z.number(),
	scoreTypeId: z.number(),
	value: z.array(z.string()).optional().nullable(),
	valueNumeric: z.number().optional().nullable(),
	valueNumericTill: z.number().optional().nullable(),
	valueFilter: z.array(z.object({ id: z.number(), name: z.string() })).nullable(),
	scoreValue: z.number().optional().nullable(),
});

export const CreateScoreTypeFilterSchema = z.object({
	id: z.number(),
	scoreId: z.number(),
	scoreTypeId: z.number(),
	value: z.string().optional(),
	valueNumeric: z.number().optional(),
	valueNumericTill: z.number().optional(),
	valueFilter: z.array(z.object({ id: z.number(), name: z.string() })).nullable(),
	scoreValue: z.number().optional(),
});

export const ScoreTypeFilterResponseSchema = z.object({
	data: z.array(ScoreTypeFilterSchema),
	meta: MetaDataSchema,
});

export type ScoreTypeFilter = z.infer<typeof ScoreTypeFilterSchema>;

export type CreateScoreTypeFilter = z.infer<typeof CreateScoreTypeFilterSchema>;

export type ScoreTypeFilterResponse = z.infer<typeof ScoreTypeFilterResponseSchema>;
