import { z } from "zod";

export const CompanySchema = z.object({
	id: z.number().gt(0),
	name: z.string(),
	linkedinUrl: z.string().nullable(),
	autoInvite: z.boolean(),
	apolloApiKey: z.string().nullable(),
	lemlistApiKey: z.string().nullable(),
	hasApolloApiKey: z.boolean(),
	hasLemlistApiKey: z.boolean(),
});

export const UpdateCompanySchema = z.object({
	name: z.string(),
	linkedinUrl: z.string().optional(),
	autoInvite: z.boolean(),
});

export const SaveApiKeysCompanySchema = z.object({
	apolloApiKey: z.string().optional(),
	lemlistApiKey: z.string().optional(),
});

export type CompanyType = z.infer<typeof CompanySchema>;
export type UpdateCompany = z.infer<typeof UpdateCompanySchema>;
export type SaveApiKeysCompany = z.infer<typeof SaveApiKeysCompanySchema>;
