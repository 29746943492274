import {
	ChangeEvent,
	Dispatch,
	FunctionComponent,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import { Slider } from "@material-tailwind/react";

import { faTrash } from "@fortawesome/pro-regular-svg-icons";

import { NumericScoreTypes, ScoreTypes } from "@/enum/score.enum";
import { SelectField } from "@/components/SelectField/SelectField";

import { InputField } from "@/components/InputField/InputField";

import { Button } from "@/components/Button/Button";
import { classNames } from "@/utils/classNames";

import { ButtonColor, ButtonSize } from "@/components/Button/types";
import { Icon, LoadingOverlay } from "@/components";

import { useUpdateScoreMutation } from "@/pages/Private/redux/score/score.api";

import { BackLink } from "@/components/BackLink/BackLink";

import { ApolloTagAutocomplete } from "../../Apollo/components/ApolloTagAutocomplete/ApolloTagAutocomplete";
import { Score } from "../schema/score.schema";
import { ProspectSearchAutocomplete } from "./ProspectSearchAutocomplete";
import { TagAutocomplete } from "../../Apollo/components/TagAutocomplete/TagAutocomplete";

export interface ScoreFilterSource {
	id: number;
	name: string;
}

export interface ScoreFilter {
	value?: string[];
	valueFilter?: ScoreFilterSource[];
	valueNumeric?: number | null;
	valueNumericTill?: number | null;
	scoreValue?: number | null;
}

export interface ScoreFilters {
	[key: string]: ScoreFilter[];
}

export interface ScoreProps {
	data: Score;
}

export const EditScoreDetail: FunctionComponent<ScoreProps> = ({ data }) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`score.${key}`);
	const [types, setTypes] = useState<ScoreTypes[]>([]);
	const [filters, setFilters] = useState<ScoreFilters>({});
	const [showInput, setShowInput] = useState<boolean>(false);
	const [name, setName] = useState<Partial<Score>>({
		name: data?.name,
		description: data?.description,
	});

	const [updateScore, { isLoading }] = useUpdateScoreMutation();

	useEffect(() => {
		if (data && data.scoreTypes) {
			const types = [] as ScoreTypes[];

			for (const type of data.scoreTypes) {
				types.push(type.type as ScoreTypes);
			}

			setTypes([...types]);

			const newFilters = {} as ScoreFilters;

			for (const type of data.scoreTypes) {
				for (const filter of type.scoreTypeFilters) {
					if (!newFilters[type.type as ScoreTypes]) {
						newFilters[type.type as ScoreTypes] = [];
					}

					newFilters[type.type as ScoreTypes].push({
						value: filter.value || [],
						valueNumeric: filter.valueNumeric,
						valueNumericTill: filter.valueNumericTill,
						scoreValue: filter.scoreValue,
						valueFilter: filter.valueFilter || [],
					});
				}
			}

			setFilters({ ...newFilters });
		}
	}, [data]);

	const saveScore = async () => {
		await updateScore({
			id: data.id,
			name: name.name,
			description: name.description,
			filters: filters,
		}).unwrap();

		setShowInput(false);
	};

	const KeywordFilterScoreTypeElement = (
		index: number,
		filters: ScoreFilters,
		type: ScoreTypes,
		setFilters: Dispatch<SetStateAction<ScoreFilters>>
	): JSX.Element => {
		const addNewFilter = () => {
			filters[type].push({
				value: [],
			});

			setFilters({
				...filters,
				[type]: filters[type],
			});
		};

		const removeCurrentFilter = () => {
			if (filters[type].length === 1) {
				delete filters[type];
				setTypes(types.filter((t) => t !== type));
			} else {
				filters[type].splice(index, 1);

				setFilters({
					...filters,
					[type]: filters[type],
				});
			}
		};

		return (
			<div
				key={`title-${index}`}
				className={classNames(
					"flex flex-col mb-4",
					index + 1 !== filters[type].length ? "border-b border-gray-200" : ""
				)}
			>
				{isLoading && <LoadingOverlay />}
				<div className="mb-2 flex">
					<div className="w-[450px] flex justify-between	">
						<TagAutocomplete
							handleValueChange={(value) => {
								filters[type][index] = {
									...filters[type][index],
									value: value,
								};

								if (filters[type][index].scoreValue === undefined) {
									filters[type][index].scoreValue = 0;
								}

								setFilters({
									...filters,
									[type]: filters[type],
								});
							}}
							kind="q_organization_keyword_tags"
							type="q_organization_keyword_tags"
							value={filters[type][index].value || []}
						/>
					</div>
				</div>
				<div className="w-[250px] mb-4 flex flex-row space-x-4 items-center">
					<Slider
						className="rounded h-[20px] !bg-gray-300/10"
						color="gray"
						defaultValue={filters[type][index].scoreValue || 0}
						max={20}
						min={-20}
						size="sm"
						step={1}
						trackClassName="[&::-webkit-slider-runnable-track]:bg-transparent [&::-moz-range-track]:bg-transparent rounded-none !bg-gray-300/10 border border-gray-300"
						onChange={(value) => {
							filters[type][index] = {
								...filters[type][index],
								scoreValue: value.target.value as unknown as number,
							};

							setFilters({
								...filters,
								[type]: filters[type],
							});
						}}
					/>

					<InputField
						className="w-16 m-4"
						handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
							console.log(event.target.value);
						}}
						isDisabled={true}
						name={"score"}
						placeholder="score"
						value={filters[type][index].scoreValue || 0}
					/>
				</div>
				<div className="flex items-center">
					{index + 1 === filters[type].length ? (
						<>
							{" "}
							<div className="w-[100px] mr-2">
								<Button
									className="w-32 mr-4"
									color={ButtonColor.ACTION_SECONDARY}
									size={ButtonSize.XS}
									title="Add new filter"
									onClick={addNewFilter}
								/>
							</div>
							<Icon
								className="cursor-pointer"
								color="#4D66F5"
								icon={faTrash}
								onClick={removeCurrentFilter}
							/>
						</>
					) : (
						<>
							<Icon
								className="cursor-pointer mb-4 ml-0"
								color="#4D66F5"
								icon={faTrash}
								onClick={removeCurrentFilter}
							/>
						</>
					)}
				</div>
			</div>
		);
	};

	const LeadFilterScoreTypeElement = (
		index: number,
		filters: ScoreFilters,
		type: ScoreTypes,
		setFilters: Dispatch<SetStateAction<ScoreFilters>>
	): JSX.Element => {
		const addNewFilter = () => {
			filters[type].push({
				value: [],
			});

			setFilters({
				...filters,
				[type]: filters[type],
			});
		};

		const removeCurrentFilter = () => {
			if (filters[type].length === 1) {
				delete filters[type];
				setTypes(types.filter((t) => t !== type));
			} else {
				filters[type].splice(index, 1);

				setFilters({
					...filters,
					[type]: filters[type],
				});
			}
		};

		return (
			<div
				key={`title-${index}`}
				className={classNames(
					"flex flex-col mb-4",
					index + 1 !== filters[type].length ? "border-b border-gray-200" : ""
				)}
			>
				{isLoading && <LoadingOverlay />}
				<div className="mb-2 flex flex-col">
					<div className="w-[450px] flex justify-between	">
						<ProspectSearchAutocomplete
							handleValueChange={function (value: ScoreFilterSource[]): void {
								filters[type][index] = {
									...filters[type][index],
									valueFilter: value,
								};

								setFilters({
									...filters,
									[type]: filters[type],
								});
							}}
							value={filters[type][index].valueFilter}
						/>
					</div>
					<div className="w-[250px] flex justify-between	">
						<InputField
							className="w-28"
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								filters[type][index] = {
									...filters[type][index],
									valueNumeric: parseInt(event.target.value),
								};

								setFilters({
									...filters,
									[type]: filters[type],
								});
							}}
							name={"valueNumeric"}
							placeholder="From Value"
							value={filters[type][index].valueNumeric || 0}
						/>

						<InputField
							className="w-28"
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								filters[type][index] = {
									...filters[type][index],
									valueNumericTill: parseInt(event.target.value),
								};

								if (filters[type][index].scoreValue === undefined) {
									filters[type][index].scoreValue = 0;
								}

								setFilters({
									...filters,
									[type]: filters[type],
								});
							}}
							name={"valueNumericTill"}
							placeholder="Till Value"
							value={filters[type][index].valueNumericTill || 0}
						/>
					</div>
				</div>
				<div className="w-[250px] mb-4 flex flex-row space-x-4 items-center">
					<Slider
						className="rounded h-[20px] !bg-gray-300/10"
						color="gray"
						defaultValue={filters[type][index].scoreValue || 0}
						max={20}
						min={-20}
						size="sm"
						step={1}
						trackClassName="[&::-webkit-slider-runnable-track]:bg-transparent [&::-moz-range-track]:bg-transparent rounded-none !bg-gray-300/10 border border-gray-300"
						onChange={(value) => {
							filters[type][index] = {
								...filters[type][index],
								scoreValue: value.target.value as unknown as number,
							};

							setFilters({
								...filters,
								[type]: filters[type],
							});
						}}
					/>

					<InputField
						className="w-16 m-4"
						handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
							console.log(event.target.value);
						}}
						isDisabled={true}
						name={"score"}
						placeholder="score"
						value={filters[type][index].scoreValue || 0}
					/>
				</div>
				<div className="flex items-center">
					{index + 1 === filters[type].length ? (
						<>
							{" "}
							<div className="w-[100px] mr-2">
								<Button
									className="w-[150px] mr-4"
									color={ButtonColor.ACTION_SECONDARY}
									size={ButtonSize.XS}
									title="Add new filter"
									onClick={addNewFilter}
								/>
							</div>
							<Icon
								className="cursor-pointer"
								color="#4D66F5"
								icon={faTrash}
								onClick={removeCurrentFilter}
							/>
						</>
					) : (
						<>
							<Icon
								className="cursor-pointer mb-4 ml-0"
								color="#4D66F5"
								icon={faTrash}
								onClick={removeCurrentFilter}
							/>
						</>
					)}
				</div>
			</div>
		);
	};

	const NumericScoreTypeElement = (
		index: number,
		filters: ScoreFilters,
		type: ScoreTypes,
		setFilters: Dispatch<SetStateAction<ScoreFilters>>
	): JSX.Element => {
		const addNewFilter = () => {
			if (NumericScoreTypes.includes(type)) {
				filters[type].push({
					valueNumeric: 0,
					valueNumericTill: 0,
				});
			} else {
				filters[type].push({
					value: [],
				});
			}

			setFilters({
				...filters,
				[type]: filters[type],
			});
		};

		const removeCurrentFilter = () => {
			if (filters[type].length === 1) {
				delete filters[type];
				setTypes(types.filter((t) => t !== type));
			} else {
				filters[type].splice(index, 1);

				setFilters({
					...filters,
					[type]: filters[type],
				});
			}
		};

		return (
			<div
				key={`title-${index}`}
				className={classNames(
					"flex flex-col mb-4",
					index + 1 !== filters[type].length ? "border-b border-gray-200" : ""
				)}
			>
				{isLoading && <LoadingOverlay />}
				<div className="mb-2 flex">
					<div className="w-[250px] flex justify-between	">
						<InputField
							className="w-28"
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								filters[type][index] = {
									...filters[type][index],
									valueNumeric: parseInt(event.target.value),
								};

								setFilters({
									...filters,
									[type]: filters[type],
								});
							}}
							name={"valueNumeric"}
							placeholder="From Value"
							value={filters[type][index].valueNumeric || 0}
						/>

						<InputField
							className="w-28"
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								filters[type][index] = {
									...filters[type][index],
									valueNumericTill: parseInt(event.target.value),
								};

								if (filters[type][index].scoreValue === undefined) {
									filters[type][index].scoreValue = 0;
								}

								setFilters({
									...filters,
									[type]: filters[type],
								});
							}}
							name={"valueNumericTill"}
							placeholder="Till Value"
							value={filters[type][index].valueNumericTill || 0}
						/>
					</div>
				</div>
				<div className="w-[250px] mb-4 flex flex-row space-x-4 items-center">
					<Slider
						className="rounded h-[20px] !bg-gray-300/10"
						color="gray"
						defaultValue={filters[type][index].scoreValue || 0}
						max={20}
						min={-20}
						size="sm"
						step={1}
						trackClassName="[&::-webkit-slider-runnable-track]:bg-transparent [&::-moz-range-track]:bg-transparent rounded-none !bg-gray-300/10 border border-gray-300"
						onChange={(value) => {
							filters[type][index] = {
								...filters[type][index],
								scoreValue: value.target.value as unknown as number,
							};

							setFilters({
								...filters,
								[type]: filters[type],
							});
						}}
					/>

					<InputField
						className="w-16 m-4"
						handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
							console.log(event.target.value);
						}}
						isDisabled={true}
						name={"score"}
						placeholder="score"
						value={filters[type][index].scoreValue || 0}
					/>
				</div>
				<div className="flex items-center">
					{index + 1 === filters[type].length ? (
						<>
							{" "}
							<div className="w-[100px] mr-2">
								<Button
									className="w-[150px] mr-4"
									color={ButtonColor.ACTION_SECONDARY}
									size={ButtonSize.XS}
									title="Add new filter"
									onClick={addNewFilter}
								/>
							</div>
							<Icon
								className="cursor-pointer"
								color="#4D66F5"
								icon={faTrash}
								onClick={removeCurrentFilter}
							/>
						</>
					) : (
						<>
							<Icon
								className="cursor-pointer mb-4 ml-0"
								color="#4D66F5"
								icon={faTrash}
								onClick={removeCurrentFilter}
							/>
						</>
					)}
				</div>
			</div>
		);
	};

	const ScoreTypeElement = (
		index: number,
		filters: ScoreFilters,
		type: ScoreTypes,
		setFilters: Dispatch<SetStateAction<ScoreFilters>>,
		filter: ScoreFilter,
		kind: string,
		apolloType: string
	): JSX.Element => {
		const addNewFilter = () => {
			if (NumericScoreTypes.includes(type)) {
				filters[type].push({
					valueNumeric: 0,
					valueNumericTill: 0,
				});
			} else {
				filters[type].push({
					value: [],
				});
			}

			setFilters({
				...filters,
				[type]: filters[type],
			});
		};

		const removeCurrentFilter = () => {
			if (filters[type].length === 1) {
				delete filters[type];
				setTypes(types.filter((t) => t !== type));
			} else {
				filters[type].splice(index, 1);

				setFilters({
					...filters,
					[type]: filters[type],
				});
			}
		};

		return (
			<div
				key={`title-${index}`}
				className={classNames(
					"flex flex-col mb-4",
					index + 1 !== filters[type].length ? "border-b border-gray-200" : ""
				)}
			>
				<div className="mb-2">
					<ApolloTagAutocomplete
						handleValueChange={(value) => {
							if (value.length > 0) {
								filters[type][index] = {
									value: [...value.map((v) => v.name)],
								};

								if (filters[type][index].scoreValue === undefined) {
									filters[type][index].scoreValue = 0;
								}

								setFilters({
									...filters,
									[type]: filters[type],
								});
							} else {
								filters[type][index] = {
									value: [],
								};

								if (filters[type][index].scoreValue === undefined) {
									filters[type][index].scoreValue = 0;
								}

								setFilters({
									...filters,
									[type]: filters[type],
								});
							}
						}}
						kind={kind}
						type={apolloType}
						value={(filter?.value as unknown as string[]) || []}
					/>
				</div>
				<div className="w-[250px] mb-4 flex flex-row space-x-4 items-center">
					<Slider
						className="rounded h-[20px] !bg-gray-300/10"
						color="gray"
						defaultValue={filters[type][index].scoreValue || 0}
						max={20}
						min={-20}
						size="sm"
						step={1}
						trackClassName="[&::-webkit-slider-runnable-track]:bg-transparent [&::-moz-range-track]:bg-transparent rounded-none !bg-gray-300/10 border border-gray-300"
						onChange={(value) => {
							filters[type][index] = {
								...filters[type][index],
								scoreValue: value.target.value as unknown as number,
							};

							setFilters({
								...filters,
								[type]: filters[type],
							});
						}}
					/>

					<InputField
						className="w-16"
						handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
							console.log(event.target.value);
						}}
						isDisabled={true}
						name={"score"}
						placeholder="score"
						value={filters[type][index].scoreValue || 0}
					/>
				</div>
				<div className="flex items-center">
					{index + 1 === filters[type].length ? (
						<>
							{" "}
							<div className="w-[100px] mr-2">
								<Button
									className="w-[150px] mr-4"
									color={ButtonColor.ACTION_SECONDARY}
									size={ButtonSize.XS}
									title="Add new filter"
									onClick={addNewFilter}
								/>
							</div>
							<Icon
								className="cursor-pointer"
								color="#4D66F5"
								icon={faTrash}
								onClick={removeCurrentFilter}
							/>
						</>
					) : (
						<>
							<Icon
								className="cursor-pointer mb-4 ml-0"
								color="#4D66F5"
								icon={faTrash}
								onClick={removeCurrentFilter}
							/>
						</>
					)}
				</div>
			</div>
		);
	};

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<BackLink />
			<div className="flex items-end justify-between w-auto mb-8">
				<div className="flex-grow">
					{showInput ? (
						<div className="flex flex-col">
							<InputField
								className="w-64 mb-4"
								handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
									setName({
										...name,
										name: event.target.value,
									});
								}}
								name={"name"}
								placeholder={ts("name")}
								value={name.name || ""}
							/>
							<InputField
								className="w-64"
								handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
									setName({
										...name,
										description: event.target.value,
									});
								}}
								name={"description"}
								placeholder={ts("create.description")}
								value={name.description || ""}
							/>
						</div>
					) : (
						<>
							<h3 className="mb-3 cursor-pointer" onClick={() => setShowInput(true)}>
								{data?.name || ts("title")}
							</h3>
							<p
								className="text-gray-700 lg:max-w-[650px] text-xs pr-5 cursor-pointer"
								onClick={() => setShowInput(true)}
							>
								{data?.description || ts("subtitle")}
							</p>
						</>
					)}
				</div>
			</div>
			<div className="w-full">
				<div className="flex items-start justify-between w-full mb-8">
					<div className="w-[250px]">
						<SelectField
							handleSelect={(type) => {
								if (!types.includes(type as ScoreTypes)) {
									setTypes(
										types?.length > 0 ? [...types, type as ScoreTypes] : [type as ScoreTypes]
									);
									if (type && !filters[type]) {
										if (NumericScoreTypes.includes(type as ScoreTypes)) {
											setFilters({
												...filters,
												[type]: [
													{
														valueNumeric: 0,
														valueNumericTill: 0,
													},
												],
											});
										} else {
											setFilters({
												...filters,
												[type]: [
													{
														value: [],
													},
												],
											});
										}
									}
								}
							}}
							options={Object.values(ScoreTypes).map((type) => {
								return {
									title: type,
									value: type,
								};
							})}
							placeholder="Select type"
							selectedOption={null}
						></SelectField>
					</div>
					<div className="w-[100px]">
						<Button
							color={ButtonColor.ACTION}
							size={ButtonSize.M}
							title="Save"
							onClick={saveScore}
						/>
					</div>
				</div>
				{Object.keys(filters).map((type) => {
					return (
						<div
							key={type}
							className="border py-2.5 px-3 pr-8 mb-2 rounded border-gray-200 bg-white"
						>
							<div className=" text-sm text-gray-900 bg-white mb-2">{ts(type)}</div>
							{type === ScoreTypes.TITLE &&
								filters[type]?.map((filter, index) => {
									return ScoreTypeElement(
										index,
										filters,
										type,
										setFilters,
										filter,
										"person_title",
										"person_titles"
									);
								})}
							{type === ScoreTypes.SENIORITY &&
								filters[type]?.map((filter, index) => {
									return ScoreTypeElement(
										index,
										filters,
										type,
										setFilters,
										filter,
										"person_seniority",
										"person_seniorities"
									);
								})}
							{type === ScoreTypes.LOCATION &&
								filters[type]?.map((filter, index) => {
									return ScoreTypeElement(
										index,
										filters,
										type,
										setFilters,
										filter,
										"location",
										"person_locations"
									);
								})}
							{type === ScoreTypes.COMPANY_LOCATION &&
								filters[type]?.map((filter, index) => {
									return ScoreTypeElement(
										index,
										filters,
										type,
										setFilters,
										filter,
										"location",
										"organization_locations"
									);
								})}
							{type === ScoreTypes.INDUSTRY &&
								filters[type]?.map((filter, index) => {
									return ScoreTypeElement(
										index,
										filters,
										type,
										setFilters,
										filter,
										"linkedin_industry",
										"organization_industry_tag_ids"
									);
								})}
							{type === ScoreTypes.EMPLOYEES &&
								filters[type]?.map((filter, index) => {
									return NumericScoreTypeElement(index, filters, type, setFilters);
								})}
							{type === ScoreTypes.NUMBER_IN_LEAD_FILTER &&
								filters[type]?.map((filter, index) => {
									return LeadFilterScoreTypeElement(index, filters, type, setFilters);
								})}

							{type === ScoreTypes.KEYWORDS &&
								filters[type]?.map((filter, index) => {
									return KeywordFilterScoreTypeElement(index, filters, type, setFilters);
								})}
						</div>
					);
				})}
			</div>
		</div>
	);
};
