import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	CreateLiPeople,
	LiPeople,
	LiPeopleResponse,
	LiPeopleResponseSchema,
	LiPeopleSchema,
	PersonSearches,
	PersonSearchesSchema,
	Suggestions,
	SuggestionsSchema,
} from "../../pages/LiPeople/schema/liPeople";

export const liPeopleApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getLiPeoples: builder.query<LiPeopleResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "liPeople",
				params: {
					...(params && { ...params }),
				},
				responseSchema: LiPeopleResponseSchema,
			}),
			providesTags: ["LiPeople"],
		}),
		geSuggestion: builder.query<Suggestions, { type: string; keyword: string }>({
			query: ({ type, keyword }) => ({
				url: `liPeople/suggest/${type}/${keyword}`,
				responseSchema: SuggestionsSchema,
			}),
			providesTags: ["LiPeople"],
		}),
		getSearchPersonSuggestion: builder.query<PersonSearches, { keyword: string }>({
			query: ({ keyword }) => ({
				url: `liPeople/suggest/search_person/${keyword}`,
				responseSchema: PersonSearchesSchema,
			}),
			providesTags: ["LiPeople"],
		}),
		getPersonSuggestion: builder.query<Suggestions, { keyword: string }>({
			query: ({ keyword }) => ({
				url: `liPeople/suggest/person/${keyword}`,
				responseSchema: SuggestionsSchema,
			}),
			providesTags: ["LiPeople"],
		}),
		getCompanySuggestion: builder.query<Suggestions, { keyword: string }>({
			query: ({ keyword }) => ({
				url: `liPeople/suggest/company/${keyword}`,
				responseSchema: SuggestionsSchema,
			}),
			providesTags: ["LiPeople"],
		}),
		getLiPeople: builder.query<LiPeople, number>({
			query: (id: number) => ({
				url: `liPeople/${id}`,
				params: {
					id,
				},
				responseSchema: LiPeopleSchema,
			}),
			providesTags: ["LiPeople"],
		}),
		createLiPeople: builder.mutation<Partial<LiPeople>, Partial<CreateLiPeople>>({
			query: (body) => ({
				url: `liPeople`,
				method: "POST",
				body,
				responseSchema: LiPeopleSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "LiPeople"];
				}

				return ["LiPeople"];
			},
		}),
		updateLiPeople: builder.mutation<Partial<LiPeople>, Partial<CreateLiPeople>>({
			query: ({ id, ...body }) => ({
				url: `liPeople/${id}`,
				method: "PATCH",
				body,
				responseSchema: LiPeopleSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "LiPeople"];
				}

				return ["LiPeople"];
			},
		}),
		deleteLiPeople: builder.mutation<Partial<LiPeople>, { id: number; listId?: number }>({
			query: ({ id, listId }) => ({
				url: listId ? `liPeople/${id}/list/${listId}` : `liPeople/${id}`,
				method: "DELETE",
				responseSchema: LiPeopleSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "LiPeople"];
				}

				return ["LiPeople"];
			},
		}),
	}),
});

export const {
	useGetLiPeoplesQuery,
	useLazyGeSuggestionQuery,
	useLazyGetLiPeoplesQuery,
	useLazyGetSearchPersonSuggestionQuery,
	useLazyGetPersonSuggestionQuery,
	useLazyGetCompanySuggestionQuery,
	useGetLiPeopleQuery,
	useUpdateLiPeopleMutation,
	useDeleteLiPeopleMutation,
	useCreateLiPeopleMutation,
} = liPeopleApi;
export const accountApiReducer = liPeopleApi.reducer;
export const accountApiMiddleware = liPeopleApi.middleware;
