import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Card,
	CardContent,
	CardHeader,
	List,
	ListItem,
	Stack,
	TextField,
	Checkbox,
	FormControlLabel,
} from "@mui/material";

import { useParams } from "react-router-dom";

import {
	useLazyGetApolloByIdQuery,
	useLazySearchOrganizationQuery,
} from "@/pages/Private/redux/apollo/apollo.api";

import { ApolloPeopleRequestParams } from "@/types/apollo";

import { ButtonColor, ButtonSize } from "@/components/Button/types";
import { Button } from "@/components/Button/Button";
import { BackLink } from "@/components/BackLink/BackLink";

import { ApolloIndustryTagAutocomplete } from "../../components/ApolloIndustryTagAutocomplete/ApolloIndustryTagAutocomplete";
import { TagAutocomplete } from "../../components/TagAutocomplete/TagAutocomplete";
import { ApolloPeopleResponse } from "../../schema/get-apollos";
import { ApolloTagAutocomplete } from "../../components/ApolloTagAutocomplete/ApolloTagAutocomplete";
import { OrganizationTable } from "../../components/OrganizationTable";
import { CreateOrganizationModal } from "../../components/CreateOrganizationModal";

export const SearchOrganization: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();
	const ts = (key: string) => t(`prospectSearch.organization.${key}`);

	const [searchParams, setSearchParams] = useState<ApolloPeopleRequestParams>();
	const [fromEmployee, setFromEmployee] = useState<number | null>(null);
	const [tillEmployee, setTillEmployee] = useState<number | null>(null);
	const [fromRevenue, setFromRevenue] = useState<number | null>(null);
	const [tillRevenue, setTillRevenue] = useState<number | null>(null);
	const [, setSearchName] = useState<string>();
	const [results, setResults] = useState<ApolloPeopleResponse>();
	const [name, setName] = useState<string>();
	const [apolloSearchOrganization] = useLazySearchOrganizationQuery();
	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

	const [getApollo] = useLazyGetApolloByIdQuery();

	useEffect(() => {
		if (id !== 0) {
			getApollo(+id).then((apolloResponse) => {
				if (apolloResponse.data) {
					setName(apolloResponse.data.name);
					setSearchName(apolloResponse?.data?.name);

					setSearchParams(apolloResponse.data.config);
					const { organization_num_employees_ranges } = apolloResponse.data.config;

					if (organization_num_employees_ranges) {
						const [from, till] = organization_num_employees_ranges[0].split(",");

						if (from) {
							setFromEmployee(+from);
						}

						if (till) {
							setTillEmployee(+till);
						}
					}
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (!fromEmployee && !tillEmployee && searchParams?.organization_num_employees_ranges) {
			const { organization_num_employees_ranges, ...newSearchParams } = searchParams;

			setSearchParams({
				...newSearchParams,
			});
		} else if (fromEmployee || tillEmployee) {
			setSearchParams({
				...searchParams,
				organization_num_employees_ranges: [`${fromEmployee || ""}, ${tillEmployee || ""}`],
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromEmployee, tillEmployee]);

	useEffect(() => {
		if (!fromRevenue && !tillRevenue && searchParams?.revenue_range) {
			const { revenue_range, ...newSearchParams } = searchParams;

			setSearchParams({
				...newSearchParams,
			});
		} else if (fromRevenue || tillRevenue) {
			let revenue_range: { min: string | undefined; max: string | undefined } | object = {};

			if (fromRevenue) {
				revenue_range = {
					...revenue_range,
					min: fromRevenue.toString(),
				};
			}

			if (tillRevenue) {
				revenue_range = {
					...revenue_range,
					max: tillRevenue.toString(),
				};
			}

			setSearchParams({
				...searchParams,
				revenue_range,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromRevenue, tillRevenue]);

	const handleSearch = async () => {
		if (searchParams) {
			const cRes = await apolloSearchOrganization(searchParams);

			setResults(cRes.data);
		}
	};

	// const handleSave = async () => {
	// 	if (!!searchParams && searchName && results && +id === 0) {
	// 		// await saveSearch({
	// 		// 	name: searchName,
	// 		// 	amount: results.meta.total_entries,
	// 		// 	config: searchParams,
	// 		// 	leadsPerDay: leadsPerDay || 0,
	// 		// });
	// 		setSearchName("");
	// 	}

	// 	if (!!searchParams && searchName && results && +id > 0) {
	// 		// const res = await updateSearch({
	// 		// 	id: +id,
	// 		// 	name: searchName,
	// 		// 	type
	// 		// 	amount: results.meta.total_entries,
	// 		// 	config: searchParams,
	// 		// 	leadsPerDay: leadsPerDay || 0,
	// 		// }).unwrap();
	// 		// setSearchName(res.name);
	// 		// setName(res.name);
	// 	}
	// };

	return (
		<div>
			<div className="sm:block flex flex-col items-start justify-between mb-1">
				<BackLink />
				<div className="flex items-end justify-between w-full mb-2">
					<div className="flex-grow">
						<h3 className="mb-3">
							{ts("title")}
							{name ? `: ${name}` : ""}
						</h3>

						{results ? (
							<>
								<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">
									{results.meta.total_entries} search results
								</p>
							</>
						) : (
							<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">{ts("subtitle")}</p>
						)}
					</div>
				</div>
			</div>

			<Stack direction="row" justifyContent="flex-start">
				<Stack
					direction="column"
					justifyContent="flex-start"
					mb={4}
					mr={2}
					sx={{
						width: 250,
					}}
				>
					<Card sx={{ padding: 1, marginBottom: 1 }}>
						<CardHeader
							subheader="Location"
							sx={{ padding: 1, paddingBottom: "0px !important" }}
							titleTypographyProps={{ variant: "overline" }}
						/>
						<CardContent sx={{ padding: 1, paddingBottom: "0px !important" }}>
							<List sx={{ padding: 0 }}>
								<ListItem disablePadding>
									<ApolloTagAutocomplete
										handleValueChange={(value) => {
											if (value.length > 0) {
												setSearchParams({
													...searchParams,
													organization_locations: value.map((v) => v.name),
												});
											} else if (searchParams?.organization_locations) {
												const { organization_locations, ...newSearchParams } = searchParams;

												setSearchParams({
													...newSearchParams,
												});
											}
										}}
										kind="location"
										type="organization_locations"
										value={(searchParams?.organization_locations as unknown as string[]) || []}
									/>
								</ListItem>
							</List>
						</CardContent>
					</Card>
					<Card sx={{ padding: 1, marginBottom: 1 }}>
						<CardHeader
							subheader="#Employees"
							sx={{ padding: 1, paddingBottom: "0px !important" }}
							titleTypographyProps={{ variant: "overline" }}
						/>
						<CardContent sx={{ padding: 1, paddingBottom: "0px !important" }}>
							<List sx={{ padding: 0 }}>
								<ListItem
									disablePadding
									sx={{
										width: "100%",
									}}
								>
									<Stack
										direction="row"
										justifyContent="space-between"
										sx={{
											width: "100%",
										}}
									>
										<TextField
											hiddenLabel
											id="employees_from"
											size="small"
											sx={{
												width: "45%",
											}}
											type="number"
											value={fromEmployee}
											variant="filled"
											onChange={(event) => {
												setFromEmployee(+event?.target?.value || null);
											}}
										/>
										<TextField
											hiddenLabel
											id="employees_till"
											size="small"
											sx={{
												width: "45%",
											}}
											type="number"
											value={tillEmployee}
											variant="filled"
											onChange={(event) => {
												setTillEmployee(+event?.target?.value || null);
											}}
										/>
									</Stack>
								</ListItem>
							</List>
						</CardContent>
					</Card>
					<Card sx={{ padding: 1, marginBottom: 1 }}>
						<CardHeader
							subheader="Industry"
							sx={{ padding: 1, paddingBottom: "0px !important" }}
							titleTypographyProps={{ variant: "overline" }}
						/>
						<CardContent sx={{ padding: 1, paddingBottom: "0px !important" }}>
							<List sx={{ padding: 0 }}>
								<ListItem disablePadding>
									<ApolloIndustryTagAutocomplete
										handleValueChange={(value) => {
											if (value.length > 0) {
												setSearchParams({
													...searchParams,
													organization_industry_tag_ids: value.map((v) => v.id),
													industries: value,
												});
											} else if (searchParams?.organization_industry_tag_ids) {
												const { organization_industry_tag_ids, industries, ...newSearchParams } =
													searchParams;

												setSearchParams({
													...newSearchParams,
												});
											}
										}}
										kind="linkedin_industry"
										type="organization_industry_tag_ids"
										value={(searchParams?.industries as { id: string; name: string }[]) || []}
									/>
								</ListItem>
								<ListItem disablePadding>
									<TagAutocomplete
										handleValueChange={(value) => {
											if (value.length > 0) {
												setSearchParams({
													...searchParams,
													q_organization_keyword_tags: value,
												});
											} else if (searchParams?.q_organization_keyword_tags) {
												const { q_organization_keyword_tags, ...newSearchParams } = searchParams;

												setSearchParams({
													...newSearchParams,
												});
											}
										}}
										kind="q_organization_keyword_tags"
										type="q_organization_keyword_tags"
										value={(searchParams?.q_organization_keyword_tags as unknown as string[]) || []}
									/>
								</ListItem>
							</List>
						</CardContent>
					</Card>
					<Card sx={{ padding: 1, marginBottom: 1 }}>
						<CardHeader
							subheader="#Revenue"
							sx={{ padding: 1, paddingBottom: "0px !important" }}
							titleTypographyProps={{ variant: "overline" }}
						/>
						<CardContent sx={{ padding: 1, paddingBottom: "0px !important" }}>
							<List sx={{ padding: 0 }}>
								<ListItem
									disablePadding
									sx={{
										width: "100%",
									}}
								>
									<Stack
										direction="row"
										sx={{
											width: "100%",
										}}
									>
										<TextField
											hiddenLabel
											id="revenue_range_from"
											placeholder="From"
											size="small"
											sx={{
												width: "100%",
											}}
											type="number"
											value={fromRevenue}
											variant="filled"
											onChange={(event) => {
												setFromRevenue(+event?.target?.value || null);
											}}
										/>
									</Stack>
								</ListItem>
								<ListItem
									disablePadding
									sx={{
										width: "100%",
									}}
								>
									<Stack
										direction="row"
										sx={{
											width: "100%",
										}}
									>
										<TextField
											hiddenLabel
											id="revenue_range_till"
											placeholder="Till"
											size="small"
											sx={{
												width: "100%",
											}}
											type="number"
											value={tillRevenue}
											variant="filled"
											onChange={(event) => {
												setTillRevenue(+event?.target?.value || null);
											}}
										/>
									</Stack>
								</ListItem>
							</List>
						</CardContent>
					</Card>

					<Card sx={{ padding: 1, marginBottom: 1 }}>
						<CardHeader
							subheader="Only Startups"
							sx={{ padding: 1, paddingBottom: "0px !important" }}
							titleTypographyProps={{ variant: "overline" }}
						/>
						<CardContent sx={{ padding: 1, paddingBottom: "0px !important", marginBottom: 1 }}>
							<List sx={{ padding: 0 }}>
								<ListItem
									disablePadding
									sx={{
										width: "100%",
									}}
								>
									<Stack
										direction="row"
										justifyContent="space-between"
										sx={{
											width: "100%",
										}}
									>
										<FormControlLabel
											control={
												<Checkbox
													checked={(searchParams?.useStartups as boolean) || false}
													id="useStartups"
													size="small"
													sx={{
														width: "40px",
													}}
													onChange={(event) => {
														const selected = +event?.target?.checked;

														if (selected) {
															setSearchParams({
																...searchParams,
																useStartups: true,
															});
														} else if (searchParams?.useStartups) {
															const { useStartups, ...newSearchParams } = searchParams;

															setSearchParams({
																...newSearchParams,
															});
														}
													}}
												/>
											}
											disableTypography={true}
											label=""
											sx={{
												fontSize: "10px",
												width: "100%",
												justifyContent: "flex-start",
											}}
										/>
									</Stack>
								</ListItem>
							</List>
						</CardContent>
					</Card>
					<div className="fixed bottom-[10px] w-[250px] bg-white z-10">
						<Stack mb={1}>
							<Button
								color={ButtonColor.ACTION}
								size={ButtonSize.S}
								testId="new-user-button"
								title={ts("search")}
								onClick={() => handleSearch()}
							/>
						</Stack>
						{results && (
							<Button
								color={ButtonColor.ACTION}
								disabled={!results}
								size={ButtonSize.S}
								testId="new-user-button"
								title={ts("save")}
								onClick={() => setIsInviteModalOpen(true)}
							/>
						)}
					</div>
				</Stack>
				<Stack direction="row" justifyContent="space-between" mb={4}>
					<Stack direction="column" justifyContent="flex-start" mb={4}>
						{results ? <OrganizationTable data={results as ApolloPeopleResponse} /> : ""}
					</Stack>
				</Stack>
			</Stack>
			{isInviteModalOpen && searchParams && (
				<CreateOrganizationModal
					amount={results?.meta.total_entries || 0}
					config={searchParams}
					isOpen={isInviteModalOpen}
					setIsOpen={setIsInviteModalOpen}
				/>
			)}
		</div>
	);
};
