import { Dispatch, FunctionComponent, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Link, useNavigate } from "react-router-dom";

import trashIcon from "@assets/icons/trashIcon.svg";

import { Modal } from "@/components";

import { useCreateScoreFromCreateListMutation } from "@/pages/Private/redux/score/score.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import {
	CreateScoreFromCreateList,
	CreateScoreFromCreateListSchema,
} from "@/pages/Private/pages/Score/schema/score.schema";
import { AutoCompleteItem, FilterDropdown } from "@/components/FilterDropdown.tsx/FilterDropdown";

import { ListSourceType } from "@/enum/list.enum";

import { MapScrapeType } from "@/enum/map.enum";

import { InputField } from "@/components/InputField/InputField";

import {
	AdditionalColumnsFiltersConfig,
	FrequentlyUsedFiltersConfig,
	GeneralFilter,
	IFilter,
	IFilterType,
	LiEventFilter,
} from "../../../constans/general-filter";
import { LiPostFilter } from "../../../constans/li-post-filter";

import { JobsFilter } from "../../../constans/jobs-filter";
import { LiCompanyActivityFilter } from "../../../constans/li-company-activity-filter";
import { LiProfileFilter } from "../../../constans/li-profile-filter";
import { MapsFilter, MapsAdditionalFilter } from "../../../constans/maps-filter";
import { List } from "../../../schema/list";

interface createScoreModalProps {
	data: List;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateScoreModal: FunctionComponent<createScoreModalProps> = ({
	data,
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const ts = (key: string) => t(`score.${key}`);
	const [createScore, { isLoading, error }] = useCreateScoreFromCreateListMutation();

	const {
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CreateScoreFromCreateList>({
		defaultValues: {
			name: "",
			scores: [{ id: 1 }],
		},
		resolver: zodResolver(CreateScoreFromCreateListSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: CreateScoreFromCreateList) => {
		try {
			const createdList = await createScore({ ...values, listId: data.id }).unwrap();

			setIsOpen(false);

			navigate(`/app/settings/${createdList.id}`);
		} catch (err) {
			console.error(err);
		}
	};

	const FrequentlyUsedFilters = useMemo(() => {
		let FilterDataType: IFilter[] = [];

		if (data) {
			if (data?.sourceType === ListSourceType.LI_EVENTS) {
				FilterDataType = LiEventFilter;
			}

			if (data?.sourceType === ListSourceType.MAPS) {
				FilterDataType = [...MapsFilter, ...MapsAdditionalFilter];

				if (data?.scrapeLevel === MapScrapeType.GET_ENRICHMENT) {
					FilterDataType = [
						...FilterDataType,
						...FrequentlyUsedFiltersConfig,
						...AdditionalColumnsFiltersConfig,
					];
				}
			}

			if (data?.sourceType === ListSourceType.JOBS) {
				FilterDataType = JobsFilter;
			}

			if (data?.sourceType === ListSourceType.LI_POST) {
				FilterDataType = LiPostFilter;
			}

			if (data?.sourceType && [ListSourceType.LI_PROFILE].includes(data.sourceType)) {
				FilterDataType = LiProfileFilter;
			}

			if (data?.sourceType === ListSourceType.LI_COMPANY_ACTIVITY) {
				FilterDataType = LiCompanyActivityFilter;
			}

			if (FilterDataType.length === 0) {
				FilterDataType = GeneralFilter;
			}
		}

		return FilterDataType;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			overflow={true}
			size="md"
			submitButtonText={t("basics.confirm")}
			subtitle="Provide proper information"
			title={ts("create.title")}
		>
			<div>
				<div className="max-w-[500px] w-full">
					<InputField
						error={!!errors.name?.message}
						handleChange={(value) => {
							setValue("name", value.target.value, {
								shouldValidate: true,
							});
						}}
						label={ts("name")}
						name="name"
						placeholder={ts("name")}
						value={getValues("name") || ""}
					/>
				</div>

				{getValues("scores")
					?.sort((a, b) => (a.id < b.id ? -1 : 1))
					?.map((score, index) => {
						console.log(score);

						return (
							<div key={`scores-${index}`} className="flex items-center justify-between">
								<div className="flex items-center justify-between w-full">
									<div className="flex-col w-full  mr-4">
										<FilterDropdown
											className="w-full mb-3"
											data={[
												...FrequentlyUsedFilters.map((type) => {
													return {
														id: type.name,
														title: t(`inbox.${type.name}`),
													};
												}),
											]}
											floating={true}
											handleSelect={(value: AutoCompleteItem) => {
												setValue(
													`scores`,
													[
														...getValues("scores").filter((s) => {
															return s.id !== score.id;
														}),
														{
															id: score.id,
															name:
																FrequentlyUsedFilters.find((f) => f.name === value.id)?.name || "",
															filterParam:
																FrequentlyUsedFilters.find((f) => f.name === value.id)
																	?.filterParam || "",
															filterType:
																(FrequentlyUsedFilters.find((f) => f.name === value.id)
																	?.filterByType as IFilterType[]) || "",
															value: "",
															selectedFilterType: null,
															score: null,
														},
													],
													{ shouldValidate: true }
												);
											}}
											value={
												getValues("scores")
													.filter((s, i) => {
														return i === index;
													})
													?.map((a) => {
														return {
															id: a.name || "",
															title: t(`inbox.${a.name}`),
														};
													})
													?.at(0) || {
													id: "noFilter",
													title: t(`filter.noFilter`),
												}
											}
										/>
									</div>
									<div className="flex-col w-full mr-4">
										{getValues("scores")[index]?.filterType?.length ? (
											<FilterDropdown
												className="w-full mb-3"
												data={[
													...(getValues("scores")[index]?.filterType?.map((type) => {
														return {
															id: type,
															title: t(`filter.${type}`),
														};
													}) || []),
												]}
												floating={true}
												handleSelect={(value: AutoCompleteItem) => {
													setValue(
														`scores`,
														[
															...getValues("scores").filter((s) => {
																return s.id !== score.id;
															}),
															{
																...score,
																selectedFilterType: (value.id as string) || "",
															},
														],
														{ shouldValidate: true }
													);
												}}
												value={{
													id: "noFilter",
													title: t(`filter.noFilter`),
												}}
											/>
										) : (
											<></>
										)}
									</div>

									<div className="flex-col w-full  mr-4">
										{getValues("scores")[index]?.selectedFilterType &&
										!["isEmpty", "isNotEmpty"].includes(
											getValues("scores")[index].selectedFilterType as string
										) ? (
											<InputField
												handleChange={(value) => {
													setValue(
														`scores`,
														[
															...getValues("scores").filter((s) => {
																return s.id !== score.id;
															}),
															{
																...score,
																value: value.target.value,
															},
														],
														{ shouldValidate: true }
													);
												}}
												name={(getValues("scores")[index]?.name as string) || ""}
												placeholder="Value"
												value={(getValues("scores")[index]?.value as string) || ""}
											/>
										) : (
											<></>
										)}
										{getValues("scores")[index]?.selectedFilterType &&
										["isEmpty", "isNotEmpty"].includes(
											getValues("scores")[index].selectedFilterType as string
										) ? (
											<InputField
												handleChange={(value) => {
													setValue(
														`scores`,
														[
															...getValues("scores").filter((s) => {
																return s.id !== score.id;
															}),
															{
																...score,
																value: value.target.value,
															},
														],
														{ shouldValidate: true }
													);
												}}
												isDisabled={true}
												name={(getValues("scores")[index]?.name as string) || ""}
												placeholder="Value"
												value={(getValues("scores")[index].selectedFilterType as string) || ""}
											/>
										) : (
											<></>
										)}
									</div>
									<div className="flex-col w-full  mr-4">
										{getValues("scores")[index]?.selectedFilterType ? (
											<InputField
												handleChange={(value) => {
													setValue(
														`scores`,
														[
															...getValues("scores").filter((s) => {
																return s.id !== score.id;
															}),
															{
																...score,
																score: +value.target.value as number,
															},
														],
														{ shouldValidate: true }
													);
												}}
												name={`${(getValues("scores")[index]?.name as string) || ""}-score`}
												placeholder="Score"
												type="number"
												value={(getValues("scores")[index].score as number) || ""}
											/>
										) : (
											<></>
										)}
									</div>
									<div className="flex-col w-1/8 min-w-[24px]">
										{getValues("scores")[index] && index >= 1 ? (
											<img
												src={trashIcon}
												onClick={() => {
													setValue(
														"scores",
														[
															...getValues("scores").filter((s) => {
																return s.id !== score.id;
															}),
														],
														{
															shouldValidate: true,
														}
													);
												}}
											/>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						);
					})}
				<div className="flex-col w-[200px]">
					<Link
						className="text-brand-light text-ssm"
						title="add"
						to="#"
						onClick={() => {
							setValue(
								"scores",
								[
									...getValues("scores"),
									{
										id: Math.max(...getValues("scores").map((s) => s.id)) + 1,
									},
								],
								{
									shouldValidate: true,
								}
							);
						}}
					>
						+ Add another score
					</Link>
				</div>

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</div>
		</Modal>
	);
};
