export enum CsvEnrichmentType {
	PERSON = "PERSON",
	PERSON_BY_TITLE = "PERSON_BY_TITLE",
	ORGANIZATION = "ORGANIZATION",
}
export enum CSVEnrichmentScrapeStatus {
	DRAFT = "DRAFT",
	INVALID = "INVALID",
	COMPANY_FINDING = "COMPANY_FINDING",
	HAS_DOMAIN = "HAS_DOMAIN",
	HAS_NO_DOMAIN = "HAS_NO_DOMAIN",
	APOLLO_ENRICHED = "APOLLO_ENRICHED",
}

export enum CsvEnrichmentScrapeStatus {
	DRAFT = "DRAFT",
	SCRAPED = "SCRAPED",
	VALIDATED = "VALIDATED",
	ERROR = "ERROR",
	RUNNING = "RUNNING",
	ENRICHED = "ENRICHED",
	INVALID = "INVALID",
	SCRAPE_BY_LINKEDIN = "SCRAPE_BY_LINKEDIN",
	SCRAPE_BY_EMAIL = "SCRAPE_BY_EMAIL",
	SCRAPE_BY_NAME_COMPANY_NAME_TITLE = "SCRAPE_BY_NAME_COMPANY_NAME_TITLE",
	SCRAPE_BY_NAME_COMPANY_NAME = "SCRAPE_BY_NAME_COMPANY_NAME",
	SCHEDULE_SCRAPE = "SCHEDULE_SCRAPE",
	LOCAL_SCAPED = "LOCAL_SCAPED",
	PUBLIC_PROFILE = "PUBLIC_PROFILE",
	COMPANY_PROFILE = "COMPANY_PROFILE",
	RETRY_COMPANY_PROFILE = "RETRY_COMPANY_PROFILE",
	RETRY_PUBLIC_PROFILE = "RETRY_PUBLIC_PROFILE",
	WAITING = "WAITING",
	COMPANY_NAME = "COMPANY_NAME",
	APOLLO_ENRICHED = "APOLLO_ENRICHED",
	DONE = "DONE",
}
