import { FunctionComponent } from "react";

import { CircularProgress } from "@mui/material";

import { CustomColumnTypes } from "@/enum/list.enum";
import { classNames } from "@/utils/classNames";

import { useUpdateCostumColumnMutation } from "@/pages/Private/redux/list/list.api";

import { ListPerson } from "../schema/list";
import { ColumnValue } from "../schema/columnValue";
import { ColumnConfig } from "../schema/columnConfig";

export interface CustomTextColumnProps {
	showEdit?: { id: number; name: string };
	columnValue?: ColumnValue;
	columnConfig: ColumnConfig;
	row: ListPerson;
	customColumnTextValue?: string;
	setCustomColumnTextValue: (value: string | undefined) => void;
	setShowEdit: (value: { id: number; name: string }) => void;
	type: CustomColumnTypes;
}

export const CustomTextColumn: FunctionComponent<CustomTextColumnProps> = ({
	showEdit,
	columnValue,
	columnConfig,
	row,
	customColumnTextValue,
	setCustomColumnTextValue,
	setShowEdit,
	type,
}) => {
	const [updatePerson, { isLoading }] = useUpdateCostumColumnMutation();

	if (isLoading) {
		return <CircularProgress />;
	}

	if (showEdit?.id === row.id && showEdit?.name === columnConfig.name) {
		return (
			<textarea
				autoFocus
				className={classNames(
					"sm:shadow-mb-card sm:border-none",
					"input-styles",
					"placeholder-placeholder",
					"text-ssm font-normal",
					"py-3 px-4 w-full"
				)}
				name={columnConfig.name}
				rows={1}
				style={{
					resize: "none",
					overflow: "hidden",
					minHeight: "auto",
					minWidth: "150px",
				}}
				value={customColumnTextValue || ""}
				onBlur={() => setShowEdit({ id: 0, name: "" })}
				onChange={(event) => setCustomColumnTextValue(event.target.value)}
				onFocus={(e: React.FormEvent<HTMLTextAreaElement>) => {
					const target = e.target as HTMLTextAreaElement;

					target.style.height = "auto";
					target.style.height = `${target.scrollHeight}px`;

					const length = target.value.length;

					target.setSelectionRange(length, length);
				}}
				onInput={(e: React.FormEvent<HTMLTextAreaElement>) => {
					const target = e.target as HTMLTextAreaElement;

					target.style.height = "auto";
					target.style.height = `${target.scrollHeight}px`;
				}}
				onKeyDown={async (e) => {
					if (e.key === "Enter" && !e.shiftKey && customColumnTextValue) {
						e.preventDefault();
						await updatePerson({
							id: row.id,
							columnId: columnConfig.id,
							customColumnName: columnConfig.name,
							value: customColumnTextValue,
							customColumnType: type,
						}).unwrap();
						setCustomColumnTextValue(undefined);
						setShowEdit({ id: 0, name: "" });
					}
				}}
			/>
		);
	} else {
		let cVal = columnValue?.value ? (columnValue.value as string) : "";

		if (isLoading) {
			cVal = customColumnTextValue || cVal;
		}

		return (
			<button
				className="w-full"
				onClick={() => {
					setCustomColumnTextValue(columnValue?.value ? (columnValue.value as string) : "");

					setShowEdit({ id: row.id, name: columnConfig.name });
				}}
			>
				{
					<div
						className={classNames(
							"h-[26px] min-w-[150px] cursor-pointer hover:bg-brand-light hover:opacity-[0.7] leading-tight hover:text-white rounded p-1",
							"text-left  w-full"
						)}
					>
						{cVal}
					</div>
				}
			</button>
		);
	}
};
