import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { PermissionCheck } from "@/components";

import { PermissionRoles } from "@/enum";

import { CompaniesOverview } from "./pages/CompaniesOverview/CompaniesOverview";
import { UserOverview } from "./pages/UserOverview/UserOverview";

export const Companies: FunctionComponent = () => {
	return (
		<PermissionCheck redirect={true} requiredPermissions={[PermissionRoles.GLOBAL_ADMIN]}>
			<Routes>
				<Route element={<CompaniesOverview />} path="/" />
				<Route element={<UserOverview />} path="/:id" />
			</Routes>
		</PermissionCheck>
	);
};
