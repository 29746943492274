import { z } from "zod";

import { ScoreTypes } from "@/enum/score.enum";
import { MetaDataSchema } from "@/schemas/meta-data";

import { ScoreTypeFilterSchema } from "./score-type-filter.schema";

export const ScoreTypeSchema = z.object({
	id: z.number(),
	type: z.nativeEnum(ScoreTypes),
	scoreTypeFilters: z.array(ScoreTypeFilterSchema),
});

export const CreateScoreTypeSchema = z.object({
	id: z.number(),
	type: z.nativeEnum(ScoreTypes),
});

export const ScoreTypeResponseSchema = z.object({
	data: z.array(ScoreTypeSchema),
	meta: MetaDataSchema,
});

export type ScoreType = z.infer<typeof ScoreTypeSchema>;

export type CreateScoreType = z.infer<typeof CreateScoreTypeSchema>;

export type ScoreTypeResponse = z.infer<typeof ScoreTypeResponseSchema>;
