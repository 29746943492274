import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { LiProfileVisitsEntrySchema } from "./liProfileVisitsEntry";

const { REQUIRED } = ERROR_TYPE;

export const LiProfileVisitsSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	liProfileVisitsEntries: z.array(LiProfileVisitsEntrySchema).optional(),
});

export const CreateLiProfileVisitsSchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
});

export const UpdateLiProfileVisitsSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
});

export const LiProfileVisitsResponseSchema = z.object({
	data: z.array(LiProfileVisitsSchema),
	meta: MetaDataSchema,
});

export type LiProfileVisits = z.infer<typeof LiProfileVisitsSchema>;

export type CreateLiProfileVisits = z.infer<typeof CreateLiProfileVisitsSchema>;

export type UpdateLiProfileVisits = z.infer<typeof UpdateLiProfileVisitsSchema>;

export type LiProfileVisitsResponse = z.infer<typeof LiProfileVisitsResponseSchema>;
