import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";

import { faGear } from "@fortawesome/pro-regular-svg-icons";

import { AssignStatus } from "@/enum/list.enum";

import { TabChip, Icon } from "@/components";
import { useGetLeanListQuery, useGetLeanListsQuery } from "@/pages/Private/redux/list/list.api";

import { classNames } from "@/utils/classNames";

import { useSearch } from "@/utils";

import { Order, SortParams } from "@/types/types";

import { AutoCompleteItem } from "@/components/Dropdown/Dropdown";

import { UnassignedTable } from "../../components/UnassignedTable";
import { ListSourceTypeElem } from "../../components/ListSourceTypeElem";
import { CustomListAutocomplete } from "../../components/CustomListAutocomplete";
import { List } from "../../schema/list";

enum Tabs {
	UNASSIGNED = "UNASSIGNED",
	ASSIGNED = "ASSIGNED",
}

export const Unassigned: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id } = useParams();
	const ts = (key: string) => t(`inbox.${key}`);
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { setSearchValue, query } = useSearch();
	const [sortState, setSortState] = useState<SortParams | undefined>();
	const [page, setPage] = useState(1);
	const [listData, setListData] = useState<List[]>([]);
	const [listLoading, setListLoading] = useState(false);

	const { data } = useGetLeanListQuery(id ? +id : 0);
	const {
		data: lists,
		isLoading,
		isFetching,
	} = useGetLeanListsQuery({
		page,
		limit: 15,
		searchValue: query,
		sorting: sortState,
	});

	useEffect(() => {
		if (lists) {
			setListData((prevData) => [...prevData, ...lists.data]);
			setListLoading(false);
		}
	}, [lists]);

	const handleLoadMore = () => {
		if (!isFetching && page < (lists?.meta?.pageCount || 0)) {
			setTimeout(() => {
				setPage((prevPage) => prevPage + 1);
			}, 500);
		}
	};
	const [currentTab, setCurrentTab] = useState<Tabs>(
		(searchParams.get("tab") as unknown as Tabs) || Tabs.UNASSIGNED
	);

	useEffect(() => {
		if (searchParams.has("tab")) {
			setTimeout(() => {
				setCurrentTab(searchParams.get("tab") as Tabs);
			}, 100);
		}
	}, [searchParams]);

	useEffect(() => {
		if (!searchParams.has("tab") || currentTab !== searchParams.get("tab")) {
			setSearchParams({ ...searchParams, tab: currentTab }, { replace: false });
		}
	}, [currentTab, searchParams, setSearchParams]);

	const handleTabsChange = useCallback((_: ChangeEvent<{}>, value: Tabs): void => {
		setCurrentTab(value);
	}, []);

	const tabs = [
		{ label: ts("tabs.unassigned"), value: Tabs.UNASSIGNED },
		{ label: ts("tabs.assigned"), value: Tabs.ASSIGNED },
	];

	const handleSortList = (value: AutoCompleteItem) => {
		setListLoading(true);
		setListData([]);
		setPage(1);
		switch (value.id) {
			case "nameASC":
				setSortState({ sortBy: "name", sortOrder: Order.ASC });
				break;
			case "nameDESC":
				setSortState({ sortBy: "name", sortOrder: Order.DESC });
				break;
			case "leadsASC":
				setSortState({ sortBy: "newLeads", sortOrder: Order.ASC });
				break;
			case "leadsDESC":
				setSortState({ sortBy: "newLeads", sortOrder: Order.DESC });
				break;
			default:
				break;
		}
	};

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<div className="flex items-end justify-between w-full mb-7">
				<div className="flex-grow inline-flex items-center justify-between">
					<div className="flex-grow inline-flex items-center">
						<div className="text-gray-700 lg:max-w-[650px] font-medium text-sm leading-4 px-2 py-1.5 mr-3">
							{data?.sourceType && <ListSourceTypeElem sourceType={data?.sourceType} />}
						</div>

						<CustomListAutocomplete
							currentPage={page}
							data={listData || []}
							floating={true}
							handleFetchMore={() => {
								handleLoadMore();
							}}
							handleSearch={(value: string) => {
								setListLoading(true);
								setListData([]);
								setPage(1);
								setSearchValue(value);
							}}
							handleSelect={(value?: Partial<List>) => {
								if (value && value.id !== undefined) {
									navigate(`/app/lists/detail/${value.id}`);
									setSortState(undefined);
									setSearchValue("");
									setPage(1);
									console.log("selected");
								} else {
									console.log("No value selected");
								}
							}}
							handleSortList={(value: AutoCompleteItem) => {
								handleSortList(value);
							}}
							isFetching={isFetching}
							isListLoading={listLoading}
							isLoading={isLoading}
							label={data?.name}
							selectedItem={data?.name || ""}
							sortState={sortState}
							totalPages={lists?.meta.pageCount || 0}
						/>
					</div>
					<Link
						className="text-sm flex items-center h-full"
						to={`/app/lists/create/settings/${id}`}
					>
						Settings <Icon className="w-[18px] ml-2 h-[18px]" color="#374151" icon={faGear} />
					</Link>
				</div>
			</div>
			<TabContext value={currentTab}>
				<TabList aria-label="Account tabs" sx={{ mb: 2.5 }} onChange={handleTabsChange}>
					{tabs.map((tab, index) => (
						<Tab
							key={index * 2}
							label={
								<div
									className={classNames(
										"flex leading-[28px]",
										currentTab === tab.value ? " px-7" : ""
									)}
								>
									{tab.label}{" "}
									{tab.value === Tabs.UNASSIGNED && data?.draftCount && data.draftCount > 0 ? (
										<TabChip active={currentTab === tab.value} label={`${data.draftCount}`} />
									) : (
										""
									)}
									{tab.value === Tabs.ASSIGNED && data?.assignedCount && data.assignedCount > 0 ? (
										<TabChip active={currentTab === tab.value} label={`${data?.assignedCount}`} />
									) : (
										""
									)}
								</div>
							}
							sx={{ py: 1.25 }}
							value={tab.value}
						/>
					))}
				</TabList>

				<TabPanel sx={{ p: 0, width: "100%" }} value={Tabs.UNASSIGNED}>
					{data?.sourceType && (
						<UnassignedTable assignStatus={AssignStatus.DRAFT} listType={data?.sourceType} />
					)}
				</TabPanel>

				<TabPanel sx={{ p: 0, width: "100%" }} value={Tabs.ASSIGNED}>
					{data?.sourceType && (
						<UnassignedTable assignStatus={AssignStatus.ASSIGNED} listType={data?.sourceType} />
					)}
				</TabPanel>
			</TabContext>
		</div>
	);
};
