import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

import { useParams } from "react-router-dom";

import { LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { useSearch } from "@/utils";

import { useGetRequestsQuery } from "@/pages/Private/redux/request/request.api";

import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";
import { RequestsFilterType } from "@/enum/requests.enum";

import { Requests } from "../schema/requests";

export const RequestsTable: FunctionComponent<{ id?: number; type?: string }> = (props) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`requests.${key}`), [t]);
	const { id = props?.id, type = props?.type } = useParams();
	const { page, setPage, limit, setLimit, query } = useSearch();
	const [filterType, setFilterType] = useState<AutoCompleteItem>();
	const { data, isLoading } = useGetRequestsQuery({
		page,
		limit,
		searchValue: query,
		filterType: filterType ? (filterType?.id as RequestsFilterType) : undefined,
		id: id ? Number(id) : undefined,
		type: type,
	});

	const columns: TableColumn<Requests>[] = useMemo(() => {
		const col: TableColumn<Requests>[] = [
			{
				label: ts("application"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.application}
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("requests"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.count}
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("costs"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row?.costs?.toFixed(2) || 0.0} $
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("time"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.month && (
								<>
									{row.month}.{row.year}
								</>
							)}
						</Stack>
					</Stack>
				),
			},
		];

		if (!id) {
			col.push({
				label: ts("companyId"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.companyId}
						</Stack>
					</Stack>
				),
			});
		}

		return col;
	}, [id, ts]);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	return !data && !isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<div className="w-[200px]">
						<Dropdown
							data={[
								{
									title: RequestsFilterType.ALL,
									id: RequestsFilterType.ALL,
								},
								{
									title: RequestsFilterType.ALL_BY_MONTH,
									id: RequestsFilterType.ALL_BY_MONTH,
								},
								{
									title: RequestsFilterType.APPLICATION,
									id: RequestsFilterType.APPLICATION,
								},
								{
									title: RequestsFilterType.APPLICATION_BY_MONTH,
									id: RequestsFilterType.APPLICATION_BY_MONTH,
								},
								{
									title: RequestsFilterType.COMPANY,
									id: RequestsFilterType.COMPANY,
								},
								{
									title: RequestsFilterType.COMPANY_BY_MONTH,
									id: RequestsFilterType.COMPANY_BY_MONTH,
								},
								{
									title: RequestsFilterType.COMPANY_BY_APPLICATION_BY_MONTH,
									id: RequestsFilterType.COMPANY_BY_APPLICATION_BY_MONTH,
								},
							]}
							handleSelect={function (value?: AutoCompleteItem) {
								if (value?.title) {
									setFilterType(value);
								}
							}}
							label="Filter Type"
						/>
					</div>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data?.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data?.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
		</div>
	);
};
