import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	CsvEnrichment,
	CsvEnrichmentResponse,
	CsvEnrichmentResponseSchema,
	CsvEnrichmentSchema,
} from "../../pages/CsvEnrichment/schema/csvEnrichment";

export const csvEnrichmentApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getCsvEnrichments: builder.query<CsvEnrichmentResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "csvEnrichment",
				params: {
					...(params && { ...params }),
				},
				responseSchema: CsvEnrichmentResponseSchema,
			}),
			providesTags: ["CsvEnrichment"],
		}),
		getCsvEnrichment: builder.query<CsvEnrichment, number>({
			query: (id: number) => ({
				url: `csvEnrichment/${id}`,
				params: {
					id,
				},
				responseSchema: CsvEnrichmentSchema,
			}),
		}),
		getCsvEnrichmentForTable: builder.query<CsvEnrichment, number>({
			query: (id: number) => ({
				url: `csvEnrichment/${id}/table`,
				params: {
					id,
				},
				responseSchema: CsvEnrichmentSchema,
			}),
		}),
		downloadCsvEnrichment: builder.query<Blob, number>({
			query: (id: number) => ({
				url: `csvEnrichment/downloadSource/${id}`,
				params: {
					id,
				},
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				responseHandler: async (response: Response) => {
					if (!response.ok) {
						return { error: response.statusText, status: response.status };
					}

					return response.blob();
				},
			}),
		}),
		triggerCsvEnrichment: builder.mutation<void, number>({
			query: (id) => ({
				url: `csvEnrichment/trigger/${id}`,
				method: "POST",
			}),
			invalidatesTags: ["CsvEnrichment"],
		}),
		updateCsvEnrichment: builder.mutation<void, Partial<CsvEnrichment>>({
			query: ({ id, ...body }) => ({
				url: `csvEnrichment/${id}`,
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["CsvEnrichment"],
		}),
		createCsvEnrichment: builder.mutation<Partial<CsvEnrichment>, FormData>({
			query: (body) => ({
				url: `csvEnrichment/upload`,
				method: "POST",
				body,
				responseSchema: CsvEnrichmentSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.get("listId")) {
					return [{ type: "List", id: +(arg.get("listId") || 0) }, "CsvEnrichment"];
				}

				return ["CsvEnrichment"];
			},
		}),
		updateCsvEnrichmentFile: builder.mutation<
			Partial<CsvEnrichment>,
			{ form: FormData; id: number; listId: number }
		>({
			query: ({ form, id }) => ({
				url: `csvEnrichment/upload/${id}`,
				method: "POST",
				body: form,
				responseSchema: CsvEnrichmentSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: +(arg.listId || 0) }, "CsvEnrichment"];
				}

				return ["CsvEnrichment"];
			},
		}),
		deleteCsvEnrichment: builder.mutation<Partial<CsvEnrichment>, { id: number; listId?: number }>({
			query: ({ id, listId }) => ({
				url: listId ? `csvEnrichment/${id}/list/${listId}` : `csvEnrichment/${id}`,
				method: "DELETE",
				responseSchema: CsvEnrichmentSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "CsvEnrichment"];
				}

				return ["CsvEnrichment"];
			},
		}),
	}),
});

export const {
	useGetCsvEnrichmentsQuery,
	useLazyGetCsvEnrichmentsQuery,
	useGetCsvEnrichmentQuery,
	useDeleteCsvEnrichmentMutation,
	useTriggerCsvEnrichmentMutation,
	useCreateCsvEnrichmentMutation,
	useUpdateCsvEnrichmentMutation,
	useGetCsvEnrichmentForTableQuery,
	useLazyDownloadCsvEnrichmentQuery,
	useUpdateCsvEnrichmentFileMutation,
} = csvEnrichmentApi;
export const accountApiReducer = csvEnrichmentApi.reducer;
export const accountApiMiddleware = csvEnrichmentApi.middleware;
