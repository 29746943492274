import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { useUpdateMeMutation } from "@/pages/Private/redux/account/account.api";

import { ToggleItem } from "./ToggleItem";

export const ConnectionScraping: FunctionComponent<{ connectionScraping: boolean }> = ({
	connectionScraping,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`account.${key}`);

	const [updateMe, { isLoading }] = useUpdateMeMutation();

	const handle2FactAuthChange = async () => {
		await updateMe({ connectionScraping: !connectionScraping }).unwrap();
	};

	return (
		<ToggleItem
			description={ts("enableConnectionScrapingDescription")}
			isLoading={isLoading}
			label={ts("enableConnectionScraping")}
			name={ts("connectionScraping")}
			value={connectionScraping}
			onChange={handle2FactAuthChange}
		/>
	);
};
