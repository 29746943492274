export const ROUTE_CONFIG = {
	LOGIN: "/login",
	TFA: "/2fa",
	LOGOUT: "/logout",
	FORGOT_PASSWORD: "/forgot-password",
	ERROR_PAGE: "/error-page",
	// APP: "/app",
	ACCOUNT: "/app/account",
	CRON: "/app/cron",
	COMPANY: "/app/company",
	BLACKLIST: "/app/blacklist",
	BLACKLIST_GLOBAL: "/app/blacklist/global",
	PITCHDECKS: "/app/pitchdecks",
	APOLLO_SEARCH_PEOPLE: "/app/prospect/people",
	APOLLO_SEARCH: "/app/prospect/search",
	APOLLO_SEARCH_ORGANIZATION: "/app/prospect/organization",
	APOLLO_SEARCH_PEOPLE_LIST: "/app/prospect",
	LISTS: "/app/lists",
	USERS: "/app/users",
	USER_ROLES: "/app/users/user-roles",
	JOBS: "/app/jobs",
	INDEED: "/app/indeed",
	NORTHDATA: "/app/bundesanzeiger",
	COMPANIES: "/app/companies",
	CSV_ENRICHMENTS: "/app/csvEnrichments",
	CUSTOM_EVENTS: "/app/custom-event",
	LINKEDIN_EVENTS: "/app/linkedin-events",
	LINKEDIN_COMPANY_ACTIVITY: "/app/linkedin-company-activity",
	LINKEDIN_POST: "/app/linkedin-posts",
	LINKEDIN_PROFILE_VISITS: "/app/linkedin-profile-visits",
	LINKEDIN_PROFILE: "/app/linkedin-profile",
	LINKEDIN_PEOPLE: "/app/linkedin-people-search",
	WORKFLOWS: "/app/workflow",
	ACCOUNTS: "/app/accounts",
	OPENAI: "/app/openai",
	DASHBOARD: "/app/dashboard",
	INBOX: "/app/inbox",
	SCORE: "/app/score",
	MAPS: "/app/maps",
	CAMPAIGNS: "/app/list-view/campaigns",
	REPLIES: "/app/list-view/replies",
	NO_REPLY: "/app/list-view/no-reply",
	TO_BE_NURTURED: "/app/list-view/to-be-nurtured",
	DO_NOT_CONTACT: "/app/list-view/do-not-contact",
};

export const PUBLIC_PAGES_AFTER_LOGIN = [ROUTE_CONFIG.FORGOT_PASSWORD];
