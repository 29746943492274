import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { LiProfileOverview } from "./pages/LiProfileOverview/LiProfileOverview";
import { LiProfileDetail } from "./pages/LiProfileDetail/LiProfileDetail";

export const LiProfile: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<LiProfileOverview />} path="/" />
			<Route element={<LiProfileDetail />} path="/:id" />
		</Routes>
	);
};
