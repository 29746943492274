import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	CreateCustomEvent,
	CustomEvent,
	CustomEventResponse,
	CustomEventResponseSchema,
	CustomEventSchema,
} from "../../pages/CustomEvent/schema/customEvent";

export const customEventApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getCustomEvents: builder.query<CustomEventResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "customEvent",
				params: {
					...(params && { ...params }),
				},
				responseSchema: CustomEventResponseSchema,
			}),
			providesTags: ["CustomEvent"],
		}),
		getCustomEvent: builder.query<CustomEvent, number>({
			query: (id: number) => ({
				url: `customEvent/${id}`,
				params: {
					id,
				},
				responseSchema: CustomEventSchema,
			}),
			providesTags: ["CustomEvent"],
		}),
		// getCustomEventById: builder.query<SavedCustomEventResponse, number>({
		// 	query: (id) => ({
		// 		url: `customEvent/${id}`,
		// 		responseSchema: SavedCustomEventSchema,
		// 	}),
		// 	providesTags: [{ type: "CustomEvents", id: "DETAIL" }],
		// }),
		triggerCustomEvent: builder.mutation<void, number>({
			query: (id) => ({
				url: `customEvent/trigger/${id}`,
				method: "POST",
			}),
			invalidatesTags: ["CustomEvent"],
		}),
		createCustomEvent: builder.mutation<Partial<CustomEvent>, CreateCustomEvent>({
			query: (body) => ({
				url: `customEvent`,
				method: "POST",
				body,
				responseSchema: CustomEventSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "CustomEvent"];
				}

				return ["CustomEvent"];
			},
		}),
		assignCsvEnrichmentToCustomEvent: builder.mutation<
			Partial<CustomEvent>,
			{ customEventId: number; csvEnrichmentIds: number[] }
		>({
			query: ({ customEventId, csvEnrichmentIds }) => ({
				url: `customEvent/assign/${customEventId}`,
				method: "POST",
				body: { csvEnrichmentIds },
				responseSchema: CustomEventSchema,
			}),
			invalidatesTags: ["CustomEvent"],
		}),
		updateCustomEvent: builder.mutation<Partial<CustomEvent>, CreateCustomEvent>({
			query: ({ id, ...body }) => ({
				url: `customEvent/${id}`,
				method: "PATCH",
				body,
				responseSchema: CustomEventSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "CustomEvent"];
				}

				return ["CustomEvent"];
			},
		}),
		resetCustomEvent: builder.mutation<Partial<CustomEvent>, number>({
			query: (id) => ({
				url: `customEvent/${id}/reset`,
				method: "DELETE",
				responseSchema: CustomEventSchema,
			}),
			invalidatesTags: ["CustomEvent"],
		}),
	}),
});

export const {
	useGetCustomEventsQuery,
	useLazyGetCustomEventsQuery,
	useGetCustomEventQuery,
	useResetCustomEventMutation,
	useTriggerCustomEventMutation,
	useCreateCustomEventMutation,
	useUpdateCustomEventMutation,
	useAssignCsvEnrichmentToCustomEventMutation,
} = customEventApi;
export const accountApiReducer = customEventApi.reducer;
export const accountApiMiddleware = customEventApi.middleware;
