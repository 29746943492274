import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Stack, TextField, Typography } from "@mui/material";

import { faArrowUpRightFromSquare, faPen, faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import {
	useDeleteLiPeopleMutation,
	useGetLiPeoplesQuery,
} from "@/pages/Private/redux/liPeople/liPeople.api";

import { LiPeople } from "../schema/liPeople";
import { UpdateLiPeopleModal } from "./UpdateLiPeopleModal";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LiPeopleTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`liPeople.${key}`), [t]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [selectedLiPeople, setSelectedLiPeople] = useState<LiPeople | null>(null);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [deleteLiPeople, { isLoading }] = useDeleteLiPeopleMutation();

	const { data, isFetching } = useGetLiPeoplesQuery({
		page,
		limit,
		searchValue: query,
	});

	const columns: TableColumn<LiPeople>[] = useMemo(
		() => [
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.name}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("urlTitle"),
				minWidth: 200,
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<div className="flex">
							<>
								{row.liPeopleEntries?.slice(0, 3).map((item, index) => {
									return (
										<Link
											key={index}
											className="!mr-1 inline-flex"
											href={`${item.url}`}
											target="_blank"
										>
											<Icon icon={faArrowUpRightFromSquare} size="xl" />
										</Link>
									);
								})}
								{row.liPeopleEntries?.length && row.liPeopleEntries?.length > 3 ? (
									<span className="ml-2 mt-1 text-md">...</span>
								) : (
									""
								)}
							</>
						</div>
					</Stack>
				),
			},
			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<PermissionCheck requiredPermissions={[PermissionRoles.LI_PEOPLE]}>
							<>
								<Box
									sx={{
										color: "neutral.500",
										"&:hover": {
											color: "primary.main",
											cursor: "pointer",
										},
									}}
									onClick={() => handleEditModal(row)}
								>
									<Icon icon={faPen} size="xl" />
								</Box>
								<Box
									sx={{
										color: "neutral.500",
										"&:hover": {
											color: "primary.main",
											cursor: "pointer",
										},
									}}
									onClick={() => handleDeleteModal(row)}
								>
									<Icon icon={faTrashCan} size="xl" />
								</Box>
							</>
						</PermissionCheck>
					</Stack>
				),
			},
		],
		[ts]
	);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	const handleDeleteModal = (row: LiPeople) => {
		setShowDeleteModal(true);
		setSelectedLiPeople(row);
	};

	const handleEditModal = (row: LiPeople) => {
		setShowEditModal(true);
		setSelectedLiPeople(row);
	};

	const handleDeleteLiPeople = async () => {
		try {
			await deleteLiPeople({ id: selectedLiPeople?.id ?? 0 }).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	return !data && isFetching ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data?.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data?.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteLiPeople}
					isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedLiPeople?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}
			{showEditModal && selectedLiPeople && (
				<UpdateLiPeopleModal
					isOpen={showEditModal}
					liPeople={selectedLiPeople}
					setIsOpen={setShowEditModal}
				/>
			)}
		</div>
	);
};
