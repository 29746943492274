import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

export const RequestsSchema = z.object({
	id: z.number(),
	application: z.string(),
	costs: z.number(),
	month: z.number(),
	year: z.number(),
	count: z.number(),
	companyId: z.number(),
});

export const RequestsResponseSchema = z.object({
	data: z.array(RequestsSchema),
	meta: MetaDataSchema,
});

export type Requests = z.infer<typeof RequestsSchema>;

export type RequestsResponse = z.infer<typeof RequestsResponseSchema>;
