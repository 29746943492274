import { api } from "@/redux/api";

import {
	ForgotPasswordRequestSchema,
	ForgotPasswordType,
} from "../pages/ForgotPassword/schema/forgot-password";
import {
	FetchMeResponseSchema,
	LoginRequestSchema,
	LoginResponse,
	LoginResponseSchema,
	LoginType,
	User,
} from "../pages/Login/schema/login";
import {
	SetPasswordBody,
	SetPasswordRequestSchema,
} from "../pages/SetPassword/schema/set-password";

export const authApi = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchMe: builder.query<User, string>({
			query: (token) => ({
				url: "users/me",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				responseSchema: FetchMeResponseSchema,
			}),
			providesTags: ["Me"],
		}),
		login: builder.mutation<LoginResponse, LoginType>({
			query: (body) => ({
				url: "users/login",
				method: "POST",
				body,
				requestSchema: LoginRequestSchema,
				responseSchema: LoginResponseSchema,
			}),
		}),
		resetPassword: builder.mutation<void, ForgotPasswordType>({
			query: (body) => ({
				url: "users/reset-password",
				method: "POST",
				body,
				requestSchema: ForgotPasswordRequestSchema,
			}),
		}),
		setPassword: builder.mutation<void, SetPasswordBody>({
			query: (body) => ({
				url: "users/set-password",
				method: "POST",
				body,
				requestSchema: SetPasswordRequestSchema,
			}),
		}),
		authTwoFactorCode: builder.mutation<LoginResponse, { code: string; token: string }>({
			query: ({ code, token }) => ({
				url: "2fa/authenticate",
				method: "POST",
				body: { code },
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
		}),
	}),
});

export const {
	useLoginMutation,
	useResetPasswordMutation,
	useSetPasswordMutation,
	useLazyFetchMeQuery,
	useAuthTwoFactorCodeMutation,
} = authApi;
export const authApiReducer = authApi.reducer;
export const authApiMiddleware = authApi.middleware;
