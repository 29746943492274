/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { faChevronDown, faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import { useParams } from "react-router-dom";

import { useGetListQuery, useLazyGetLogQuery } from "@/pages/Private/redux/list/list.api";

import { Icon, LoadingOverlay } from "@/components";

import { Toggle } from "@/components/Toggle/Toggle";
import { ListAction } from "@/enum/list.enum";

import { classNames } from "@/utils/classNames";

import { Button } from "@/components/Button/Button";
import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";

import { ListLog } from "../schema/list";

export const ListLogTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();

	const [expanded, setExpanded] = useState<number>();

	const ts = useCallback((key: string) => t(`list.${key}`), [t]);

	const [logs, setLogs] = useState<ListLog[]>();
	const [tail, setTail] = useState<boolean>(true);
	const [page, setPage] = useState<number>(1);
	const [hasNextPage, setHasNextPage] = useState<boolean>(true);
	const interval = useRef<NodeJS.Timeout>();
	const [valuesForIterations, setValuesForIterations] = useState<AutoCompleteItem[]>([]);
	const [iteration, setIteration] = useState<number>();

	const [load, { isLoading }] = useLazyGetLogQuery();

	const { data } = useGetListQuery(+id);

	useEffect(() => {
		if (data?.iteration) {
			setIteration(data.iteration);
			const iterations = [] as AutoCompleteItem[];

			for (let i = 1; i <= data.iteration; i++) {
				iterations.push({ title: `${ts("iteration")}: ${i}`, id: i });
			}

			setValuesForIterations(iterations);
		}
	}, [data, ts]);

	useEffect(() => {
		const appendRows = async (params: {
			id: number;
			page: number;
			limit: number;
			iteration?: number;
		}) => {
			const results = await load({
				id: params.id,
				page: params.page,
				limit: 100,
				iteration,
			}).unwrap();

			setHasNextPage(results.meta.hasNextPage);

			const newInLogs = results.data.filter((log) => {
				return !logs?.map((l) => l.id).includes(log.id);
			});

			if (newInLogs.length > 0) {
				setLogs((prevLogs) => [...(prevLogs || []), ...newInLogs].sort((a, b) => b.id - a.id));
			}
		};

		const loadNewRows = async (params: {
			id: number;
			page: number;
			limit: number;
			iteration?: number;
		}) => {
			if (tail) {
				clearInterval(interval.current);

				appendRows(params);

				interval.current = setInterval(async () => {
					appendRows({ ...params, page: 1 });
				}, 10000);

				return () => clearInterval(interval.current);
			} else {
				if (interval.current) {
					clearInterval(interval.current);
				}

				appendRows(params);
			}
		};

		loadNewRows({ id: Number(id), page: page, limit: 100, iteration: iteration });
	}, [id, load, page, tail, iteration, logs]);

	return !logs ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-1/2 mb-8">
				<div className="flex-grow mr-4 flex mb-3 items-center">
					<Dropdown
						data={valuesForIterations}
						floating={true}
						handleSelect={function (value: AutoCompleteItem): void {
							setIteration(value.id as number);
						}}
						value={
							iteration ? { title: `${ts("iteration")}: ${iteration}`, id: iteration } : undefined
						}
					/>

					<Toggle
						className="mr-4 ml-4"
						isChecked={tail}
						onChange={() => {
							setTail(!tail);
						}}
					></Toggle>
					<span className="text-xs text-gray-700 ">{ts("automaticUpdate")}</span>
				</div>
			</div>
			{isLoading ? <LoadingOverlay /> : ""}
			<div className="w-fit bg-white mb-2">
				<ul className="w-full">
					{logs?.length > 0
						? logs?.map((log, index) => {
								return (
									<li
										key={`key-${index}`}
										className={classNames(
											"text-sm",
											index % 2 === 0 ? "bg-gray-100" : "bg-gray-200",
											"w-full p-2"
										)}
									>
										<div className="w-full">
											<span className="mr-1 cursor-pointer w-3 h-3">
												{log.data ? (
													expanded === log.id ? (
														<Icon
															className=" w-3 h-3"
															icon={faChevronDown}
															onClick={() => setExpanded(undefined)}
														/>
													) : (
														<Icon
															className=" w-3 h-3"
															icon={faChevronRight}
															onClick={() => setExpanded(log.id)}
														/>
													)
												) : (
													"n"
												)}
											</span>
											<span className="mr-1">{log.createdAt}</span>
											<span
												className={classNames(
													log.action === ListAction.GENERAL ? "text-gray-500" : "",
													log.action === ListAction.EXCLUDED ? "text-red-500" : "",
													log.action === ListAction.ADDED ? "text-green-500" : ""
												)}
											>
												{log.description}
											</span>
										</div>
										{expanded === log.id ? (
											<div>
												{log.data ? (
													<div className="text-gray-500">
														{Object.keys(log.data).map((prop: string, index: number) => {
															return (
																<div key={`key-${index}`}>
																	<div>
																		<span>
																			{prop}: {log.data[prop]?.toString()}
																		</span>
																	</div>
																</div>
															);
														})}
													</div>
												) : (
													""
												)}
											</div>
										) : (
											""
										)}
									</li>
								);
						  })
						: ""}
				</ul>
				{hasNextPage && <Button title={ts("loadMore")} onClick={() => setPage(page + 1)} />}
			</div>
		</div>
	);
};
