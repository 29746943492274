export enum PromptRole {
	HUMAN = "HUMAN",
	GPTSYSTEM = "GPTSYSTEM",
	AI = "AI",
}

export enum PromptType {
	EMAIL = "EMAIL",
	SUBJECT = "SUBJECT",
}

export enum AiEnrichmentTarget {
	ACCOUNT = "ACCOUNT",
	CONTACT = "CONTACT",
	NORTHDATA = "NORTHDATA",
}
