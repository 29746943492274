import { FunctionComponent } from "react";

import { RequestsTable } from "../components/RequestsTable";

export const RequestsOverview: FunctionComponent = () => {
	return (
		<>
			<RequestsTable />
		</>
	);
};
