import { FunctionComponent, useCallback } from "react";

import { Chip } from "@material-tailwind/react";

import { useTranslation } from "react-i18next";

import { JobsScrapeStatus } from "@/enum/scrape-enum";

import { CsvEnrichment } from "../schema/csvEnrichment";

interface JobsScrapeStatusProps {
	row: CsvEnrichment;
}

export const CsvEnrichmentStatus: FunctionComponent<JobsScrapeStatusProps> = ({ row }) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`list.${key}`), [t]);

	if (row?.status === JobsScrapeStatus.DONE) {
		return (
			<Chip className="bg-brand-light" color="blue" size="sm" value={ts(`status.${row.status}`)} />
		);
	}

	if (row?.status === JobsScrapeStatus.IN_PROGRESS) {
		return <Chip className="bg-yellow-500" size="sm" value={ts(`status.${row.status}`)} />;
	}

	if (row?.status === JobsScrapeStatus.DRAFT) {
		return <Chip className="bg-gray-500" size="sm" value={ts(`status.${row.status}`)} />;
	}

	return <></>;
};
