import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { CustomEventOverview } from "./pages/CustomEventOverview/CustomEventOverview";

export const CustomEvent: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<CustomEventOverview />} path="/" />
		</Routes>
	);
};
