import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	Score,
	ScoreFromCreateList,
	ScoreResponse,
	ScoreResponseSchema,
	ScoreSchema,
	UpdateScore,
} from "../../pages/Score/schema/score.schema";

export const scoreApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getScores: builder.query<ScoreResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "score",
				params: {
					...(params && { ...params }),
				},
				responseSchema: ScoreResponseSchema,
			}),
			providesTags: ["Score"],
		}),
		getScore: builder.query<Score, number>({
			query: (id: number) => ({
				url: `score/${id}`,
				params: {
					id,
				},
				responseSchema: ScoreSchema,
			}),
			providesTags: ["Score"],
		}),
		createScore: builder.mutation<Partial<Score>, Partial<Score>>({
			query: (body) => ({
				url: `score`,
				method: "POST",
				body,
				responseSchema: ScoreSchema,
			}),
			invalidatesTags: ["Score"],
		}),
		createScoreFromCreateList: builder.mutation<
			Partial<ScoreFromCreateList>,
			Partial<ScoreFromCreateList>
		>({
			query: (body) => ({
				url: `score`,
				method: "POST",
				body: {
					...body,
					scores: JSON.stringify(body.scores),
				},
				responseSchema: ScoreSchema,
			}),
			invalidatesTags: ["Score"],
		}),
		updateScore: builder.mutation<Partial<Score>, Partial<UpdateScore>>({
			query: ({ id, ...body }) => ({
				url: `score/${id}`,
				method: "PATCH",
				body: body,
				responseSchema: ScoreSchema,
			}),
			invalidatesTags: ["Score"],
		}),
		deleteScore: builder.mutation<Partial<Score>, number>({
			query: (id) => ({
				url: `score/${id}`,
				method: "DELETE",
				responseSchema: ScoreSchema,
			}),
			invalidatesTags: ["Score"],
		}),
	}),
});

export const {
	useGetScoresQuery,
	useLazyGetScoresQuery,
	useGetScoreQuery,
	useUpdateScoreMutation,
	useDeleteScoreMutation,
	useCreateScoreMutation,
	useCreateScoreFromCreateListMutation,
} = scoreApi;
export const accountApiReducer = scoreApi.reducer;
export const accountApiMiddleware = scoreApi.middleware;
