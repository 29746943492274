import { FunctionComponent } from "react";

import { LoadingOverlay } from "@/components";
import { useGetColumnByIdQuery } from "@/pages/Private/redux/list/list.api";

import { EditCustomColumnModal } from "./EditCustomColumnModal";

export interface EditCustomColumnModalWrapperProps {
	setOpen: (value: boolean) => void;
	open: boolean;
	listId: number;
	columnId: number;
}

export const EditCustomColumnModalWrapper: FunctionComponent<EditCustomColumnModalWrapperProps> = ({
	open,
	setOpen,
	listId,
	columnId,
}) => {
	const { data } = useGetColumnByIdQuery(columnId);

	return !data ? (
		<LoadingOverlay />
	) : data ? (
		<EditCustomColumnModal
			column={data}
			columnId={columnId}
			listId={listId}
			open={open}
			setOpen={setOpen}
		/>
	) : (
		<></>
	);
};
