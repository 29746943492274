import { IFilter } from "./general-filter";

const content: IFilter = {
	name: "content",
	filterParam: "li_company_activity_entry_result.content",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const comment: IFilter = {
	name: "comment",
	filterParam: "li_company_activity_entry_result.contentResponse",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const type: IFilter = {
	name: "type",
	filterParam: "li_company_activity_entry_result.type",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const contentDate: IFilter = {
	name: "contentDate",
	filterParam: "li_company_activity_entry_result.contentDate",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const LiCompanyActivityFilter: IFilter[] = [content, comment, type, contentDate];
