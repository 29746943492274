import { z } from "zod";

import { JobsScrapeStatus } from "@/enum/scrape-enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { EmploymentType, JobScrapeType } from "@/enum/job.enum";

import { ListStatus } from "@/enum/list.enum";

import { SuggestionSchema } from "../../LiPeople/schema/liPeople";
import { OfferSchema } from "./offer";

const { REQUIRED } = ERROR_TYPE;

export const JobSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	query: z.string().min(4, { message: REQUIRED }).trim(),
	employmentType: z.nativeEnum(EmploymentType),
	offers: z.array(OfferSchema),
	status: z.nativeEnum(JobsScrapeStatus),
	activeStatus: z.nativeEnum(ListStatus),
	jobScrapeType: z.nativeEnum(JobScrapeType),
	repeatDaily: z.boolean(),
	location: SuggestionSchema,
});

export const CreateJobSchema = z.object({
	id: z.number().optional(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	query: z.string().min(4, { message: REQUIRED }).trim(),
	employmentType: z.nativeEnum(EmploymentType),
	jobScrapeType: z.nativeEnum(JobScrapeType),
	repeatDaily: z.boolean(),
	listId: z.number().optional(),
	location: SuggestionSchema,
});

export const ToggleJobSchema = z.object({
	id: z.number(),
	activeStatus: z.nativeEnum(ListStatus),
});

export const JobResponseSchema = z.object({
	data: z.array(JobSchema),
	meta: MetaDataSchema,
});

export type Job = z.infer<typeof JobSchema>;

export type ToggleJob = z.infer<typeof ToggleJobSchema>;

export type CreateJob = z.infer<typeof CreateJobSchema>;

export type JobResponse = z.infer<typeof JobResponseSchema>;
