import { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as XLSX from "xlsx";
import { FileUploader } from "react-drag-drop-files";

import { useCreateCsvEnrichmentMutation } from "@/pages/Private/redux/csvEnrichment/csvEnrichment.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import { Button } from "@/components/Button/Button";
import { ButtonColor } from "@/components/Button/types";

import { InputField } from "@/components/InputField/InputField";

import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";

import { classNames } from "@/utils/classNames";

import { List } from "../../List/schema/list";
import {
	CreateCsvEnrichment,
	CreateCsvEnrichmentSchema,
	CsvEnrichment,
} from "../schema/csvEnrichment";

interface CreateCsvEnrichmentFormProps {
	csvEnrichment?: CsvEnrichment | null;
	list: List;
	handleSelect?: (csvEnrichment: CsvEnrichment | null) => void;
}

const minimumFields = [
	["email"],
	["linkedinUrl"],
	["name", "companyName"],
	["title", "companyName"],
	["firstName", "lastName", "companyName"],
	["companyName"],
	["companyLinkedinUrl"],
	["companyDomain"],
];

const defaultOptions = [
	{ title: "Do Not import", id: "" },
	{ title: "Email Adress", id: "email" },
	{ title: "Job Title", id: "title" },
	{ title: "Name", id: "name" },
	{ title: "Firstname", id: "firstName" },
	{ title: "Lastname", id: "lastName" },
	{ title: "Company Name", id: "companyName" },
	{ title: "Company Website", id: "companyDomain" },
	{ title: "Personal Linkedin", id: "linkedinUrl" },
	{ title: "Company Linkedin", id: "companyLinkedinUrl" },
	{ title: "Save as Custom Field", id: "customField" },
];

export const CreateCsvEnrichmentForm: FunctionComponent<CreateCsvEnrichmentFormProps> = ({
	csvEnrichment,
	list,
	handleSelect,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`csvEnrichment.${key}`);
	const [createCsvEnrichment, { isLoading, error }] = useCreateCsvEnrichmentMutation();
	const [fileContent, setFileContent] = useState<string>();
	const [foundFields, setFoundFields] = useState<{ foundField: string; assignedField: string }[]>();

	const [allowUpload, setAllowUpload] = useState<boolean>(false);

	const [currentFormState, setCurrentFormState] = useState<CreateCsvEnrichment>({
		name: "",
		file: "",
		withEmail: false,
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof CreateCsvEnrichment) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const {
		handleSubmit,
		reset,
		setValue,
		getValues,
		formState: { errors, isDirty },
	} = useForm<CreateCsvEnrichment>({
		defaultValues: {
			withEmail: false,
		},
		resolver: zodResolver(CreateCsvEnrichmentSchema),
	});

	useEffect(() => {
		if (csvEnrichment) {
			reset({
				id: csvEnrichment.id,
				name: csvEnrichment.name,
				withEmail: csvEnrichment.withEmail,
			});
		} else {
			reset({
				withEmail: false,
			});
		}
	}, [list.scrapeLevel, csvEnrichment, reset]);

	useEffect(() => {
		if (foundFields) {
			const isMinimumFields = minimumFields.some((field) => {
				return field.every((f) => foundFields.map((m) => m.assignedField).includes(f));
			});

			setAllowUpload(isMinimumFields);
		}
	}, [foundFields]);

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: CreateCsvEnrichment) => {
		try {
			const { file: fileRaw } = values;

			const formData = new FormData();
			const res: Response = await fetch(fileRaw);
			const blob: Blob = await res.blob();
			const file = new File([blob], "file.csv");

			formData.append("file", file);
			formData.append("name", values.name || file.name);
			formData.append("withEmail", "false");
			formData.append("listId", list.id.toString());
			formData.append("type", "csv");
			formData.append("configuration", JSON.stringify(foundFields));

			await createCsvEnrichment(formData).unwrap();
			handleSelect?.(null);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		const processFoundFields = async (fileContent?: string) => {
			if (!fileContent) {
				setFoundFields(undefined);

				return;
			}

			const res = await fetch(fileContent);

			const buffer = await res.arrayBuffer();

			const workbook = XLSX.read(buffer);
			const content = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);

			const firstLine = content.split("\r\n")[0].split("\n")[0].split("\r")[0];
			const fields = firstLine.includes(";") ? firstLine.split(";") : firstLine.split(",");

			setFoundFields(
				fields.map((field) => ({
					foundField: field,
					assignedField: defaultOptions.map((o) => o.id).includes(field) ? field : "",
				}))
			);
		};

		processFoundFields(fileContent);
	}, [fileContent]);

	const options = useMemo(() => {
		return defaultOptions.filter((a) => {
			if (a.id === "customField" || a.id === "") {
				return true;
			}

			if (foundFields?.map((m) => m.assignedField).includes(a.id)) {
				return false;
			}

			return true;
		});
	}, [foundFields]);

	return (
		<div className="">
			<div className="text-md mb-5">Sources</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.name?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "name");
					}}
					label={ts("name")}
					name={"name"}
					placeholder={ts("namePlaceholder")}
					value={currentFormState?.name || ""}
				/>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				{fileContent ? (
					<></>
				) : (
					<FileUploader
						handleChange={(file: File) => {
							if (!getValues("name")) {
								setValue("name", file.name, {
									shouldValidate: true,
									shouldDirty: true,
								});
							}

							setValue("file", URL.createObjectURL(file));
							setFileContent(URL.createObjectURL(file));
						}}
						name="file"
						types={["xlsx"]}
					/>
				)}
			</div>
			<div className="flex flex-col w-full mb-4">
				{foundFields ? (
					<div className="text-text text-left text-ssm font-semibold mt-4 mb-2">
						{ts("foundFields")}
					</div>
				) : (
					""
				)}
				<div className="w-full">
					<ul>
						{foundFields
							?.filter((a) => a.foundField)
							?.map((item, index) => {
								const selectedField = defaultOptions.find((o) => {
									return o.id === item.assignedField;
								});

								return (
									<li
										key={`list-index-${index}`}
										className="text-xs flex flex-row mb-2 justify-between items-center"
									>
										<div className="text-sm">{item.foundField}</div>
										<div className="w-[350px]">
											<Dropdown
												classNameButton={classNames(
													selectedField?.id === "" ? "text-gray-200" : ""
												)}
												data={options}
												defaultValue={selectedField || { title: "Please select", id: "" }}
												floating={true}
												handleSelect={(value?: AutoCompleteItem) => {
													if (value) {
														setFoundFields([
															...foundFields.slice(0, index),
															{
																foundField: item.foundField,
																assignedField: value?.id?.toString() || "",
															},
															...foundFields.slice(index + 1),
														]);
													}
												}}
											/>
										</div>
									</li>
								);
							})}
					</ul>
				</div>
			</div>

			<div className="mb-2">
				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</div>
			<div className="flex gap-4 w-full justify-end">
				<div className="flex max-w-[100px] w-full">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						title="cancel"
						onClick={() => {
							handleSelect?.(null);
							setFileContent(undefined);
							reset({
								name: "",
							});
						}}
					/>
				</div>
				<div className="flex max-w-[150px] w-full">
					<Button
						disabled={!allowUpload || !isDirty}
						isLoading={isLoading}
						title={csvEnrichment?.id ? "Save" : "Upload"}
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</div>
		</div>
	);
};
