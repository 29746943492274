import { FunctionComponent, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import upIcon from "@assets/icons/chevron_up.svg";
import downIcon from "@assets/icons/chevron_down.svg";
import closeIcon from "@assets/icons/close.svg";

import {
	useCreatePromptMutation,
	useDeleteOpenAiPromptMutation,
	useGetOpenAiForScrapeQuery,
	useUpdateOpenAiMutation,
	useUpdatePromptMutation,
} from "@/pages/Private/redux/openAi/openAi.api";
import { LoadingOverlay } from "@/components";

import { Button } from "@/components/Button/Button";

import { TextArea } from "@/components/TextArea/TextArea";

import { AiEnrichmentTarget, PromptRole } from "@/enum/ai-enrichment.enum";

import { SelectField, SelectFieldValue } from "@/components/SelectField/SelectField";

import { classNames } from "@/utils/classNames";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { OpenAiPrompt } from "../schema/openAiPrompt";
import { OpenAi } from "../schema/openAi";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OpenAiPromptTable: FunctionComponent = () => {
	const { id = 0 } = useParams();
	const [updatePrompt] = useUpdatePromptMutation();
	const [createPrompt] = useCreatePromptMutation();
	const [deletePrompt] = useDeleteOpenAiPromptMutation();
	const [updateOpenAi] = useUpdateOpenAiMutation();
	const [aiEnrichment, setAiEnrichment] = useState<OpenAi>();
	const [toBeDeletedPrompts, setToBeDeletedPrompts] = useState<OpenAiPrompt[]>([]);
	const [jsonResponse, setJsonResponse] = useState<boolean>(false);

	const { data: openAiData, isLoading } = useGetOpenAiForScrapeQuery({
		id: +id,
		page: 1,
		limit: 1000,
	});

	useEffect(() => {
		if (!isLoading && openAiData) {
			setAiEnrichment(openAiData);
			if (openAiData.jsonResponse) {
				setJsonResponse(openAiData.jsonResponse);
			}
		}
	}, [isLoading, openAiData]);

	useEffect(() => {
		if (aiEnrichment) {
			setAiEnrichment({ ...aiEnrichment, jsonResponse: jsonResponse });
		}
	}, [aiEnrichment, jsonResponse]);

	const handlePromptChange = (prompt: string, index: number) => {
		if (aiEnrichment) {
			const newPrompts = [...aiEnrichment?.prompts];

			const updatedPrompt = { ...newPrompts[index], prompt: prompt };

			// Replace the object at the 'index' position with the new object
			newPrompts[index] = updatedPrompt;
			setAiEnrichment({ ...aiEnrichment, prompts: newPrompts });
		}
	};

	const addAdditionalEmptyPrompt = () => {
		if (aiEnrichment) {
			const newPrompts = [...aiEnrichment?.prompts];

			newPrompts.push({
				prompt: "",
				promptRole: PromptRole.HUMAN,
				order: aiEnrichment?.prompts.length + 1,
				aiEnrichmentId: aiEnrichment.id,
			});

			setAiEnrichment({ ...aiEnrichment, prompts: newPrompts });
		}
	};

	const selectPromptRoleForCurrentPrompt = (role: SelectFieldValue, index: number) => {
		if (aiEnrichment) {
			const newPrompts = [...aiEnrichment?.prompts];

			const updatedPrompt = {
				...newPrompts[index],
				promptRole: role as PromptRole,
			};

			// Replace the object at the 'index' position with the new object
			newPrompts[index] = updatedPrompt;
			setAiEnrichment({ ...aiEnrichment, prompts: newPrompts });
		}
	};

	const handlePutDown = (index: number) => {
		if (aiEnrichment) {
			const newPrompts = [...aiEnrichment?.prompts];

			const temp = newPrompts[index];

			newPrompts[index] = newPrompts[index + 1];
			newPrompts[index + 1] = temp;

			setAiEnrichment({ ...aiEnrichment, prompts: newPrompts });
		}
	};

	const handlePutUp = (index: number) => {
		if (aiEnrichment) {
			const newPrompts = [...aiEnrichment?.prompts];

			const temp = newPrompts[index];

			newPrompts[index] = newPrompts[index - 1];
			newPrompts[index - 1] = temp;

			setAiEnrichment({ ...aiEnrichment, prompts: newPrompts });
		}
	};

	const handleRemovePrompt = (index: number) => {
		if (aiEnrichment) {
			if (aiEnrichment.prompts[index].id) {
				if (toBeDeletedPrompts.includes(aiEnrichment.prompts[index])) {
					const newPrompts = [...toBeDeletedPrompts];

					newPrompts.splice(newPrompts.indexOf(aiEnrichment.prompts[index]), 1);

					setToBeDeletedPrompts(newPrompts);
				} else {
					setToBeDeletedPrompts([...toBeDeletedPrompts, aiEnrichment.prompts[index]]);
				}
			} else {
				const newPrompts = [...aiEnrichment?.prompts];

				newPrompts.splice(index, 1);

				setAiEnrichment({ ...aiEnrichment, prompts: newPrompts });
			}
		}
	};

	const handleSaveAllPrompts = async () => {
		if (aiEnrichment) {
			updateOpenAi({
				id: aiEnrichment.id,
				name: aiEnrichment.name,
				aiEnrichmentTarget: aiEnrichment.aiEnrichmentTarget,
				prompts: aiEnrichment.prompts,
				jsonResponse: aiEnrichment.jsonResponse,
			});
			const newPrompts = [...aiEnrichment?.prompts];

			for (const index in newPrompts) {
				const prompt = { ...newPrompts[index] };

				if (prompt.id) {
					prompt.order = +index + 1;
					await updatePrompt({
						prompt: prompt.prompt,
						order: prompt.order,
						aiEnrichmentId: aiEnrichment.id,
						promptRole: prompt.promptRole,
						id: prompt.id,
					}).unwrap();
				} else {
					await createPrompt({
						prompt: prompt.prompt,
						order: prompt.order,
						promptRole: prompt.promptRole,
						aiEnrichmentId: aiEnrichment.id,
					}).unwrap();
				}
			}

			for (const prompt of toBeDeletedPrompts) {
				if (prompt.id) {
					await deletePrompt({ id: aiEnrichment.id, promptId: prompt.id }).unwrap();
				}
			}
		}
	};

	const renderVariableDescription = (aiEnrichmentTarget: AiEnrichmentTarget) => {
		switch (aiEnrichmentTarget) {
			case AiEnrichmentTarget.CONTACT:
				return (
					<code className="text-xs block">
						name, firstName, lastName, title, headline, email, linkedinUrl, seniority, gender,
						genderProbability, city, country, organization.websiteUrl, organization.name,
						organization.linkedinUrl, organization.foundedYear, organization.annualRevenue,
						organization.primaryDomain, organization.seoDescription, organization.shortDescription,
						organization.estimatedNumEmployees, organization.industry, organization.country,
						organization.city, organization.facebookUrl, organization.twitterUrl
					</code>
				);
			case AiEnrichmentTarget.ACCOUNT:
				return (
					<code className="text-xs block">
						websiteUrl, name, foundedYear, annualRevenue, primaryDomain, seoDescription,
						shortDescription, estimatedNumEmployees, industry, country, city, linkedinUrl,
						facebookUrl, twitterUrl
					</code>
				);
			default:
				return "";
		}
	};

	return !aiEnrichment || isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<h3 className="mb-3">{aiEnrichment.name}</h3>
				</div>
			</div>
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<div className="text-sm mb-2">You can use these values within the prompt.</div>
					{renderVariableDescription(aiEnrichment.aiEnrichmentTarget)}
					<div className="text-sm mt-2">
						Variables always need to be put into curly brackets.{" "}
						<b>
							Example: {"{"}
							organization.websiteUrl{"}"}{" "}
						</b>
					</div>
				</div>
				<div>
					<Button title="Save" onClick={handleSaveAllPrompts} />
				</div>
			</div>
			<div className="flex  w-full mb-8">
				<Checkbox
					className="p-1"
					isChecked={jsonResponse}
					onChange={() => setJsonResponse(!jsonResponse)}
				>
					JSON response
				</Checkbox>
			</div>
			<div className="w-full bg-white">
				{aiEnrichment.prompts.map((prompt, index) => {
					return (
						<div
							key={`prompt-id-${index}`}
							className={classNames(
								"flex items-start justify-between p-4 border-b border-gray-200",
								toBeDeletedPrompts.includes(prompt) ? "bg-gray-500" : ""
							)}
						>
							<div className="w-[80px] flex items-center justify-between mr-2">
								{aiEnrichment.prompts.length !== index + 1 ? (
									<a href="">
										{" "}
										<img
											alt=""
											src={downIcon}
											onClick={(e) => {
												e.preventDefault();
												handlePutDown(index);
											}}
										/>
									</a>
								) : (
									""
								)}
								{aiEnrichment.prompts.length > index && index !== 0 ? (
									<a href="">
										{" "}
										<img
											alt=""
											src={upIcon}
											onClick={(e) => {
												e.preventDefault();
												handlePutUp(index);
											}}
										/>
									</a>
								) : (
									""
								)}
							</div>
							<div className="w-full mr-2">
								<TextArea
									handleChange={(event) => {
										handlePromptChange(event.target.value, index);
									}}
									name={"prompt"}
									showError={false}
									value={prompt.prompt}
								/>
							</div>

							<div className="w-[250px]">
								<SelectField
									handleSelect={(role) => {
										selectPromptRoleForCurrentPrompt(role, index);
									}}
									options={[
										{
											value: PromptRole.GPTSYSTEM,
											title: PromptRole.GPTSYSTEM,
										},
										{
											value: PromptRole.HUMAN,
											title: PromptRole.HUMAN,
										},
										{
											value: PromptRole.AI,
											title: PromptRole.AI,
										},
									]}
									selectedOption={prompt.promptRole}
								></SelectField>
							</div>
							<div className="w-[50px] h-[50px] px-2 py-2">
								{aiEnrichment.prompts.length > 1 ? (
									<a href="">
										{" "}
										<img
											alt=""
											src={closeIcon}
											onClick={(e) => {
												e.preventDefault();
												handleRemovePrompt(index);
											}}
										/>
									</a>
								) : (
									""
								)}
							</div>
						</div>
					);
				})}
				<Button title="Add new prompt" onClick={addAdditionalEmptyPrompt} />
			</div>
		</div>
	);
};
