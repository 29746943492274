import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	Companies,
	CompaniesResponse,
	CompaniesResponseSchema,
	CompaniesSchema,
	CreateCompanies,
} from "../../pages/Companies/schema/companies";

export const companiesApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getCompanies: builder.query<CompaniesResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "company",
				params: {
					...(params && { ...params }),
				},
				responseSchema: CompaniesResponseSchema,
			}),
			providesTags: ["Companies"],
		}),
		getCompany: builder.query<Companies, number>({
			query: (id: number) => ({
				url: `company/${id}`,
				params: {
					id,
				},
				responseSchema: CompaniesSchema,
			}),
			providesTags: ["Companies"],
		}),
		createCompanies: builder.mutation<Partial<Companies>, CreateCompanies>({
			query: (body) => ({
				url: `company`,
				method: "POST",
				body,
				responseSchema: CompaniesSchema,
			}),
			invalidatesTags: ["Companies"],
		}),
		updateCompanies: builder.mutation<Partial<Companies>, Partial<Companies>>({
			query: ({ id, ...body }) => ({
				url: `company/${id}`,
				method: "PATCH",
				body,
				responseSchema: CompaniesSchema,
			}),
			invalidatesTags: ["Companies"],
		}),
		deleteCompanies: builder.mutation<Partial<Companies>, number>({
			query: (id) => ({
				url: `company/${id}`,
				method: "DELETE",
				responseSchema: CompaniesSchema,
			}),
			invalidatesTags: ["Companies"],
		}),
	}),
});

export const {
	useGetCompaniesQuery,
	useGetCompanyQuery,
	useUpdateCompaniesMutation,
	useDeleteCompaniesMutation,
	useCreateCompaniesMutation,
} = companiesApi;
export const accountApiReducer = companiesApi.reducer;
export const accountApiMiddleware = companiesApi.middleware;
