import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import { LiEventScrapeStatus } from "@/enum/scrape-enum";

import { PeopleResponseSchema } from "../../pages/Apollo/schema/get-people";
import {
	CreateLiEvent,
	LiEvent,
	LiEventResponse,
	LiEventResponseSchema,
	LiEventSchema,
} from "../../pages/LiEvent/schema/liEvent";
import {
	LiEventEntrySchema,
	LiEventJob,
	LiEventJobsResponse,
	LiEventJobsResponseSchema,
} from "../../pages/LiEvent/schema/liEventEntry";
import { LiEventEntryAttendeeResponse } from "../../pages/LiEvent/schema/liEventEntryAttendee";

export const liEventApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getLiEvents: builder.query<LiEventResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "liEvent",
				params: {
					...(params && { ...params }),
				},
				responseSchema: LiEventResponseSchema,
			}),
			providesTags: ["LiEvent"],
		}),
		getLiEventForScrape: builder.query<
			LiEventJobsResponse,
			RequestPaginationParams & { id: number } & { filter: LiEventScrapeStatus[] }
		>({
			query: (params) => ({
				url: `liEvent/${params.id}/events`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: LiEventJobsResponseSchema,
			}),
			providesTags: ["LiEvent"],
		}),
		updateLiEventEntry: builder.mutation<Partial<LiEventJob>, Partial<LiEventJob>>({
			query: ({ id, ...body }) => ({
				url: `liEvent/event/${id}`,
				method: "PATCH",
				body,
				responseSchema: LiEventEntrySchema,
			}),
			invalidatesTags: ["LiEvent"],
		}),
		getLiEventAttendees: builder.query<
			LiEventEntryAttendeeResponse,
			RequestPaginationParams & { id: number }
		>({
			query: (params) => ({
				url: `liEvent/${params.id}/attendees`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: PeopleResponseSchema,
			}),
			providesTags: ["People"],
		}),
		getLiEvent: builder.query<LiEvent, number>({
			query: (id: number) => ({
				url: `liEvent/${id}`,
				params: {
					id,
				},
				responseSchema: LiEventSchema,
			}),
			providesTags: ["LiEvent"],
		}),
		// getLiEventById: builder.query<SavedLiEventResponse, number>({
		// 	query: (id) => ({
		// 		url: `liEvent/${id}`,
		// 		responseSchema: SavedLiEventSchema,
		// 	}),
		// 	providesTags: [{ type: "LiEvents", id: "DETAIL" }],
		// }),
		triggerLiEvent: builder.mutation<void, number>({
			query: (id) => ({
				url: `liEvent/trigger/${id}`,
				method: "POST",
			}),
			invalidatesTags: ["LiEvent"],
		}),
		createLiEvent: builder.mutation<Partial<LiEvent>, CreateLiEvent>({
			query: (body) => ({
				url: `liEvent`,
				method: "POST",
				body,
				responseSchema: LiEventSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "LiEvent"];
				}

				return ["LiEvent"];
			},
		}),
		updateLiEvent: builder.mutation<Partial<LiEvent>, Partial<LiEvent>>({
			query: ({ id, ...body }) => ({
				url: `liEvent/${id}`,
				method: "PATCH",
				body,
				responseSchema: LiEventSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "LiEvent"];
				}

				return ["LiEvent"];
			},
		}),
		toggleLiEvent: builder.mutation<Partial<LiEvent>, Partial<LiEvent>>({
			query: ({ id, ...body }) => ({
				url: `liEvent/toggle/${id}`,
				method: "PATCH",
				body,
				responseSchema: LiEventSchema,
			}),
			invalidatesTags: ["LiEvent"],
		}),
		deleteLiEvent: builder.mutation<Partial<LiEvent>, { id: number; listId?: number }>({
			query: ({ id, listId }) => ({
				url: listId ? `liEvent/${id}/list/${listId}` : `liEvent/${id}`,
				method: "DELETE",
				responseSchema: LiEventSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.listId) {
					return [{ type: "List", id: arg.listId }, "LiEvent"];
				}

				return ["LiEvent"];
			},
		}),
	}),
});

export const {
	useGetLiEventsQuery,
	useLazyGetLiEventsQuery,
	useGetLiEventQuery,
	useGetLiEventForScrapeQuery,
	useLazyGetLiEventForScrapeQuery,
	useGetLiEventAttendeesQuery,
	useUpdateLiEventMutation,
	useToggleLiEventMutation,
	useDeleteLiEventMutation,
	useTriggerLiEventMutation,
	useCreateLiEventMutation,
	useUpdateLiEventEntryMutation,
} = liEventApi;
export const accountApiReducer = liEventApi.reducer;
export const accountApiMiddleware = liEventApi.middleware;
