import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";

import { faAdd, faChevronDown, faMegaphone } from "@fortawesome/pro-regular-svg-icons";

import { useTranslation } from "react-i18next";

import { Autocomplete, TextField } from "@mui/material";

import trashIcon from "@assets/icons/trashIcon.svg";

import { Button } from "@/components/Button/Button";
import { ButtonColor, ButtonSize } from "@/components/Button/types";
import { Icon, LoadingOverlay } from "@/components";

import { AutoComplete, AutoCompleteItem } from "@/components/AutoComplete/AutoComplete";

import {
	AutoCompleteItem as DropdownAutoCompleteItem,
	Dropdown,
} from "@/components/Dropdown/Dropdown";

import { FilterDropdown } from "@/components/FilterDropdown.tsx/FilterDropdown";

import { InputField } from "@/components/InputField/InputField";

import { InclusionFilterStatus } from "@/enum/list.enum";

import { useLazyGetFilterByPropertyQuery } from "@/pages/Private/redux/list/list.api";

import { classNames } from "@/utils/classNames";

import { Lemlist, List } from "../schema/list";
import { CreateCampaignRule } from "../schema/campaign-rule";
import {
	CampaignRuleFieldsGeneral,
	IFilter,
	IFilterType,
	INumericFilterType,
	ITextFilterType,
} from "../constans/general-filter";

import {
	ICurrentFilter,
	ICurrentFilterValue,
} from "../pages/ListEditFilter/interfaces/config-interface";

export interface AdvancedCampaignSettingsProps {
	list: List;
	lemlists: Lemlist[];
	setSearchLemlist: (value: string) => void;
	showCampaignRules: boolean;
	setShowCampaignRules: (value: boolean) => void;
	setCampaignRules: (value: CreateCampaignRule[]) => void;
	campaignRules: CreateCampaignRule[];
}

export interface CampaignRuleLine {
	conditions?: CampaignRuleLineItem[];
}

export interface CampaignRuleLineItem {
	field?: IFilter;
	filterType?: IFilterType;
	value?:
		| string
		| number
		| {
				name: string;
				id: number;
		  }[]
		| {
				title: string;
				id: string;
		  }[]
		| {
				name: string;
				id: number;
		  };
}

export const AdvancedCampaignSettings: FunctionComponent<AdvancedCampaignSettingsProps> = ({
	list,
	lemlists,
	setSearchLemlist,
	showCampaignRules,
	setCampaignRules,
	campaignRules,
}) => {
	const { t } = useTranslation();

	const [getFilterByValue] = useLazyGetFilterByPropertyQuery();

	const [inputValues, setInputValues] = useState<string>();

	const [isLoadingByPropertyName, setIsLoadingByPropertyName] = useState<string | null>(null);

	const [currentFilter, setCurrentFilter] = useState<ICurrentFilter>({});

	const [counter, setCounter] = useState<number>(0);

	const handleDelete = (index: number, conditionIndex?: number) => {
		if (conditionIndex) {
			setCampaignRules([
				...wrapInCampaignRules(campaignRules, index, {
					...campaignRules[index],
					...{
						config: {
							...campaignRules[index].config,
							conditions: [
								...campaignRules[index].config.conditions.slice(0, conditionIndex),
								...campaignRules[index].config.conditions.slice(conditionIndex + 1),
							],
						},
					},
				}),
			]);
		} else {
			setCampaignRules([...campaignRules.slice(0, index), ...campaignRules.slice(index + 1)]);
		}

		setCounter(counter + 1);
	};

	useEffect(() => {
		const fetchData = async () => {
			let currentFilterVaules: ICurrentFilter = { ...currentFilter };

			if (campaignRules) {
				for (const campaignRule of campaignRules) {
					for (const condition of campaignRule.config.conditions) {
						if (["dropdown", "dropdownCustom"].includes(condition.filterType)) {
							setIsLoadingByPropertyName(condition.field.name);

							const filterValues = await getFilterByValue(condition.field.name).unwrap();

							if (filterValues?.data) {
								currentFilterVaules = {
									...currentFilterVaules,
									[condition.field.name]: filterValues.data.map((rec) => {
										return {
											id: rec.value,
											title: rec.value,
										} as ICurrentFilterValue;
									}) as ICurrentFilterValue[],
								} as ICurrentFilter;
							}

							setIsLoadingByPropertyName(null);
						}
					}
				}
			}

			setCurrentFilter({
				...currentFilterVaules,
			});
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campaignRules]);

	const wrapInCampaignRules = (
		campaignRules: CreateCampaignRule[],
		index: number,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		child: any
	) => {
		return [
			...campaignRules.slice(0, index),
			{
				...campaignRules[index],
				...child,
			},
			...campaignRules.slice(index + 1),
		];
	};

	const wrapInCampaignRule = (
		campaignRules: CreateCampaignRule[],
		index: number,
		conditionIndex: number,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		child?: any
	) => {
		return {
			config: {
				...campaignRules[index].config,
				conditions: [
					...campaignRules[index].config.conditions.slice(0, conditionIndex),
					child,
					...campaignRules[index].config.conditions.slice(conditionIndex + 1),
				],
			},
		};
	};

	return (
		<div className={classNames(!showCampaignRules && "hidden")}>
			{campaignRules?.map((cR, index) => {
				return (
					<div
						key={`campaign-rule-${index}`}
						className="w-full border border-gray-200 rounded-xl mb-4"
					>
						<div className="h-[48px] p-3 bg-brand-white border-b border-b-gray-200 rounded-xl rounded-b-none">
							Campaign {index + 1}
						</div>
						<div className="p-3">
							{cR.config.conditions?.map(
								(condition: CampaignRuleLineItem, conditionIndex: number) => {
									return (
										<div
											key={`campaignRules-${index}-conditions-${conditionIndex}-${counter}`}
											className="min-h-[48px] flex items-start  mb-4"
										>
											<div className="w-[80px] flex items-center h-[48px] ">
												{conditionIndex === 0 ? "If" : "And"}
											</div>

											<div className="flex min-h-[48px] flex-row grow gap-2">
												<div className="w-1/3">
													<Dropdown
														classNameButton="!w-full"
														data={CampaignRuleFieldsGeneral.map((c) => {
															return {
																id: c.filterParam,
																title: t(`inbox.${c.name}`),
															} as DropdownAutoCompleteItem;
														})}
														floating={true}
														handleSelect={(value: DropdownAutoCompleteItem) => {
															const currentField = CampaignRuleFieldsGeneral.find(
																(f) => f.filterParam === value.id
															);

															if (
																campaignRules[index].config?.conditions[conditionIndex]?.field !==
																currentField
															) {
																setCampaignRules([
																	...wrapInCampaignRules(campaignRules, index, {
																		...campaignRules[index],
																		...wrapInCampaignRule(campaignRules, index, conditionIndex, {
																			...condition,
																			field: currentField,
																		}),
																	}),
																]);
															}
														}}
														title={condition?.field ? undefined : "Column"}
														value={
															condition?.field?.name
																? CampaignRuleFieldsGeneral.map((c) => {
																		return {
																			id: c.filterParam,
																			title: t(`inbox.${c.name}`),
																		} as DropdownAutoCompleteItem;
																  }).find((c) => c.id === condition.field?.filterParam)
																: undefined
														}
													/>
												</div>
												<div className="w-1/3">
													{condition?.field && (
														<FilterDropdown
															className="w-full"
															data={[
																{
																	id: "noFilter",
																	title: t(`filter.noFilter`),
																},
																...condition?.field?.filterByType.map((type: string) => {
																	return {
																		id: type,
																		title: t(`filter.${type}`),
																	};
																}),
															]}
															defaultValue={{
																id: "noFilter",
																title: t(`filter.noFilter`),
															}}
															floating={true}
															handleSelect={(value: DropdownAutoCompleteItem) => {
																setCampaignRules([
																	...wrapInCampaignRules(campaignRules, index, {
																		...campaignRules[index],
																		...wrapInCampaignRule(campaignRules, index, conditionIndex, {
																			...condition,
																			filterType: value.id as IFilterType,
																		}),
																	}),
																]);
															}}
															value={
																condition?.filterType
																	? {
																			id: condition?.filterType as string,
																			title: t(`filter.${condition?.filterType}`),
																	  }
																	: {
																			id: "noFilter",
																			title: t(`filter.noFilter`),
																	  }
															}
														/>
													)}
												</div>
												<div className="w-1/3  mb-[-12px]">
													{condition?.filterType &&
														[condition?.filterType].map((currentFilterType, inputIndex) => {
															if (ITextFilterType.some((type) => type === currentFilterType)) {
																return (
																	<div key={`input-${index}-${conditionIndex}-${inputIndex}`}>
																		<InputField
																			handleChange={function (
																				event: ChangeEvent<HTMLInputElement>
																			): void {
																				setCampaignRules([
																					...wrapInCampaignRules(campaignRules, index, {
																						...campaignRules[index],
																						...wrapInCampaignRule(
																							campaignRules,
																							index,
																							conditionIndex,
																							{
																								...condition,
																								value: event.target.value,
																							}
																						),
																					}),
																				]);
																			}}
																			name={condition?.field?.name || "filter"}
																			placeholder={t("filter.name")}
																			value={(condition?.value as string) || ""}
																		/>
																	</div>
																);
															}

															if (
																currentFilterType !== "between" &&
																INumericFilterType.some((type) => type === currentFilterType)
															) {
																return (
																	<div key={`ft-${index}`}>
																		<InputField
																			handleChange={function (
																				event: ChangeEvent<HTMLInputElement>
																			): void {
																				setCampaignRules([
																					...wrapInCampaignRules(campaignRules, index, {
																						...campaignRules[index],
																						...wrapInCampaignRule(
																							campaignRules,
																							index,
																							conditionIndex,
																							{
																								...condition,
																								value: event.target.value,
																							}
																						),
																					}),
																				]);
																			}}
																			name={condition?.field?.name || ""}
																			placeholder={t("filter.name")}
																			type="number"
																			value={(condition.value as number) || ""}
																		/>
																	</div>
																);
															}

															if (
																currentFilterType === "between" &&
																INumericFilterType.some((type) => type === currentFilterType)
															) {
																return (
																	<div
																		key={`ft-${index}`}
																		className="w-full flex flex-row gap-2 mb-[-3]"
																	>
																		<InputField
																			handleChange={function (
																				event: ChangeEvent<HTMLInputElement>
																			): void {
																				setCampaignRules([
																					...wrapInCampaignRules(campaignRules, index, {
																						...campaignRules[index],
																						...wrapInCampaignRule(
																							campaignRules,
																							index,
																							conditionIndex,
																							{
																								...condition,
																								value: `${event.target.value || ""},${
																									condition?.value?.toString()?.split(",")[1]
																								}`,
																							}
																						),
																					}),
																				]);
																			}}
																			name={`from_${condition?.field?.name || ""}`}
																			placeholder={t(`from_${condition?.field?.name || ""}`)}
																			type="number"
																			value={condition?.value?.toString()?.split(",")[0] || ""}
																		/>
																		<InputField
																			handleChange={function (
																				event: ChangeEvent<HTMLInputElement>
																			): void {
																				setCampaignRules([
																					...wrapInCampaignRules(campaignRules, index, {
																						...campaignRules[index],
																						...wrapInCampaignRule(
																							campaignRules,
																							index,
																							conditionIndex,
																							{
																								...condition,
																								value: `${
																									condition?.value?.toString().split(",")[0] || ""
																								},${event.target.value || ""}`,
																							}
																						),
																					}),
																				]);
																			}}
																			name={`till_${condition?.field?.name || ""}`}
																			placeholder={t(`till_${condition?.field?.name || ""}`)}
																			type="number"
																			value={condition?.value?.toString().split(",")[1] || ""}
																		/>
																	</div>
																);
															}

															if (
																currentFilterType === "dropdown" &&
																condition?.field?.name === "inclusionStatus"
															) {
																return (
																	<div key={`ft-${index}`}>
																		<FilterDropdown
																			className="w-full"
																			data={[
																				{
																					title: InclusionFilterStatus.ADDED,
																					id: InclusionFilterStatus.ADDED,
																				},
																				{
																					title: InclusionFilterStatus.EXCLUDED,
																					id: InclusionFilterStatus.EXCLUDED,
																				},
																				{
																					title: InclusionFilterStatus.MAYBE,
																					id: InclusionFilterStatus.MAYBE,
																				},
																				{
																					title: InclusionFilterStatus.BOTH,
																					id: InclusionFilterStatus.BOTH,
																				},
																			]}
																			floating={true}
																			handleSelect={(value: DropdownAutoCompleteItem) => {
																				if (value.id) {
																					setCampaignRules([
																						...wrapInCampaignRules(campaignRules, index, {
																							...campaignRules[index],
																							...wrapInCampaignRule(
																								campaignRules,
																								index,
																								conditionIndex,
																								{
																									...condition,
																									value: value.id,
																								}
																							),
																						}),
																					]);
																				}
																			}}
																		/>
																	</div>
																);
															} else if (currentFilterType === "dropdown") {
																if (!currentFilter[condition?.field?.name || ""]) {
																	return (
																		<>
																			<LoadingOverlay />
																		</>
																	);
																}

																return (
																	<Autocomplete
																		key={`drop-${condition?.field?.name}-${index}-${conditionIndex}`}
																		multiple
																		defaultValue={[]}
																		getOptionLabel={(option) => option.title}
																		isOptionEqualToValue={(option, currentValue) => {
																			if (currentValue.title === "" && currentValue.id === "") {
																				return true;
																			}

																			return option.id === currentValue.id;
																		}}
																		loading={isLoadingByPropertyName === condition?.field?.name}
																		loadingText="Loading..."
																		options={currentFilter[condition.field?.name || ""] || []}
																		renderInput={(params) => (
																			<TextField
																				sx={{
																					width: "100%",
																					minHeight: 48,
																					"& .MuiFilledInput-root": {
																						minHeight: 48,
																						marginBottom: 0,
																						borderRadius: 1.5,
																						paddingTop: "10px",
																						paddingBottom: "10px !important",
																						paddingLeft: 1.5,
																					},
																				}}
																				{...params}
																			/>
																		)}
																		size="small"
																		sx={{
																			width: "100%",
																			marginBottom: 0,
																		}}
																		value={
																			(condition?.value as {
																				title: string;
																				id: string;
																			}[]) || []
																		}
																		onChange={(event, value) => {
																			setCampaignRules([
																				...wrapInCampaignRules(campaignRules, index, {
																					...campaignRules[index],
																					...wrapInCampaignRule(
																						campaignRules,
																						index,
																						conditionIndex,
																						{
																							...condition,
																							value: value,
																						}
																					),
																				}),
																			]);
																		}}
																	/>
																);
															} else if (currentFilterType === "dropdownCustom") {
																if (!currentFilter[condition?.field?.name || ""]) {
																	return (
																		<>
																			<LoadingOverlay />
																		</>
																	);
																}

																return (
																	<Autocomplete
																		key={`drop-${condition?.field?.name}-${index}-${conditionIndex}`}
																		multiple
																		clearOnBlur={true}
																		getOptionLabel={(option) => option.title}
																		inputValue={inputValues || ""}
																		isOptionEqualToValue={(option, currentValue) => {
																			if (currentValue.title === "" && currentValue.id === "") {
																				return true;
																			}

																			return option.id === currentValue.id;
																		}}
																		loading={
																			isLoadingByPropertyName === condition?.field?.name || false
																		}
																		loadingText="Loading..."
																		options={
																			[
																				{ id: inputValues || "", title: inputValues || "" },
																				...currentFilter[condition?.field?.name || ""],
																			]?.filter((r) => r.id !== "") || []
																		}
																		popupIcon={
																			<Icon
																				className="text-gray-500 h-[24px] w-[15px]"
																				icon={faChevronDown}
																			/>
																		}
																		renderInput={(params) => (
																			<TextField
																				sx={{
																					width: "100%",
																					minHeight: 48,
																					"& .MuiFilledInput-root": {
																						minHeight: 48,
																						marginBottom: 0,
																						borderRadius: 1.5,
																						paddingTop: "10px",
																						paddingBottom: "10px !important",
																						paddingLeft: 1.5,
																					},
																				}}
																				{...params}
																			/>
																		)}
																		size="small"
																		sx={{
																			width: "100%",
																		}}
																		value={
																			(condition?.value as {
																				title: string;
																				id: string;
																			}[]) || []
																		}
																		onChange={(event, value) => {
																			setCampaignRules([
																				...wrapInCampaignRules(campaignRules, index, {
																					...campaignRules[index],
																					...wrapInCampaignRule(
																						campaignRules,
																						index,
																						conditionIndex,
																						{
																							...condition,
																							value: value,
																						}
																					),
																				}),
																			]);

																			setInputValues("");
																		}}
																		onInputChange={(event, newValue, reason) => {
																			if (reason === "input") {
																				setInputValues(newValue);
																			}
																		}}
																	/>
																);
															}

															return <></>;
														})}
												</div>
											</div>
											{conditionIndex !== 0 ? (
												<button
													className="ml-2 p-3 border border-gray-200 hover:border-brand-light rounded-xl"
													onClick={() => handleDelete(index, conditionIndex)}
												>
													<img alt="delete" className="cursor-pointer" src={trashIcon} />
												</button>
											) : (
												<div className="h-[48px] w-[58px]" />
											)}
										</div>
									);
								}
							) || (
								<div key={`campaign-${index}`} className="h-[48px] flex items-center  mb-4">
									<div className="w-[80px]">If</div>

									<div className=""></div>
								</div>
							)}

							<div className="min-h-[48px] flex items-center mb-4">
								<div className="w-[80px]">Then</div>

								<div
									className="grow"
									onClick={() => {
										setSearchLemlist("");
									}}
								>
									<AutoComplete
										data={
											lemlists?.map((c) => {
												return { id: c.id, title: c.name } as AutoCompleteItem;
											}) || []
										}
										handleSearch={(value: string) => setSearchLemlist(value)}
										handleSelect={(value?: AutoCompleteItem) => {
											setCampaignRules([
												...campaignRules.slice(0, index),
												{
													...campaignRules[index],
													config: {
														...campaignRules[index].config,
														campaign: value,
													},
												},
												...campaignRules.slice(index + 1),
											]);
										}}
										icon={faMegaphone}
										selectedItem={cR.config.campaign?.title}
									/>
								</div>
							</div>
							<div className="flex flex-row gap-4 w-full mb-4">
								<div className="w-auto">
									<Button
										color={ButtonColor.ACTION_SECONDARY}
										image={<Icon className="mr-2" icon={faAdd} />}
										size={ButtonSize.ML}
										title={"Add campaign rules"}
										onClick={() =>
											setCampaignRules([
												...campaignRules.slice(0, index),
												{
													...campaignRules[index],
													config: {
														...campaignRules[index].config,
														conditions: [...campaignRules[index].config.conditions, {}],
													},
												},
												...campaignRules.slice(index + 1),
											])
										}
									/>
								</div>
								<button
									className="p-3 h-[42px] flex text-sm items-center text-status-error"
									onClick={() => handleDelete(index)}
								>
									<img alt="delete" className="cursor-pointer mr-2" src={trashIcon} /> Delete
									Campaign Rule
								</button>
							</div>
						</div>
					</div>
				);
			})}
			<div className="flex flex-col gap-4 w-full mb-4"></div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<div className="w-auto">
					<Button
						color={ButtonColor.BRAND}
						image={<Icon className="mr-2" icon={faAdd} />}
						size={ButtonSize.ML}
						title={"Add campaign rules"}
						onClick={() =>
							setCampaignRules([
								...campaignRules,
								{
									config: {
										conditions: [{}],
									},
									listId: list.id,
								},
							])
						}
					/>
				</div>
			</div>
		</div>
	);
};
