import { ChangeEvent, FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Card,
	InputAdornment,
	MenuItem,
	OutlinedInput,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { faTrashCan, faPenToSquare, faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";

import { useNavigate, useParams } from "react-router-dom";

import { faRightToBracket } from "@fortawesome/pro-regular-svg-icons";

import {
	useDeleteUserMutation,
	useGetUsersQuery,
	useLoginAsMutation,
} from "@/pages/Private/redux/admin/admin.api";
import {
	Icon,
	LoadingOverlay,
	Modal,
	TableColumn,
	TableComponent,
	Chip,
	Avatar,
	PermissionCheck,
} from "@/components";
import { User } from "@/pages/Public/pages/Login/schema/login";
import { useSearch } from "@/utils";
import { PermissionRoles, UserStatus } from "@/enum";
import { DEFAULT } from "@/constants";

import { authSelector, setAuth } from "@/pages/Public/redux/auth.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";

import { EditUserModal } from "./EditUserModal";

export const UsersTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`users.${key}`);

	const { id } = useParams();
	const dispatch = useAppDispatch();
	const userTokenObj = useAppSelector(authSelector);
	const navigate = useNavigate();
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedUser, setSelectedUser] = useState<User | null>(null);
	const [searchBy, setSearchBy] = useState<string>(DEFAULT);
	const [loginAsUser] = useLoginAsMutation();
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();

	const { data: users, isLoading: isUsersLoading } = useGetUsersQuery({
		page: page,
		limit,
		searchValue: query,
		fieldName: searchBy === DEFAULT ? undefined : searchBy,
		companyId: id ? +id : undefined,
	});
	const [deleteUser, { isLoading }] = useDeleteUserMutation();

	const loginAs = async (user: User) => {
		localStorage.setItem("initialUser", JSON.stringify(userTokenObj));

		const newUser = await loginAsUser(user.id).unwrap();

		dispatch(setAuth(newUser));
		navigate("/");
	};

	const columns: TableColumn<User>[] = useMemo(
		() => [
			{
				label: t("basics.name"),
				minWidth: 170,
				format: (row) => (
					<Stack alignItems="center" direction="row" spacing={1}>
						<Avatar firstName={row.firstName} lastName={row.lastName} />
						<Typography color="inherit" variant="subtitle2">
							{`${row.firstName} ${row.lastName}`}
						</Typography>
					</Stack>
				),
			},
			{
				label: t("basics.email"),
				minWidth: 170,
				format: (row) => <Typography variant="body2">{row.email}</Typography>,
			},
			{
				label: t("basics.role"),
				minWidth: 100,
				format: (row) =>
					row.roles.map((role, index) => (
						<Box key={index}>
							<Typography variant="body2">{role.name}</Typography>
						</Box>
					)),
			},
			{
				label: t("basics.status"),
				minWidth: 100,
				format: (row) => {
					const ownerState =
						row.status === UserStatus.ACTIVE
							? "success"
							: row.status === UserStatus.BLOCKED
							? "error"
							: row.status === UserStatus.INVITED
							? "warning"
							: "secondary";

					return <Chip color={ownerState} label={row.status.toLowerCase()} />;
				},
			},
			{
				align: "right",
				label: t("basics.actions"),
				minWidth: 50,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						{id ? (
							<PermissionCheck requiredPermissions={[PermissionRoles.GLOBAL_ADMIN]}>
								<Box
									sx={{
										color: "neutral.500",
										"&:hover": {
											cursor: "pointer",
											color: "primary.main",
										},
									}}
									onClick={() => loginAs(row)}
								>
									<Icon icon={faRightToBracket} size="lg" />
								</Box>
							</PermissionCheck>
						) : (
							<></>
						)}
						<Box
							sx={{
								color: "neutral.500",
								"&:hover": {
									cursor: "pointer",
									color: "primary.main",
								},
							}}
							onClick={() => handleEditUser(row)}
						>
							<Icon icon={faPenToSquare} size="lg" />
						</Box>
						<Box
							sx={{
								color: "neutral.500",
								"&:hover": {
									color: "primary.main",
									cursor: "pointer",
								},
							}}
							onClick={() => handleDeleteModal(row)}
						>
							<Icon icon={faTrashCan} size="lg" />
						</Box>
					</Stack>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[id, t]
	);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(0);
	};

	const handleEditUser = (row: User) => {
		setShowEditModal(true);
		setSelectedUser(row);
	};

	const handleDeleteModal = (row: User) => {
		setShowDeleteModal(true);
		setSelectedUser(row);
	};

	const handleDeleteUser = async () => {
		try {
			await deleteUser(selectedUser?.id ?? 0).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	const handleCloseEditModal = () => {
		setShowEditModal(false);
		setSelectedUser(null);
	};

	const searchByOptions = useMemo(
		() => [
			{
				value: DEFAULT,
				label: t("basics.value"),
			},
			{
				value: "name",
				label: t("basics.name"),
			},
			{
				value: "email",
				label: t("basics.email"),
			},
		],
		[t]
	);

	return !users && !isUsersLoading ? (
		<LoadingOverlay />
	) : (
		<Card>
			<Stack direction="row" gap={2} px={3} py={2}>
				<OutlinedInput
					fullWidth
					placeholder={t("basics.search", { something: ts("search") })}
					startAdornment={
						<InputAdornment position="start">
							<Icon icon={faMagnifyingGlass} />
						</InputAdornment>
					}
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
				/>
				<TextField
					select
					label={t("basics.label")}
					sx={{ width: "12%" }}
					value={searchBy}
					onChange={(e) => setSearchBy(e.target.value)}
				>
					{searchByOptions.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
			</Stack>
			<TableComponent
				columns={columns}
				data={users?.data || []}
				handleChangeLimit={handleChangeRowsPerPage}
				handleChangePage={(_: unknown, page: number) => setPage(page)}
				itemCount={users?.meta?.itemCount ?? 0}
				limit={limit}
				page={page}
			/>

			{showEditModal && (
				<EditUserModal
					companyId={id ? +id : undefined}
					handleClose={handleCloseEditModal}
					isVisible={showEditModal}
					user={selectedUser}
				/>
			)}

			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteUser}
					isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={t("modals.deleteUser.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedUser?.email}
						inputProps={{ readOnly: true }}
						label={t("basics.email")}
					/>
				</Modal>
			)}
		</Card>
	);
};
