import { FunctionComponent, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { faClock, faClose } from "@fortawesome/pro-regular-svg-icons";

import CheckIcon from "@assets/icons/list-builder/checkGreen.svg";

import CalendarIcon from "@assets/icons/list-builder/calendarIcon.svg";

import { ListProcessingStatus } from "@/enum/list.enum";
import { Icon } from "@/components";

interface ListProcessingStatusChipProps {
	processingStatus: ListProcessingStatus;
}

export const ListProcessingStatusChip: FunctionComponent<ListProcessingStatusChipProps> = ({
	processingStatus,
}) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`list.${key}`), [t]);

	switch (processingStatus) {
		case ListProcessingStatus.ERROR:
			return (
				<div className="px-2.5 py-1.5 bg-status-error-light items-center rounded-xl text-status-error text-ssm flex flex-row">
					<Icon className="mr-1.5 text-status-error" icon={faClose} />
					{ts(`status.${processingStatus}`)}
				</div>
			);
		case ListProcessingStatus.PROCESSING:
		case ListProcessingStatus.API_WAITING:
			return (
				<div className="px-2.5 py-1.5 bg-status-running-light items-center rounded-xl text-status-running text-ssm flex flex-row">
					<Icon className="mr-1.5 text-status-running" icon={faClock} />
					{ts(`status.${processingStatus}`)}
				</div>
			);
		case ListProcessingStatus.DONE:
			return (
				<div className="px-2.5 py-1.5 bg-status-success-light rounded-xl text-status-success text-ssm flex flex-row">
					<img alt="checkIcon" className="mr-1" src={CheckIcon} />
					{ts(`status.${processingStatus}`)}
				</div>
			);
		case ListProcessingStatus.IDLE:
			return (
				<div className="px-2.5 py-1.5 bg-inactive-item items-center rounded-xl text-gray-700 text-ssm flex flex-row">
					<Icon className="mr-1 text-gray-700" icon={faClock} />
					{ts(`status.${processingStatus}`)}
				</div>
			);
		case ListProcessingStatus.WAITING:
			return (
				<div className="px-2.5 py-1.5 bg-status-info-light rounded-xl text-status-info text-ssm flex flex-row">
					<img alt="CalendarIcon" className="mr-1" src={CalendarIcon} />
					{ts(`status.${processingStatus}`)}
				</div>
			);
		case ListProcessingStatus.DESKTOP_APP:
			return (
				<div className="px-2.5 py-1.5 bg-status-info-light items-center rounded-xl text-status-info text-ssm flex flex-row">
					<Icon className="mr-1.5 text-gray-700" icon={faClock} />
					{ts(`status.${processingStatus}`)}
				</div>
			);
		default:
			return <></>;
	}

	return <></>;
};
