import { ChangeEvent, FunctionComponent, useCallback, useState } from "react";

import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import { Tab } from "@mui/material";

import { BackLink } from "@/components/BackLink/BackLink";

import { useGetMapQuery } from "@/pages/Private/redux/map/map.api";

import { PermissionRoles } from "@/enum";
import { useHasPermissions } from "@/utils/useHasPermissions";

import { PermissionCheck } from "@/components";

import { MapBusinessTable } from "../../components/MapBusinessTable";

import { RequestsTable } from "../../../Request/components/RequestsTable";

enum Tabs {
	RESULTS = "RESULTS",
	COSTS = "COSTS",
}

export const MapDetail: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`map.${key}`);
	const { id = 0 } = useParams();

	const { data } = useGetMapQuery(+id);

	const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.RESULTS);

	const { hasPermissions } = useHasPermissions();

	let tabs = [
		{ label: ts("tabs.results"), value: Tabs.RESULTS, permission: PermissionRoles.JOB },
		{ label: ts("tabs.costs"), value: Tabs.COSTS, permission: PermissionRoles.GLOBAL_ADMIN },
	];

	tabs = tabs.filter((tab) => hasPermissions([tab.permission]));

	const handleTabsChange = useCallback((_: ChangeEvent<{}>, value: Tabs): void => {
		setCurrentTab(value);
	}, []);

	return (
		<TabContext value={currentTab}>
			<TabList aria-label="Account tabs" sx={{ mb: 4 }} onChange={handleTabsChange}>
				{tabs.map((tab, index) => (
					<Tab key={index} label={tab.label} value={tab.value} />
				))}
			</TabList>

			<TabPanel sx={{ p: 0 }} value={Tabs.RESULTS}>
				<div className="sm:block flex flex-col items-start justify-between mb-6">
					<BackLink />
					<div className="flex items-end justify-between w-full mb-8">
						<div className="flex-grow">
							<h3 className="mb-3">
								{data?.name} ({data?.status})
							</h3>
							<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">Query: {data?.query}</p>
						</div>
					</div>

					{<MapBusinessTable />}
				</div>
			</TabPanel>
			<PermissionCheck requiredPermissions={[PermissionRoles.GLOBAL_ADMIN]}>
				<>
					<TabPanel sx={{ p: 0 }} value={Tabs.COSTS}>
						<RequestsTable id={+id} type={"map"} />
					</TabPanel>
				</>
			</PermissionCheck>
		</TabContext>
	);
};
