import { createSlice } from "@reduxjs/toolkit";

import { JobState } from "@/types/job";

import { RootState } from "@/redux";

import { jobApi } from "./job.api";

const initialState: JobState = {
	jobs: {
		data: [],
		meta: {
			page: 1,
			limit: 15,
			itemCount: 15,
			pageCount: 1,
			hasPreviousPage: false,
			hasNextPage: false,
		},
	},
};

export const jobSlice = createSlice({
	name: "job",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(jobApi.endpoints.getJobs.matchFulfilled, (state, { payload }) => {
			state.jobs = payload;
		});
		builder.addMatcher(jobApi.endpoints.toggleJob.matchFulfilled, (state, { payload }) => {
			if (!payload.id) {
				return;
			}

			const index = state.jobs.data.findIndex((list) => list.id === payload.id);

			if (index === -1) {
				return;
			}

			if (payload.id) {
				state.jobs.data[index] = {
					...state.jobs.data[index],
					...payload,
				};
			}
		});
	},
});

export const {} = jobSlice.actions;
export const jobReducer = jobSlice.reducer;
export const jobSelector = (state: RootState) => state[jobSlice.name];
