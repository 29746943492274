import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { MapOverview } from "./pages/MapOverview/MapOverview";
import { MapDetail } from "./pages/MapDetail/MapDetail";

export const Map: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<MapOverview />} path="/" />
			<Route element={<MapDetail />} path="/:id" />
		</Routes>
	);
};
