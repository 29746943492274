import { api } from "@/redux/api";

import {
	CreditsResponse,
	CreditsResponseSchema,
	UsageResponse,
	UsageResponseSchema,
} from "../../pages/Usage/schema/usage";

export const usageApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getUsage: builder.query<UsageResponse, void>({
			query: () => ({
				url: "list/usage",
				responseSchema: UsageResponseSchema,
			}),
			providesTags: ["Usage"],
		}),
		getCredits: builder.query<CreditsResponse, void>({
			query: () => ({
				url: "usage",
				responseSchema: CreditsResponseSchema,
			}),
			providesTags: ["Credits"],
		}),
	}),
});

export const { useGetUsageQuery, useGetCreditsQuery } = usageApi;
export const accountApiReducer = usageApi.reducer;
export const accountApiMiddleware = usageApi.middleware;
