import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { ListOverview } from "./pages/ListOverview/ListOverview";
import { ListEdit } from "./pages/ListEdit/ListEdit";
import { ListDetail } from "./pages/ListDetail/ListDetail";
import { Unassigned } from "./pages/Unassigned/Unassigned";
import { ListCreate } from "./pages/ListCreate/ListCreate";
import { ListEditDetails } from "./pages/ListEditDetails/ListEditDetails";
import { ListEditSource } from "./pages/ListEditSource/ListEditSource";
import { ListEditFilter } from "./pages/ListEditFilter/ListEditFilter";
import { ListEditScore } from "./pages/ListEditScore/ListEditScore";
import { ListEditSetting } from "./pages/ListEditSetting/ListEditSetting";

export const List: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<ListOverview />} path="/" />
			<Route element={<ListEditDetails />} path="/create/details/:id" />
			<Route element={<ListEditFilter />} path="/create/filter/:id" />
			<Route element={<ListEditSource />} path="/create/source/:id" />
			<Route element={<ListEditScore />} path="/create/score/:id" />
			<Route element={<ListEditSetting />} path="/create/settings/:id" />

			<Route element={<ListCreate />} path="/create" />
			<Route element={<ListEdit />} path="/:id" />
			<Route element={<ListDetail />} path="/settings/:id" />
			<Route element={<Unassigned />} path="/detail/:id" />
		</Routes>
	);
};
