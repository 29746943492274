import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Stack, TextField, Typography } from "@mui/material";

import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import {
	useDeleteOpenAiMutation,
	useGetOpenAisQuery,
} from "@/pages/Private/redux/openAi/openAi.api";

import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import { OpenAi } from "../schema/openAi";

export const OpenAiTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`openAi.${key}`), [t]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedOpenAi, setSelectedOpenAi] = useState<OpenAi | null>(null);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [deleteOpenAi] = useDeleteOpenAiMutation();

	const { data, isLoading } = useGetOpenAisQuery({
		page,
		limit,
		searchValue: query,
	});

	const columns: TableColumn<OpenAi>[] = useMemo(
		() => [
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Link href={`/app/openai/${row?.id}`}>
								<Typography color="inherit" mb={0} variant="body2">
									{row?.name}
								</Typography>
							</Link>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("aiEnrichmentTarget"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{ts(`aiEnrichmentTargets.${row?.aiEnrichmentTarget.toLowerCase()}`)}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<PermissionCheck requiredPermissions={[PermissionRoles.LI_EVENT]}>
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										color: "primary.main",
										cursor: "pointer",
									},
								}}
								onClick={() => handleDeleteModal(row)}
							>
								<Icon icon={faTrashCan} size="xl" />
							</Box>
						</PermissionCheck>
					</Stack>
				),
			},
		],
		[ts]
	);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	const handleDeleteModal = (row: OpenAi) => {
		setShowDeleteModal(true);
		setSelectedOpenAi(row);
	};

	const handleDeleteOpenAi = async () => {
		try {
			await deleteOpenAi(selectedOpenAi?.id ?? 0).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	return !data ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteOpenAi}
					isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedOpenAi?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}
		</div>
	);
};
