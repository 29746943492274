import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";

import { FileUploader } from "react-drag-drop-files";

import { Modal } from "@/components";

import { useCreateCsvEnrichmentMutation } from "@/pages/Private/redux/csvEnrichment/csvEnrichment.api";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";

import { CreateCsvEnrichment, CreateCsvEnrichmentSchema } from "../schema/csvEnrichment";

interface createCsvEnrichmentModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateCsvEnrichmentModal: FunctionComponent<createCsvEnrichmentModalProps> = ({
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`csvEnrichment.${key}`);
	const [createCsvEnrichment, { isLoading }] = useCreateCsvEnrichmentMutation();
	const [fileContent, setFileContent] = useState<string>();
	const [foundFields, setFoundFields] = useState<{ foundField: string; assignedField: string }[]>();
	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CreateCsvEnrichment>({
		defaultValues: {
			name: "",
			file: "",
			withEmail: false,
		},
		resolver: zodResolver(CreateCsvEnrichmentSchema),
	});

	const onSubmit = async (values: CreateCsvEnrichment) => {
		try {
			const { file: fileRaw, name, withEmail } = values;

			const formData = new FormData();
			const res: Response = await fetch(fileRaw);
			const blob: Blob = await res.blob();
			const file = new File([blob], "file.csv");

			formData.append("file", file);
			formData.append("name", name || file.name);
			formData.append("type", "csv");
			formData.append("withEmail", withEmail.toString());
			await createCsvEnrichment(formData).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		const processFoundFields = async (fileContent?: string) => {
			if (!fileContent) {
				setFoundFields(undefined);

				return;
			}

			const res = await fetch(fileContent);
			const resText = await res.text();
			const firstLine = resText.split("\r\n")[0].split("\n")[0].split("\r")[0];
			const fields = firstLine.includes(";") ? firstLine.split(";") : firstLine.split(",");

			setFoundFields(fields.map((field) => ({ foundField: field, assignedField: "" })));
		};

		processFoundFields(fileContent);
	}, [fileContent]);

	// const renderPersonalEnrichment = () => {
	// 	const options = personalEnrichments.filter((pE) => pE.every((i) => foundFields?.includes(i)));

	// 	if (options.length === 0) {
	// 		return renderPersonByTitle();
	// 	}

	// 	return (
	// 		<div>
	// 			<div className="text-sm mt-4 font-bold mb-2">
	// 				Enough data for enrichments of individuals. The following methods can be applied:
	// 			</div>
	// 			<ul>
	// 				{options.map((option, index) => {
	// 					return (
	// 						<li key={`renderPersonalEnrichment-${index}`} className="text-xs">
	// 							{option.join(" & ")}
	// 						</li>
	// 					);
	// 				})}
	// 			</ul>
	// 		</div>
	// 	);
	// };

	// const renderPersonByTitle = () => {
	// 	const options = personByTitle.filter((pE) => pE.every((i) => foundFields?.includes(i)));

	// 	if (options.length === 0) {
	// 		return renderCompanyList();
	// 	}

	// 	return (
	// 		<div>
	// 			<div className="text-sm mt-2 mb-1">{ts("personByTitle")}</div>
	// 			<ul>
	// 				{options.map((option, index) => {
	// 					return (
	// 						<li key={`renderPersonByTitle-${index}`} className="text-xs">
	// 							{option.join(" & ")}
	// 						</li>
	// 					);
	// 				})}
	// 			</ul>
	// 		</div>
	// 	);
	// };

	// const renderCompanyList = () => {
	// 	const options = companyList.filter((pE) => pE.every((i) => foundFields?.includes(i)));

	// 	if (options.length === 0) {
	// 		return <></>;
	// 	}

	// 	return (
	// 		<div>
	// 			<div className="text-sm mt-2 mb-1">{ts("companyEnrichment")}</div>
	// 			<ul>
	// 				{options.map((option, index) => {
	// 					return (
	// 						<li key={`renderPersonByTitle-${index}`} className="text-xs">
	// 							{option.join(" ")}
	// 						</li>
	// 					);
	// 				})}
	// 			</ul>
	// 		</div>
	// 	);
	// };

	console.log(getValues("file"), foundFields);

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<div className="flex flex-col">
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>
				<Checkbox
					className="mb-4"
					isChecked={getValues("withEmail")}
					name="withEmail"
					onChange={() => {
						setValue("withEmail", !getValues("withEmail"), {
							shouldValidate: true,
						});
					}}
				>
					{ts("withEmail")}
				</Checkbox>
				<div className="mt-4">
					<FileUploader
						handleChange={(file: Blob | MediaSource) => {
							setValue("file", URL.createObjectURL(file));
							setFileContent(URL.createObjectURL(file));
						}}
						name="file"
						types={["csv"]}
					/>
				</div>
				{foundFields ? <div className="text-sm mt-4 font-bold mb-2">{ts("foundFields")}</div> : ""}
				<ul>
					{foundFields?.map((item, index) => {
						return (
							<li key={`list-index-${index}`} className="text-xs">
								{item.foundField}{" "}
								<Dropdown
									data={[{ title: "All", id: "" }]}
									defaultValue={{ title: "All", id: "" }}
									floating={true}
									handleSelect={(value?: AutoCompleteItem) => {
										setFoundFields([
											...foundFields.slice(0, index),
											{ foundField: item.foundField, assignedField: value?.title || "" },
											...foundFields.slice(index + 1),
										]);
									}}
									title={ts("status")}
									value={{ title: item.assignedField, id: 1 }}
								/>
							</li>
						);
					})}
				</ul>

				{/* <div>{renderPersonalEnrichment()}</div> */}

				{/* <div>{getValues("file") ? <>123{getValues("file").src.substring(100)}123</> : ""}</div> */}
				{/* {getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null} */}
			</div>
		</Modal>
	);
};
