import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Stack, TextField, Typography } from "@mui/material";

import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import {
	useDeleteBlacklistMutation,
	useGetBlacklistsQuery,
} from "@/pages/Private/redux/blacklist/blacklist.api";

import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import { Blacklist } from "../schema/blacklist";
import { BlacklistOverviewProps } from "../pages/BlacklistOverview/BlacklistOverview";

export const BlacklistTable: FunctionComponent<BlacklistOverviewProps> = ({ isGlobal }) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`blacklist.${key}`), [t]);

	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedBlacklist, setSelectedBlacklist] = useState<Blacklist | null>(null);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [deleteBlacklist, { isLoading }] = useDeleteBlacklistMutation();

	const { data, isFetching } = useGetBlacklistsQuery({
		page,
		limit,
		searchValue: query,
		isGlobal,
	});

	const columns: TableColumn<Blacklist>[] = useMemo(
		() => [
			{
				label: ts("type"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.type}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("domain"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Link href={`/app/blacklist/${row?.id}`}>
								<Typography color="inherit" mb={0} variant="body2">
									{row?.domain}
								</Typography>
							</Link>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("email"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.email}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("linkedinUrl"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.linkedinUrl}
							</Typography>
						</Stack>
					</Stack>
				),
			},

			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<PermissionCheck requiredPermissions={[PermissionRoles.BLACKLIST]}>
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										color: "primary.main",
										cursor: "pointer",
									},
								}}
								onClick={() => handleDeleteModal(row)}
							>
								<Icon icon={faTrashCan} size="xl" />
							</Box>
						</PermissionCheck>
					</Stack>
				),
			},
		],
		[ts]
	);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	// const handleEditBlacklist = (row: Blacklist) => {
	// 	setShowEditModal(true);
	// 	setSelectedBlacklist(row);
	// };

	const handleDeleteModal = (row: Blacklist) => {
		setShowDeleteModal(true);
		setSelectedBlacklist(row);
	};

	const handleDeleteBlacklist = async () => {
		try {
			await deleteBlacklist(selectedBlacklist?.id ?? 0).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	// const handleCloseEditModal = () => {
	// 	setShowEditModal(false);
	// 	setSelectedBlacklist(null);
	// };

	return isFetching ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data?.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data?.meta?.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteBlacklist}
					isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={
							selectedBlacklist?.domain ||
							selectedBlacklist?.email ||
							selectedBlacklist?.linkedinUrl
						}
						inputProps={{ readOnly: true }}
						label={ts("delete.confirmation")}
					/>
				</Modal>
			)}
		</div>
	);
};
