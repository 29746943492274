import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import { Cron, CronResponse, CronResponseSchema } from "../../pages/Cron/schema/cron";

export const cronApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getCrons: builder.query<CronResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "cron",
				params: {
					...(params && { ...params }),
				},
				responseSchema: CronResponseSchema,
			}),
			providesTags: ["Cron"],
		}),
		promote: builder.mutation<Cron, { id: number }>({
			query: ({ id }) => ({
				url: `cron/${id}`,
				method: "PATCH",
			}),
		}),
		delete: builder.mutation<Cron, { id: number }>({
			query: ({ id }) => ({
				url: `cron/${id}`,
				method: "DELETE",
			}),
		}),
	}),
});

export const { useGetCronsQuery, useLazyGetCronsQuery, useDeleteMutation, usePromoteMutation } =
	cronApi;
export const accountApiReducer = cronApi.reducer;
export const accountApiMiddleware = cronApi.middleware;
