import { AssignStatus } from "@/enum/list.enum";

import { SvgIcon } from "@/components/Icon/SvgIcon";
import { ContextMenu, MenuItem } from "@/components/ContextMenu/ContextMenu";
import { ReactComponent as Tick } from "@assets/icons/inbox/tick.svg";
import { ReactComponent as Reassign } from "@assets/icons/inbox/reassign.svg";
import { ReactComponent as ThreeDots } from "@assets/icons/three-dots.svg";

import { ListPerson } from "../../List/schema/list";

export const createContextMenuItems = (
	row: ListPerson,
	handleContextMenu: (value: string, row: ListPerson) => void,
	ts: (key: string) => string
): MenuItem[][] => {
	const itemsBlock1: MenuItem[] = [];

	const itemsBlock2: MenuItem[] = [];

	if (
		row.plannedAssignStatus &&
		[AssignStatus.DO_NOT_CONTACT, AssignStatus.TO_BE_NURTURED].includes(row.plannedAssignStatus)
	) {
		itemsBlock1.push({
			title: ts("confirmAssignment"),
			icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={Tick} />,
			onClick: () => handleContextMenu && handleContextMenu("confirmAssignment", row),
		});

		itemsBlock1.push({
			title: ts("reassign"),
			icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={Reassign} />,
			onClick: () => handleContextMenu && handleContextMenu("reassignPlanned", row),
		});
	}

	if (row.assignStatus === AssignStatus.ASSIGNED) {
		itemsBlock1.push({
			title: ts("reassign"),
			icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={Reassign} />,
			onClick: () => handleContextMenu && handleContextMenu("reassign", row),
		});
	}

	// itemsBlock2.push({
	// 	title: ts("reportRecord"),
	// 	icon: <SvgIcon className="w-[20px] h-[20px] text-brand-black" svgIcon={Flag} />,
	// 	onClick: () => handleContextMenu && handleContextMenu("reportRecord", row),
	// });

	return [itemsBlock1, itemsBlock2].filter((block) => block.length > 0);
};

export const getContextMenuItem = (
	handleContextMenu: (value: string, row: ListPerson) => void,
	ts: (key: string) => string
) => {
	return {
		title: "",
		width: "fit-content",
		locked: "leftFourth",
		allowExclude: false,
		orderStatus: 0,
		hideable: false,
		moveable: false,
		uid: "contextMenu",
		show: true,
		render: (row: ListPerson) => (
			<>
				{handleContextMenu && (
					<>
						<div className="flex items-center">
							<ContextMenu
								data={createContextMenuItems(row, handleContextMenu, ts)}
								position="bottom"
								width="200"
							>
								<SvgIcon
									className="w-[20px] h-[20px] text-gray-700 cursor-pointer"
									svgIcon={ThreeDots}
								/>
							</ContextMenu>
						</div>
					</>
				)}
			</>
		),
	};
};
