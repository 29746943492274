import { createSlice } from "@reduxjs/toolkit";

import { CsvEnrichmentState } from "@/types/csvEnrichment";

import { RootState } from "@/redux";

import { csvEnrichmentApi } from "./csvEnrichment.api";

const initialState: CsvEnrichmentState = {
	csvEnrichments: [],
};

export const csvEnrichmentSlice = createSlice({
	name: "csvEnrichment",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			csvEnrichmentApi.endpoints.getCsvEnrichments.matchFulfilled,
			(state, { payload }) => {
				state.csvEnrichments = payload.data;
			}
		);
	},
});

export const {} = csvEnrichmentSlice.actions;
export const csvEnrichmentReducer = csvEnrichmentSlice.reducer;
export const csvEnrichmentSelector = (state: RootState) => state[csvEnrichmentSlice.name];
