import { IFilter } from "./general-filter";

const content: IFilter = {
	name: "content",
	filterParam: "li_post_result.content",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const likes: IFilter = {
	name: "likes",
	filterParam: "li_post_result.likes",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

const comments: IFilter = {
	name: "comments",
	filterParam: "li_post_result.comments",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

const shares: IFilter = {
	name: "shares",
	filterParam: "li_post_result.shares",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

export const LiPostFilter: IFilter[] = [content, likes, comments, shares];
