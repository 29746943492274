import { ChangeEvent, FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";

import { useGetCronsQuery } from "@/pages/Private/redux/cron/cron.api";
import { LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { useSearch } from "@/utils";

import { InputField } from "@/components/InputField/InputField";

import { Cron } from "../schema/cron";

export const CronTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`cron.${key}`), [t]);

	// const [promote] = usePromoteMutation();

	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const { data, isLoading } = useGetCronsQuery({
		page,
		limit,
		searchValue: query,
	});

	const columns: TableColumn<Cron>[] = useMemo(() => {
		return [
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.name} {!row?.recordId ? "(Scheduled)" : ""}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("recordId"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.recordId}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("recordName"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.recordName}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("priority"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.priority}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("status"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.status}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("nextExecution"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.nextExecutionInSeconds && row?.nextExecutionInSeconds > 0
									? row?.nextExecutionInSeconds
									: ""}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("startedAt"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.nextExecutionInSeconds && row?.nextExecutionInSeconds < 0
									? row?.nextExecutionInSeconds * -1
									: ""}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			// {
			// 	align: "right",
			// 	label: "",
			// 	minWidth: 20,
			// 	format: (row) => (
			// 		<Stack direction="row" justifyContent="flex-end" spacing={2}>
			// 			{row?.recordId && (
			// 				<PermissionCheck requiredPermissions={[PermissionRoles.GLOBAL_ADMIN]}>
			// 					<Box
			// 						sx={{
			// 							color: "neutral.500",
			// 							"&:hover": {
			// 								color: "primary.main",
			// 								cursor: "pointer",
			// 							},
			// 						}}
			// 						onClick={() => handlePromoteCron(row)}
			// 					>
			// 						<Icon icon={faChevronsUp} size="xl" />
			// 					</Box>
			// 				</PermissionCheck>
			// 			)}
			// 		</Stack>
			// 	),
			// },
		];
	}, [ts]);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data?.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data?.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
		</div>
	);
};
