export enum Enrichments {
	DRAFT = "DRAFT",
	PUBLIC_PROFILE = "PUBLIC_PROFILE",
	COMPANY_PROFILE = "COMPANY_PROFILE",
	INVALID = "INVALID",
	TRIGGERED = "TRIGGERED",
	IN_PROGRESS = "IN_PROGRESS",
	SCRAPED = "SCRAPED",
	ERROR = "ERROR",
	WAITING = "WAITING",
	APOLLO_ENRICHED = "APOLLO_ENRICHED",
	APOLLO_PERSON_ENRICHED = "APOLLO_PERSON_ENRICHED",
	DONE = "DONE",
}
