import {
	company,
	companyCity,
	companyCountry,
	companyLinkedinUrl,
	companyState,
	estimatedNumEmployees,
	foundedYear,
	IFilter,
	industry,
} from "./general-filter";

const jobTitle: IFilter = {
	name: "jobTitle",
	filterParam: "offer.jobTitle",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const JobsFilter: IFilter[] = [jobTitle];

export const FrequentlyUsedJobFiltersConfig: IFilter[] = [
	jobTitle,
	industry,
	estimatedNumEmployees,
	companyCountry,
];

export const AdditionalColumnsJobFiltersConfig: IFilter[] = [
	company,

	companyState,
	companyCity,
	companyLinkedinUrl,
	foundedYear,
];
