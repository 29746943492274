import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { PermissionCheck } from "@/components";

import { PermissionRoles } from "@/enum";

import { ButtonColor, ButtonSize } from "@/components/Button/types";
import { Button } from "@/components/Button/Button";

import { MapTable } from "../../components/MapTable";
import { CreateMapModal } from "../../components/CreateMapModal";

export const MapOverview: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`map.${key}`);

	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<h3 className="mb-3">{ts("title")}</h3>
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">{ts("subtitle")}</p>
				</div>
				<div className="flex justify-end flex">
					<PermissionCheck requiredPermissions={[PermissionRoles.STEPSTONE]}>
						<Button
							color={ButtonColor.ACTION}
							size={ButtonSize.S}
							testId="new-user-button"
							title={ts("create.button")}
							onClick={() => setIsInviteModalOpen(true)}
						/>
					</PermissionCheck>
				</div>
			</div>

			{<MapTable />}

			{isInviteModalOpen && (
				<CreateMapModal isOpen={isInviteModalOpen} setIsOpen={setIsInviteModalOpen} />
			)}
		</div>
	);
};
