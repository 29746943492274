import {
	ChangeEvent,
	Dispatch,
	FunctionComponent,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { Modal } from "@/components";

import { useUpdateLiPeopleMutation } from "@/pages/Private/redux/liPeople/liPeople.api";

import { CreateLiPeople, CreateLiPeopleSchema, LiPeople } from "../schema/liPeople";

interface updateLiPeopleModalProps {
	liPeople: LiPeople;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const UpdateLiPeopleModal: FunctionComponent<updateLiPeopleModalProps> = ({
	liPeople,
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liPeople.${key}`);

	const [selectedLiPeople, setSelectedLiPeople] = useState<LiPeople>(liPeople);

	const [updateLiPeople] = useUpdateLiPeopleMutation();
	const [tempUrls, setTempUrls] = useState<string>();

	useEffect(() => {
		if (liPeople) {
			setSelectedLiPeople(liPeople);
			setTempUrls(liPeople?.liPeopleEntries?.map((liPeopleEntry) => liPeopleEntry.url)?.join(","));
		}
	}, [liPeople]);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<CreateLiPeople>({
		defaultValues: {
			name: selectedLiPeople?.name ?? "",
			urls: selectedLiPeople?.liPeopleEntries?.map((liPeopleEntry) => liPeopleEntry.url) ?? [],
		},
		resolver: zodResolver(CreateLiPeopleSchema),
	});

	const handleUrlsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setTempUrls(event.target.value);
	};

	const handleUrlsOnBlur = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setTempUrls(event.target.value);
		setValue(
			"urls",
			event.target.value?.includes(",")
				? event.target.value?.split(",")
				: event.target.value?.includes("\n")
				? event.target.value?.split("\n")
				: [event.target.value]
		);
	};

	const onSubmit = async (values: CreateLiPeople) => {
		try {
			await updateLiPeople({
				id: selectedLiPeople.id,
				name: values.name,
				urls: values.urls,
			}).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			// isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("update.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>
				<TextField
					multiline
					label={ts("urls")}
					placeholder={ts("urls")}
					value={tempUrls}
					onBlur={handleUrlsOnBlur}
					onChange={handleUrlsChange}
				/>
			</Stack>
		</Modal>
	);
};
