import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { Button } from "@/components/Button/Button";

import { InputField } from "@/components/InputField/InputField";

import { ButtonColor } from "@/components/Button/types";

import { EmploymentType, JobScrapeType } from "@/enum/job.enum";
import { useCreateJobMutation, useUpdateJobMutation } from "@/pages/Private/redux/job/job.api";
import { Dropdown, AutoCompleteItem } from "@/components/Dropdown/Dropdown";

import { List } from "../../List/schema/list";
import { CreateJob, CreateJobSchema, Job } from "../schema/job";
import {
	LinkedinParams,
	LinkedinTagAutocomplete,
} from "../../LiPeople/components/LinkedinTagAutocomplete";
import { Suggestion } from "../../LiPeople/schema/liPeople";

interface CreateJobFormProps {
	job?: Job | null;
	list: List;
	handleSelect?: (job: Job | null) => void;
	handleDuplicate?: (job: Job | null) => void;
}

export const CreateJobForm: FunctionComponent<CreateJobFormProps> = ({
	job,
	list,
	handleSelect,
	handleDuplicate,
}) => {
	const initialState = {
		name: "",
		query: "",
		employmentType: EmploymentType.FULLTIME,
		jobScrapeType: JobScrapeType.GET_INFO_AT,
		repeatDaily: false,
	};
	const { t } = useTranslation();
	const ts = (key: string) => t(`job.${key}`);

	const [employmnetType, setEmploymentType] = useState<EmploymentType | null>(
		job?.employmentType || null
	);

	const [update] = useUpdateJobMutation();
	const [createJob, { error, isLoading }] = useCreateJobMutation();
	const [location, setLocation] = useState<LinkedinParams>();

	const [currentFormState, setCurrentFormState] = useState<Partial<CreateJob>>(initialState);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof CreateJob) => {
		let newValue = {
			...currentFormState,
			[name]: value,
		};

		if (name === "query") {
			newValue = {
				...newValue,
				name: value,
			};

			setValue("name", value, {
				shouldDirty: true,
				shouldValidate: true,
			});
		}

		setCurrentFormState(newValue);

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const {
		reset,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<CreateJob>({
		defaultValues: initialState,
		resolver: zodResolver(CreateJobSchema),
	});

	useEffect(() => {
		if (job) {
			const formState = {
				name: job.name,
				query: job.query,
				employmentType: job.employmentType,
				jobScrapeType: job.jobScrapeType,
				repeatDaily: false,
				listId: list.id,
				location: job.location,
			};

			if (job.location) {
				setLocation({
					id: job.location.urn,
					name: job.location.title,
				} as LinkedinParams);
			}

			setEmploymentType(job.employmentType);

			if (handleDuplicate) {
				reset({
					...formState,
				});
				setCurrentFormState({
					...formState,
				});
			} else {
				reset({
					id: job.id,
					...formState,
				});
				setCurrentFormState({
					...formState,
					id: job?.id,
				});
			}
		} else {
			reset();
			setEmploymentType(null);
			setLocation(undefined);
		}
	}, [list.scrapeLevel, job, reset, handleDuplicate, list.id]);

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	useEffect(() => {
		if (list.scrapeLevel) {
			setValue("jobScrapeType", list.scrapeLevel as JobScrapeType);
			setValue("repeatDaily", false);
		}
	}, [list.scrapeLevel, setValue]);

	const onSubmit = async (values: CreateJob) => {
		try {
			if (values.id && !handleDuplicate && location) {
				await update({
					id: values.id,
					...values,
					name: values.query,
					employmentType: employmnetType as EmploymentType,
					listId: list.id,
					location: { title: location.name, urn: location.id } as Suggestion,
				}).unwrap();
			} else if (location) {
				await createJob({
					...values,
					name: values.query,
					employmentType: employmnetType as EmploymentType,
					listId: list.id,
					location: { title: location.name, urn: location.id } as Suggestion,
				}).unwrap();
			}

			handleSelect?.(null);
			handleDuplicate?.(null);

			reset();
		} catch (err) {
			console.error(err);
		}
	};
	const buttonTitle =
		handleDuplicate && job?.id
			? t("basics.copyCriteria")
			: job?.id
			? t("basics.save")
			: t("basics.addCriteria");

	console.log(errors);

	return (
		<div className="">
			<div className="text-md mb-5">Sources</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.query?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "query");
					}}
					label={ts("query")}
					name={"query"}
					placeholder={ts("queryPlaceholder")}
					value={currentFormState.query ?? ""}
				/>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<LinkedinTagAutocomplete
					handleValueChange={(value: LinkedinParams[]) => {
						if (value) {
							setLocation(value[0]);
							handleSaveFormState(
								{
									title: value[0].name,
									urn: value[0].id,
								},
								"location"
							);
						} else {
							setLocation(undefined);
						}
					}}
					multiple={false}
					placeholder={location ? undefined : t("basics.pleaseSelect")}
					type={"location"}
					value={location ? [location] : []}
				/>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<Dropdown
					data={[
						{
							title: ts(EmploymentType.FULLTIME),
							id: EmploymentType.FULLTIME,
						},
						{
							title: ts(EmploymentType.PARTTIME),
							id: EmploymentType.PARTTIME,
						},
						{
							title: ts(EmploymentType.INTERN),
							id: EmploymentType.INTERN,
						},
						{
							title: ts(EmploymentType.CONTRACTOR),
							id: EmploymentType.CONTRACTOR,
						},
						{
							title: ts(EmploymentType.VOLUNTEER),
							id: EmploymentType.VOLUNTEER,
						},
						{
							title: ts(EmploymentType.TEMPORARY),
							id: EmploymentType.VOLUNTEER,
						},
					]}
					defaultValue={
						employmnetType
							? {
									id: employmnetType,
									title: ts(employmnetType),
							  }
							: undefined
					}
					floating={true}
					handleSelect={(value: AutoCompleteItem) => {
						setEmploymentType(value.id as EmploymentType);
						handleSaveFormState(value.id as EmploymentType, "employmentType");
					}}
					label={ts("employmentType")}
				/>
			</div>

			{getAllErrors(error, formErrors).length
				? renderErrorMessages(getAllErrors(error, formErrors))
				: null}

			<div className="flex gap-4 w-full justify-end">
				{handleSelect && (
					<div className="flex max-w-[100px] w-full">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							title="cancel"
							onClick={() => {
								handleSelect(null);
								handleDuplicate?.(null);
								reset(initialState);
								setCurrentFormState(initialState);
								setEmploymentType(EmploymentType.FULLTIME);
							}}
						/>
					</div>
				)}
				<div className="flex max-w-[150px] w-full">
					<Button isLoading={isLoading} title={buttonTitle} onClick={handleSubmit(onSubmit)} />
				</div>
			</div>
		</div>
	);
};
