import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { OpenAiOverview } from "./pages/OpenAiOverview/OpenAiOverview";
import { OpenAiDetail } from "./pages/OpenAiDetail/OpenAiDetail";

export const OpenAi: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<OpenAiOverview />} path="/" />
			<Route element={<OpenAiDetail />} path="/:id" />
		</Routes>
	);
};
