import { FunctionComponent } from "react";

import { ListLogTable } from "../../components/ListLogTable";

export const ListLog: FunctionComponent = () => {
	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<ListLogTable />
		</div>
	);
};
