import { z } from "zod";

export const CampaignRuleSchema = z.object({
	id: z.number().optional(),
	listId: z.number(),
	lemlistId: z.number().optional(),
	config: z.any(),
});

export const CreateCampaignRuleSchema = z.object({
	id: z.number().optional(),
	listId: z.number(),
	lemlistId: z.number().optional(),
	config: z.any(),
});

export type CampaignRule = z.infer<typeof CampaignRuleSchema>;

export type CreateCampaignRule = z.infer<typeof CreateCampaignRuleSchema>;
