import { createSlice } from "@reduxjs/toolkit";

import { LemlistState } from "@/types/lemlist";

import { RootState } from "@/redux";

import { lemlistApi } from "./lemlist.api";

const initialState: LemlistState = {
	lemlists: [],
};

export const lemlistSlice = createSlice({
	name: "lemlist",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(lemlistApi.endpoints.getLemlists.matchFulfilled, (state, { payload }) => {
			state.lemlists = payload.data;
		});
	},
});

export const {} = lemlistSlice.actions;
export const lemlistReducer = lemlistSlice.reducer;
export const lemlistSelector = (state: RootState) => state[lemlistSlice.name];
