import { createSlice } from "@reduxjs/toolkit";

import { UsageState } from "@/types/usage";

import { RootState } from "@/redux";

import { usageApi } from "./usage.api";

const initialState: UsageState = {
	usage: [],
	aiUsage: [],
};

export const usageSlice = createSlice({
	name: "usage",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(usageApi.endpoints.getUsage.matchFulfilled, (state, { payload }) => {
			state.usage = payload.usage;
		});
	},
});

export const {} = usageSlice.actions;
export const requestsReducer = usageSlice.reducer;
export const requestsSelector = (state: RootState) => state[usageSlice.name];
