import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Stack } from "@mui/material";

import { Error, CheckCircle } from "@mui/icons-material";

import { useParams } from "react-router-dom";

import {
	faMars,
	faVenus,
	faEnvelope,
	faArrowUpRightFromSquare,
} from "@fortawesome/pro-regular-svg-icons";

import LinkedinIcon from "@assets/icons/linkedin.svg";

import { Icon, LoadingOverlay } from "@/components";

import { useGetCsvEnrichmentForTableQuery } from "@/pages/Private/redux/csvEnrichment/csvEnrichment.api";

import { CsvEnrichmentScrapeStatus } from "@/enum/csv-enrichment.enum";

import { Table, TableColumn } from "@/components/Table/Table";
import { useCopyToClipboard } from "@/utils/useCopyToClipboard";
import { Tooltip } from "@/components/Tooltip/Tooltip";

import { CsvEnrichmentOrganization } from "../schema/csvEnrichmentOrganization";
import { CsvEnrichmentPerson } from "../schema/csvEnrichmentPerson";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CsvEnrichmentResultsTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();
	const ts = useCallback((key: string) => t(`inbox.${key}`), [t]);

	const { renderSnackbar, handleCopyClick } = useCopyToClipboard();
	// const [page, setPage] = useState(1);
	const [, setAllOrgData] = useState<CsvEnrichmentOrganization[]>([]);
	const [, setAllPersonData] = useState<CsvEnrichmentPerson[]>([]);

	const { data, isFetching } = useGetCsvEnrichmentForTableQuery(+id);

	useEffect(() => {
		if (data?.people) {
			setAllPersonData((prevData) => [...prevData, ...data.people]);
		}
	}, [data?.people]);

	useEffect(() => {
		if (data?.organizations) {
			setAllOrgData((prevData) => [...prevData, ...data.organizations]);
		}
	}, [data?.organizations]);

	const renderStatus = (status: CsvEnrichmentScrapeStatus) => {
		switch (status) {
			case CsvEnrichmentScrapeStatus.DONE:
			case CsvEnrichmentScrapeStatus.APOLLO_ENRICHED:
				return (
					<>
						<CheckCircle color="primary" /> Found
					</>
				);
			case CsvEnrichmentScrapeStatus.INVALID:
				return (
					<>
						<Error color="warning" />
						Not enriched
					</>
				);
			default:
				return <></>;
		}
	};

	const personColumns: TableColumn[] = useMemo(
		() => [
			{
				title: ts("status"),
				width: "fit-content",
				orderTitle: "status",
				orderStatus: 0,
				uid: "status",
				render: (row: CsvEnrichmentPerson) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{renderStatus(row.status)}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("name"),
				width: "fit-content",
				orderTitle: "prospect.name",
				orderStatus: 0,
				uid: "name",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<a
								className="cursor-pointer"
								onClick={() => handleCopyClick(row.name || row.prospect?.name)}
							>
								{row.name}
								{row.prospect?.gender === "male" && (
									<Tooltip
										content={`${row.prospect?.gender.capitalizeString()} ${
											row.prospect?.genderProbability
										}% certain`}
									>
										<Icon className="cursor-pointer ml-1" icon={faMars} />
									</Tooltip>
								)}
								{row.prospect?.gender === "female" && (
									<Tooltip
										content={`${row.prospect?.gender.capitalizeString()} ${
											row.prospect?.genderProbability
										}% certain`}
									>
										<Icon className="cursor-pointer ml-1" icon={faVenus} />
									</Tooltip>
								)}
							</a>
							<div className="flex">
								{row.prospect?.linkedinUrl && (
									<div className="mr-1 cursor-pointer">
										<Link href={row.linkedinUrl} target="_blank">
											<img alt="linkedin" className="h-[20px] mr-1" src={LinkedinIcon} />
										</Link>
									</div>
								)}
								{row.prospect?.email && row.prospect?.emailStatus === "VALID" && (
									<a
										className="mr-1 cursor-pointer"
										onClick={() => handleCopyClick(row.prospect?.email)}
									>
										<Icon className="h-[20px]" icon={faEnvelope} />
									</a>
								)}
							</div>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("title"),
				width: "fit-content",
				orderTitle: "prospect.title",
				uid: "title",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<a
								className="text-ellipsis overflow-hidden max-w-[250px] cursor-pointer"
								onClick={() => handleCopyClick(row.prospect?.title)}
							>
								{row.prospect?.title}
							</a>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("level"),
				width: "fit-content",
				orderTitle: "prospect.seniority",
				orderStatus: 0,
				uid: "level",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<a className="cursor-pointer" onClick={() => handleCopyClick(row.prospect?.title)}>
							{row.prospect?.seniority?.capitalizeString()}
						</a>
					</Stack>
				),
			},
			{
				title: ts("company"),
				width: "500",
				orderTitle: "organization.name",
				orderStatus: 0,
				uid: "company",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<div className="flex">
								{row.prospect?.organization?.logoUrl && (
									<img
										alt="logo"
										className="h-[45px] rounded"
										src={row.prospect?.organization?.logoUrl}
									/>
								)}
								<div className="ml-2 flex flex-col">
									<a
										className="cursor-pointer"
										onClick={() =>
											handleCopyClick(
												row.prospect?.organization?.name || row.mapBusiness?.companyName
											)
										}
									>
										{row.prospect?.organization?.name || row.mapBusiness?.companyName}
									</a>
									<div className="flex">
										{row.mapBusiness?.companyLinkedinUrl && (
											<Link href={row.mapBusiness?.companyLinkedinUrl} target="_blank">
												<img alt="linkedin" className="h-[20px]  flex mr-1" src={LinkedinIcon} />
											</Link>
										)}
										{row.prospect?.organization?.linkedinUrl && (
											<Link href={row.prospect?.organization?.linkedinUrl} target="_blank">
												<img alt="linkedin" className="h-[20px]  flex mr-1" src={LinkedinIcon} />
											</Link>
										)}
										{row.mapBusiness?.website && (
											<Link href={row.mapBusiness?.website} target="_blank">
												<Icon className="h-[20px] flex" icon={faArrowUpRightFromSquare} />
											</Link>
										)}
										{row.prospect?.organization?.websiteUrl && (
											<Link href={row.prospect?.organization?.websiteUrl} target="_blank">
												<Icon className="h-[20px] flex" icon={faArrowUpRightFromSquare} />
											</Link>
										)}
									</div>
								</div>
							</div>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("connections"),
				orderTitle: "connections",
				orderStatus: 0,
				uid: "connections",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.connections?.length || ""}
							{row.connections?.map((user: string, index: number) => (
								<div key={index} className="flex text-nowrap">
									{user}
								</div>
							))}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("industry"),
				width: "fit-content",
				orderTitle: "organization.industry",
				orderStatus: 0,
				uid: "industry",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<a
								className="cursor-pointer"
								onClick={() =>
									handleCopyClick(
										row.prospect?.organization?.industry?.capitalizeString() ||
											row.mapBusiness?.type
									)
								}
							>
								{row.prospect?.organization?.industry?.capitalizeString() || row.mapBusiness?.type}
							</a>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("companyStats"),
				width: "fit-content",
				orderTitle: "organization.estimatedNumEmployees",
				orderStatus: 0,
				uid: "companyStats",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<a
								className="cursor-pointer"
								onClick={() => handleCopyClick(row.prospect?.organization?.estimatedNumEmployees)}
							>
								{row.prospect?.organization?.estimatedNumEmployees} FTE
							</a>
							<a
								className="cursor-pointer"
								onClick={() =>
									row.prospect?.organization?.foundedYear &&
									handleCopyClick(row.prospect?.organization?.foundedYear)
								}
							>
								{ts("founded")}: {row.prospect?.organization?.foundedYear}
							</a>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("personLocation"),
				width: "fit-content",
				orderTitle: "prospect.country",
				uid: "personLocation",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<a
								className="cursor-pointer"
								onClick={() => row.prospect?.city && handleCopyClick(row.prospect?.city)}
							>
								{row.prospect?.city}
							</a>
							<a
								className="cursor-pointer"
								onClick={() => row.prospect?.country && handleCopyClick(row.prospect?.country)}
							>
								{row.prospect?.country}
							</a>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("companyLocation"),
				width: "fit-content",
				orderTitle: "organization.country",
				uid: "companyLocation",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<a
								className="cursor-pointer"
								onClick={() =>
									row.prospect?.organization?.city ||
									(row.companyCity &&
										handleCopyClick(row.prospect?.organization?.city || row.companyCity))
								}
							>
								{row.prospect?.organization?.city || row.companyCity}
							</a>
							<a
								className="cursor-pointer"
								onClick={() =>
									row.prospect?.organization?.country ||
									(row.country &&
										handleCopyClick(row.prospect?.organization?.country || row.country))
								}
							>
								{row.prospect?.organization?.country || row.country}
							</a>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("language"),
				width: "fit-content",
				orderTitle: "prospect.language",
				uid: "language",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<div>{row.prospect?.language}</div>
						</Stack>
					</Stack>
				),
			},
		],
		[handleCopyClick, ts]
	);

	const organizationColumns: TableColumn[] = useMemo(
		() => [
			{
				title: ts("status"),
				width: "fit-content",
				orderTitle: "status",
				orderStatus: 0,
				uid: "status",
				render: (row: CsvEnrichmentPerson) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{renderStatus(row.status)}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("company"),
				width: "500",
				orderTitle: "organization.name",
				orderStatus: 0,
				uid: "company",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<div className="flex">
								{row.organization?.logoUrl && (
									<img alt="logo" className="h-[45px] rounded" src={row.organization?.logoUrl} />
								)}
								<div className="ml-2 flex flex-col">
									<a
										className="cursor-pointer"
										onClick={() => handleCopyClick(row.organization?.name || row.companyName)}
									>
										{row.organization?.name || row.companyName}
									</a>
									<div className="flex">
										{row.companyLinkedinUrl ? (
											<Link href={row.companyLinkedinUrl} target="_blank">
												<img alt="linkedin" className="h-[20px]  flex mr-1" src={LinkedinIcon} />
											</Link>
										) : row.organization?.linkedinUrl ? (
											<Link href={row.organization?.linkedinUrl} target="_blank">
												<img alt="linkedin" className="h-[20px]  flex mr-1" src={LinkedinIcon} />
											</Link>
										) : (
											""
										)}
										{row.domain && (
											<Link
												href={row.domain.includes("http") ? row.domain : `https://${row.domain}`}
												target="_blank"
											>
												<Icon className="h-[20px] flex" icon={faArrowUpRightFromSquare} />
											</Link>
										)}
									</div>
								</div>
							</div>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("industry"),
				width: "fit-content",
				orderTitle: "organization.industry",
				orderStatus: 0,
				uid: "industry",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<a
								className="cursor-pointer"
								onClick={() => handleCopyClick(row.organization?.industry?.capitalizeString())}
							>
								{row.organization?.industry?.capitalizeString()}
							</a>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("companyStats"),
				width: "fit-content",
				orderTitle: "organization.estimatedNumEmployees",
				orderStatus: 0,
				uid: "companyStats",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<a
								className="cursor-pointer"
								onClick={() => handleCopyClick(row.organization?.estimatedNumEmployees)}
							>
								{row.organization?.estimatedNumEmployees &&
									`FTE: ${row.organization?.estimatedNumEmployees}`}
							</a>
							<a
								className="cursor-pointer"
								onClick={() =>
									row.prospect?.organization?.foundedYear &&
									handleCopyClick(row.prospect?.organization?.foundedYear)
								}
							>
								{row.prospect?.organization?.foundedYear &&
									`${ts("founded")}: ${row.prospect?.organization?.foundedYear}`}
							</a>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("companyLocation"),
				width: "fit-content",
				orderTitle: "organization.country",
				uid: "companyLocation",
				render: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<a
								className="cursor-pointer"
								onClick={() =>
									row.organization?.city ||
									(row.companyCity && handleCopyClick(row.organization?.city || row.companyCity))
								}
							>
								{row.organization?.city || row.companyCity}
							</a>
							<a
								className="cursor-pointer"
								onClick={() =>
									row.organization?.country ||
									(row.country && handleCopyClick(row.organization?.country || row.country))
								}
							>
								{row.organization?.country || row.country}
							</a>
						</Stack>
					</Stack>
				),
			},
		],
		[handleCopyClick, ts]
	);

	return !data ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<h3 className="mb-3">{data.name}</h3>
				</div>
			</div>
			<div className="w-full bg-white">
				{data?.people.length > 0 && (
					<Table
						columns={personColumns}
						data={(data?.people as unknown as CsvEnrichmentPerson[]) || []}
						// handleFetchMore={() => {
						// 	if (data?.meta?.itemCount && allPersonData.length < data?.meta?.itemCount && !isFetching) {
						// 		handleFetchMore();
						// 	}
						// }}
						// isFetching={isFetching}
						// isLoading={isLoading}
						isLoading={isFetching}
					/>
				)}

				{data?.organizations.length > 0 && (
					<Table
						columns={organizationColumns}
						data={(data?.organizations as unknown as CsvEnrichmentOrganization[]) || []}
						// handleFetchMore={() => {
						// 	if (data?.meta?.itemCount && allOrgData.length < data?.meta?.itemCount && !isFetching) {
						// 		handleFetchMore();
						// 	}
						// }}
						// isFetching={isFetching}
						// isLoading={isLoading}
						isLoading={isFetching}
					/>
				)}
			</div>
			{renderSnackbar()}
		</div>
	);
};
