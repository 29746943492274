import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { Modal } from "@/components";

import { useUpdateJobMutation } from "@/pages/Private/redux/job/job.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { Dropdown, AutoCompleteItem } from "@/components/Dropdown/Dropdown";
import { EmploymentType, JobScrapeType } from "@/enum/job.enum";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { Radio } from "@/components/Radio/Radio";

import { CreateJob, CreateJobSchema, Job } from "../schema/job";

interface createJobModalProps {
	job: Job;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditJobModal: FunctionComponent<createJobModalProps> = ({
	isOpen,
	setIsOpen,
	job,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`job.${key}`);
	const [update, { error, isLoading }] = useUpdateJobMutation();
	const [employmentType, setEmploymentType] = useState<EmploymentType>(job.employmentType);

	const {
		register,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors },
	} = useForm<CreateJob>({
		defaultValues: {
			name: job.name,
			query: job.query,
			employmentType: job.employmentType,
			jobScrapeType: job.jobScrapeType,
			repeatDaily: job.repeatDaily || false,
		},
		resolver: zodResolver(CreateJobSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: Partial<Job>) => {
		try {
			await update({ id: job.id, ...values, employmentType }).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>

				<TextField
					error={!!errors.query?.message}
					label={ts("query")}
					placeholder={ts("queryPlaceholder")}
					{...register("query")}
				/>

				<Dropdown
					data={[
						{
							title: ts(EmploymentType.FULLTIME),
							id: EmploymentType.FULLTIME,
						},
						{
							title: ts(EmploymentType.PARTTIME),
							id: EmploymentType.PARTTIME,
						},
						{
							title: ts(EmploymentType.INTERN),
							id: EmploymentType.INTERN,
						},
						{
							title: ts(EmploymentType.CONTRACTOR),
							id: EmploymentType.CONTRACTOR,
						},
					]}
					floating={true}
					handleSelect={(value: AutoCompleteItem) => {
						setEmploymentType(value.id as EmploymentType);
					}}
					label={ts("employmentType")}
					value={{
						id: employmentType,
						title: ts(employmentType),
					}}
				/>

				<div className="flex flex-col">
					<div className="text-sm font-medium mb-2">{ts("scrapeLevel")}</div>
					<Radio
						className="mb-8"
						isChecked={getValues("jobScrapeType") === JobScrapeType.GET_INFO_AT}
						name="getInfoAt"
						value="getInfoAt"
						onChange={() =>
							setValue("jobScrapeType", JobScrapeType.GET_INFO_AT, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("getInfoAt")}
					</Radio>
					<Radio
						className="mb-8"
						isChecked={getValues("jobScrapeType") === JobScrapeType.GET_MANAGING_DIRECTOR}
						name="getManagingDirector"
						value="getManagingDirector"
						onChange={() =>
							setValue("jobScrapeType", JobScrapeType.GET_MANAGING_DIRECTOR, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("getManagingDirector")}
					</Radio>
					<Radio
						className="mb-8"
						isChecked={getValues("jobScrapeType") === JobScrapeType.GET_ENRICHMENT}
						name="getEnrichment"
						value="getEnrichment"
						onChange={() =>
							setValue("jobScrapeType", JobScrapeType.GET_ENRICHMENT, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("getEnrichment")}
					</Radio>
					<Radio
						className="mb-8"
						isChecked={getValues("jobScrapeType") === JobScrapeType.GET_PERSON_FROM_OFFER}
						name="getPersonFromOffer"
						value="getPersonFromOffer"
						onChange={() =>
							setValue("jobScrapeType", JobScrapeType.GET_PERSON_FROM_OFFER, {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("getPersonFromOffer")}
					</Radio>
				</div>

				<div className="flex flex-col">
					<div className="text-sm font-medium mb-2">{ts("scrapeDaily")}</div>
					<Checkbox
						className="mb-8"
						isChecked={getValues("repeatDaily")}
						onChange={() =>
							setValue("repeatDaily", !getValues("repeatDaily"), {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("repeatDaily")}
					</Checkbox>
				</div>
				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</Stack>
		</Modal>
	);
};
