import { createSlice } from "@reduxjs/toolkit";

import { LiEventState } from "@/types/liEvent";

import { RootState } from "@/redux";

import { liEventApi } from "./liEvent.api";

const initialState: LiEventState = {
	liEvents: [],
};

export const liEventSlice = createSlice({
	name: "liEvent",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(liEventApi.endpoints.getLiEvents.matchFulfilled, (state, { payload }) => {
			state.liEvents = payload.data;
		});
	},
});

export const {} = liEventSlice.actions;
export const liEventReducer = liEventSlice.reducer;
export const liEventSelector = (state: RootState) => state[liEventSlice.name];
