import { FunctionComponent, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { faClock, faClose } from "@fortawesome/pro-regular-svg-icons";

import CheckIcon from "@assets/icons/list-builder/checkGreen.svg";

import { ReactComponent as CalendarIcon } from "@assets/icons/campaigns/calendar.svg";

import PausedIcon from "@assets/icons/campaigns/paused.svg";

import { Icon } from "@/components";
import { SvgIcon } from "@/components/Icon/SvgIcon";

interface ListProcessingStatusChipProps {
	processingStatus: string;
}

export const ListProcessingStatusChip: FunctionComponent<ListProcessingStatusChipProps> = ({
	processingStatus,
}) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`list.${key}`), [t]);

	switch (processingStatus) {
		case "error":
			return (
				<div className="px-2.5 py-1.5 bg-status-error-light items-center rounded-xl text-status-error text-ssm flex flex-row items-center">
					<Icon className="mr-1.5 text-status-error" icon={faClose} />
					{ts(`status.${processingStatus}`)}
				</div>
			);
		case "running":
			return (
				<div className="px-2.5 py-1.5 bg-light-blue items-center rounded-xl text-brand-light text-ssm flex flex-row items-center">
					<Icon className="mr-1.5  text-brand-light" icon={faClock} />
					{ts(`status.${processingStatus}`)}
				</div>
			);
		case "ended":
			return (
				<div className="px-2.5 py-1.5 bg-status-success-light rounded-xl text-status-success text-ssm flex flex-row items-center">
					<img alt="checkIcon" className="mr-1" src={CheckIcon} />
					{ts(`status.${processingStatus}`)}
				</div>
			);
		case "paused":
			return (
				<div className="px-2.5 py-1.5 bg-status-running-light items-center rounded-xl text-status-running text-ssm flex flex-row items-center">
					<img alt="paused" className="mr-1" src={PausedIcon} />
					{ts(`status.${processingStatus}`)}
				</div>
			);
		case "waiting":
			return (
				<div className="px-2.5 py-1.5 bg-status-info-light rounded-xl text-status-info text-ssm flex flex-row items-center">
					<SvgIcon className="w-[20px] h-[20px] text-brand-light mr-1" svgIcon={CalendarIcon} />
					{ts(`status.${processingStatus}`)}
				</div>
			);

		default:
			return <></>;
	}

	return <></>;
};
