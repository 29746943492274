import { z } from "zod";

export const UsageSchema = z.object({
	id: z.number(),
	filtered: z.number(),
	identified: z.number(),
	month: z.number(),
	year: z.number(),
});

export const AiUsageSchema = z.object({
	id: z.number().optional(),
	inputTokens: z.number(),
	outputTokens: z.number(),
	month: z.number(),
	year: z.number(),
});

export const UsageResponseSchema = z.object({
	usage: z.array(UsageSchema),
	aiUsage: z.array(AiUsageSchema),
	limits: z.object({
		identified: z.number(),
		filtered: z.number(),
		inputTokens: z.number(),
		outputTokens: z.number(),
	}),
});

export const CreditsResponseSchema = z.object({
	credits: z.number(),
	limit: z.number(),
	nextCycle: z.date(),
});

export type Usage = z.infer<typeof UsageSchema>;
export type AiUsage = z.infer<typeof AiUsageSchema>;
export type CreditsResponse = z.infer<typeof CreditsResponseSchema>;

export type UsageResponse = z.infer<typeof UsageResponseSchema>;
