import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";

import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import { Breadcrumb } from "@/components/Breadcrump/Breadcrump";

import { Button } from "@/components/Button/Button";
import { useAddScoresToListMutation, useGetListQuery } from "@/pages/Private/redux/list/list.api";

import { Icon, LoadingOverlay } from "@/components";

import { ButtonColor, ButtonSize } from "@/components/Button/types";

import { useGetScoresQuery } from "@/pages/Private/redux/score/score.api";
import { useSearch } from "@/utils";
import { Checkbox } from "@/components/Checkbox/Checkbox";

import { getAvailableSteps, isDetailsNeeded, getDoneSteps } from "../../utils/create-list-steps";
import { Score } from "../../../Score/schema/score.schema";
import { CreateScoreModal } from "./components/CreateScoreModal";
import { Stepper } from "../ListEditDetails/components/Stepper";

export const ListEditScore: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();

	const navigate = useNavigate();
	const ts = (key: string) => t(`list.${key}`);

	const [selectedScore, setSelectedScore] = useState<Score[]>([]);
	const [showCreateScoreModal, setShowCreateScoreModal] = useState<boolean>(false);

	const { data, isFetching } = useGetListQuery(+id);

	const [addScoresToList] = useAddScoresToListMutation();

	const { page, limit, query } = useSearch();

	const { data: scores, isLoading } = useGetScoresQuery({
		page,
		limit,
		searchValue: query,
	});

	useEffect(() => {
		if (data && scores) {
			setSelectedScore(
				scores.data.filter((score) => score?.lists?.map((list) => list.id).includes(data.id))
			);
		}
	}, [data, scores]);

	const counter = useRef<number>(0);

	const submit = async () => {
		const scoreIds = selectedScore.map((score) => score.id);

		await addScoresToList({ listId: +id, scoreIds }).unwrap();

		navigate(`/app/lists/create/settings/${id}`);
	};

	return isFetching || !data || isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="sm:block flex flex-col items-start justify-between mb-32 pb-32">
			<Breadcrumb
				path={[
					{ name: ts("title"), link: "/" },
					{ name: ts("create.title"), link: "/create" },
					{ name: data?.name || "", link: `/create/${id}/details` },
				]}
			/>
			<div className="text-lg leading-9 mb-5">Create {ts(data.sourceType)} List</div>
			<Stepper
				availableSteps={getAvailableSteps(isDetailsNeeded(data), data)}
				currentStep="score"
				doneSteps={getDoneSteps(data)}
				isDirty={counter.current > 0}
				listId={data.id}
			/>
			<div className="w-full">
				<div className="text-md mb-5">Score</div>
				{scores?.data?.map((score) => (
					<div key={score.id} className="flex flex-row justify-between items-center w-full py-4">
						<Checkbox
							className="flex items-center"
							isChecked={selectedScore.map((s) => s.id).includes(score.id)}
							onChange={() => {
								if (selectedScore.map((s) => s.id).includes(score.id)) {
									setSelectedScore(selectedScore.filter((s) => s.id !== score.id));
								} else {
									setSelectedScore([...selectedScore, score]);
								}

								counter.current += 1;
							}}
						>
							<div className="text-ssm text-black-100">{score.name}</div>
						</Checkbox>
					</div>
				))}
				<div className="flex flex-row justify-between items-center w-[200px]">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						image={<Icon className="mr-2" icon={faPlus} />}
						size={ButtonSize.ML}
						title={t("score.create.button")}
						onClick={() => setShowCreateScoreModal(true)}
					/>
				</div>
			</div>

			<div className="absolute mt-14 h-[72px] flex flex-row justify-between items-center w-auto border-t border-t-border px-8 py-[14px] rounded-t-16 bottom-0 right-0 z-20 bg-white w-full">
				<div></div>
				<div className="flex flex-row self-left items-center gap-x-4">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						title={t("basics.previous")}
						onClick={() => navigate(`/app/lists/create/filter/${id}`)}
					/>
					<Button isLoading={false} title={t("basics.next")} onClick={submit} />
				</div>
			</div>
			{showCreateScoreModal && (
				<CreateScoreModal
					data={data}
					isOpen={showCreateScoreModal}
					setIsOpen={setShowCreateScoreModal}
				/>
			)}
		</div>
	);
};
