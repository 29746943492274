import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { CronOverview } from "./pages/CronOverview/CronOverview";

export const Cron: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<CronOverview />} path="/" />
		</Routes>
	);
};
