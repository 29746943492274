import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { JobOverview } from "./pages/JobOverview/JobOverview";
import { JobDetail } from "./pages/JobDetail/JobDetail";

export const Job: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<JobOverview />} path="/" />
			<Route element={<JobDetail />} path="/:id" />
		</Routes>
	);
};
