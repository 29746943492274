import { createSlice } from "@reduxjs/toolkit";

import { LiPeopleState } from "@/types/liPeople";

import { RootState } from "@/redux";

import { liPeopleApi } from "./liPeople.api";

const initialState: LiPeopleState = {
	liPeoples: [],
};

export const liPeopleSlice = createSlice({
	name: "liPeople",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(liPeopleApi.endpoints.getLiPeoples.matchFulfilled, (state, { payload }) => {
			state.liPeoples = payload.data;
		});
	},
});

export const {} = liPeopleSlice.actions;
export const liPeopleReducer = liPeopleSlice.reducer;
export const liPeopleSelector = (state: RootState) => state[liPeopleSlice.name];
