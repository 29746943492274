import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Stack, TextField, Typography } from "@mui/material";

import { faCopy, faPen, faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import {
	useDeleteMapMutation,
	useGetMapsQuery,
	useToggleMapMutation,
} from "@/pages/Private/redux/map/map.api";
import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import { Toggle } from "@/components/Toggle/Toggle";

import { ListStatus } from "@/enum/list.enum";

import { mapSelector } from "@/pages/Private/redux/map/map.slice";

import { useAppSelector } from "@/redux/hooks";

import { Map, ToggleMap } from "../schema/map";
import { EditMapModal } from "./EditMapModal";
import { JobStatus } from "../../Job/components/JobStatus";
import { CreateMapModal } from "./CreateMapModal";

export const MapTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`map.${key}`), [t]);
	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedMap, setSelectedMap] = useState<Map | null>(null);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [deleteMap, { isLoading: isDeleteLoading }] = useDeleteMapMutation();
	const [toggleMap] = useToggleMapMutation();

	const { maps: data } = useAppSelector(mapSelector);

	const { isLoading } = useGetMapsQuery({
		page,
		limit,
		searchValue: query,
	});

	const columns: TableColumn<Map>[] = useMemo(() => {
		const handleToggle = async (row: Map) => {
			const updater: ToggleMap = {
				id: row.id,
				activeStatus:
					row.activeStatus === ListStatus.ACTIVE ? ListStatus.PAUSED : ListStatus.ACTIVE,
			};

			await toggleMap({ ...updater }).unwrap();
		};

		return [
			{
				label: "",
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								<Toggle
									isChecked={row?.activeStatus === ListStatus.ACTIVE}
									onChange={() => {
										if (row.id && row.activeStatus !== ListStatus.ERROR) {
											handleToggle(row);
										}
									}}
								/>
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Link href={`/app/maps/${row?.id}`}>
								<Typography color="inherit" mb={0} variant="body2">
									{row?.name}
								</Typography>
							</Link>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("status"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<JobStatus status={row.status} />
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("query"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.query}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("countOfResults"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row.mapBusiness?.length}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<PermissionCheck requiredPermissions={[PermissionRoles.JOB]}>
							<>
								<Box
									sx={{
										color: "neutral.500",
										"&:hover": {
											cursor: "pointer",
											color: "primary.main",
										},
									}}
									onClick={() => {
										handleDuplicateMap(row);
									}}
								>
									<Icon icon={faCopy} size="xl" />
								</Box>
								<Box
									sx={{
										color: "neutral.500",
										"&:hover": {
											color: "primary.main",
											cursor: "pointer",
										},
									}}
									onClick={() => handleEditMap(row)}
								>
									<Icon icon={faPen} size="xl" />
								</Box>
								<Box
									sx={{
										color: "neutral.500",
										"&:hover": {
											color: "primary.main",
											cursor: "pointer",
										},
									}}
									onClick={() => handleDeleteModal(row)}
								>
									<Icon icon={faTrashCan} size="xl" />
								</Box>
							</>
						</PermissionCheck>
					</Stack>
				),
			},
		];
	}, [toggleMap, ts]);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	const handleDuplicateMap = (row: Map) => {
		setIsInviteModalOpen(true);
		setSelectedMap(row);
	};

	const handleEditMap = (row: Map) => {
		setShowEditModal(true);
		setSelectedMap(row);
	};

	const handleDeleteModal = (row: Map) => {
		setShowDeleteModal(true);
		setSelectedMap(row);
	};

	const handleDeleteMap = async () => {
		try {
			await deleteMap({ id: selectedMap?.id ?? 0 }).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data?.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data?.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteMap}
					isLoading={isDeleteLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedMap?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}

			{showEditModal && selectedMap && (
				<EditMapModal isOpen={showEditModal} map={selectedMap} setIsOpen={setShowEditModal} />
			)}

			{isInviteModalOpen && selectedMap && (
				<CreateMapModal
					isOpen={isInviteModalOpen}
					map={selectedMap}
					setIsOpen={setIsInviteModalOpen}
				/>
			)}
		</div>
	);
};
