import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/system";

import { FileUploader } from "react-drag-drop-files";

import { Modal } from "@/components";

import { useUploadBlacklistMutation } from "@/pages/Private/redux/blacklist/blacklist.api";

import { UploadBlacklist, UploadBlacklistSchema } from "../schema/blacklist";

interface createCsvEnrichmentModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	isGlobal: boolean;
}

export const UploadBlacklistModal: FunctionComponent<createCsvEnrichmentModalProps> = ({
	isOpen,
	setIsOpen,
	isGlobal,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`blacklist.${key}`);
	const [uploadBlacklist, { isLoading }] = useUploadBlacklistMutation();
	const { handleSubmit, setValue } = useForm<UploadBlacklist>({
		defaultValues: {
			file: "",
		},
		resolver: zodResolver(UploadBlacklistSchema),
	});

	const onSubmit = async (values: UploadBlacklist) => {
		try {
			const { file: fileRaw } = values;

			const formData = new FormData();
			const res: Response = await fetch(fileRaw);
			const blob: Blob = await res.blob();
			const file = new File([blob], "file.csv");

			formData.append("file", file);
			formData.append("type", "csv");
			formData.append("isGlobal", isGlobal?.toString() || "false");

			await uploadBlacklist(formData).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<FileUploader
					handleChange={(file: Blob | MediaSource) => setValue("file", URL.createObjectURL(file))}
					name="file"
					types={["csv"]}
				/>

				{/* {getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null} */}
			</Stack>
		</Modal>
	);
};
