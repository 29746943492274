import { IFilter } from "./general-filter";

const content: IFilter = {
	name: "content",
	filterParam: "li_profile_entry_result.content",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const contentResponse: IFilter = {
	name: "contentResponse",
	filterParam: "li_profile_entry_result.contentResponse",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const LiProfileFilter: IFilter[] = [content, contentResponse];
