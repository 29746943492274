import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/system";
import { Button, Card, CardContent, Unstable_Grid2 as Grid, Typography } from "@mui/material";

import FileIcon from "@assets/icons/file.svg";

import { useUploadMutation } from "@/pages/Private/redux/account/account.api";

import { CsvUploader } from "@/components/CsvUploader/CsvUploader";

interface LinkedinConnectionsProps {
	amount: number;
}

export const LinkedinConnections: FunctionComponent<LinkedinConnectionsProps> = ({ amount }) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`account.${key}`);

	const [showAvatarModal, setShowAvatarModal] = useState<boolean>(false);
	const [fileRaw, setAvatarSrc] = useState<string>("");

	const [upload] = useUploadMutation();

	useEffect(() => {
		if (fileRaw) {
			const uploadMyFile = async (fileRaw: string) => {
				if (!fileRaw) {
					return;
				}

				try {
					const formData = new FormData();
					const res: Response = await fetch(fileRaw);
					const blob: Blob = await res.blob();
					const file = new File([blob], "file.csv");

					formData.append("file", file);
					formData.append("type", "csv");
					await upload(formData).unwrap();
				} catch (err) {
					console.error(err);
				} finally {
					setAvatarSrc("");
				}
			};

			uploadMyFile(fileRaw);
		}
	}, [fileRaw, upload]);

	return (
		<>
			<Card>
				<CardContent>
					<Grid container spacing={3}>
						<Grid md={4} xs={12}>
							<Typography variant="h6">
								{ts("linkedinConnections")} {amount > 0 ? amount : ""}
							</Typography>
						</Grid>

						<Grid md={8} xs={12}>
							<Stack spacing={3}>
								<Box>
									<Stack direction="row" spacing={2}>
										<Box onClick={() => setShowAvatarModal(true)}>
											<img alt="file" className="h-full" src={FileIcon} />
										</Box>

										<Button onClick={() => setShowAvatarModal(true)}>{t("basics.upload")}</Button>
										<CsvUploader
											handleClose={() => setShowAvatarModal(false)}
											handleFile={(file) => setAvatarSrc(URL.createObjectURL(file))}
											isOpened={showAvatarModal}
										/>
									</Stack>
								</Box>
							</Stack>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</>
	);
};
