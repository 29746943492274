import { Chip } from "@mui/material";
import { FunctionComponent } from "react";

import { JobsScrapeStatus, JobScrapeStatus } from "@/enum/scrape-enum";
import { Spinner } from "@/components/Spinner/Spinner";

interface JobStatusProps {
	status: JobsScrapeStatus | JobScrapeStatus;
}

export const JobStatus: FunctionComponent<JobStatusProps> = ({ status }) => {
	switch (status) {
		case JobsScrapeStatus.DRAFT:
			return <Chip color="info" label="Not yet scraped" />;
		case JobsScrapeStatus.TRIGGERED:
			return <Chip color="primary" label="Scheduled" />;
		case JobsScrapeStatus.IN_PROGRESS:
			return (
				<div className="invert">
					<Spinner />
				</div>
			);
		case JobsScrapeStatus.DONE:
			return <Chip color="success" label="Done" />;
		default:
			return <></>;
	}

	return <></>;
};
