import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { useNavigate } from "react-router-dom";

import { Modal } from "@/components";

import { useCreateScoreMutation } from "@/pages/Private/redux/score/score.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { CreateScore, CreateScoreSchema } from "../schema/score.schema";

interface createScoreModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateScoreModal: FunctionComponent<createScoreModalProps> = ({
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const ts = (key: string) => t(`score.${key}`);
	const [createScore, { isLoading, error }] = useCreateScoreMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<CreateScore>({
		defaultValues: {
			name: "",
			description: "",
		},
		resolver: zodResolver(CreateScoreSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: CreateScore) => {
		try {
			const createdList = await createScore(values).unwrap();

			setIsOpen(false);

			navigate(`/app/score/${createdList.id}`);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			size="sm"
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>

				<TextField
					error={!!errors.description?.message}
					label={ts("description")}
					placeholder={ts("description")}
					{...register("description")}
				/>

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</Stack>
		</Modal>
	);
};
