import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useSearch = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const paramPageValue = searchParams.get("page") || 1;

	const [searchValue, setSearchValue] = useState<string>("");
	const [query, setQuery] = useState<string>("");
	const [page, setPage] = useState<number>(Number(paramPageValue));
	const [limit, setLimit] = useState<number>(15);

	const handleSetPage = (pageParam: number) => {
		if (+pageParam !== page) {
			setPage(pageParam);
			if (!searchParams.has("page")) {
				searchParams.append("page", pageParam.toString());
			} else {
				searchParams.set("page", pageParam.toString());
			}

			setSearchParams(searchParams);
		}
	};

	const searchTimer = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (searchTimer.current) {
			clearTimeout(searchTimer.current);
		}

		searchTimer.current = setTimeout(() => {
			setQuery(searchValue);
		}, 800);
	}, [searchValue]);

	return {
		searchValue,
		setSearchValue,
		query,
		page,
		setPage: handleSetPage,
		limit,
		setLimit,
		searchParams,
		setSearchParams,
	};
};
