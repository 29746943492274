export enum ButtonStatus {
	SUCCESS = "SUCCESS",
	ACTION = "ACTION",
}

export enum ButtonColor {
	BRAND = "BRAND",
	ACTION = "ACTION",
	ACTION_SECONDARY = "ACTION_SECONDARY",
	DANGER = "DANGER",
	ERROR = "error",
	DANGER_SECONDARY = "DANGER_SECONDARY",
	WARNING = "WARNING",
	SUCCESS = "SUCCESS",
	DISABLED = "DISABLED",
}

export enum ButtonSize {
	XS = "XS",
	S = "S",
	M = "M",
	ML = "ML",
	L = "L",
}

export enum FontSize {
	XS = "XS",
	SM = "SM",
}
