import { ChangeEvent, FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { useAppSelector } from "@/redux/hooks";
import { authSelector } from "@/pages/Public/redux/auth.slice";

import { UserLanguage } from "@/enum/user.enum";

import { ProfileInformation } from "./components/ProfileInformation";
import { ProfileSecurity } from "./components/ProfileSecurity";
import { LinkedinConnections } from "./components/LinkedinConnections";
import { LinkedinAutomation } from "./components/LinkedinAutomation";
import { ProfileVisits } from "./components/ProfileVisits";
import { ConnectionScraping } from "./components/ConnectionScraping";

enum Tabs {
	GENERAL = "GENERAL",
	LINKEDIN_CONNECTIONS = "LINKEDIN_CONNECTIONS",
	NOTIFICATIONS = "NOTIFICATIONS",
}

export const Account: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`account.${key}`);

	const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.GENERAL);

	const { user } = useAppSelector(authSelector);

	const tabs = [
		{ label: ts("tabs.general"), value: Tabs.GENERAL },
		{ label: ts("tabs.linkedinConnections"), value: Tabs.LINKEDIN_CONNECTIONS },
	];

	const handleTabsChange = useCallback((_: ChangeEvent<{}>, value: Tabs): void => {
		setCurrentTab(value);
	}, []);

	return (
		<>
			<Typography mb={4} variant="h4">
				{ts("title")}
			</Typography>

			<TabContext value={currentTab}>
				<TabList aria-label="Account tabs" sx={{ mb: 4 }} onChange={handleTabsChange}>
					{tabs.map((tab, index) => (
						<Tab key={index} label={tab.label} value={tab.value} />
					))}
				</TabList>

				<TabPanel sx={{ p: 0 }} value={Tabs.GENERAL}>
					<Stack spacing={4}>
						<ProfileInformation
							firstName={user?.firstName ?? ""}
							lastName={user?.lastName ?? ""}
							linkedinUrl={user?.linkedinUrl ?? ""}
							userLanguage={user?.userLanguage ?? UserLanguage.EN}
						/>
						<ProfileSecurity is2FAEnabled={user?.is2FAEnabled || false} />
						<LinkedinAutomation autoAccept={user?.autoAccept || false} />
						<ProfileVisits profileVisits={user?.profileVisits || false} />
						<ConnectionScraping connectionScraping={user?.connectionScraping || false} />
					</Stack>
				</TabPanel>

				<TabPanel sx={{ p: 0 }} value={Tabs.LINKEDIN_CONNECTIONS}>
					<Stack spacing={4}>
						<LinkedinConnections amount={user?.prospects?.length || 0} />
					</Stack>
				</TabPanel>
				{/* 
				<TabPanel sx={{ p: 0 }} value={Tabs.NOTIFICATIONS}>
					{ts("tabs.notifications")}
				</TabPanel> */}
			</TabContext>
		</>
	);
};
