import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { AiEnrichmentTarget } from "@/enum/ai-enrichment.enum";

import { OpenAiPromptSchema } from "./openAiPrompt";

const { REQUIRED } = ERROR_TYPE;

export const OpenAiSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	aiEnrichmentTarget: z.nativeEnum(AiEnrichmentTarget),
	prompts: z.array(OpenAiPromptSchema),
	jsonResponse: z.boolean().optional(),
});

export const AiEnrichmentResultSchema = z.object({
	id: z.number(),
	aiEnrichmentId: z.number(),
	aiEnrichmentTarget: z.nativeEnum(AiEnrichmentTarget),
	organizationId: z.number().nullable(),
	prospectId: z.number().nullable(),
	results: z.string().nullable(),
	aiEnrichment: OpenAiSchema,
});

export const CreateOpenAiSchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	aiEnrichmentTarget: z.nativeEnum(AiEnrichmentTarget),
});

export const OpenAiResponseSchema = z.object({
	data: z.array(OpenAiSchema),
	meta: MetaDataSchema,
});

export type OpenAi = z.infer<typeof OpenAiSchema>;

export type CreateOpenAi = z.infer<typeof CreateOpenAiSchema>;

export type OpenAiResponse = z.infer<typeof OpenAiResponseSchema>;
