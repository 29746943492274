import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/redux";

import { RequestsState } from "@/types/requests";

import { requestsApi } from "./request.api";

const initialState: RequestsState = {
	requests: [],
};

export const requestsSlice = createSlice({
	name: "requests",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(requestsApi.endpoints.getRequests.matchFulfilled, (state, { payload }) => {
			state.requests = payload.data;
		});
	},
});

export const {} = requestsSlice.actions;
export const requestsReducer = requestsSlice.reducer;
export const requestsSelector = (state: RootState) => state[requestsSlice.name];
