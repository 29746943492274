import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { RequestsOverview } from "./pages/Requests";

export const Requests: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<RequestsOverview />} path="/" />
			<Route element={<RequestsOverview />} path="/:type/:id" />
		</Routes>
	);
};
