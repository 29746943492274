import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { useCreateMapMutation, useUpdateMapMutation } from "@/pages/Private/redux/map/map.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { MapScrapeType } from "@/enum/map.enum";

import { Button } from "@/components/Button/Button";

import { InputField } from "@/components/InputField/InputField";

import { ButtonColor } from "@/components/Button/types";

import { CreateMap, CreateMapSchema, Map } from "../schema/map";
import { List } from "../../List/schema/list";

interface CreateMapFormProps {
	map?: Map | null;
	list: List;
	handleSelect?: (map: Map | null) => void;
	handleDuplicate?: (map: Map | null) => void;
}

export const CreateMapForm: FunctionComponent<CreateMapFormProps> = ({
	map,
	list,
	handleSelect,
	handleDuplicate,
}) => {
	const initialState = {
		query: "",
		limit: undefined,
		lat: undefined,
		lng: undefined,
		language: undefined,
		region: undefined,
		zoom: undefined,
		mapScrapeType: map?.mapScrapeType || (list.scrapeLevel as MapScrapeType),
		repeatDaily: false,
	};

	const { t } = useTranslation();
	const ts = (key: string) => t(`map.${key}`);

	const [update] = useUpdateMapMutation();
	const [createMap, { error, isLoading }] = useCreateMapMutation();
	const [url, setUrl] = useState<string | null>(null);

	const [currentFormState, setCurrentFormState] = useState<CreateMap>(initialState);

	const {
		handleSubmit,
		setValue,
		formState: { errors },
		reset,
	} = useForm<CreateMap>({
		defaultValues: {
			query: map?.query ?? "",
			limit: map?.limit ?? undefined,
			lat: map?.lat ?? undefined,
			lng: map?.lng ?? undefined,
			language: map?.language ?? undefined,
			region: map?.region ?? undefined,
			zoom: map?.zoom ?? undefined,
			mapScrapeType: map?.mapScrapeType || (list.scrapeLevel as MapScrapeType),
			repeatDaily: false,
		},
		resolver: zodResolver(CreateMapSchema),
	});

	const handleSaveFormState = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(value: any, name: keyof CreateMap) => {
			setCurrentFormState({
				...currentFormState,
				[name]: value,
			});

			setValue(name, value, {
				shouldDirty: true,
				shouldValidate: true,
			});
		},
		[currentFormState, setValue]
	);

	useEffect(() => {
		if (map) {
			const formState = {
				query: map?.query ?? "",
				limit: map?.limit ?? undefined,
				lat: map?.lat ?? undefined,
				lng: map?.lng ?? undefined,
				language: map?.language ?? undefined,
				region: map?.region ?? undefined,
				zoom: map?.zoom ?? undefined,
				mapScrapeType: map?.mapScrapeType || (list.scrapeLevel as MapScrapeType),
				repeatDaily: false,
			};

			if (handleDuplicate) {
				reset({
					...formState,
				});
				setCurrentFormState({
					...formState,
				});
			} else {
				reset({
					id: map.id,
					...formState,
				});
				setCurrentFormState({
					...formState,
					id: map?.id,
				});
			}
		} else {
			reset();
		}
	}, [handleDuplicate, list.scrapeLevel, map, reset]);

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	useEffect(() => {
		if (list.scrapeLevel) {
			setValue("mapScrapeType", list.scrapeLevel as MapScrapeType);
			setValue("repeatDaily", false);
		}
	}, [list.scrapeLevel, setValue]);

	const onSubmit = async (values: CreateMap) => {
		try {
			if (values.id && !handleDuplicate) {
				await update({ id: values.id, ...values, listId: list.id }).unwrap();
			} else {
				await createMap({ ...values, listId: list.id }).unwrap();
			}

			reset();
			setUrl(null);
			handleSelect?.(null);
			handleDuplicate?.(null);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		if (url && url.includes("https://www.google.com/maps/search/")) {
			const [, allParams] = url.split("https://www.google.com/maps/search/");
			const [query, params] = allParams.split("/");
			const [lat, lng, zoom] = params.replaceAll("@", "")?.replaceAll("z", "")?.split(",");

			setValue("lat", +lat, { shouldValidate: true, shouldDirty: true });
			handleSaveFormState(+lat, "lat");
			setValue("lng", +lng, { shouldValidate: true, shouldDirty: true });
			handleSaveFormState(+lng, "lng");
			setValue("zoom", +zoom, { shouldValidate: true, shouldDirty: true });
			handleSaveFormState(+zoom, "zoom");

			setValue("query", query.replaceAll("+", " "), {
				shouldValidate: true,
				shouldDirty: true,
			});
			handleSaveFormState(query.replaceAll("+", " "), "query");
		}
	}, [handleSaveFormState, setValue, url]);

	const buttonTitle =
		handleDuplicate && map?.id
			? t("basics.copyCriteria")
			: map?.id
			? t("basics.save")
			: t("basics.addCriteria");

	return (
		<div className="">
			<div className="text-md mb-5">Sources</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setUrl(event.target.value);
					}}
					label={ts("fromUrl")}
					name={"url"}
					placeholder={ts("fromUrlPlaceholder")}
					value={url || ""}
				/>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "query");
					}}
					label={ts("query")}
					name={"query"}
					placeholder={ts("queryPlaceholder")}
					value={currentFormState?.query ?? ""}
				/>
			</div>

			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "region");
					}}
					label={ts("region")}
					name={"region"}
					placeholder={ts("regionPlaceholder")}
					showError={!!errors.region?.message}
					value={currentFormState?.region ?? ""}
				/>

				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "language");
					}}
					label={ts("language")}
					name={"language"}
					placeholder={ts("languagePlaceholder")}
					showError={!!errors.language?.message}
					value={currentFormState?.language ?? ""}
				/>
			</div>

			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						if (event.target.value === "") {
							handleSaveFormState(undefined, "limit");
						} else {
							handleSaveFormState(+event.target.value, "limit");
						}
					}}
					label={ts("limit")}
					name={"limit"}
					placeholder={ts("limitPlaceholder")}
					showError={!!errors.limit?.message}
					type="number"
					value={currentFormState?.limit ?? ""}
				/>

				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						if (event.target.value === "") {
							handleSaveFormState(undefined, "zoom");
						} else {
							handleSaveFormState(+event.target.value, "zoom");
						}
					}}
					label={ts("zoom")}
					name={"zoom"}
					placeholder={ts("zoomPlaceholder")}
					showError={!!errors.zoom?.message}
					type="number"
					value={currentFormState?.zoom ?? ""}
				/>
			</div>

			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						if (event.target.value === "") {
							handleSaveFormState(undefined, "lat");
						} else {
							handleSaveFormState(+event.target.value, "lat");
						}
					}}
					label={ts("lat")}
					name={"lat"}
					placeholder={ts("latPlaceholder")}
					showError={!!errors.lat?.message}
					type="lat"
					value={currentFormState?.lat ?? ""}
				/>

				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						if (event.target.value === "") {
							handleSaveFormState(undefined, "lng");
						} else {
							handleSaveFormState(+event.target.value, "lng");
						}
					}}
					label={ts("lng")}
					name={"lng"}
					placeholder={ts("lngPlaceholder")}
					showError={!!errors.lng?.message}
					type="lng"
					value={currentFormState?.lng ?? ""}
				/>
			</div>

			{getAllErrors(error, formErrors).length
				? renderErrorMessages(getAllErrors(error, formErrors))
				: null}

			<div className="flex gap-4 w-full justify-end">
				{handleSelect && (
					<div className="flex max-w-[100px] w-full">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							title="cancel"
							onClick={() => {
								handleSelect(null);
								handleDuplicate?.(null);
								reset(initialState);
								setCurrentFormState(initialState);
							}}
						/>
					</div>
				)}

				<div className="flex max-w-[150px] w-full">
					<Button isLoading={isLoading} title={buttonTitle} onClick={handleSubmit(onSubmit)} />
				</div>
			</div>
		</div>
	);
};
