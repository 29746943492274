import { createSlice } from "@reduxjs/toolkit";

import { CronState } from "@/types/cron";

import { RootState } from "@/redux";

import { cronApi } from "./cron.api";

const initialState: CronState = {
	crons: [],
};

export const cronSlice = createSlice({
	name: "cron",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(cronApi.endpoints.getCrons.matchFulfilled, (state, { payload }) => {
			state.crons = payload.data;
		});
	},
});

export const {} = cronSlice.actions;
export const cronReducer = cronSlice.reducer;
export const cronSelector = (state: RootState) => state[cronSlice.name];
