import { createSlice } from "@reduxjs/toolkit";

import { OpenAiState } from "@/types/openAi";

import { RootState } from "@/redux";

import { openAiApi } from "./openAi.api";

const initialState: OpenAiState = {
	openAis: [],
};

export const openAiSlice = createSlice({
	name: "openAi",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(openAiApi.endpoints.getOpenAis.matchFulfilled, (state, { payload }) => {
			state.openAis = payload.data;
		});
	},
});

export const {} = openAiSlice.actions;
export const openAiReducer = openAiSlice.reducer;
export const openAiSelector = (state: RootState) => state[openAiSlice.name];
