import { configureStore, isRejectedWithValue, Middleware } from "@reduxjs/toolkit";

import { authReducer, authSlice } from "@/pages/Public/redux/auth.slice";

import { apolloReducer, apolloSlice } from "@/pages/Private/redux/apollo/apollo.slice";

import { companiesReducer, companiesSlice } from "@/pages/Private/redux/companies/companies.slice";

import {
	csvEnrichmentReducer,
	csvEnrichmentSlice,
} from "@/pages/Private/redux/csvEnrichment/csvEnrichment.slice";

import { liEventReducer, liEventSlice } from "@/pages/Private/redux/liEvent/liEvent.slice";

import { liPeopleReducer, liPeopleSlice } from "@/pages/Private/redux/liPeople/liPeople.slice";

import { openAiReducer, openAiSlice } from "@/pages/Private/redux/openAi/openAi.slice";

import { listReducer, listSlice } from "@/pages/Private/redux/list/list.slice";

import { lemlistSlice } from "@/pages/Private/redux/lemlist/lemlist.slice";

import { customEventSlice } from "@/pages/Private/redux/customEvent/customEvent.slice";

import { scoreSlice } from "@/pages/Private/redux/score/score.slice";

import { blacklistSlice } from "@/pages/Private/redux/blacklist/blacklist.slice";

import {
	liCompanyActivityReducer,
	liCompanyActivitySlice,
} from "@/pages/Private/redux/liCompanyActivity/liCompanyActivity.slice";

import { jobReducer, jobSlice } from "@/pages/Private/redux/job/job.slice";
import {
	liProfileVisitsReducer,
	liProfileVisitsSlice,
} from "@/pages/Private/redux/liProfileVisits/liProfileVisits.slice";

import { mapSlice } from "@/pages/Private/redux/map/map.slice";

import { requestsSlice } from "@/pages/Private/redux/request/request.slice";

import { liProfileReducer, liProfileSlice } from "@/pages/Private/redux/liProfile/liProfile.slice";

import { cronSlice } from "@/pages/Private/redux/cron/cron.slice";

import { liPostSlice } from "@/pages/Private/redux/liPost/liPost.slice";

import { usageSlice } from "@/pages/Private/redux/usage/usage.slice";

import { ROUTE_CONFIG } from "../routes/config";
import { api } from "./api";

const { LOGOUT, ERROR_PAGE } = ROUTE_CONFIG;

const rtkRejectQueryMiddleware: Middleware = () => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		if (action.payload.status === 401) {
			if (
				!["login", "authTwoFactorCode", "verifyTwoFactor", "disableTwoFactor"].includes(
					action.meta.arg.endpointName
				)
			) {
				window.location.href = LOGOUT;
			}
		} else if (action.payload.status === 403) {
			window.location.href = ERROR_PAGE;
		}
	}

	return next(action);
};

export const store = configureStore({
	reducer: {
		[authSlice.name]: authReducer,
		[api.reducerPath]: api.reducer,
		[apolloSlice.name]: apolloReducer,
		[jobSlice.name]: jobReducer,
		[companiesSlice.name]: companiesReducer,
		[csvEnrichmentSlice.name]: csvEnrichmentReducer,
		[liEventSlice.name]: liEventReducer,
		[liPeopleSlice.name]: liPeopleReducer,
		[liCompanyActivitySlice.name]: liCompanyActivityReducer,
		[liProfileVisitsSlice.name]: liProfileVisitsReducer,
		[liProfileSlice.name]: liProfileReducer,
		[openAiSlice.name]: openAiReducer,
		[listSlice.name]: listReducer,
		[lemlistSlice.name]: lemlistSlice.reducer,
		[customEventSlice.name]: customEventSlice.reducer,
		[scoreSlice.name]: scoreSlice.reducer,
		[blacklistSlice.name]: blacklistSlice.reducer,
		[mapSlice.name]: mapSlice.reducer,
		[requestsSlice.name]: requestsSlice.reducer,
		[cronSlice.name]: cronSlice.reducer,
		[liPostSlice.name]: liPostSlice.reducer,
		[usageSlice.name]: usageSlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false })
			.concat(rtkRejectQueryMiddleware)
			.concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
