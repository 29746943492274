import { FunctionComponent, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { AssignStatus } from "@/enum/list.enum";

interface ListPersonStatusProps {
	status: AssignStatus;
}

export const ListPersonStatus: FunctionComponent<ListPersonStatusProps> = ({ status }) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`list.${key}`), [t]);

	switch (status) {
		case AssignStatus.DRAFT:
			return (
				<div className="px-2.5 py-1.5 bg-status-info-light rounded-xl text-status-info text-ssm flex flex-row items-center">
					{ts(`assignStatus.unassigned`)}
				</div>
			);
		case AssignStatus.ASSIGNED:
			return (
				<div className="px-2.5 py-1.5 bg-light-blue rounded-xl text-brand-light text-ssm flex flex-row items-center">
					{ts(`assignStatus.assigned`)}
				</div>
			);
		case AssignStatus.NOT_NOW:
			return (
				<div className="px-2.5 py-1.5 bg-status-running-light rounded-xl text-status-running text-ssm flex flex-row items-center">
					{ts(`assignStatus.notNow`)}
				</div>
			);
		case AssignStatus.BLACKLIST:
			return (
				<div className="px-2.5 py-1.5 bg-status-error-light rounded-xl text-status-error text-ssm flex flex-row items-center">
					{ts(`assignStatus.blacklist`)}
				</div>
			);
		default:
			return <></>;
	}
};
