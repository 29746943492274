import { z } from "zod";

import { BlacklistType } from "@/enum/blacklist.enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

const { REQUIRED } = ERROR_TYPE;

export const BlacklistSchema = z.object({
	id: z.number(),
	domain: z.string().min(4, { message: REQUIRED }).trim(),
	email: z.string().min(4, { message: REQUIRED }).trim(),
	linkedinUrl: z.string().min(4, { message: REQUIRED }).trim(),
	type: z.nativeEnum(BlacklistType),
	isGlobal: z.boolean().optional(),
});

export const CreateBlacklistSchema = z.object({
	domain: z.string().trim().optional(),
	email: z.string().trim().optional(),
	linkedinUrl: z.string().trim().optional(),
	type: z.nativeEnum(BlacklistType),
	isGlobal: z.boolean().optional(),
});

export const UploadBlacklistSchema = z.object({
	file: z.string(),
});

export const BlacklistResponseSchema = z.object({
	data: z.array(BlacklistSchema),
	meta: MetaDataSchema,
});

export type Blacklist = z.infer<typeof BlacklistSchema>;

export type CreateBlacklist = z.infer<typeof CreateBlacklistSchema>;
export type UploadBlacklist = z.infer<typeof UploadBlacklistSchema>;

export type BlacklistResponse = z.infer<typeof BlacklistResponseSchema>;
