import { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";

import { Avatar } from "@material-tailwind/react";

import { SearchField } from "@/components/SearchField/SearchField";
import { classNames } from "@/utils/classNames";

export interface LinkedinPersonSuggestionItem {
	urn: string;
	title: string;
	subtitle?: string;
	image?: string;
}

export interface LinkedinPersonSuggestionProps {
	handleSelect: (value?: LinkedinPersonSuggestionItem) => void;
	handleSearch?: (value: string) => void;
	selectedItem: LinkedinPersonSuggestionItem | undefined;
	data: LinkedinPersonSuggestionItem[];
	label?: string;
	testId?: string;
	floating?: boolean;
	icon?: IconDefinition;
}

export const LinkedinPersonSuggestion: FunctionComponent<LinkedinPersonSuggestionProps> = ({
	handleSelect,
	handleSearch,
	selectedItem,
	data,
	label,
	testId,
	floating,
}) => {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState("");

	// Dropdown Logic
	const [showDropdown, setShowDropdown] = useState(false);
	const selectFieldRef = useRef<HTMLDivElement>(null);

	const handleSelectItem = (selected: LinkedinPersonSuggestionItem) => {
		handleSelect(selected);
		setShowDropdown(false);
	};

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const selectedItem = data.find((item) => item.title === e.target.value);

		handleSearch && handleSearch(e.target.value);

		setSearchValue(e.target.value);

		if (selectedItem) {
			handleSelect(selectedItem);
		}

		setShowDropdown(true);
	};

	useEffect(() => {
		const handleClick = ({ target }: MouseEvent) => {
			if (!(selectFieldRef.current && selectFieldRef.current?.contains(target as Node))) {
				if (showDropdown) {
					setShowDropdown(false);
				}
			}
		};

		window.addEventListener("click", handleClick);

		return () => window.removeEventListener("click", handleClick);
	}, [selectFieldRef, showDropdown]);

	useEffect(() => {
		if (selectedItem) {
			setSearchValue(selectedItem.title);
		}
	}, [selectedItem]);

	const renderResults = () => {
		if (!data.length) {
			return <p className="py-2.5 px-3 text-sm italic text-gray-700">{t("basics.noResults")}</p>;
		}

		return (
			<div className={data.length ? "border-b border-b-border z-100" : ""}>
				{data.map((item) => {
					const { urn, title, image, subtitle } = item;

					return (
						<button
							key={`option-select-${urn}`}
							className="flex items-start gap-4 mx-1 my-1"
							onClick={() => handleSelectItem({ urn, title, image, subtitle })}
						>
							{image ? (
								<Avatar height={40} size="xs" src={image} variant="rounded" width={40} />
							) : (
								<div className="w-[40px] h-[40px]"></div>
							)}
							<div>
								<div className="text-left text-xs font-bold">{title}</div>
								<div className="text-left text-xs text-gray-700 font-normal">{subtitle}</div>
							</div>
						</button>
					);
				})}
			</div>
		);
	};

	const handleClickInput = () => setShowDropdown(!showDropdown);

	return (
		<div ref={selectFieldRef} className="relative w-full mt-0">
			{label && (
				<label className="block mb-2 text-sm font-medium text-gray-900 appearance-none">
					{label}
				</label>
			)}
			<div className="relative">
				{handleSearch ? (
					<SearchField
						className="!top-[0px] !left-[0px] h-[40px] ml-1 mt-1"
						handleChange={handleSearchChange}
						handleReset={() => {
							setSearchValue("");
							handleSelect();
						}}
						icon={
							selectedItem?.image ? (
								<Avatar className="h-full mr-4" src={selectedItem.image} />
							) : undefined
						}
						name="searchForLinkedinPerson"
						placeholder={t("basics.pleaseSelect")}
						testId={testId}
						value={searchValue}
						onClick={handleClickInput}
					/>
				) : (
					""
				)}
			</div>
			{showDropdown && (
				<div
					className={classNames(
						"w-full mt-0.5 overflow-hidden bg-white border border-gray-200 rounded-md max-h-[220px] overflow-y-auto bb-scrollbar-darker",
						floating ? "absolute z-50" : "relative"
					)}
				>
					{renderResults()}
				</div>
			)}
		</div>
	);
};
