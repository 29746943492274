import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/redux";

import { LiCompanyActivityState } from "@/types/liCompanyActivity";

import { liCompanyActivityApi } from "./liCompanyActivity.api";

const initialState: LiCompanyActivityState = {
	liCompanyActivities: [],
};

export const liCompanyActivitySlice = createSlice({
	name: "liCompanyActivity",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			liCompanyActivityApi.endpoints.getLiCompanyActivitys.matchFulfilled,
			(state, { payload }) => {
				state.liCompanyActivities = payload.data;
			}
		);
	},
});

export const {} = liCompanyActivitySlice.actions;
export const liCompanyActivityReducer = liCompanyActivitySlice.reducer;
export const liCompanyActivitySelector = (state: RootState) => state[liCompanyActivitySlice.name];
