import { ChangeEvent, FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { default as Chart } from "react-apexcharts";

import { LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { useSearch } from "@/utils";

import { useGetUsageQuery } from "@/pages/Private/redux/usage/usage.api";

import { Usage } from "../schema/usage";

export const UsageTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`usage.${key}`), [t]);
	const { page, setPage, limit, setLimit } = useSearch();
	const { data, isLoading } = useGetUsageQuery();

	const columns: TableColumn<Usage>[] = useMemo(() => {
		const col: TableColumn<Usage>[] = [
			{
				label: ts("filtered"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.filtered} / {data?.limits.filtered}
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("identified"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.identified} / {data?.limits.identified}
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("time"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.month && (
								<>
									{row.month.toString().padStart(2, "0")}.{row.year}
								</>
							)}
						</Stack>
					</Stack>
				),
			},
		];

		return col;
	}, [ts, data]);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	const currentMonth = new Date().getMonth() + 1;
	const currentYear = new Date().getFullYear();

	const valuesOfCurrentMonth = useMemo(() => {
		return data?.usage.find((item) => item.month === currentMonth && item.year === currentYear);
	}, [currentMonth, currentYear, data]);

	const aiValuesOfCurrentMonth = useMemo(() => {
		return data?.aiUsage.find((item) => item.month === currentMonth && item.year === currentYear);
	}, [currentMonth, currentYear, data]);

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full flex flex-col">
			<div className="w-full flex flex-row mb-16">
				<div className="flex w-[50%] flex-col">
					<div className="text-sm font-bold text-gray-800 w-full">{ts("filteredProspects")}</div>
					<Chart
						height={360}
						options={{
							noData: {
								text: "No data found",
							},
							chart: {
								offsetY: 0,
								type: "donut",
								redrawOnParentResize: true,
							},
							plotOptions: {
								radialBar: {
									startAngle: -90,
									endAngle: 90,
									track: {
										background: "#e7e7e7",
										strokeWidth: "97%",
										margin: 5, // margin is in pixels
										dropShadow: {
											enabled: true,
											top: 2,
											left: 0,
											color: "#999",
											opacity: 1,
											blur: 2,
										},
									},
									dataLabels: {
										name: {
											show: false,
										},
										value: {
											offsetY: -2,
											fontSize: "22px",
										},
									},
								},
							},
							legend: {
								show: true,
							},
							stroke: {
								width: 0,
							},
							labels: [ts("used"), ts("notUsed")],
							colors: ["#1D3DF2", "#E5E7EB"],
							dataLabels: {
								enabled: true,
							},
						}}
						series={[
							valuesOfCurrentMonth?.filtered ?? 0,
							(data?.limits.filtered ?? 0) - (valuesOfCurrentMonth?.filtered ?? 0),
						]}
						type="donut"
						width="80%"
					/>
				</div>

				<div className="flex w-[50%] flex-col">
					<div className="text-sm font-bold text-gray-800 w-full">{ts("identifiedProspects")}</div>
					<Chart
						height={360}
						options={{
							noData: {
								text: "No data found",
							},
							chart: {
								offsetY: 0,
								type: "donut",
								redrawOnParentResize: true,
							},
							plotOptions: {
								radialBar: {
									startAngle: -90,
									endAngle: 90,
									track: {
										background: "#e7e7e7",
										strokeWidth: "97%",
										margin: 5, // margin is in pixels
										dropShadow: {
											enabled: true,
											top: 2,
											left: 0,
											color: "#999",
											opacity: 1,
											blur: 2,
										},
									},
									dataLabels: {
										name: {
											show: false,
										},
										value: {
											offsetY: -2,
											fontSize: "22px",
										},
									},
								},
							},
							legend: {
								show: true,
							},
							stroke: {
								width: 0,
							},
							labels: [ts("used"), ts("notUsed")],
							colors: ["#1D3DF2", "#E5E7EB"],
							dataLabels: {
								enabled: true,
							},
						}}
						series={[
							valuesOfCurrentMonth?.identified ?? 0,
							(data?.limits.identified ?? 0) - (valuesOfCurrentMonth?.identified ?? 0),
						]}
						type="donut"
						width="80%"
					/>
				</div>
			</div>
			<div className="w-full flex flex-row mb-16">
				<div className="flex w-[50%] flex-col">
					<div className="text-sm font-bold text-gray-800 w-full">{ts("aiInputTokens")}</div>
					<Chart
						height={360}
						options={{
							noData: {
								text: "No data found",
							},
							chart: {
								offsetY: 0,
								type: "donut",
								redrawOnParentResize: true,
							},
							plotOptions: {
								radialBar: {
									startAngle: -90,
									endAngle: 90,
									track: {
										background: "#e7e7e7",
										strokeWidth: "97%",
										margin: 5, // margin is in pixels
										dropShadow: {
											enabled: true,
											top: 2,
											left: 0,
											color: "#999",
											opacity: 1,
											blur: 2,
										},
									},
									dataLabels: {
										name: {
											show: false,
										},
										value: {
											offsetY: -2,
											fontSize: "22px",
										},
									},
								},
							},
							legend: {
								show: true,
							},
							stroke: {
								width: 0,
							},
							labels: [ts("used"), ts("notUsed")],
							colors: ["#1D3DF2", "#E5E7EB"],
							dataLabels: {
								enabled: true,
							},
						}}
						series={[
							aiValuesOfCurrentMonth?.inputTokens ?? 0,
							(data?.limits.inputTokens ?? 0) - (aiValuesOfCurrentMonth?.inputTokens ?? 0) < 0
								? 17
								: (data?.limits.inputTokens ?? 0) - (aiValuesOfCurrentMonth?.inputTokens ?? 0),
						]}
						type="donut"
						width="80%"
					/>
				</div>
				<div className="flex w-[50%] flex-col">
					<div className="text-sm font-bold text-gray-800 w-full">{ts("aiOutputTokens")}</div>
					<Chart
						height={360}
						options={{
							noData: {
								text: "No data found",
							},
							chart: {
								offsetY: 0,
								type: "donut",
								redrawOnParentResize: true,
							},
							plotOptions: {
								radialBar: {
									startAngle: -90,
									endAngle: 90,
									track: {
										background: "#e7e7e7",
										strokeWidth: "97%",
										margin: 5, // margin is in pixels
										dropShadow: {
											enabled: true,
											top: 2,
											left: 0,
											color: "#999",
											opacity: 1,
											blur: 2,
										},
									},
									dataLabels: {
										name: {
											show: false,
										},
										value: {
											offsetY: -2,
											fontSize: "22px",
										},
									},
								},
							},
							legend: {
								show: true,
							},
							stroke: {
								width: 0,
							},
							labels: [ts("used"), ts("notUsed")],
							colors: ["#1D3DF2", "#E5E7EB"],
							dataLabels: {
								enabled: true,
							},
						}}
						series={[
							aiValuesOfCurrentMonth?.outputTokens ?? 0,
							(data?.limits.outputTokens ?? 0) - (aiValuesOfCurrentMonth?.outputTokens ?? 0) < 0
								? 17
								: (data?.limits.outputTokens ?? 0) - (aiValuesOfCurrentMonth?.outputTokens ?? 0),
						]}
						type="donut"
						width="80%"
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data?.usage || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data?.usage.length ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
		</div>
	);
};
