import { ChangeEvent, FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { useParams } from "react-router-dom";

import { SearchPeople } from "../SearchPeople/SearchPeople";
import { SearchOrganization } from "../SearchOrganization/SearchOrganization";

enum Tabs {
	PEOPLE = "people",
	COMPANY = "organization",
}

export const Search: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`prospectSearch.${key}`);
	const { type = Tabs.PEOPLE } = useParams();

	const [currentTab, setCurrentTab] = useState<Tabs>(type as Tabs);

	const tabs = [
		{ label: ts("tabs.people"), value: Tabs.PEOPLE },
		{ label: ts("tabs.companies"), value: Tabs.COMPANY },
	];

	const handleTabsChange = useCallback((_: ChangeEvent<{}>, value: Tabs): void => {
		setCurrentTab(value);
	}, []);

	return (
		<>
			<TabContext value={currentTab}>
				<TabList aria-label="Account tabs" sx={{ mb: 4 }} onChange={handleTabsChange}>
					{tabs.map((tab, index) => (
						<Tab key={index} label={tab.label} value={tab.value} />
					))}
				</TabList>

				<TabPanel sx={{ p: 0 }} value={Tabs.PEOPLE}>
					<Stack spacing={4}>
						<SearchPeople />
					</Stack>
				</TabPanel>

				<TabPanel sx={{ p: 0 }} value={Tabs.COMPANY}>
					<Stack spacing={4}>
						<SearchOrganization />
					</Stack>
				</TabPanel>
			</TabContext>
		</>
	);
};
