import { FunctionComponent, useEffect, useState } from "react";
import {
	DndContext,
	DragOverlay,
	PointerSensor,
	useSensor,
	useSensors,
	DragStartEvent,
	DragEndEvent,
	TouchSensor,
	closestCenter,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";

import Item from "./Item";
import SortableItem from "./SortableItem";

export type TItem = {
	id: number;
	columnId?: number;
	content: string;
	locked: boolean;
	show?: boolean;
	uid: string;
	custom: boolean;
	allowExclude: boolean;
	hideable?: boolean;
	moveable?: boolean;
};

export interface SortableListProps {
	defaultItems: TItem[];
	handleShowToggle?: (uid: string) => void;
	handleSwitchColumns?: (uid: string, uid2: string) => void;
	handleDelete?: (uid: string) => void;
	handleEdit?: (uid: string) => void;
}

export const SortableList: FunctionComponent<SortableListProps> = ({
	defaultItems,
	handleShowToggle,
	handleSwitchColumns,
	handleDelete,
	handleEdit,
}) => {
	const [items, setItems] = useState<TItem[]>(defaultItems);

	useEffect(() => {
		setItems(defaultItems);
	}, [defaultItems]);

	// for drag overlay
	const [activeItem, setActiveItem] = useState<TItem>();

	// for input methods detection
	const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

	// triggered when dragging starts
	const handleDragStart = (event: DragStartEvent) => {
		const { active } = event;

		setActiveItem(items.find((item) => item.id === active.id));
	};

	// triggered when dragging ends
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (!over) {
			return;
		}

		const activeItem = items.find((item) => item.id === active.id);
		const overItem = items.find((item) => item.id === over.id);

		if (!activeItem || !overItem) {
			return;
		}

		setItems(arrayMove(items, items.indexOf(activeItem), items.indexOf(overItem)));

		handleSwitchColumns && handleSwitchColumns(activeItem.uid, overItem.uid);
	};

	const handleDragCancel = () => {
		setActiveItem(undefined);
	};

	return (
		<DndContext
			collisionDetection={closestCenter}
			sensors={sensors}
			onDragCancel={handleDragCancel}
			onDragEnd={handleDragEnd}
			onDragStart={handleDragStart}
		>
			<SortableContext items={items} strategy={rectSortingStrategy}>
				<div className="flex flex-col">
					{items.map((item) => (
						<SortableItem
							key={item.id}
							handleDelete={handleDelete}
							handleEdit={handleEdit}
							handleShowToggle={handleShowToggle}
							item={item}
						/>
					))}
				</div>
			</SortableContext>
			<DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
				{activeItem ? (
					<Item
						isDragging
						handleDelete={handleDelete}
						handleEdit={handleEdit}
						handleShowToggle={handleShowToggle}
						item={activeItem}
					/>
				) : null}
			</DragOverlay>
		</DndContext>
	);
};
