import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { PermissionCheck } from "@/components";

import { PermissionRoles } from "@/enum";

import { ButtonColor, ButtonSize } from "@/components/Button/types";
import { Button } from "@/components/Button/Button";

import { BlacklistTable } from "../../components/BlacklistTable";
import { CreateBlacklistModal } from "../../components/CreateBlacklistModal";
import { UploadBlacklistModal } from "../../components/UploadBlacklistModal";

export interface BlacklistOverviewProps {
	isGlobal: boolean;
}

export const BlacklistOverview: FunctionComponent<BlacklistOverviewProps> = ({ isGlobal }) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`blacklist.${key}`);
	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);
	const [openBulkUpload, setOpenBulkUpload] = useState<boolean>(false);

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<div className="flex items-end justify-end w-full mb-8">
				<div className="flex justify-end">
					<PermissionCheck requiredPermissions={[PermissionRoles.BLACKLIST]}>
						<Button
							className="mr-4"
							color={ButtonColor.ACTION_SECONDARY}
							size={ButtonSize.S}
							testId="new-user-button"
							title={ts("create.upload")}
							onClick={() => setOpenBulkUpload(true)}
						/>
					</PermissionCheck>
					<PermissionCheck requiredPermissions={[PermissionRoles.BLACKLIST]}>
						<Button
							color={ButtonColor.ACTION}
							size={ButtonSize.S}
							testId="new-user-button"
							title={ts("create.button")}
							onClick={() => setIsInviteModalOpen(true)}
						/>
					</PermissionCheck>
				</div>
			</div>

			{<BlacklistTable isGlobal={isGlobal} />}
			{openBulkUpload && (
				<UploadBlacklistModal
					isGlobal={isGlobal}
					isOpen={openBulkUpload}
					setIsOpen={setOpenBulkUpload}
				/>
			)}

			{isInviteModalOpen && (
				<CreateBlacklistModal
					isGlobal={isGlobal}
					isOpen={isInviteModalOpen}
					setIsOpen={setIsInviteModalOpen}
				/>
			)}
		</div>
	);
};
