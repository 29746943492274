import { z } from "zod";

import { JobsScrapeStatus } from "@/enum/scrape-enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { CustomEventType, EventType } from "@/enum/custom-event.enum";

import { ListStatus } from "@/enum/list.enum";
import { CompanySchema } from "@/pages/Public/pages/Login/schema/login";

import { CsvEnrichmentSchema } from "../../CsvEnrichment/schema/csvEnrichment";

const { REQUIRED } = ERROR_TYPE;

export const CustomEventSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string(),
	status: z.nativeEnum(JobsScrapeStatus),
	activeStatus: z.nativeEnum(ListStatus),
	type: z.nativeEnum(CustomEventType).optional(),
	eventType: z.nativeEnum(EventType),
	eventAt: z.string().transform((arg) => new Date(arg)),
	listProvidedAt: z
		.string()
		.transform((arg) => new Date(arg))
		.optional(),
	listProvidedUntil: z.string().transform((arg) => new Date(arg)),
	loginNotes: z.string().optional(),
	credentialsSaved: z.boolean().optional(),
	company: CompanySchema.optional(),
	csvEnrichments: z.array(CsvEnrichmentSchema),
});

export const CreateCustomEventSchema = z.object({
	id: z.number().optional(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string(),
	eventType: z.nativeEnum(EventType),
	eventAt: z.date(),
	listProvidedUntil: z.date(),
	loginNotes: z.string().optional(),
	username: z.string().optional(),
	password: z.string().optional(),
	otpSecret: z.string().optional(),
	listId: z.number().optional(),
});

export const CustomEventResponseSchema = z.object({
	data: z.array(CustomEventSchema),
	meta: MetaDataSchema,
});

export type CustomEvent = z.infer<typeof CustomEventSchema>;

export type CreateCustomEvent = z.infer<typeof CreateCustomEventSchema>;

export type CustomEventResponse = z.infer<typeof CustomEventResponseSchema>;
