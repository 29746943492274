import { createSlice } from "@reduxjs/toolkit";

import { MapState } from "@/types/map";

import { RootState } from "@/redux";

import { mapApi } from "./map.api";

const initialState: MapState = {
	maps: {
		data: [],
		meta: {
			page: 1,
			limit: 15,
			itemCount: 15,
			pageCount: 1,
			hasPreviousPage: false,
			hasNextPage: false,
		},
	},
};

export const mapSlice = createSlice({
	name: "map",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(mapApi.endpoints.getMaps.matchFulfilled, (state, { payload }) => {
			state.maps = payload;
		});
		builder.addMatcher(mapApi.endpoints.toggleMap.matchFulfilled, (state, { payload }) => {
			if (!payload.id) {
				return;
			}

			const index = state.maps.data.findIndex((list) => list.id === payload.id);

			if (index === -1) {
				return;
			}

			if (payload.id) {
				state.maps.data[index] = {
					...state.maps.data[index],
					...payload,
				};
			}
		});
	},
});

export const {} = mapSlice.actions;
export const mapReducer = mapSlice.reducer;
export const mapSelector = (state: RootState) => state[mapSlice.name];
