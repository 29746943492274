import { FunctionComponent, useEffect, useState } from "react";

import { Autocomplete, TextField } from "@mui/material";

import { useLazyGetListLeadFilterQuery } from "@/pages/Private/redux/list/list.api";

import { ScoreFilterSource } from "./EditScoreDetail";

interface ProspectSearchAutocompleteProps {
	value?: ScoreFilterSource[];
	handleValueChange: (value: ScoreFilterSource[]) => void;
}

export const ProspectSearchAutocomplete: FunctionComponent<ProspectSearchAutocompleteProps> = ({
	value,
	handleValueChange,
}) => {
	const [inputValue, setInputValue] = useState<string>();
	const [options, setOptions] = useState<ScoreFilterSource[]>([]);
	const [defaults, setDefaults] = useState<ScoreFilterSource[]>([]);
	const [getLeadFilters] = useLazyGetListLeadFilterQuery();

	useEffect(() => {
		if (value) {
			setDefaults(value);
		}
	}, [value]);

	useEffect(() => {
		const setMyOptions = async (inputValue: string) => {
			if (inputValue) {
				const results = await getLeadFilters({
					limit: 100,
					page: 1,
					searchValue: inputValue,
				}).unwrap();
				const optionValues = results?.data;

				optionValues && setOptions(optionValues);
			}
		};

		if (inputValue) {
			setMyOptions(inputValue);
		}
	}, [inputValue, getLeadFilters]);

	return (value && defaults) || !value ? (
		<>
			<Autocomplete
				multiple
				defaultValue={[]}
				getOptionLabel={(option) => option.name}
				options={options}
				renderInput={(params) => <TextField {...params} label={"Lead Filter"} />}
				size="small"
				sx={{
					width: 450,
				}}
				value={defaults}
				onChange={(event, value) => {
					handleValueChange(value);
				}}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
			/>
		</>
	) : (
		<></>
	);
};
