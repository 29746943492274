import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { HTMLAttributes } from "react";

import Item from "./Item";
import { TItem } from "./SortableList";

type Props = {
	item: TItem;
	handleShowToggle?: (uid: string) => void;
	handleEdit?: (uid: string) => void;
	handleDelete?: (uid: string) => void;
} & HTMLAttributes<HTMLDivElement>;

const SortableItem = ({ item, handleShowToggle, handleEdit, handleDelete, ...props }: Props) => {
	const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
		id: item.id,
	});

	const styles = {
		transform: CSS.Transform.toString(transform),
		transition: transition || undefined,
	};

	const newListeners = item.locked ? {} : listeners;

	return (
		<Item
			ref={setNodeRef}
			isOpacityEnabled={isDragging}
			item={item}
			style={styles}
			{...props}
			{...attributes}
			{...newListeners}
			handleDelete={handleDelete}
			handleEdit={handleEdit}
			handleShowToggle={handleShowToggle}
		/>
	);
};

export default SortableItem;
