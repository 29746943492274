import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { LiPostOverview } from "./pages/LiPostOverview/LiPostOverview";
import { LiPostDetail } from "./pages/LiPostDetail/LiPostDetail";

export const LiPost: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<LiPostOverview />} path="/" />
			<Route element={<LiPostDetail />} path="/:id" />
		</Routes>
	);
};
