import { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MenuItem, Stack, TextField } from "@mui/material";

import {
	useAssignUserRolesMutation,
	useGetRolesQuery,
	useUnAssignUserRolesMutation,
	useUpdateUserMutation,
} from "@/pages/Private/redux/admin/admin.api";
import { Loader, Modal, ChipsList } from "@/components";
import {
	ERROR_TYPE,
	getAllUserRoles,
	getUserRolesDiff,
	getAllErrors,
	renderErrorMessages,
} from "@/utils";
import { User } from "@/pages/Public/pages/Login/schema/login";
import { DEFAULT } from "@/constants";

import { UpdateUser, UpdateUserForm, UpdateUserFormSchema } from "../schema/update-user";

interface EditUserModalProps {
	user: User | null;
	isVisible: boolean;
	handleClose: () => void;
	companyId?: number;
}

export const EditUserModal: FunctionComponent<EditUserModalProps> = ({
	user,
	isVisible,
	handleClose,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`modals.editUser.${key}`);

	const [selectedUser, setSelectedUser] = useState<User | null>(user);

	const { data: roles } = useGetRolesQuery();
	const [updateUser, { isLoading, error }] = useUpdateUserMutation();
	const [assignRole, { isLoading: isAssignRoleLoading }] = useAssignUserRolesMutation();
	const [unAssignRole, { isLoading: isUnassignRoleLoading }] = useUnAssignUserRolesMutation();

	const initialValues: UpdateUserForm = {
		firstName: selectedUser?.firstName ?? "",
		lastName: selectedUser?.lastName ?? "",
		email: selectedUser?.email ?? "",
		roles: getAllUserRoles(selectedUser?.roles || []) ?? [],
	};

	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<UpdateUserForm>({
		defaultValues: initialValues,
		resolver: zodResolver(UpdateUserFormSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const {
		fields: userRoles,
		remove,
		append,
	} = useFieldArray({
		control,
		name: "roles",
	});

	const onSubmit = async (values: UpdateUserForm) => {
		const body = {} as UpdateUser;
		const { roles: selectedRoles, ...info } = values;

		for (const key in info) {
			const initialValue = initialValues[key as keyof UpdateUser];
			const currentValue = values[key as keyof UpdateUser];

			if (currentValue !== initialValue) {
				body[key as keyof UpdateUser] = currentValue;
			}
		}

		try {
			if (Object.entries(body).length > 0) {
				await updateUser({ id: selectedUser?.id ?? 0, body });
			}

			if (selectedUser && roles) {
				const { addedRoleIds, removedRoleIds } = getUserRolesDiff(
					selectedUser.roles.map((role) => role.code),
					selectedRoles?.map((role) => `${role.identifier}`) ?? [],
					roles.data
				);

				if (addedRoleIds.length) {
					await assignRole({
						id: selectedUser.id,
						body: { roles: addedRoleIds },
					}).unwrap();
				}

				if (removedRoleIds.length) {
					await unAssignRole({
						id: selectedUser.id,
						body: { roles: removedRoleIds },
					}).unwrap();
				}
			}

			handleClose();
		} catch (err) {
			console.error(err);
		}
	};

	const handleUnselectRole = (code: string) => {
		const indexOfRemovedRole = userRoles.findIndex((role) => role.identifier === code);

		remove(indexOfRemovedRole);
	};

	const handleUserRoleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const selectedRole = roles?.data.find((role) => role.code === event.target.value);

		if (selectedRole) {
			append(getAllUserRoles([selectedRole]));
		}
	};

	const rolesOptions = useMemo(() => {
		return (roles?.data ?? []).filter(
			(role) => !userRoles.some((userRole) => userRole.identifier === role.code)
		);
	}, [roles?.data, userRoles]);

	useEffect(() => {
		if (user) {
			setSelectedUser(user);
		}
	}, [user]);

	return (
		<Modal
			handleClose={handleClose}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading || isAssignRoleLoading || isUnassignRoleLoading}
			isOpened={isVisible}
			title={ts("title")}
		>
			{!roles ? (
				<Loader />
			) : (
				<>
					<Stack spacing={2}>
						<TextField
							error={!!errors.email?.message}
							inputProps={{ readOnly: true }}
							label={t("basics.email")}
							{...register("email")}
						/>

						<TextField
							error={!!errors.firstName?.message}
							label={t("basics.firstName")}
							{...register("firstName")}
						/>

						<TextField
							error={!!errors.lastName?.message}
							label={t("basics.lastName")}
							{...register("lastName")}
						/>

						<TextField
							select
							error={!!errors.roles?.message}
							label={t("basics.role")}
							value={DEFAULT}
							onChange={handleUserRoleChange}
						>
							<MenuItem disabled value={DEFAULT}>
								{ts("rolePlaceholder")}
							</MenuItem>
							{rolesOptions.map((role) => (
								<MenuItem key={role.id} value={role.code}>
									{role.name}
								</MenuItem>
							))}
						</TextField>

						<ChipsList handleDelete={handleUnselectRole} selectedItems={userRoles} />

						{getAllErrors(error, formErrors).length
							? renderErrorMessages(getAllErrors(error, formErrors))
							: null}
					</Stack>
				</>
			)}
		</Modal>
	);
};
